import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

type Props = { onChange: (e: any) => void; style?: object; value: string };

const ControlledSearchInput: React.FunctionComponent<Props> = ({ onChange, value = '', style = {} }) => {
    return (
        <div style={{ width: '100%', ...style }}>
            <FormControl variant="outlined" size="small" fullWidth>
                {/* <InputLabel style={{ width: '100%' }}>חיפוש חופשי</InputLabel> */}
                <OutlinedInput
                    fullWidth
                    style={{ width: '100%', backgroundColor: 'white' }}
                    id="outlined-free-search"
                    type="text"
                    onChange={onChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    placeholder="חיפוש חופשי"
                    value={value}
                />
            </FormControl>
        </div>
    );
};

export default ControlledSearchInput;

import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setIsExportingToPdf, summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import { Props } from 'google-map-react';
import RouteElementPDF, { A4PageDiv, WaypointsNotInRoutePDFElement } from './Page';
import { useReactToPrint } from 'react-to-print';
import DataDisplay from '../DataDisplay';
import { allRoutesDataSelector, simulationResultSelector } from 'src/store/slices/optimizationsSlice';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { YitLogo } from 'src/components/InfoFooter';
import { cs } from 'src/styles/commonStyles';
import { stringifyAddressV2 } from 'src/utils/utilis';
import { IOptimumRouteData, IOptimumWaypoint } from 'src/types/optimization/types';
import { useRoutesMapPolylines } from '../Map/useRoutesMapPolylines';
import { usePdfStationsPassengersCount } from './usePdfStationsPassengersCount';

const TargetText: React.FC<{ targetWaypoint: IOptimumWaypoint | undefined }> = ({ targetWaypoint }) => {
    return targetWaypoint ? (
        <div style={{ ...cs.flexCenter, paddingBottom: '16px' }}>
            <span style={{ fontWeight: 'bold', fontSize: '18px' }}>יעד: &nbsp;</span>
            <span style={{ fontSize: '18px' }}>{stringifyAddressV2(targetWaypoint)}</span>
        </div>
    ) : null;
};

const PDF: FC<Props> = ({}) => {
    const dispatch = useAppDispatch();
    const { stationsNotInRoutes, stationsPerRoute } = usePdfStationsPassengersCount();

    const allRoutesData = useAppSelector((state) => allRoutesDataSelector(state));
    const isExportingToPdf = useAppSelector((state) => summaryStageSliceSelector(state).isExportingToPdf);
    const simDescription =
        useAppSelector((state) => simulationResultSelector(state)?.requestBody.description) || '';

    const targetWaypoint = allRoutesData[0]?.optimumRoute?.at(-1);

    const pagesRefsList = useRef<React.MutableRefObject<HTMLDivElement>[]>([]);

    const { fetchPolylines, polylinesByRouteId } = useRoutesMapPolylines({
        selectedRoutes: allRoutesData,
        selectedRouteUuids: allRoutesData.map((r) => r.routeID),
        options: {
            withPolylinesByRouteId: true,
            skipEffectFetch: true,
        },
    });

    const printableDivRef = React.useRef<HTMLDivElement | null>(null);

    const dispatchAlert = useDispatchAlert();

    const handlePrint = useReactToPrint({
        content: () => printableDivRef.current,
        onAfterPrint: () => {
            dispatch(setIsExportingToPdf(false));
        },
        onPrintError: () => {
            dispatchAlert('error', 'משהו השתבש, ההדפסה נכשלה', true);
        },
    });

    useEffect(() => {
        if (!isExportingToPdf || !pagesRefsList.current) return;

        fetchPolylines()
            .then(() => {
                setTimeout(() => {
                    handlePrint();
                }, 0);
            })
            .catch((err) => {
                console.log('error', err);
                dispatchAlert('error', 'משהו השתבש, לא ניתן לטעון מסלולים להדפסה', true);
            });

        return () => {
            dispatch(setIsExportingToPdf(false));
        };
    }, [dispatch, isExportingToPdf]);

    return (
        <div style={{ display: 'none' }}>
            <div ref={printableDivRef} dir="rtl">
                <A4PageDiv style={{ minHeight: '270mm' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
                        <YitLogo style={{ height: '50px' }} />
                    </div>
                    <h1 style={{ textAlign: 'center', marginBottom: 0 }}>
                        סיכום מסלולים - {simDescription}{' '}
                    </h1>
                    {/* <h1 style={{ textAlign: 'center', marginBottom: 0, paddingBottom: '10px' }}>
                        {simDescription}
                    </h1> */}
                    <TargetText targetWaypoint={targetWaypoint} />
                    <DataDisplay isPDFOrigin />
                </A4PageDiv>
                {allRoutesData.map((route, i) => (
                    <RouteElementPDF
                        pageRef={pagesRefsList.current[i]}
                        key={`${i}`} // Make sure to have a unique key
                        route={route}
                        routesOverviewPath={polylinesByRouteId[route.routeID] || []}
                        stationsList={stationsPerRoute[route.routeID] || []}
                    />
                ))}
                <WaypointsNotInRoutePDFElement stationsList={stationsNotInRoutes} />
            </div>
        </div>
    );
};

export default PDF;

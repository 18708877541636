import React, { useEffect } from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import TextInput from './TextInput';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useAppDispatch } from 'src/store/hooks';

const Input = styled(MuiInput)`
    width: 42px;
`;

export interface SliderProps {
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSliderChange: (event: Event, newValue: number | number[], activeThumb: number) => void;
    handleSliderDragEnd?: React.ComponentProps<typeof Slider>['onChangeCommitted'];
    value: number;
    min: number;
    max: number;
    step: number;
    containerStyle?: {};
    label?: string;
    error?: boolean;
    parseToRTL?: boolean; // if true, should return value as rtl
    debouncedValidation?: {
        minRangeErrMsg: string;
        maxRangeErrMsg: string;
        onFailedMinValidation: () => void;
        onFailedMaxValidation: () => void;
    };
}

const toRTL = ({ min, max, ltrVal }: { min: number; max: number; ltrVal: number }) => max - ltrVal + min;

const NumberSlider: React.FC<SliderProps> = ({
    handleInputChange,
    debouncedValidation,
    parseToRTL = false,
    handleSliderChange,
    handleSliderDragEnd,
    value,
    min,
    max,
    step,
    containerStyle = {},
    label,
    error = false,
}) => {
    const dispatch = useAppDispatch();
    const dispatchAlert = useDispatchAlert();

    useEffect(() => {
        if (!debouncedValidation) return;

        const validate = () => {
            if (value < min) {
                debouncedValidation.onFailedMinValidation();
                dispatchAlert('error', debouncedValidation.minRangeErrMsg, true);
            }

            if (value > max) {
                debouncedValidation.onFailedMaxValidation();
                dispatchAlert('error', debouncedValidation.maxRangeErrMsg, true);
            }
        };

        const timeout = setTimeout(validate, 500);
        return () => clearTimeout(timeout);
    }, [value]);

    return (
        <Box style={containerStyle}>
            {label ? (
                <div style={{ fontSize: '14px', fontWeight: 400, color: '#242424' }}>{label}</div>
            ) : null}
            <div style={{ display: 'flex' }}>
                <div style={{ transform: 'scaleX(-1)', width: '100%' }}>
                    <Slider
                        value={typeof value === 'number' ? value : 0}
                        onChange={(e, valLTR, activeThumb) => {
                            if (parseToRTL && typeof valLTR === 'number') {
                                handleSliderChange(e, toRTL({ min, max, ltrVal: valLTR }), activeThumb);
                                return;
                            }
                            handleSliderChange(e, valLTR, activeThumb);
                        }}
                        aria-labelledby="input-slider"
                        max={max}
                        min={min}
                        onChangeCommitted={(e, val) => {
                            if (!handleSliderDragEnd) return;

                            if (parseToRTL && typeof val === 'number') {
                                handleSliderDragEnd(e, toRTL({ min, max, ltrVal: val }));
                                return;
                            }

                            handleSliderDragEnd(e, val);
                        }}
                        sx={{
                            color: '#424242',
                            '& .MuiSlider-thumb': {
                                color: '#020202',
                            },
                        }}
                    />
                </div>
                <div>
                    <TextInput
                        value={value}
                        size="small"
                        onChange={handleInputChange}
                        error={error}
                        inputProps={{ step, min, max, type: 'string', 'aria-labelledby': 'input-slider' }}
                        sx={{ width: '55px', marginRight: '15px' }}
                    />
                </div>
            </div>
        </Box>
    );
};
export default NumberSlider;

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Polyline } from '@react-google-maps/api';
import { Coords } from 'google-map-react';
import React from 'react';
import { uuid } from 'src/store/helpers';

export type PolylineConfig = {
    id: string;
    paths: Coords[];
    color: string;
    options?: Partial<google.maps.PolylineOptions>;
};

const PolylineRenderer: React.FC<{
    refs: React.MutableRefObject<
        {
            polyline: google.maps.Polyline;
            id: string;
        }[]
    >;
    polyline: PolylineConfig;
}> = ({ polyline, refs }) => {
    const [id] = React.useState(uuid());

    React.useEffect(() => {
        const refsCopy = refs.current;
        return () => {
            // setTimeout(() => {
            refsCopy.forEach((ref) => {
                if (ref.id === id) {
                    console.log('removing polyline', id);
                    ref.polyline.setMap(null);

                    // Remove the item from the array
                    refsCopy.splice(refsCopy.indexOf(ref), 1);

                    refs.current = refsCopy;
                }
            });
            // }, 0);
        };
    }, [id, refs]);

    return (
        <Polyline
            path={polyline.paths}
            options={{
                strokeColor: polyline.color,
                strokeOpacity: 1,
                strokeWeight: 6,
                ...(polyline.options || {}),
            }}
            onLoad={(loadedPolyline) => {
                refs.current.push({ polyline: loadedPolyline, id });
            }}
        />
    );
};

export const PolylinesListRenderer: React.FC<{
    polylines: {
        id: string;
        paths: Coords[];
        color: string;
        options?: Partial<google.maps.PolylineOptions>;
    }[];
}> = ({ polylines }) => {
    const polylinesRefs = React.useRef<{ polyline: google.maps.Polyline; id: string }[]>([]);
    // console.log('polylinesRefs', polylinesRefs.current);

    return (
        <>
            {polylines.map((polyline, i) => (
                <PolylineRenderer refs={polylinesRefs} polyline={polyline} key={i} />
            ))}
        </>
    );
};

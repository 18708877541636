import ReactDOMServer from 'react-dom/server';
import StationOrWayPointMarker from 'src/components/Icons/StationOrWayPointMarker';
import { IOptimizationWaypoint, IOptimumWaypoint, IOptimumRouteData } from 'src/types/optimization/types';
import { getRandomColor } from 'src/utils/utilis';
import * as React from 'react';

export const buildWaypointName = (waypoint: IOptimizationWaypoint) => {
    const fields = [waypoint.city, waypoint.street, waypoint.houseNum, waypoint.placeName];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    return relevantFields.join(', ');
};

export const buildWaypointNameForOptimizationResult = (waypoint: IOptimumWaypoint) => {
    const fields = [waypoint.city, waypoint.street, waypoint.houseNum];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    return relevantFields.join(', ');
};

export const buildRouteName = (route: IOptimumRouteData) => {
    const fields = [route.optimumRoute[0].city, route.optimumRoute[0].street, route.optimumRoute[0].houseNum];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    const routeLength = route.optimumRoute.length;
    const lastFields = [
        route.optimumRoute[routeLength - 1].city,
        route.optimumRoute[routeLength - 1].street,
        route.optimumRoute[routeLength - 1].houseNum,
    ];
    const lastRelevantFields = lastFields.filter(
        (field) => field !== '' && field !== null && field !== undefined
    );

    const firstWaypointName = relevantFields.join(', ');
    const lastWaypointName = lastRelevantFields.join(', ');

    const routeName = `${firstWaypointName} - ${lastWaypointName}`;

    return routeName;
};

export const updateWaypoints = (waypoints1: IOptimizationWaypoint[], waypoints2: IOptimizationWaypoint[]) => {
    const updatedWaypoints: IOptimizationWaypoint[] = [];
    const waypointDict: { [key: string]: IOptimizationWaypoint } = {};

    waypoints1.forEach((waypoint1) => {
        waypointDict[waypoint1.waypointId] = waypoint1;
    });

    waypoints2.forEach((waypoint2) => {
        if (waypointDict[waypoint2.waypointId]) {
            waypointDict[waypoint2.waypointId] = { ...waypointDict[waypoint2.waypointId], ...waypoint2 };
        } else {
            waypointDict[waypoint2.waypointId] = waypoint2;
        }
    });

    Object.values(waypointDict).forEach((waypoint) => {
        updatedWaypoints.push(waypoint);
    });

    return updatedWaypoints;
};

export function isAllObjectsContainsCoords(array: any[]) {
    for (let i = 0; i < array.length; i++) {
        const obj = array[i];
        if (
            obj === null ||
            // eslint-disable-next-line no-prototype-builtins
            !obj.hasOwnProperty('lat') ||
            // eslint-disable-next-line no-prototype-builtins
            !obj.hasOwnProperty('lng') ||
            !obj.lat ||
            !obj.lng ||
            (obj.lat === 0 && obj.lng === 0)
        ) {
            return false;
        }
    }
    return true;
}

export const getIconUrl = (
    index: number,
    isDestination?: boolean,
    isEdited?: boolean,
    isRegularMarker?: boolean,
    isSelected?: boolean,
    isTarget?: boolean
) => {
    if (isTarget) return '/targetMarker.svg';
    if (isSelected) return '/selectedMarker.svg';
    if (isRegularMarker) return '/regularMarker.svg';
    if (isEdited) return '/editedMarker.svg';
    if (isDestination) return '/destinationMarker.svg';

    // index 0 is the origin marker
    return `/marker${index}.svg`;
};

export const componentToString = (Component: () => JSX.Element) => {
    const startPath = 'data:image/svg+xml;charset=UTF-8,';
    const svgString = ReactDOMServer.renderToString(Component());

    // Use encodeURIComponent to safely encode the SVG string
    const encodedSvgString = encodeURIComponent(svgString);

    const result = startPath + encodedSvgString;

    return result;
};

export const getSvgUrl = (index: number, color: string) => {
    const startPath = 'data:image/svg+xml;charset=UTF-8,';
    const svgString = ReactDOMServer.renderToString(
        <StationOrWayPointMarker
            markerIndex={index}
            markerColor={color}
            textColor="white"
            isStationMarker={false}
        />
    );

    // Use encodeURIComponent to safely encode the SVG string
    const encodedSvgString = encodeURIComponent(svgString);

    const result = startPath + encodedSvgString;

    return result;
};

export const componentToUrl = (Component: () => JSX.Element) => {
    const startPath = 'data:image/svg+xml;charset=UTF-8,';
    const svgString = ReactDOMServer.renderToString(<Component />);

    // Use encodeURIComponent to safely encode the SVG string
    const encodedSvgString = encodeURIComponent(svgString);

    const result = startPath + encodedSvgString;

    return result;
};

import React, { FC } from 'react';
import NumberSlider from 'src/components/ParametersStage/NumberSlider';
import { IconButton } from '@mui/material';
import { carsStageSliceSelector, setCarInputsValues } from 'src/store/slices/carsStageSlice';
import { CarInputItem } from 'src/types/globalTypes';

import styled from 'styled-components';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { CarType } from '../types';
import { EqualizerParametersSliders } from './EqualizerParametersSliders';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import useOptimizationRunner from '../useOptimizationRunner';
import { isStringNumber } from 'src/store/helpers';
import { useCarInputsUtils } from 'src/hooks/useCarInputsUtils';

const C1 = styled.div<{ expanded: boolean }>`
    height: ${({ expanded }) => (expanded ? '120px' : '0px')};
    overflow: hidden;
    padding: ${({ expanded }) => (expanded ? '14px 14px 14px 0 ' : '0px')};
    border-bottom: 1px solid #e0e0e0;
`;

const VALUE_LIMITS = {
    pricePerHour: {
        min: 0,
        max: 500,
    },
    pricePerKm: {
        min: 0,
        max: 500,
    },
};

const CarInputAccordion: React.FC<{
    carInput: CarInputItem;
    setCarsInputValues: (newValues: CarType) => void;
    expanded: boolean;
    toggleExpanded: () => void;
}> = ({ carInput, expanded, toggleExpanded, setCarsInputValues }) => {
    const dispatch = useAppDispatch();

    return (
        <div style={{}}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 10px 0 0',
                    cursor: 'pointer',
                }}
                onClick={toggleExpanded}
            >
                <p>
                    {carInput.carDescription
                        ? `${carInput.carName} - ${carInput.carDescription}`
                        : carInput.carName}
                </p>
                <IconButton>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
            </div>
            {/* ------ PRICE PER HOUR --------- */}
            <C1 expanded={expanded}>
                <NumberSlider
                    handleInputChange={(event) => {
                        if (!isStringNumber(event.target.value)) return;

                        const { value } = event.target;

                        setCarsInputValues({
                            ...carInput,
                            pricePerHour: Number(value),
                        });
                    }}
                    handleSliderChange={(e, value) => {
                        if (typeof value === 'number') {
                            setCarsInputValues({
                                ...carInput,
                                pricePerHour: value,
                            });
                        }
                    }}
                    parseToRTL
                    value={carInput.pricePerHour}
                    min={VALUE_LIMITS.pricePerHour.min}
                    max={VALUE_LIMITS.pricePerHour.max}
                    step={1}
                    label="מחיר לשעה (₪)"
                    debouncedValidation={{
                        maxRangeErrMsg: `הערך המקסימלי הינו ${VALUE_LIMITS.pricePerHour.max}`,
                        minRangeErrMsg: `הערך המינימלי הינו ${VALUE_LIMITS.pricePerHour.min}`,
                        onFailedMaxValidation: () => {
                            setCarsInputValues({
                                ...carInput,
                                pricePerHour: VALUE_LIMITS.pricePerHour.max,
                            });
                        },
                        onFailedMinValidation: () => {
                            setCarsInputValues({
                                ...carInput,
                                pricePerHour: VALUE_LIMITS.pricePerHour.min,
                            });
                        },
                    }}
                />
                {/* ------ PRICE PER KM --------- */}
                <NumberSlider
                    handleInputChange={(event) => {
                        if (!isStringNumber(event.target.value)) return;

                        const { value } = event.target;

                        setCarsInputValues({
                            ...carInput,
                            pricePerKm: Number(value),
                        });
                    }}
                    handleSliderChange={(e, value) => {
                        if (typeof value === 'number') {
                            setCarsInputValues({
                                ...carInput,
                                pricePerKm: value,
                            });
                        }
                    }}
                    value={carInput.pricePerKm}
                    min={VALUE_LIMITS.pricePerKm.min}
                    max={VALUE_LIMITS.pricePerKm.max}
                    parseToRTL
                    step={1}
                    label="מחיר לק''מ (₪)"
                    debouncedValidation={{
                        maxRangeErrMsg: `הערך המקסימלי הינו ${VALUE_LIMITS.pricePerKm.max}`,
                        minRangeErrMsg: `הערך המינימלי הינו ${VALUE_LIMITS.pricePerKm.min}`,
                        onFailedMaxValidation: () => {
                            setCarsInputValues({
                                ...carInput,
                                pricePerKm: VALUE_LIMITS.pricePerKm.max,
                            });
                        },
                        onFailedMinValidation: () => {
                            setCarsInputValues({
                                ...carInput,
                                pricePerKm: VALUE_LIMITS.pricePerKm.min,
                            });
                        },
                    }}
                />
            </C1>
        </div>
    );
};

interface Props {}

const EqualizerBody: FC<Props> = ({}) => {
    const dispatch = useAppDispatch();
    const isFirstRender = useIsFirstRender();
    const { runOpt } = useOptimizationRunner();

    const carInputsValues = useAppSelector(
        (state) => carsStageSliceSelector(state).carsForPricingInputsItems
    );
    const carsInputsUtils = useCarInputsUtils();

    const [expandedCarInputs, setExpandedCarInputs] = React.useState<number[]>([]);

    React.useEffect(() => {
        if (isFirstRender) return;

        const timeout = setTimeout(() => {
            carsInputsUtils.handleCarInputsSave(carInputsValues);
            runOpt({ carsForPricing: carInputsValues });
        }, 500);

        return () => clearTimeout(timeout);
    }, [carInputsValues]);

    return (
        <div style={{ width: '100%' }}>
            <EqualizerParametersSliders />
            <>
                {carInputsValues.map((carInput, index) => {
                    return (
                        <CarInputAccordion
                            carInput={carInput}
                            expanded={expandedCarInputs.includes(index)}
                            key={carInput.inputId}
                            toggleExpanded={() => {
                                if (expandedCarInputs.includes(index)) {
                                    setExpandedCarInputs((prev) => prev.filter((i) => i !== index));
                                } else {
                                    setExpandedCarInputs((prev) => [...prev, index]);
                                }
                            }}
                            setCarsInputValues={(newValues) => {
                                const index = carInputsValues.findIndex(
                                    (item) => item.inputId === carInput.inputId
                                );
                                const updated = [...carInputsValues];
                                updated[index] = { inputId: carInput.inputId, ...newValues };
                                dispatch(setCarInputsValues(updated));
                            }}
                        />
                    );
                })}
            </>
        </div>
    );
};

export default EqualizerBody;

import React, { FC } from 'react';
import { Dialog, Button } from '@mui/material';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';

export const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    position: relative;
    border-radius: 9px;
    box-shadow: 0px 5px 12px rgba(217, 226, 233, 0.5);
    color: black;
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    ImgSrc: string;
    height?: number;
    width?: number;
}

export const DEFAULT_BODY_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    paddingRight: '11px',
} as const;

// Generic version of the delete modal component
const ImageModal: FC<Props> = ({ isOpen, ImgSrc, height, width, onClose }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '760px', // Set your width here
                    },
                },
            }}
        >
            <ModalContainer>
                <ModalBody>
                    <img src={ImgSrc} alt="img" />
                </ModalBody>
            </ModalContainer>
        </Dialog>
    );
};

const BodyContainer = styled.div<{}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
`;

export default ImageModal;

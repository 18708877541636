// import { TextField } from '@mui/material';
import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import styled, { css } from 'styled-components';
import { colors } from 'src/styles/defaultTheme';

export const StyledTextInput = styled.input<{
    error?: boolean;
    disabled?: boolean;
}>`
    margin: 0;
    padding: 1px;
    width: 100%;
    height: 36px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgb(120 120 120 / 48%);
    font-size: 1rem;
    text-indent: 10px;
    :focus {
        outline: none;
        border: 2px solid #1976d2;
        padding: 0;
    }
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            border: 1px solid rgb(120 120 120);
        `}
    ${({ error }) =>
        error
            ? css`
                  border: 1px solid ${colors.muiRed} !important;
                  padding: 1px;
                  :focus {
                      padding: 1px !important;
                  }
              `
            : ``}
`;

type HTMLInputProps = React.HTMLProps<HTMLInputElement>;

const BaseTextField = (props: {
    label?: string;
    inputProps: Omit<HTMLInputProps, 'type'> & {
        error?: boolean;
        type?: string;
    };
    containerStyle?: React.CSSProperties;
}) => {
    return (
        <div style={props.containerStyle || {}}>
            <LabelStyled disabled={props.inputProps.disabled}>{props.label}</LabelStyled>
            <StyledTextInput type="text" {...(props.inputProps as any)} error={props.inputProps.error} />
        </div>
    );
};

export default BaseTextField;

export const LabelStyled = styled.label<{
    disabled?: boolean;
}>`
    ${({ disabled }) => (disabled ? `opacity: 0.5` : '')}
`;

import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { t } from 'src/utils/lang';
import StationsList from './StationList/StationList';
import {
    optiSliceActions,
    stationsConfiStageSelector,
    stationsToWaypointsSelector,
} from 'src/store/slices/optimizationsSlice';

const getText = (selectedRoutesCount: number) => {
    if (selectedRoutesCount === 0) {
        return t.noStationsSelectedToDisplay;
    } else if (selectedRoutesCount === 1) {
        return 'נבחרה תחנה אחת להצגה במפה';
    } else {
        return `נבחרו ${selectedRoutesCount} תחנות להצגה במפה`;
    }
};

const StationsSection: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const selectedStationsIds = useAppSelector(
        (state) => stationsConfiStageSelector(state).selectedStationsIds
    );
    const selectAllStationsChecked = useAppSelector(
        (state) => stationsConfiStageSelector(state).selectAllStationsChecked
    );

    const stationsWithWaypoints = useAppSelector(stationsToWaypointsSelector);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 1 }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '6px 17px 6px 6px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="checkbox"
                        checked={selectAllStationsChecked}
                        onChange={() => {}}
                        onClick={() => {
                            if (selectAllStationsChecked) {
                                dispatch(optiSliceActions.unselectAllStations());
                            } else {
                                dispatch(optiSliceActions.selectAllStations());
                            }
                        }}
                    />
                    <p style={{ margin: '0 10px', fontSize: '0.9rem' }}>{t.selectAll}</p>
                </div>
                <div style={{ display: 'flex', gap: '30px' }}>
                    <p
                        style={{ fontSize: '0.9rem' }}
                    >{`${t.stationsList} (${stationsWithWaypoints.length})`}</p>
                    <p style={{ fontSize: '0.9rem' }}>{getText(selectedStationsIds.length)}</p>
                </div>
            </div>
            <StationsList
                stationsWithWaypoints={stationsWithWaypoints}
                selectedStationsIds={selectedStationsIds}
            />
        </div>
    );
};

export default StationsSection;

import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import OTPInput from 'react-otp-input';
import { LoginResponse, sendSms } from 'src/api/loginApi';
import BaseTextField from 'src/components/TextInput';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { ISODateString, isNumeric } from 'src/store/helpers';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setClientPhone, setTwoFactorToken } from 'src/store/slices/authSlice';
import styled from 'styled-components';
import { useSubmitLogin } from './useSubmitLogin';

export const mobilePhoneRegex = new RegExp(/^05[0-9]{1}(-)?\d{7}$/);

export const getExpirationDateByMinutes = (
    expirationByMinutes: LoginResponse['data']['expirationByMinutes']
): ISODateString | null => {
    const now = moment();

    const expirationDate = now.add(expirationByMinutes, 'minutes').toDate().toISOString();

    return expirationDate;
};

interface Props {}

export const texts = {
    login: 'התחברות',
    welcome: 'ברוכים הבאים למערכת תכנון תקציב נסיעות,',
    continue: 'המשך',
    phoneNumber: 'מספר טלפון',
    invalidPhone: 'טלפון לא תקין',
    emptyPhone: 'אנא הכנס מספר טלפון',
    toLogin: 'על מנת להתחבר אנא הכניסו את מספר הטלפון שלכם',
    phoneConfirmation: 'אימות טלפון',
    smsSent: 'קוד אימות חד פעמי נשלח למספר ',
    enterBelow: 'אנא הכנס אותו למטה',
    commonError: 'תקלה זמנית. לא ניתן לבצע חיבור. ניתן ליצור קשר עם התמיכה בטלפון 08-9464288',
    sendSmsPunishment: 'יותר מדי ניסיונות אימות, ניתן לנסות שוב בעוד 5 דקות',
};

const MOCK_PHONE = '92849032490283409283492340239048230984';

const LoginBox: FC<Props> = ({}) => {
    const [phoneInput, setPhoneInput] = React.useState('');
    const [code, setCode] = useState('');

    const dispatch = useAppDispatch();

    const isAuth = useAppSelector((state) => state.auth.isAuth);

    const [isLoading, setIsLoading] = useState(false);
    const [isSecondLoading, setIsSecondLoading] = useState(false);

    const [isSecondLogin, setIsSecondLogin] = useState(false);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const buttonRef = useRef<HTMLButtonElement>(null); // Specify the correct element type

    const dispatchAlert = useDispatchAlert();

    const { onLogin } = useSubmitLogin({ code, setIsSecondLoading });

    const focusButton = () => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    };

    const isMockPhone = false;

    const onSendSms = async () => {
        setIsLoading(true);

        try {
            const res = await sendSms({ $phoneNumber: phoneInput });

            if (res && res.data) {
                if (res.data.isSuccess) {
                    dispatch(setClientPhone(phoneInput));
                    dispatch(setTwoFactorToken(res.data.data.twoFactorToken));
                    setIsSecondLogin(true);
                } else if (res.data.errorCode === 1) {
                    // wrong parameter
                    dispatchAlert('error', 'פרמטר שגוי', true);
                } else if (res.data.errorCode === 2) {
                    // phone not recognized
                    dispatchAlert('error', 'לא זוהה מספר הטלפון', true);
                } else if (res.data.errorCode === 3) {
                    // interface not found or not active
                    dispatchAlert('error', 'ממשק לא נמצא או לא פעיל', true);
                } else if (res.data.errorCode === 6) {
                    // general error
                    dispatchAlert('error', texts.commonError, true);
                } else if (res.data.errorCode === 9) {
                    //  tried to login to many times in one minute
                    dispatchAlert('error', texts.sendSmsPunishment, true);
                }
            } else {
                dispatchAlert('error', texts.commonError, true);
            }
            setIsLoading(false);
        } catch (error) {
            dispatchAlert('error', texts.commonError, true);
            setIsLoading(false);

            console.log(error);
        }
    };

    const handleCodeChange = (otp: string) => {
        setCode(otp);
    };

    useEffect(() => {
        // event lister onKeyDown

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.keyCode === 13) {
                e.preventDefault();
                onSendSms();
            }
        };

        if (isMockPhone || (validationErrors.length === 0 && phoneInput.length === 10 && !isSecondLogin)) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (
                isMockPhone ||
                (validationErrors.length === 0 && phoneInput.length === 10 && !isSecondLogin)
            ) {
                document.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [phoneInput, isSecondLogin, isMockPhone]);

    useEffect(() => {
        // event lister onKeyDown

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.keyCode === 13) {
                e.preventDefault();
                onLogin();
            }
        };

        if (validationErrors.length === 0 && code.length === 6 && isSecondLogin && !isAuth) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (validationErrors.length === 0 && code.length === 6 && isSecondLogin && !isAuth) {
                document.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [code, isSecondLogin, isAuth]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        backgroundColor: '#fff',
                        width: '550px',
                        height: '450px',
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                    }}
                >
                    {!isSecondLogin ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                paddingBottom: '70px',
                                paddingTop: '70px',
                                paddingLeft: '50px',
                                paddingRight: '50px',
                                height: '100%',
                            }}
                        >
                            <TextStyled
                                style={{ fontSize: '34px', fontWeight: 'bold', marginBottom: '24px' }}
                            >
                                {texts.login}
                            </TextStyled>
                            <TextStyled style={{ fontSize: '20px', marginBottom: '5px' }}>
                                {texts.welcome}
                            </TextStyled>
                            <TextStyled style={{ fontSize: '20px', marginBottom: '55px' }}>
                                {texts.toLogin}
                            </TextStyled>
                            <div
                                style={{
                                    width: '97%',
                                    marginBottom: '40px',
                                    position: 'relative',
                                    right: -4,
                                }}
                            >
                                <BaseTextField
                                    // label={texts.activationCode}
                                    inputProps={{
                                        value: phoneInput,
                                        maxLength: 10,
                                        error: validationErrors.length > 0,
                                        placeholder: `${texts.phoneNumber}`,
                                        onChange: (e) => {
                                            if ('value' in e.target) {
                                                const val =
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    'value' in e.target ? (e.target.value as string) : '';
                                                if (!isNumeric(val) && val !== '') return;
                                                setPhoneInput(val);
                                                if (!val || val === '') {
                                                    setValidationErrors([texts.emptyPhone]);
                                                } else if (val.length < 10 || !mobilePhoneRegex.test(val)) {
                                                    setValidationErrors([texts.invalidPhone]);
                                                } else {
                                                    setValidationErrors([]);
                                                    focusButton();
                                                }
                                            } else {
                                                // setError('התקבלה שגיאה, אנא נסה שנית');
                                                dispatchAlert('error', 'התקבלה שגיאה, אנא נסה שנית', true);
                                            }
                                        },
                                        style: {
                                            padding: '0 6px 0 0 ',
                                        },
                                        autoFocus: true,
                                    }}
                                />
                                {validationErrors.length > 0 ? (
                                    validationErrors.map((err, index) => (
                                        <TextStyled key={index} style={{ color: 'red', paddingTop: '4px' }}>
                                            {err}
                                        </TextStyled>
                                    ))
                                ) : (
                                    <div style={{ height: '20px' }}></div>
                                )}
                            </div>
                            <LoadingButton
                                ref={buttonRef}
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#2196F3',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                }}
                                onClick={() => {
                                    onSendSms();
                                }}
                                loading={isLoading}
                                disabled={
                                    phoneInput !== MOCK_PHONE &&
                                    (isLoading || validationErrors.length > 0 || phoneInput === '')
                                }
                            >
                                {texts.continue}
                            </LoadingButton>
                            {/* <p
                                style={{
                                    color: 'red',
                                }}
                            >
                                {error}
                            </p> */}
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '70px',
                                height: '100%',
                            }}
                        >
                            <TextStyled
                                style={{
                                    fontSize: '34px',
                                    fontWeight: 'bold',
                                    marginBottom: '24px',
                                }}
                            >
                                {texts.phoneConfirmation}
                            </TextStyled>
                            <TextStyled style={{ fontSize: '20px', marginBottom: '5px' }}>
                                {texts.smsSent}
                                {phoneInput}
                            </TextStyled>
                            <TextStyled style={{ fontSize: '20px', marginBottom: '40px' }}>
                                {texts.enterBelow}
                            </TextStyled>
                            <OTPInput
                                value={code}
                                inputType="tel"
                                onChange={handleCodeChange}
                                numInputs={6}
                                shouldAutoFocus
                                containerStyle={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    gap: '16px',
                                    justifyContent: 'space-between',
                                    marginBottom: '20px',
                                }}
                                inputStyle={{
                                    width: '50px',
                                    height: '66px',
                                    fontSize: '18px',
                                    borderRadius: '8px',
                                    border: '1px solid #B4BEC9',
                                }}
                                renderInput={(props) => <input {...props} />}
                            />

                            <div
                                style={{
                                    fontWeight: 400,
                                    fontSize: '16px',
                                    color: '#494949',
                                    marginBottom: '20px',
                                }}
                            >
                                לא קיבלת קוד?{' '}
                                <span
                                    style={{
                                        color: '#2196F3',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    }}
                                    onClick={onSendSms}
                                >
                                    שלח שוב
                                </span>
                            </div>
                            <LoadingButton
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#2196F3',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                }}
                                onClick={() => {
                                    onLogin();
                                }}
                                loading={isSecondLoading}
                                disabled={code.length < 6}
                            >
                                {texts.continue}
                            </LoadingButton>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LoginBox;

const TextStyled = styled.p`
    margin: 0;
    padding: 0;
`;

/* eslint-disable no-debugger */
import { InfoWindow, Marker, OverlayView } from '@react-google-maps/api';
import React, { FC } from 'react';
import MapType2 from 'src/components/MapType2';
import { uuid } from 'src/store/helpers';
import { useAppSelector } from 'src/store/hooks';
import { ISimulationStationToWaypoint } from 'src/types/optimization/types';
import { Coords } from 'google-map-react';
import { componentToString } from 'src/components/stages/Summary/Map/helpers';
import { directionsApi } from 'src/api/directionsApi';
import {
    PolylineConfig,
    PolylinesListRenderer,
} from 'src/components/PolylinesRenderer/PolylinesListRenderer';
import { PersonIcon } from 'src/components/Icons/PersonIcon';
import { useStationsConfiMap } from './useStationsConfiMap';
import { DistanceMarker } from './DistanceMarker';
import { stationsConfiUtils } from '../../utils.stationsConfirmation';
import { useDirectionsErrorHandler } from './useDirectionsErrorHandler';
import { PassengerOverlay, StationHoverOverlay } from './Overlays';
import { logger } from 'src/api/loggerApi';

interface Props {
    selectedStations: ISimulationStationToWaypoint[];
    selectedStationsIds: string[];
}

const DEFAULT_MARKER_PROPS = {
    scaledSize: new window.google.maps.Size(30, 30),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(15, 25),
};

const MIN_ZOOM_TO_DISPLAY_POLYLINES = 13;
const MIN_ZOOM_TO_DISPLAY_DISTANCE_MARKERS = 14;
const MIN_ZOOM_TO_DISPLAY_PASSENGERS_MARKERS = 13;

const lineSymbol = {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    scale: 4,
};

const polylineOptions = {
    strokeOpacity: 0,
    icons: [
        {
            icon: lineSymbol,
            offset: '0',
            repeat: '20px',
        },
    ],
};

const StationsResultMap: FC<Props> = ({ selectedStations, selectedStationsIds }) => {
    const clientToken = useAppSelector((state) => state.auth.clientToken) || '';

    const mapConfig = useStationsConfiMap(selectedStations, selectedStationsIds, {});
    // console.log('mapConfig', mapConfig);
    // const selectedStations: any[] = generateDummyStations();
    // const selectedStationsIds = selectedStations.map((s) => s.stationId);
    const { handleDirectionsErr } = useDirectionsErrorHandler();

    const [distanceMarkers, setDistanceMarkers] = React.useState<
        {
            distance: number;
            coords: Coords;
        }[]
    >([]);

    const [polylines, setPolylines] = React.useState<PolylineConfig[]>([]);
    const [iconOffset, setIconOffset] = React.useState<number>(0);

    const [hoveredStation, setHoveredStation] = React.useState<string>('');
    const [hoveredPass, setHoveredPass] = React.useState<string>('');

    const fetchPolylinesAndDistanceData = async () => {
        if (!selectedStationsIds.length) {
            setDistanceMarkers([]);
            setPolylines([]);
            return;
        }

        try {
            const response = await directionsApi.getGoogleDirectionsByList({
                params: { clientToken },
                data: stationsConfiUtils.buildDirectionsApiPayloadData(selectedStations),
                mode: 1,
            });

            const newPolylines: typeof polylines = [];
            const newDistanceMarkers: typeof distanceMarkers = [];

            // building polylines and markers list
            response
                .filter((section) => section.isSuccess)
                .forEach((section) => {
                    newPolylines.push({
                        id: section.destinationId,
                        paths:
                            section.polyLine.length < 50
                                ? section.polyLine
                                : section.polyLine.filter((p, i) => {
                                      return i % 3 === 0;
                                  }), // for performance reasons
                        color:
                            selectedStations.find((s) => s.stationId === section.destinationId)?.color ||
                            '#aeaeae',
                        options: polylineOptions,
                    });

                    const distanceMarkerCoords = section.polyLine[0];

                    if (distanceMarkerCoords) {
                        const OFFSET = 0.00009; // to not hide the person marker
                        newDistanceMarkers.push({
                            distance: section.totalDistanceInRouteMeters,
                            coords: {
                                lat: distanceMarkerCoords.lat + OFFSET,
                                lng: distanceMarkerCoords.lng + OFFSET,
                            },
                        });
                    } else {
                        console.log('dont have distanceMarkerCoords');
                    }
                });

            setDistanceMarkers(newDistanceMarkers);
            setPolylines(newPolylines);
        } catch (error: any) {
            if (error.name === 'AxiosError') {
                logger({
                    type: 'apiReqError',
                    feature: 'Stations opti map - Error with getGoogleDirectionsByList request',
                    data: error,
                });
            }
            console.log(error);
            setDistanceMarkers([]);
            setPolylines([]);
            handleDirectionsErr(error);
        }
    };

    // ----------------------------- EFFECTS ---------------------------
    React.useEffect(() => {
        fetchPolylinesAndDistanceData();
    }, [selectedStationsIds]);

    // to avoid station icon collide with passenger icon

    React.useEffect(() => {
        switch (mapConfig.zoom) {
            case 14:
                setIconOffset(0.0025);
                break;
            case 15:
                setIconOffset(0.0012);
                break;
            case 16:
                setIconOffset(0.0007);
                break;
            case 17:
                setIconOffset(0.00032);
                break;
            case 18:
                setIconOffset(0.0002);
                break;
            case 19:
                setIconOffset(0.0001);
                break;
            case 20:
                setIconOffset(0.00005);
                break;
            case 21:
                setIconOffset(0.00003);
                break;
            case 22:
                setIconOffset(0.000012);
                break;
        }
    }, [mapConfig.zoom]);

    return (
        <>
            <div style={{ height: '100%', flexGrow: 1 }} ref={mapConfig.mapDivRef}>
                <MapType2 GoogleMapProps={mapConfig.GoogleMapProps}>
                    {/* {shouldDisplayPolylines && <PolylinesListRenderer polylines={dummyPolylines} />} */}

                    {mapConfig.zoom > MIN_ZOOM_TO_DISPLAY_POLYLINES && (
                        <PolylinesListRenderer polylines={polylines} />
                    )}
                    {selectedStations.map((s) => (
                        // station markers
                        <>
                            <Marker
                                icon={{ url: 'stationMarker.svg', ...DEFAULT_MARKER_PROPS }}
                                key={s.stationId}
                                position={{ lat: s.station.lat, lng: s.station.lng }}
                                onMouseOver={() => setHoveredStation(s.stationId)}
                                onMouseOut={() => setHoveredStation('')}
                            />
                            {hoveredStation === s.stationId && <StationHoverOverlay station={s} />}
                        </>
                    ))}
                    {mapConfig.zoom > MIN_ZOOM_TO_DISPLAY_PASSENGERS_MARKERS &&
                        selectedStations.map((s) => {
                            // passengers markers
                            return s.wayPoints.map((waypoint) => (
                                <>
                                    <Marker
                                        icon={{
                                            url: componentToString(() => <PersonIcon fill={s.color} />),
                                            ...DEFAULT_MARKER_PROPS,
                                        }}
                                        key={waypoint.waypointId}
                                        position={
                                            waypoint.lat === s.station.lat && waypoint.lng === s.station.lng
                                                ? { lat: waypoint.lat, lng: waypoint.lng + iconOffset }
                                                : { lat: waypoint.lat, lng: waypoint.lng }
                                        }
                                        onMouseOver={() => setHoveredPass(waypoint.waypointId)}
                                        onMouseOut={() => setHoveredPass('')}
                                    />
                                    {hoveredPass === waypoint.waypointId && (
                                        <PassengerOverlay passenger={waypoint} />
                                    )}
                                </>
                            ));
                        })}
                    {mapConfig.zoom > MIN_ZOOM_TO_DISPLAY_DISTANCE_MARKERS &&
                        distanceMarkers.map((dm) => (
                            <OverlayView
                                position={dm.coords}
                                key={JSON.stringify(dm.coords)}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <DistanceMarker distance={dm.distance} />
                            </OverlayView>
                        ))}
                    {/* {createRandomMarkers()} */}
                </MapType2>
            </div>
        </>
    );
};

export default StationsResultMap;

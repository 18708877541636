import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setEditPrevOptimizationAction } from 'src/store/slices/optimizationsSlice';
import { setStageAction } from 'src/store/slices/commonSlice';
import { Stages } from 'src/constants/common';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { BaseButtonIconStyled } from './ExportsBtns';
import { buildWaypointName } from '../stages/Summary/Map/helpers';
import { IOptimumWaypoint } from 'src/types/optimization/types';
import BaseTooltip from '../BaseTooltip';

export const EditOptiWaypointsBtn: React.FC<{}> = () => {
    const dispatch = useAppDispatch();
    const hashId = useAppSelector((state) => state.optimization.data.getSimulationResult.data?.hashID) || '';
    const wayPointsJsonForStations =
        useAppSelector(
            (state) => state.optimization.data.getSimulationResult.data?.requestBody.wayPointsJsonForStations
        ) || [];
    const wayPointsJson =
        useAppSelector(
            (state) => state.optimization.data.getSimulationResult.data?.requestBody.wayPointsJson
        ) || [];

    return (
        <div style={{ display: 'flex', gap: '16px' }}>
            <BaseTooltip title="עריכת הרצה" placement="top">
                <BaseButtonIconStyled
                    onClick={() => {
                        let waypointArr =
                            wayPointsJsonForStations.length >= wayPointsJson.length // because i assume the longer has the passengers
                                ? wayPointsJsonForStations
                                : wayPointsJson;

                        waypointArr = waypointArr.filter((w) => !w.isTarget);

                        dispatch(
                            setEditPrevOptimizationAction({
                                isOpen: true,
                                hashId,
                                fieldsValues: waypointArr,
                                inputsTextByWaypointId: waypointArr.reduce(
                                    (acc: Record<string, string>, curr) => {
                                        acc[curr.waypointId] = buildWaypointName(curr);
                                        return acc;
                                    },
                                    {}
                                ),
                            })
                        );
                        dispatch(setStageAction({ stage: Stages.DataLoading }));
                    }}
                >
                    <ModeEditOutlineOutlinedIcon htmlColor="white" />
                </BaseButtonIconStyled>
            </BaseTooltip>
        </div>
    );
};

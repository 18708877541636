import { InfoWindowF, Marker, OverlayView } from '@react-google-maps/api';
import React, { FC } from 'react';
import MapType2 from 'src/components/MapType2';
import { uuid } from 'src/store/helpers';
import { useAppSelector } from 'src/store/hooks';
import { IOptimumRouteData, IOptimumWaypoint } from 'src/types/optimization/types';
import { componentToString, getSvgUrl } from './helpers';
import { optimizationsDataSelector, summaryStageExtraSelector } from 'src/store/slices/optimizationsSlice';
import { useRoutesSummaryMap } from './useRoutesSummaryMap';
import { PolylinesListRenderer } from 'src/components/PolylinesRenderer/PolylinesListRenderer';
import { useRoutesMapPolylines } from './useRoutesMapPolylines';
import { BaseOverlayCont } from '../../StationConfirmation/components/Map/Overlays';
import StarsIcon from '@mui/icons-material/Stars';
import { getTimeBetweenLegsOverlays } from './utils.routesMap';
import { TimeFromPrev } from '../RoutesBoxsList/TimeFromPrev';
import PickupStarIcon from 'src/components/Icons/PickupStarIcon';
import { ActivatedDisplaysPicker } from './ActivatedDisplaysPicker';

interface Props {
    selectedRoutes: IOptimumRouteData[];
    selectedRouteUuids: string[];
}

const RoutesResultMap: FC<Props> = ({ selectedRoutes, selectedRouteUuids }) => {
    const [hoveredMarker, setHoveredMarker] = React.useState<string>('');
    const [activatedDisplays, setActivatedDisplays] = React.useState<string[]>(['timeFromPrev']);

    const didSelectUnusableWaypoints = useAppSelector(
        (state) => summaryStageExtraSelector(state).didSelectUnusableWaypoints
    );

    const routeType = useAppSelector((state) => state.optimization.ui.routeType);

    const wayPointNotInRoutes =
        useAppSelector(
            (state) => optimizationsDataSelector(state).getSimulationResult.data?.wayPointNotInRoutes
        ) || [];

    const { polylines } = useRoutesMapPolylines({ selectedRoutes, selectedRouteUuids, options: {} });

    const { GoogleMapProps, mapDivRef, map, zoom } = useRoutesSummaryMap(selectedRoutes, selectedRouteUuids);

    return (
        <>
            <div style={{ height: '85%', position: 'relative' }} ref={mapDivRef}>
                <ActivatedDisplaysPicker
                    setActivatedDisplays={setActivatedDisplays}
                    activatedDisplays={activatedDisplays}
                />
                <MapType2 GoogleMapProps={GoogleMapProps}>
                    {/* <RoutesRenderers rendererProps={rendererProps} /> */}
                    {<PolylinesListRenderer polylines={polylines} />}

                    {didSelectUnusableWaypoints &&
                        wayPointNotInRoutes.map((waypoint) => (
                            <Marker
                                icon={{
                                    url: 'unusedWaypoint.svg',
                                    scaledSize: new window.google.maps.Size(30, 30),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(15, 25),
                                }}
                                key={uuid()}
                                position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                onMouseOver={() => setHoveredMarker(waypoint.waypointId)}
                                onMouseOut={() => setHoveredMarker('')}
                            >
                                {hoveredMarker === waypoint.waypointId && (
                                    <OverlayView
                                        position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                        key={waypoint.waypointId}
                                    >
                                        <BaseOverlayCont>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                <span style={{ fontSize: '16px', fontWeight: 750 }}>
                                                    {' '}
                                                    תחנה:
                                                </span>
                                                <div>{waypoint.stationName}</div>
                                            </div>
                                        </BaseOverlayCont>
                                    </OverlayView>
                                )}
                            </Marker>
                        ))}

                    {/* {tov} */}
                    {zoom > 15 &&
                        activatedDisplays.includes('timeFromPrev') &&
                        selectedRoutes.map((route) => {
                            return route.optimumRoute.map((waypoint, i) => {
                                // eslint-disable-next-line no-debugger
                                // debugger;

                                const { routeDirection } = route;

                                if (!routeDirection.isSuccess) return null;

                                let coords = null;

                                if (waypoint.isTarget) {
                                    return;
                                    // const polyLineLen = routeDirection.polyLine.length;
                                    // const markerCoordsIndex = Math.ceil(polyLineLen / 2);

                                    // coords = routeDirection.polyLine[markerCoordsIndex];
                                } else {
                                    coords = {
                                        lat: waypoint.lat,
                                        lng: waypoint.lng,
                                    };
                                }

                                const timeInLegMin = routeDirection.legs[i - 1]?.timeInLegMin;

                                if (timeInLegMin === undefined) return;

                                return (
                                    <OverlayView
                                        position={coords}
                                        key={JSON.stringify(coords)}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    >
                                        <TimeFromPrev timeFromPrev={timeInLegMin} />
                                    </OverlayView>
                                );
                            });
                        })}

                    {selectedRoutes.map((route) => {
                        const routeLength = route.optimumRoute.length;

                        return route.optimumRoute.map((waypoint: IOptimumWaypoint, index: number) => (
                            <Marker
                                icon={{
                                    url: !waypoint.isTarget
                                        ? getSvgUrl(routeType === 'drop' ? index - 1 : index, route.color)
                                        : routeType === 'drop'
                                        ? componentToString(() => <PickupStarIcon />)
                                        : 'targetMarker.svg',
                                    scaledSize:
                                        routeLength - 1 !== index
                                            ? new window.google.maps.Size(30, 30)
                                            : new window.google.maps.Size(40, 40),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(15, 25),
                                }}
                                key={waypoint.waypointId}
                                position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                onMouseOver={() => setHoveredMarker(waypoint.waypointId)}
                                onMouseOut={() => setHoveredMarker('')}
                            >
                                {hoveredMarker === waypoint.waypointId && (
                                    <OverlayView
                                        position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                        key={waypoint.waypointId}
                                    >
                                        <BaseOverlayCont>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                <span style={{ fontSize: '16px', fontWeight: 750 }}>
                                                    {' '}
                                                    תחנה:
                                                </span>
                                                <div>{waypoint.stationName}</div>
                                            </div>
                                        </BaseOverlayCont>
                                    </OverlayView>
                                )}
                            </Marker>
                        ));
                    })}
                </MapType2>
            </div>
        </>
    );
};

/*

    const [showMap, setShowMap] = useState(true);

    React.useEffect(() => {
        if (showMap) return;
        const t = setTimeout(() => {
            setShowMap(true);
        }, 10);

        return () => clearTimeout(t);
    }, [showMap]);
*/

export default RoutesResultMap;

// type DirectionsRendererItemsRef = React.MutableRefObject<
//     {
//         renderer: google.maps.DirectionsRenderer;
//         id: string;
//     }[]
// >;

// export const DirectionsRendererComponent = ({
//     rendererProp,
//     refs,
// }: {
//     rendererProp: DirectionsRendererProps;
//     refs: DirectionsRendererItemsRef;
// }) => {
//     const [id] = useState(uuid());

//     const onLoad: (directionsRenderer: google.maps.DirectionsRenderer) => void = (directionsRenderer) => {
//         // Save the directionsRenderer instance in the ref
//         refs.current.push({ renderer: directionsRenderer, id });
//     };

//     useEffect(() => {
//         const refsCopy = refs.current;
//         return () => {
//             setTimeout(() => {
//                 refsCopy.forEach((ref) => {
//                     if (ref.id === id) {
//                         ref.renderer.setMap(null);

//                         // Remove the item from the array
//                         refsCopy.splice(refsCopy.indexOf(ref), 1);

//                         refs.current = refsCopy;
//                     }
//                 });
//             }, 0);
//         };
//     }, [id, refs]);

//     return <DirectionsRenderer {...rendererProp} onLoad={onLoad} />;
// };

// export const RoutesRenderers: React.FC<{ rendererProps: DirectionsRendererProps[] }> = ({
//     rendererProps,
// }) => {
//     const refs: DirectionsRendererItemsRef = useRef([]);

//     return (
//         <>
//             {rendererProps.map((rendererProp, i) => {
//                 return <DirectionsRendererComponent rendererProp={rendererProp} key={i} refs={refs} />;
//             })}
//         </>
//     );
// };

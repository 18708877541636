import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setOptiFlowType } from 'src/store/slices/optimizationsSlice';
import CreateCoursesSvg from 'src/components/Icons/CreateCoursesSvg';
import CreateStationsSvg from 'src/components/Icons/CreateStationsSvg';
import styled from 'styled-components';
import { OptiFlowTypes } from 'src/constants/common';
import { Tooltip } from '@mui/material';
import { tooltipStylesV2 } from '../BaseTooltip';

const explanationRowStyle = { fontSize: '20px', color: '#494949', fontWeight: 400, padding: 0, margin: 0 };

const Div1 = styled.div<{ isSelected: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 36px 36px 0 36px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    justify-content: space-between;
    align-items: space-between;
    :hover {
        cursor: pointer;
    }
    border: ${(props) => (props.isSelected ? '3px solid #2196F3' : '3px solid #cdcdcd')};
`;

const FlowTypeBtn: React.FC<{
    Image: () => JSX.Element;
    text: string;
    isSelected: boolean;
    onClick: () => void;
}> = ({ Image, text, isSelected, onClick }) => {
    return (
        <Div1 isSelected={isSelected} onClick={onClick}>
            <Image />
            <p
                style={{
                    textAlign: 'center',
                    fontSize: 24,
                    fontFamily: 'Rubik',
                    color: '#494949',
                    padding: '24px 0',
                    fontWeight: isSelected ? 600 : 400,
                }}
            >
                {text}
            </p>
        </Div1>
    );
};

const ChooseFlowTypeStage: React.FC = () => {
    const optiFlowType = useAppSelector((state) => state.optimization.ui.selectedOptiFlowType);
    const dispatch = useAppDispatch();

    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <div
                    style={{
                        fontSize: '36px',
                        color: '#494949',
                        fontWeight: 700,
                        marginTop: '40px',
                        marginBottom: '16px',
                    }}
                >
                    בחירת פעולה
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                        alignItems: 'center',
                    }}
                >
                    <p style={explanationRowStyle}>בחר האם אתה מעוניין לבצע אופטימיזציה של תחנות, ​</p>
                    <p style={explanationRowStyle}>או לדלג ליצירת מסלולי נסיעה ​</p>
                </div>

                <div style={{ display: 'flex', gap: '32px', marginTop: '36px' }}>
                    <Tooltip
                        PopperProps={{
                            sx: tooltipStylesV2,
                        }}
                        title={<>שיוך נוסעים לתחנה הקרובה לביתם ,ויצירת מסלול מתחנות</>}
                        arrow
                    >
                        <div>
                            <FlowTypeBtn
                                Image={CreateStationsSvg}
                                text={'איסוף מתחנות'}
                                isSelected={optiFlowType === OptiFlowTypes.WithStations}
                                onClick={() => {
                                    dispatch(setOptiFlowType(OptiFlowTypes.WithStations));
                                }}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        PopperProps={{
                            sx: tooltipStylesV2,
                        }}
                        title={<>בניית מסלולים על פי כתובת המגורים של הנוסע</>}
                        arrow
                    >
                        <div>
                            <FlowTypeBtn
                                Image={CreateCoursesSvg}
                                isSelected={optiFlowType === OptiFlowTypes.RoutesOnlyCreation}
                                onClick={() => {
                                    dispatch(setOptiFlowType(OptiFlowTypes.RoutesOnlyCreation));
                                }}
                                text={'איסוף מהבית'}
                            />
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};
export default ChooseFlowTypeStage;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

export function StationFlagIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g mask="url(#mask0_10168_139902)">
                <path
                    d="M6 2H7.42857V16.2857C7.42857 16.6802 7.10877 17 6.71429 17C6.3198 17 6 16.6802 6 16.2857V2Z"
                    fill="#494949"
                />
                <rect x="6" y="2" width="5.71429" height="7.14286" rx="2" fill="#494949" />
            </g>
        </svg>
    );
}

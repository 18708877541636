import React, { useEffect, useRef, useState } from 'react';
import styles from 'src/styles/mapStyles/styles';
import MapType2, { defaultCenter } from '../MapType2';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Coords } from 'google-map-react';
import AddressAutoComplete, { EMPTY_ADDRESS_DATA } from '../AddressAutoComplete';
import { AutocompleteAddress } from 'src/types/googleMapsTypes';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector, setTargetWaypointAction } from 'src/store/slices/optimizationsSlice';
import { buildWaypointName, uuid } from 'src/store/helpers';
import CheckIcon from '../Icons/CheckIcon';
import WrenchIcon from '../Icons/WrenchIcon';

const TargetSelection: React.FC = () => {
    const [zoom, setZoom] = useState<number>(8);
    const [center, setCenter] = useState<Coords>(defaultCenter);
    const [inputText, setInputText] = useState('');
    const [addressData, setAddressData] = useState<AutocompleteAddress>({ ...EMPTY_ADDRESS_DATA });

    const dispatch = useAppDispatch();
    const targetWaypoint = useAppSelector((state) => optimizationsRootSelector(state).ui.targetWaypoint);

    const GoogleMapProps: GoogleMap['props'] = {
        center,
        onZoomChanged(this: google.maps.Map) {
            try {
                setZoom(zoom);
            } catch (error) {
                console.log(error);
            }
        },
        options: {
            fullscreenControl: false,
        },
    };

    if (zoom) {
        try {
            // Is supposedly readonly, however the top-view button is not working without this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            GoogleMapProps.zoom = zoom;
        } catch (error) {
            console.log(error);
        }
    }

    const onSelection = (addressData: AutocompleteAddress) => {
        dispatch(
            setTargetWaypointAction({
                waypoint: {
                    ...addressData,
                    waypointId: uuid(),
                    isTarget: true,
                    firstName: '',
                    lastName: '',
                },
            })
        );
    };

    useEffect(() => {
        if (targetWaypoint) {
            setCenter({ lat: targetWaypoint.lat, lng: targetWaypoint.lng });
            setZoom(12);
        }
    }, [targetWaypoint]);

    useEffect(() => {
        if (targetWaypoint) {
            setInputText(buildWaypointName(targetWaypoint));
        }
    }, []);

    const myRef = useRef<HTMLInputElement | null>(null);
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '6rem',
                    height: '100%',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        height: '90%',
                        width: '30%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.3rem',
                        overflow: 'auto',
                    }}
                >
                    <div style={{ fontSize: '36px', fontWeight: 700, marginTop: '40px', color: '#494949' }}>
                        לאן נוסעים?
                    </div>
                    <div style={{ fontSize: '20px', fontWeight: 400, color: '#494949' }}>
                        אנא הכנס את כתובת היעד הרצויה
                    </div>
                    <div style={{ width: '100%', display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <AddressAutoComplete
                            currentAddress={addressData}
                            inputText={inputText}
                            setInputText={setInputText}
                            setAddressData={setAddressData}
                            onSelection={onSelection}
                            isInitialValue
                        />
                        {targetWaypoint ? (
                            <div>
                                <CheckIcon />
                            </div>
                        ) : (
                            <div style={{ height: '40px', width: '25px' }}></div>
                        )}
                    </div>
                </div>
                <div style={{ height: '80%', width: '40%' }}>
                    <styles.Container className="Container">
                        <styles.MapDiv className="MapDiv" ref={myRef} isOpen>
                            <MapType2 GoogleMapProps={GoogleMapProps}>
                                {targetWaypoint && (
                                    <Marker
                                        icon={{
                                            url: 'targetMarker.svg',
                                            scaledSize: new window.google.maps.Size(45, 45),
                                            origin: new window.google.maps.Point(0, 0),
                                            anchor: new window.google.maps.Point(15, 25),
                                        }}
                                        position={{ lat: targetWaypoint.lat, lng: targetWaypoint.lng }}
                                    />
                                )}
                            </MapType2>
                        </styles.MapDiv>
                    </styles.Container>
                </div>
            </div>
        </div>
    );
};
export default TargetSelection;

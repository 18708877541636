import { CarType } from 'src/components/stages/Summary/types';
import { AutocompleteAddress } from 'src/types/googleMapsTypes';
import { IOptimizationWaypoint, IOptimumRouteData, IOptimumWaypoint } from 'src/types/optimization/types';
import { v4 } from 'uuid';

export type ISODateString = string; // to avoid using unserializable Date object in redux store

const getAsArray = <T>(set: Iterable<T>) => new Array(set);

const getAsSet = <T>(arr: Iterable<T>) => new Set(arr);

export const buildWaypointName = (waypoint: IOptimizationWaypoint | AutocompleteAddress) => {
    const fields = [waypoint.city, waypoint.street, waypoint.houseNum, waypoint.placeName];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    return relevantFields.join(', ');
};

export const buildWaypointNameWithoutPlaceName = (waypoint: any) => {
    const fields = [waypoint.city, waypoint.street, waypoint.houseNum];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    return relevantFields.join(', ');
};

export const buildRouteName = (route: IOptimumRouteData) => {
    const fields = [route.optimumRoute[0].city, route.optimumRoute[0].street, route.optimumRoute[0].houseNum];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    const routeLength = route.optimumRoute.length;
    const lastFields = [
        route.optimumRoute[routeLength - 1].city,
        route.optimumRoute[routeLength - 1].street,
        route.optimumRoute[routeLength - 1].houseNum,
    ];
    const lastRelevantFields = lastFields.filter(
        (field) => field !== '' && field !== null && field !== undefined
    );

    const firstWaypointName = relevantFields.join(', ');
    const lastWaypointName = lastRelevantFields.join(', ');

    const routeName = `${firstWaypointName} - ${lastWaypointName}`;

    return routeName;
};

export const buildWaypointNameForOptimizationResult = (waypoint: IOptimizationWaypoint) => {
    const fields = [waypoint.city, waypoint.street, waypoint.houseNum];
    const relevantFields = fields.filter((field) => field !== '' && field !== null && field !== undefined);

    return relevantFields.join(', ');
};

export const getOptimizationWaypointsForSet = (relevantWaypoints: IOptimizationWaypoint[]) => {
    const waypointsJson: IOptimumWaypoint[] = relevantWaypoints.map((waypoint) => {
        return {
            waypointId: waypoint.waypointId,
            city: waypoint.city,
            street: waypoint.street,
            houseNum: waypoint.houseNum,
            lat: waypoint.lat,
            lng: waypoint.lng,
            place_id: `${waypoint.lat},${waypoint.lng}`,
            distanceToStationInMeters: waypoint.distanceToStationInMeters
                ? waypoint.distanceToStationInMeters
                : 0,
            firstName: waypoint.firstName,
            lastName: waypoint.lastName,
            isTarget: waypoint.isTarget,
            isStation: true,
            stationName: buildWaypointNameForOptimizationResult(waypoint),
        };
    });

    return waypointsJson;
};

export const isNumeric = (str: string): boolean => {
    // Using a regular expression to check if the string consists of digits only
    return /^\d+$/.test(str);
};

export const getBiggestCapacity = (cars: CarType[]): number => {
    if (cars.length === 0) {
        return 0;
    }

    let maxCapacity = cars[0].capacity;

    for (let i = 1; i < cars.length; i++) {
        if (cars[i].capacity > maxCapacity) {
            maxCapacity = cars[i].capacity;
        }
    }

    return maxCapacity;
};

export const addCommasToNumber = (number: string | undefined) => {
    if (typeof number === 'undefined') return;
    const numberWithCommas = number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return numberWithCommas;
};

export const limitString = (str: string, limit: number): string => {
    return str.slice(0, limit) + '...';
};

export const uuid = v4;

export const DEMO = 'Demo';

export const isStringNumber = (input: string): boolean => {
    return !isNaN(Number(input));
};

export const getDistanceOfFarthestPassenger = (waypoints: IOptimumWaypoint[]) => {
    let farthestDistance = 0;
    waypoints.forEach((waypoint: IOptimumWaypoint) => {
        if (farthestDistance < waypoint.distanceToStationInMeters)
            farthestDistance = waypoint.distanceToStationInMeters;
    });

    return farthestDistance;
};

export const setHelpers = {
    getAsArray,
    getAsSet,
};

import { useDispatchAlert } from 'src/hooks/useAlert';
import { getErrWithCode, t } from 'src/utils/lang';

export const useDirectionsErrorHandler = () => {
    const dispatchAlert = useDispatchAlert();

    const handleDirectionsErr = (error: any) => {
        if (error.name === 'AxiosError') {
            dispatchAlert('error', `( ${error.message} ) ${t.errDrawingRoute} `, true);
            return;
        }
        dispatchAlert('error', getErrWithCode(t.errDrawingRoute, 67865), true);
    };

    return {
        handleDirectionsErr,
    };
};

export const STORAGE_KEYS = {
    AUTH: 'auth',
    CLIENT_NAME: 'clientName',
} as const;

type StorageKey = (typeof STORAGE_KEYS)[keyof typeof STORAGE_KEYS];

export const storageUtils = {
    sessionStorage: {
        setItem: (key: StorageKey, value: any) => {
            try {
                sessionStorage.setItem(key, JSON.stringify(value));
                return 1;
            } catch (error) {
                console.error('Could not set item in sessionStorage:', error);
                return null;
            }
        },
        getItem: (key: StorageKey) => {
            try {
                const item = sessionStorage.getItem(key);
                return item ? JSON.parse(item) : undefined;
            } catch (error) {
                console.error('Could not get item from sessionStorage:', error);
                return null;
            }
        },
    },
};

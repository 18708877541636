import React, { FC } from 'react';
import styled from 'styled-components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { ISimulationStationToWaypoint } from 'src/types/optimization/types';
import { toFixed } from 'src/utils/utilis';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colors } from 'src/styles/defaultTheme';
import { summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import { getDistanceOfFarthestPassenger, limitString } from 'src/store/helpers';
import { optiSliceActions, stationsConfiStageSelector } from 'src/store/slices/optimizationsSlice';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { PassengersListCont, passengersListContStyles } from './PassengersListCont';
import { EllipsisText } from 'src/components/stages/Summary/PDF/PdfRouteBox';

// export interface IOptimumRouteData {
//     id: string;
//     routeName: string;
//     color: string; // hex color
//     routeWaypoints: IOptimumWaypoint[];
//     distanceInKm: number;
//     durationInMinutes: number;
//     carId: string | null;
//     carName: string;
//     price: number | null;
//     carDescription: string;
//     numberOfPassengersInRoute: number;
// }

export const styles = {
    summary: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        // padding: '0 10px',
    },
    distanceCont: {},
} as const;

export const BaseSeparator = styled.div`
    height: 20px;
    width: 2px;
    background-color: #e0e0e0;
    margin: 0 10px;
`;

export const css = {
    icon: {
        color: colors.primary,
    },
    dataItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        justifyContent: 'center',
        fontSize: window.innerWidth < 1800 ? '0.9rem' : '1rem',
        // border: '1px solid rgba(0, 123, 255, 1)',
    },

    priceBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        backgroundColor: 'rgba(33, 150, 243, 0.1)',
        padding: '6px 0px',
        borderRadius: '4px',
        fontSize: '16px',
        minWidth: '80px',
        fontWeight: 'bold',
        color: colors.primary,
        justifyContent: 'center',
    },
    accordion: {
        border: '1px solid rgba(180, 190, 201, 0.303017)',
        padding: 0,
        margin: 0,
        borderRadius: '8px',
        boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.1)',
    },
    locationIcon: {
        paddingRight: '6px',
    },
} as const;

const getRouteNameLimit = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth > 1800) return 55;
    if (innerWidth > 1700) return 50;
    if (innerWidth > 1600) return 45;
    return 40;
};

const ROUTE_NAME_LIMIT = getRouteNameLimit();
const equalizerOpenRouteNameLimit = ROUTE_NAME_LIMIT - 20;

const StationDescription: React.FC<{
    stationName: string;
    color: string;
}> = ({ stationName, color }) => {
    const isEqualizerDrawerOpen = useAppSelector(
        (state) => stationsConfiStageSelector(state).isEqualizerDrawerOpen
    );

    const nameLimit = isEqualizerDrawerOpen ? equalizerOpenRouteNameLimit : ROUTE_NAME_LIMIT;
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocationOnIcon sx={{ ...css.locationIcon, color }} fontSize="small" />
            <p
                style={{
                    whiteSpace: 'nowrap',
                    width: '100%',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    paddingRight: '6px',
                }}
            >
                {stationName.length < nameLimit ? stationName : limitString(stationName, nameLimit)}
            </p>
        </div>
    );
};

const StationSelectionCheckbox: React.FC<{ id: string; isSelected: boolean }> = ({ id, isSelected }) => {
    const dispatch = useAppDispatch();

    return (
        <input
            type="checkbox"
            checked={isSelected}
            onChange={() => {}}
            onClick={(e) => {
                e.stopPropagation();

                if (isSelected) {
                    dispatch(optiSliceActions.unselectStation(id));
                } else {
                    dispatch(optiSliceActions.selectStation(id));
                }
            }}
        />
    );
};

export const StationAccordion = React.memo(
    ({
        stationData,
        expanded,
        toggleExpand,
        isSelected,
        color,
        index,
    }: {
        stationData: ISimulationStationToWaypoint;
        expanded: boolean;
        toggleExpand: (panel: string, extend: boolean) => void;
        isSelected: boolean;
        color: string;
        index: number;
    }) => {
        const dispatch = useAppDispatch();

        const {
            maxDistanceFromStation,
            station,
            stationPlaceId,
            wayPoints,
            wayPointsPlaceId,
            stationId,
            waypointsId,
        } = stationData;

        return (
            <Accordion
                expanded={expanded}
                onChange={(_, newIsExpanded) => toggleExpand(stationId, newIsExpanded)}
                disableGutters
                sx={css.accordion}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{ ...styles.summary, gap: '8px' }}>
                        <>
                            <div className="flex-rows" style={{ width: '50%' }}>
                                <StationSelectionCheckbox id={stationId} isSelected={isSelected} />
                                <StationDescription
                                    stationName={`תחנה ${index + 1} - ${station.stationName}`}
                                    color={color}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '50%',
                                    justifyContent: 'flex-end',
                                    overflow: 'auto',
                                    paddingLeft: '20px',
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                                    <div style={{ ...passengersListContStyles.distanceCont, marginLeft: 8 }}>
                                        <p style={{ color: '#494949', fontSize: 12, fontFamily: 'Rubik' }}>
                                            {getDistanceOfFarthestPassenger(wayPoints)} מ׳
                                        </p>
                                    </div>
                                    <span style={{ width: '18px', textAlign: 'left' }}>
                                        {wayPoints.length}
                                    </span>
                                    <PeopleAltOutlinedIcon fontSize="small" sx={css.icon} />
                                </div>
                            </div>
                        </>
                    </div>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, margin: 0, borderTop: '1px solid #e5e5e5' }}>
                    <PassengersListCont passengers={wayPoints} />
                </AccordionDetails>
            </Accordion>
        );
    }
);

StationAccordion.displayName === 'StationAccordion';

// const getPriceStr = (price: number | null) => {
//     if (price === null) return '0';

//     let res = toFixed(price);

//     if (res[0] === '0') {
//         res = res.slice(1);
//     }

//     return `${res || 0} ₪`;
// };

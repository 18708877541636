import React, { FC } from 'react';
import { Dialog, Button } from '@mui/material';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';

export const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    height: 203px;
    width: 293px;
    position: relative;
    padding: 0 0 10px 0;
    border-radius: 9px;
    box-shadow: 0px 5px 12px rgba(217, 226, 233, 0.5);
    color: black;
`;

export const CircleModal = styled.div`
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #ebb835;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    transform: translateY(-50%);
    z-index: 9999;
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
`;

export const ModalAction = styled.div`
    display: flex;
    justify-content: center;
    margin-top: auto;
`;

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    onConfirm: () => void;
    Body: () => JSX.Element;
    loading?: boolean;
    Img: string | (() => JSX.Element);
    isRedirectPopup?: boolean;
    height?: number;
    width?: number;
    withBodyContainer?: boolean;
}

export const DEFAULT_BODY_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    paddingRight: '11px',
} as const;

// Generic version of the delete modal component
const ActionConfirmModal: FC<Props> = ({
    isOpen,
    onClose,
    onConfirm,
    Body,
    loading: showSpinner,
    Img,
    height,
    width,
    withBodyContainer,
}) => {
    return (
        <Dialog open={isOpen} onClose={onClose ? onClose : onConfirm}>
            <ModalContainer
                style={{ height: height ? `${height}px` : '203px', width: width ? `${width}px` : '293px' }}
            >
                <CircleModal>{typeof Img === 'string' ? <img src={Img} alt="img" /> : <Img />}</CircleModal>
                <ModalBody>{withBodyContainer ? <BodyContainer>{Body()}</BodyContainer> : Body()}</ModalBody>
                <ModalAction>
                    <LoadingButton onClick={onConfirm} color="primary" loading={showSpinner}>
                        אישור
                    </LoadingButton>
                    {onClose && (
                        <Button onClick={onClose} style={{ color: 'gray' }}>
                            ביטול
                        </Button>
                    )}
                </ModalAction>
            </ModalContainer>
        </Dialog>
    );
};

const BodyContainer = styled.div<{}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
`;

export default ActionConfirmModal;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { CarType } from 'src/components/stages/Summary/types';
import { createInitialCarInputItem } from './carsStageSlice';
import { RouteStatistics } from 'src/components/stages/Summary/utils';
import { getCarsForPricingFromLocalStorage } from 'src/utils/utilis';
import { AdvancedParam } from 'src/api/optimizationApi';

export interface SummaryStageSliceState {
    routesStatistics: RouteStatistics | null;
    carsForPricing: CarType[];
    isQuickDataLoading: boolean;
    isExportingToPdf: boolean;
    routesOverviewPaths: {
        [key: string]: {
            // key is route uuid
            lat: number;
            lng: number;
        }[];
    };
    configModal: {
        isOpen: boolean;
    };
    chartImgUrl: string;
    advancedParams: {
        api: {
            data: AdvancedParam[];
            isSuccess: boolean;
        };
        isOnAdvancedMode: boolean;
        values: AdvancedParam[];
        allValuesAreValid: boolean;
        triggerRunOptCount: number;
    };
}

const carsForPricingFromLocalStorage = getCarsForPricingFromLocalStorage();

const initialState: SummaryStageSliceState = {
    routesStatistics: null,
    carsForPricing: carsForPricingFromLocalStorage?.length
        ? carsForPricingFromLocalStorage
        : [createInitialCarInputItem()],
    isQuickDataLoading: false,
    isExportingToPdf: false,
    routesOverviewPaths: {},
    configModal: {
        isOpen: false,
    },
    chartImgUrl: '',
    advancedParams: {
        api: {
            data: [],
            isSuccess: false,
        },
        isOnAdvancedMode: false,
        values: [],
        allValuesAreValid: true,
        triggerRunOptCount: 0,
    },
};

export const summaryStageSlice = createSlice({
    name: 'summaryStage',
    initialState,
    reducers: {
        triggerRunOptCount: (state) => {
            state.advancedParams.triggerRunOptCount++;
        },
        setAdvancedParamsApi: (
            state,
            action: PayloadAction<SummaryStageSliceState['advancedParams']['api']>
        ) => {
            state.advancedParams.api = action.payload;
        },
        setIsOnAdvancedParamsMode: (
            state,
            action: PayloadAction<SummaryStageSliceState['advancedParams']['isOnAdvancedMode']>
        ) => {
            state.advancedParams.isOnAdvancedMode = action.payload;
        },
        updateAdvancedParamValue: (state, action: PayloadAction<AdvancedParam>) => {
            const updatedValues = state.advancedParams.values.map((param) => {
                if (param.paramId === action.payload.paramId) {
                    return action.payload;
                }
                return param;
            });

            state.advancedParams.values = updatedValues;

            // validating
            state.advancedParams.allValuesAreValid = updatedValues.every((param) => {
                return param.paramValue >= param.paramMinValue && param.paramValue <= param.paramMaxValue;
            });
        },
        setAllAdvancedParamValues: (state, action: PayloadAction<AdvancedParam[]>) => {
            state.advancedParams.values = action.payload;
        },
        setChartImgUrl: (state, action: PayloadAction<string>) => {
            state.chartImgUrl = action.payload;
        },
        setIsExportingToPdf: (state, action: PayloadAction<boolean>) => {
            state.isExportingToPdf = action.payload;
        },

        setRoutesStatistics: (state, action: PayloadAction<SummaryStageSliceState['routesStatistics']>) => {
            state.routesStatistics = action.payload;
        },
        setCarsForPricing: (state, action: PayloadAction<CarType[]>) => {
            state.carsForPricing = action.payload;
        },

        setIsQuickDataLoading: (state, action: PayloadAction<boolean>) => {
            state.isQuickDataLoading = action.payload;
        },
        restartSummarySlice: () => {
            return initialState;
        },
        setRoutesOverviewPaths: (
            state,
            action: PayloadAction<SummaryStageSliceState['routesOverviewPaths']>
        ) => {
            state.routesOverviewPaths = action.payload;
        },
        openConfigModal: (state) => {
            state.configModal.isOpen = true;
        },
        closeConfigModal: (state) => {
            state.configModal.isOpen = false;
        },
    },
});

export const {
    setRoutesStatistics,
    setCarsForPricing,
    setIsQuickDataLoading,
    restartSummarySlice,
    setIsExportingToPdf,
    setRoutesOverviewPaths,
    openConfigModal,
    setChartImgUrl,
    closeConfigModal,
} = summaryStageSlice.actions;

export const summaryActions = summaryStageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const summaryStageSliceSelector = (state: RootState) => state.summaryStage;
export const advancedParamsSelector = (state: RootState) => state.summaryStage.advancedParams;

export default summaryStageSlice.reducer;

import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useAdvancedParamsApi } from 'src/hooks/useAdvancedParamsApi';
import { advancedParamsSelector, summaryActions } from 'src/store/slices/summaryStageSlice';
import { AdvancedParam } from 'src/api/optimizationApi';
import NumberSlider from 'src/components/ParametersStage/NumberSlider';
import { isStringNumber } from 'src/store/helpers';
import { SliderLabelStyled } from '../../OptimizationParameters/OptimizationParameters';
import { useRunCbAfterTimeout } from 'src/hooks/useRunCbAfterTimeout';
import useOptimizationRunner from '../useOptimizationRunner';
import { CircularProgress } from '@mui/material';

export const AdvancedParamsInput: React.FC<{ param: AdvancedParam }> = ({ param }) => {
    const dispatch = useAppDispatch();

    const handleValueChange = (paramValue: number) => {
        dispatch(
            summaryActions.updateAdvancedParamValue({
                ...param,
                paramValue,
            })
        );
        dispatch(summaryActions.triggerRunOptCount());
    };

    const handleSliderChange = (event: Event, valRTL: number | number[]) => {
        if (typeof valRTL === 'number') handleValueChange(valRTL);
    };

    const handleTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isStringNumber(event.target.value)) return;

        handleValueChange(Number(event.target.value));
    };

    return (
        <>
            <SliderLabelStyled>{param.paramName}</SliderLabelStyled>
            <NumberSlider
                handleInputChange={handleTextInputChange}
                handleSliderChange={handleSliderChange}
                value={param.paramValue}
                max={param.paramMaxValue}
                min={param.paramMinValue}
                step={5}
                parseToRTL
                debouncedValidation={{
                    maxRangeErrMsg: `הערך המקסימלי הינו ${param.paramMaxValue}`,
                    minRangeErrMsg: `הערך המינימלי הינו ${param.paramMinValue}`,
                    onFailedMaxValidation: () => {
                        handleValueChange(param.paramMaxValue);
                    },
                    onFailedMinValidation: () => {
                        handleValueChange(param.paramMinValue);
                    },
                }}
            />
        </>
    );
};

export const AdvancedParamsSection: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const { isLoading } = useAdvancedParamsApi();
    const { runOpt } = useOptimizationRunner();

    const { isOnAdvancedMode, values, allValuesAreValid, triggerRunOptCount } = useAppSelector((state) =>
        advancedParamsSelector(state)
    );

    useRunCbAfterTimeout({
        cb: () => {
            if (!isOnAdvancedMode || !allValuesAreValid) return;

            runOpt({});
        },
        delay: 500,
        dep: triggerRunOptCount,
    });

    return (
        <div style={{ paddingTop: '16px' }}>
            {isLoading && isOnAdvancedMode && (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <CircularProgress color="inherit" />
                </div>
            )}
            {isOnAdvancedMode && !isLoading && (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', paddingTop: '20px' }}>
                    {values.map((param) => (
                        <AdvancedParamsInput param={param} key={param.paramId} />
                    ))}
                </div>
            )}
        </div>
    );
};

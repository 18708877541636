/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function PersonCheckListSvg({ width = '28', height = '28', color = '#494949' }) {
    return (
<svg width="251" height="339" viewBox="0 0 251 339" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100.872 294.324C106.505 294.324 111.907 296.562 115.891 300.545C119.874 304.528 122.111 309.93 122.111 315.563C122.111 321.196 119.874 326.598 115.891 330.581C111.908 334.564 106.505 336.802 100.873 336.802C95.2396 336.802 89.8373 334.564 85.8542 330.581C81.8712 326.598 79.6335 321.196 79.6335 315.563C79.6335 309.93 81.8712 304.528 85.8542 300.545C89.8373 296.562 95.2395 294.324 100.872 294.324Z" fill="#CCCCCC"/>
<path d="M97.1249 324.048C96.5695 323.984 96.05 323.741 95.6447 323.356L95.6196 323.332L90.3388 318.214C90.0941 317.977 89.8985 317.694 89.7633 317.381C89.6282 317.068 89.556 316.731 89.5509 316.39C89.5459 316.049 89.608 315.711 89.7338 315.394C89.8596 315.077 90.0466 314.788 90.2842 314.543C90.5217 314.299 90.8051 314.103 91.1182 313.968C91.4312 313.833 91.7678 313.761 92.1087 313.756C92.4496 313.751 92.7882 313.814 93.105 313.94C93.4219 314.066 93.7108 314.253 93.9553 314.49L97.375 317.812L107.612 307.269C107.849 307.025 108.132 306.829 108.445 306.695C108.758 306.56 109.094 306.488 109.435 306.483C109.776 306.478 110.114 306.54 110.431 306.665C110.747 306.791 111.036 306.978 111.28 307.215L111.281 307.216L111.217 307.286L111.283 307.216C111.776 307.696 112.058 308.352 112.068 309.04C112.078 309.728 111.815 310.392 111.336 310.886L99.2958 323.28C99.0174 323.566 98.677 323.783 98.301 323.916C97.925 324.049 97.5234 324.093 97.1275 324.045L97.1249 324.048Z" fill="white"/>
<path d="M215.451 328.308L205.395 328.307L200.613 289.52L215.454 289.521L215.451 328.308Z" fill="#E7C297"/>
<path d="M198.213 325.434H217.606V337.644H186.002C186.002 336.041 186.318 334.453 186.931 332.971C187.545 331.49 188.445 330.144 189.578 329.01C190.712 327.876 192.058 326.977 193.54 326.363C195.021 325.749 196.609 325.434 198.213 325.434Z" fill="#494949"/>
<path d="M175.428 328.308L165.372 328.307L160.591 289.52L175.432 289.521L175.428 328.308Z" fill="#E7C297"/>
<path d="M158.19 325.434H177.584V337.644H145.979C145.979 336.041 146.295 334.453 146.909 332.971C147.523 331.49 148.422 330.144 149.556 329.01C150.69 327.876 152.036 326.977 153.517 326.363C154.999 325.749 156.587 325.434 158.19 325.434Z" fill="#494949"/>
<path d="M223.198 194.212C222.389 193.318 221.785 192.258 221.43 191.105C221.075 189.953 220.977 188.737 221.143 187.542C221.308 186.348 221.734 185.205 222.39 184.192C223.045 183.18 223.914 182.324 224.936 181.684L221.822 163.095L232.657 158.447L236.687 184.761C237.647 186.549 237.919 188.628 237.45 190.603C236.981 192.578 235.803 194.312 234.14 195.477C232.478 196.642 230.446 197.157 228.429 196.924C226.412 196.691 224.551 195.726 223.198 194.212Z" fill="#E7C297"/>
<path d="M138.371 185.426C139.317 184.678 140.087 183.732 140.628 182.654C141.168 181.576 141.466 180.393 141.5 179.187C141.534 177.982 141.303 176.784 140.824 175.677C140.344 174.571 139.628 173.583 138.726 172.783L144.869 154.964L134.951 148.59L126.629 173.876C125.386 175.481 124.775 177.486 124.911 179.512C125.048 181.537 125.923 183.442 127.37 184.866C128.817 186.29 130.737 187.133 132.764 187.236C134.792 187.339 136.786 186.695 138.371 185.426Z" fill="#E7C297"/>
<path d="M189.872 54.1558C201.804 54.1558 211.476 44.4832 211.476 32.5515C211.476 20.6198 201.804 10.9473 189.872 10.9473C177.94 10.9473 168.268 20.6198 168.268 32.5515C168.268 44.4832 177.94 54.1558 189.872 54.1558Z" fill="#E7C297"/>
<path d="M137.703 172.097C137.332 172.097 136.963 172.045 136.607 171.942L130.31 170.134C129.305 169.843 128.455 169.167 127.946 168.252C127.437 167.338 127.311 166.259 127.594 165.251L140.59 119.369L158.711 74.8819C160.513 70.4593 164.278 67.5589 168.537 67.3128C170.482 67.2177 172.412 67.6887 174.095 68.6687C175.778 69.6486 177.14 71.0955 178.017 72.834C178.992 74.6947 179.527 76.7541 179.582 78.8539C179.636 80.9537 179.208 83.0381 178.331 84.9467L157.733 129.992L141.358 169.648C141.06 170.372 140.553 170.992 139.902 171.428C139.252 171.864 138.486 172.096 137.703 172.097Z" fill="#2196F3"/>
<path d="M202.064 50.6676L190.06 50.881C188.81 50.9056 187.571 50.6361 186.444 50.0941C185.317 49.5522 184.333 48.753 183.572 47.7609C182.81 46.7689 182.293 45.6118 182.06 44.383C181.828 43.1542 181.888 41.888 182.235 40.6865C182.492 39.8184 182.684 38.9327 182.812 38.0365C182.947 37.0591 182.973 36.0698 182.891 35.0867C182.815 34.1021 182.406 33.1727 181.733 32.4507C181.059 31.7287 180.16 31.2571 179.183 31.1131C178.206 30.9691 177.209 31.1613 176.356 31.6581C175.503 32.155 174.843 32.9271 174.486 33.8478C172.468 33.8766 168.168 33.2186 166.15 33.2473C161.864 22.2611 171.203 7.88521 181.256 2.48561C191.505 -3.01902 204.808 2.58122 208.737 14.054C214.189 14.152 218.619 19.5293 219.321 25.5825C220.024 31.6357 217.534 37.9565 213.724 42.5959C209.915 47.2352 204.913 50.6169 202.064 50.6676Z" fill="#494949"/>
<path d="M162.606 324.687C161.633 324.681 160.697 324.319 159.973 323.669C159.25 323.019 158.79 322.126 158.681 321.159L154.228 171.418L210.336 175.412L210.379 175.773C223.058 281.909 218.724 318.65 218.679 319.009C218.648 319.552 218.505 320.082 218.26 320.568C218.014 321.053 217.671 321.483 217.252 321.83C216.833 322.177 216.347 322.433 215.825 322.584C215.302 322.735 214.754 322.776 214.214 322.705L201.815 323.002C200.875 322.896 200.004 322.456 199.36 321.763C198.717 321.07 198.343 320.169 198.306 319.224L186.72 210.767C186.649 210.486 186.48 210.238 186.244 210.068C186.009 209.899 185.721 209.817 185.431 209.838C185.132 209.838 184.843 209.94 184.612 210.129C184.381 210.318 184.223 210.581 184.165 210.874L179.689 319.686C179.736 320.696 179.394 321.685 178.732 322.45C178.071 323.215 177.14 323.696 176.134 323.794L162.999 324.667C162.868 324.68 162.737 324.687 162.606 324.687Z" fill="#494949"/>
<path d="M185.889 189.312C185.553 189.312 185.218 189.307 184.881 189.296C168.748 188.811 156.125 176.604 152.641 172.875C152.198 172.399 151.878 171.821 151.712 171.192C151.546 170.563 151.539 169.903 151.69 169.271L160.998 129.895L158.528 95.6634C158.14 90.9125 158.763 86.1333 160.356 81.6405C161.949 77.1477 164.475 73.0433 167.769 69.5975C170.514 66.6981 173.85 64.4227 177.551 62.9254C181.252 61.4281 185.231 60.744 189.22 60.9195C204.925 61.6689 217.316 75.7071 217.428 92.8781C217.602 119.473 216.819 121.3 216.562 121.901C208.723 140.194 213.004 165.872 214.503 173.307C214.637 173.97 214.599 174.656 214.393 175.301C214.187 175.946 213.82 176.527 213.327 176.99C204.706 185.169 195.479 189.311 185.889 189.312Z" fill="#2196F3"/>
<path d="M226.407 179.301C225.512 179.301 224.643 178.996 223.943 178.438C223.243 177.88 222.754 177.101 222.554 176.228L212.951 134.396L200.082 86.5823C199.533 84.5549 199.455 82.4285 199.856 80.3665C200.256 78.3045 201.124 76.3618 202.393 74.6877C203.546 73.1188 205.129 71.9175 206.95 71.2291C208.771 70.5407 210.753 70.3946 212.655 70.8085C216.815 71.7551 220.049 75.2375 221.095 79.8971L231.611 126.718L236.855 174.165C236.968 175.206 236.665 176.249 236.012 177.067C235.359 177.885 234.409 178.412 233.37 178.533L226.861 179.275C226.71 179.293 226.559 179.301 226.407 179.301Z" fill="#2196F3"/>
<path d="M65.8815 338.146H249.721C249.954 338.146 250.178 338.053 250.343 337.888C250.508 337.723 250.601 337.5 250.601 337.266C250.601 337.033 250.508 336.809 250.343 336.644C250.178 336.479 249.954 336.387 249.721 336.387H65.8815C65.6482 336.387 65.4245 336.479 65.2595 336.644C65.0946 336.809 65.0019 337.033 65.0019 337.266C65.0019 337.5 65.0946 337.723 65.2595 337.888C65.4245 338.053 65.6482 338.146 65.8815 338.146Z" fill="#EEEEEE"/>
</svg>


    );
}

export default PersonCheckListSvg;

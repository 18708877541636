import axios, { AxiosResponse } from 'axios';
import {
    IBaseAddress,
    IOptimizationWaypoint,
    IOptimumWaypoint,
    IReverseGeoResult,
    ISimulationResult,
    ISimulationStationToWaypoint,
} from 'src/types/optimization/types';
import { CarType } from 'src/components/stages/Summary/types';
import { OptimizationMethods } from 'src/constants/common';
import { convertParamsToXFormUrlEncoded } from './utilis';
import { EnvKeys } from 'src/utils/utilis';

export type AxiosRes<T> = Promise<AxiosResponse<T>>;

export type PlacePredictionsConfig = {
    input: string;
    language: string;
    clientToken: string;
    region: string;
    components: string;
};

export type PlaceDetailsConfig = {
    place_id: string;
    language: string;
    clientToken: string;
};

export enum OptimizationHistory {
    None = 0,
    OnlyStationsOpt = 1,
    OnlyRoutesOpt = 2,
    BothStationsAndRoutesOpt = 3,
}

export type SetOptimizationSimulationConfig = {
    clientToken: string;
    maxValueLimitInRoute: number;
    passengerLimitInVehicle: number;
    maxWalkingDistanceFromWayPoint: number;
    maxWalkingDistanceFromWayPointMeters: number;
    maxWalkingDistanceFromWayPointTier2Meters: number;
    toTarget: boolean;
    wayPointsJson: IOptimumWaypoint[];
    wayPointsJsonForStations: IOptimumWaypoint[];
    carsType: CarType[];
    optimizationType?: number;
    optimizationHistory: OptimizationHistory;
    description: string;
    optimizationMethod: OptimizationMethods;
    stationToWayPoints?: ISimulationStationToWaypoint[];
    advancedSettingsList?: AdvancedParam[];
};

export type GetCreditNeededForCalculationConfig = {
    clientToken: string;
    waypoints: IOptimizationWaypoint[];
};

export type GetCreditNeededForCalculationResult = {
    isSuccess: boolean;
    errorMessage: string;
    creditNeeded: number;
};

export type FetchCoordsFromAddressConfig = {
    clientToken: string;
    city: string;
    street: string;
    house: string;
    language: string;
};

export type FetchListOfWaypointsWithCoordsConfig = {
    clientToken: string;
    baseAddresses: IBaseAddress[];
};

export type getPlaceDetailsConfig = {
    clientToken: string;
    googlePlace_Id: { googlePlace_Id: string; city: string }[];
};
export type GetOptimizationSimulationConfig = { hashID: string };

interface PlacePredictionsResponse {
    predictions: google.maps.places.AutocompletePrediction[];
    status: string;
}

export interface GooglePlaceDetailsResponse {
    html_attributions: string[];
    result: google.maps.places.PlaceResult;
    status: string;
}

// Optimization Simulation

// REPLACE TO PROD IF NEEDED

const BASE_URL_PROD = 'https://optimizationapp.y-it.co.il';
const BASE_DEV_URL = 'https://localhost:60000';

// export const OPTIMIZATION_SERVER_URL =
//     process.env[EnvKeys.NodeEnvironment] === 'development' ? BASE_DEV_URL : BASE_URL_PROD;

export const OPTIMIZATION_SERVER_URL = window.location.href.includes('localhost') ? BASE_DEV_URL : BASE_URL_PROD;

const createUrl = (prefix: string, endpoint: string, hashId?: string) =>
    hashId
        ? `${OPTIMIZATION_SERVER_URL}/${prefix}/${endpoint}/?hashID=${encodeURIComponent(hashId)}`
        : `${OPTIMIZATION_SERVER_URL}/${prefix}/${endpoint}`;

// const createGoogleApiUrl = (endpoint: string) => `${OPTIMIZATION_GOOGLE_API}/${endpoint}`;

export const ENDPOINTS = {
    SET_WAYPOINTS_POINTS_FOR_OPTIMIZATIONS: 'setWayPointsForOptimization',
    GET_OPTIMUM_ROUTE_RESULT: 'getOptimumRouteResults',
    INIT_OPTIMIZATION_PROCESS: 'initOptimizationProcess',
    REVERSE_GEO: 'reverseGeo',
    MULTIPLE_REVERSE_GEO: 'reverseGeoListOfWayPoints',
    PLACE_DETAILS: 'placeDetails',
    AUTO_COMPLETE: 'autoComplete',
    FIND_STATIONS: 'findStations',
    GET_CREDIT_NEEDED_FOR_CALCULATION: 'getCreditNeededForCalculation',
    GET_ADVANCED_SETTINGS_LIST: 'getAdvancedSettingsList',
} as const;

const PREFIXES = {
    OPTIMIZATION_SIMULATION: 'OptServices',
    OPTIMIZATION_GOOGLE_API: 'GoogleApi',
    ROUTE_PLANNER: 'RoutePlanner',
} as const;

export const getOptimizationSimulationResult = (hashId: string): AxiosRes<ISimulationResult> => {
    return axios.post(
        createUrl(PREFIXES.OPTIMIZATION_SIMULATION, ENDPOINTS.GET_OPTIMUM_ROUTE_RESULT, hashId)
    );
};

export const setWayPointsForOptimization = (
    requestConfig: SetOptimizationSimulationConfig
): AxiosRes<ISimulationResult> => {
    return axios.post(
        createUrl(PREFIXES.OPTIMIZATION_SIMULATION, ENDPOINTS.SET_WAYPOINTS_POINTS_FOR_OPTIMIZATIONS),
        requestConfig
    );
};

export const getCreditNeededForCalculation = (
    requestConfig: GetCreditNeededForCalculationConfig
): AxiosRes<GetCreditNeededForCalculationResult> => {
    const url = `${createUrl(
        PREFIXES.OPTIMIZATION_SIMULATION,
        ENDPOINTS.GET_CREDIT_NEEDED_FOR_CALCULATION
    )}?clientToken=${requestConfig.clientToken}`;

    return axios.post(url, requestConfig.waypoints);
};

export const setWayPointsForStationOptimization = (
    requestConfig: SetOptimizationSimulationConfig
): AxiosRes<ISimulationResult> => {
    return axios.post(createUrl(PREFIXES.OPTIMIZATION_SIMULATION, ENDPOINTS.FIND_STATIONS), requestConfig);
};

export const setMagicWandWayPointsForOptimization = (
    requestConfig: SetOptimizationSimulationConfig
): AxiosRes<ISimulationResult> => {
    return axios.post(
        createUrl(PREFIXES.ROUTE_PLANNER, ENDPOINTS.SET_WAYPOINTS_POINTS_FOR_OPTIMIZATIONS),
        requestConfig
    );
};

export const initOptimizationProcess = (): AxiosRes<string> => {
    return axios.post(
        `${BASE_DEV_URL}/${PREFIXES.OPTIMIZATION_SIMULATION}/${ENDPOINTS.INIT_OPTIMIZATION_PROCESS}?token=token`
    );
};

const sendPostRequest = async <T>(url: string, params: URLSearchParams): Promise<AxiosResponse<T>> => {
    try {
        const response = await axios.post(url, params.toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return response;
    } catch (error) {
        throw new Error(`Error sending POST request`);
    }
};

export const getCoordsFromAddress = async (
    requestConfig: FetchCoordsFromAddressConfig
): Promise<AxiosResponse<IReverseGeoResult>> => {
    const url = createUrl(PREFIXES.OPTIMIZATION_GOOGLE_API, ENDPOINTS.REVERSE_GEO);
    const params = new URLSearchParams();

    // Convert parameters to x-www-form-urlencoded
    for (const key in requestConfig) {
        if (Object.prototype.hasOwnProperty.call(requestConfig, key)) {
            params.append(key, requestConfig[key as keyof FetchCoordsFromAddressConfig]);
        }
    }

    return sendPostRequest<IReverseGeoResult>(url, params);
};

export const getListOfWaypointsWithCoords = async (
    requestConfig: FetchListOfWaypointsWithCoordsConfig
): Promise<AxiosResponse<IReverseGeoResult[]>> => {
    const url = `${createUrl(PREFIXES.OPTIMIZATION_GOOGLE_API, ENDPOINTS.MULTIPLE_REVERSE_GEO)}?clientToken=${
        requestConfig.clientToken
    }`;

    return axios.post(url, requestConfig.baseAddresses);
};

export const getPlaceDetailsFromReverseGeo = async (
    requestConfig: getPlaceDetailsConfig
): Promise<AxiosResponse<IReverseGeoResult[]>> => {
    const url = `${createUrl(PREFIXES.OPTIMIZATION_GOOGLE_API, ENDPOINTS.MULTIPLE_REVERSE_GEO)}?clientToken=${
        requestConfig.clientToken
    }`;

    return axios.post(url, requestConfig.googlePlace_Id);
};

export const getPlacePredictions = async (
    requestConfig: PlacePredictionsConfig
): Promise<AxiosResponse<PlacePredictionsResponse>> => {
    const url = createUrl(PREFIXES.OPTIMIZATION_GOOGLE_API, ENDPOINTS.AUTO_COMPLETE);
    const params = new URLSearchParams();

    // Convert parameters to x-www-form-urlencoded
    for (const key in requestConfig) {
        if (Object.prototype.hasOwnProperty.call(requestConfig, key)) {
            params.append(key, requestConfig[key as keyof PlacePredictionsConfig]);
        }
    }

    return sendPostRequest<PlacePredictionsResponse>(url, params);
};

export const getPlaceDetails = async (
    requestConfig: PlaceDetailsConfig
): Promise<AxiosResponse<GooglePlaceDetailsResponse>> => {
    const url = createUrl(PREFIXES.OPTIMIZATION_GOOGLE_API, ENDPOINTS.PLACE_DETAILS);
    const params = new URLSearchParams();

    // Convert parameters to x-www-form-urlencoded
    for (const key in requestConfig) {
        if (Object.prototype.hasOwnProperty.call(requestConfig, key)) {
            params.append(key, requestConfig[key as keyof PlaceDetailsConfig]);
        }
    }

    return sendPostRequest<GooglePlaceDetailsResponse>(url, params);
};

export type AdvancedParam = {
    paramId: number;
    paramName: string;
    paramMinValue: number;
    paramMaxValue: number;
    paramValue: number;
};

export const reqGetAdvancedSettingsList = async (): Promise<AxiosResponse<AdvancedParam[]>> => {
    return axios.post(createUrl(PREFIXES.OPTIMIZATION_SIMULATION, ENDPOINTS.GET_ADVANCED_SETTINGS_LIST));
};

export type PrevOpti = {
    hashId: string;
    description: string;
    dateTime: string; //     "2023-07-25 06:02"
};

export const getClientHistory = async ({
    params,
}: {
    params: { token: string };
}): Promise<AxiosResponse<{ clientName: string; history: PrevOpti[] }>> => {
    const url = `${OPTIMIZATION_SERVER_URL}/OptServices/getClientHistory?${convertParamsToXFormUrlEncoded({
        ...params,
        clientName: 'DUMMY_CLIENT_NAME',
    })}`;

    return axios.post(url);
};

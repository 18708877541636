import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetOptimizationSimulation } from 'src/hooks/useGetOptimizationSimulationResult';
import { DEMO } from 'src/store/helpers';
import { useAppDispatch } from 'src/store/hooks';
import { setClientToken } from 'src/store/slices/authSlice';

const Demo: React.FC = (props: {}) => {
    const dispatch = useAppDispatch();
    const getOptimizationSimulation = useGetOptimizationSimulation();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(setClientToken(DEMO));

        // getOptimizationSimulation.getOptimizationSimulation(DEMO, false, true);
        getOptimizationSimulation.getOptimizationSimulation({
            hashID: DEMO,
            isQuickRefetch: false,
            isPrevOptimization: true,
        });
        navigate('/');
    }, []);

    return <div></div>;
};

export default Demo;

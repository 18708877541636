import { Slide } from '@mui/material';
import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import ToggleConfigBtn from '../ConfigIcon';
import StationsConfiEqualizerDrawer from './StationsConfiEqualizerDrawer';
import { optiSliceActions, stationsConfiStageSelector } from 'src/store/slices/optimizationsSlice';

export const StationsConfiEqualizerSection: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const isOpen = useAppSelector((state) => stationsConfiStageSelector(state).isEqualizerDrawerOpen);

    const containerRef = useRef<any>(null);

    return (
        <>
            {!isOpen && (
                <div
                    style={{
                        height: '100%',
                        width: '30px',
                        background: '#f9f8f8',
                        boxShadow: '-2px 0px 6px rgba(0, 0, 0, 0.1)',
                        zIndex: 2,
                    }}
                >
                    <ToggleConfigBtn
                        sx={{ position: 'absolute', right: '10px', bottom: '45%' }}
                        onClick={() => {
                            dispatch(optiSliceActions.setIsEqualizerDrawerOpen(true));
                        }}
                        tooltipComponent={<div>לחץ כאן לשינוי פרמטרים לאופטימיזציה</div>}
                    />
                </div>
            )}
            <Slide direction="left" in={isOpen} container={containerRef.current} unmountOnExit mountOnEnter>
                <StationsConfiEqualizerDrawer />
            </Slide>
        </>
    );
};

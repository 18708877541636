import React from 'react';
import NumberSlider from '../../ParametersStage/NumberSlider';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { optiUiSelector, setMaxStationsCount } from 'src/store/slices/optimizationsSlice';
import { isStringNumber } from 'src/store/helpers';
import { MAX_STATIONS_COUNT, MIN_STATIONS_COUNT, SliderLabelStyled } from './OptimizationParameters';

export const StationsCountSlider: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const maxStationsCountVal = useAppSelector(
        (state) => optiUiSelector(state).parametersForm.maxStationsCountVal
    );

    const handleSliderChange = (event: Event, valRTL: number | number[]) => {
        if (typeof valRTL === 'number') dispatch(setMaxStationsCount(valRTL));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isStringNumber(event.target.value)) return;

        dispatch(setMaxStationsCount(Number(event.target.value)));
    };

    return (
        <>
            <SliderLabelStyled>מהי כמות התחנות המקסימלית למסלול</SliderLabelStyled>
            <NumberSlider
                handleInputChange={handleInputChange}
                handleSliderChange={handleSliderChange}
                value={maxStationsCountVal}
                max={MAX_STATIONS_COUNT}
                min={MIN_STATIONS_COUNT}
                step={1}
                parseToRTL
                debouncedValidation={{
                    minRangeErrMsg: `כמות התחנות המינימלית הינה ${MIN_STATIONS_COUNT}`,
                    maxRangeErrMsg: `כמות התחנות המקסימלית הינה ${MAX_STATIONS_COUNT}`,
                    onFailedMinValidation: () => {
                        dispatch(setMaxStationsCount(MIN_STATIONS_COUNT));
                    },
                    onFailedMaxValidation: () => {
                        dispatch(setMaxStationsCount(MAX_STATIONS_COUNT));
                    },
                }}
            />
        </>
    );
};

import { Button } from '@mui/material';
import React from 'react';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useSetOptimizationSimulation } from 'src/hooks/useSetOptimizationSimulation';
import { DEMO } from 'src/store/helpers';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    approveWaypointsAction,
    optiSliceActions,
    optimizationsRootSelector,
    resetTargetWaypointAction,
    setDisplaySimulationCalcLoaderAction,
    setEditPrevOptimizationAction,
    setIsFixAddressModalOpenAction,
    setWaypointsForOptiObject,
    resetWaypointsForOptiObject,
    setIsNotEnoughCreditModalOpenAction,
    optiUiSelector,
} from 'src/store/slices/optimizationsSlice';
import styled from 'styled-components';
import ActionConfirmModal from '../Modals/ActionConfirmModal';
import { useModal } from './utils';
import { useCarsStageConfig } from './useCarsStageConfig';
import { getUnRecognizedWaypointsCount } from '../stages/DataLoading/DataLoading';
import { closeConfigModal } from 'src/store/slices/summaryStageSlice';
import { setStageAction } from 'src/store/slices/commonSlice';
import { OptiFlowTypes, Stages } from 'src/constants/common';
import useGetCreditNeededForCalc from 'src/hooks/useGetCreditNeededForCalc';
import { EditOptiWaypointsBtn } from './EditOptiWaypointsBtn';
import { getExtractedWaypointsStations } from './btnsFooterUtils';
import { useWaypointsForRunningOpti } from 'src/hooks/useWaypointsForRunningOpti';
import { useCarInputsUtils } from 'src/hooks/useCarInputsUtils';
import { useSyncCarInputsWithCurrFlow } from './useSyncCarInputsWithCurrFlow';
import { Exports } from './ExportsBtns';
import { carsStageSliceSelector, setCarInputsValues } from 'src/store/slices/carsStageSlice';

const StyledFooter = styled.div<{}>`
    border-top: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 10%;
    background-color: #fff;
`;

const Footer: React.FC = () => {
    const dispatch = useAppDispatch();
    const dispatchAlert = useDispatchAlert();

    const syncCarInputsWithCurrFlow = useSyncCarInputsWithCurrFlow();

    const { modalState, setModalState, resetModal } = useModal();

    const displaySimulationCalcLoader = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.displaySimulationCalcLoader
    );

    const carInputsValues = useAppSelector(
        (state) => carsStageSliceSelector(state).carsForPricingInputsItems
    );

    const stationsOptiIsLoading = useAppSelector(
        (state) => optiUiSelector(state).stationsConfiStage.isQuickDataLoading
    );

    const optiFlowType = useAppSelector((state) => optimizationsRootSelector(state).ui.selectedOptiFlowType);

    const isEditPrevOptOpen = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.editPrevOptimizationScreen.isOpen
    );

    const editPrevOptWaypointsValues = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.editPrevOptimizationScreen.fieldsValues
    );

    const stage = useAppSelector((state) => state.common.stage);

    const waypointsForOptiObject = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.waypointsForOptiObject
    );
    const targetWaypoint = useAppSelector((state) => optimizationsRootSelector(state).ui.targetWaypoint);

    const optimizationDescription = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.optimizationDescription
    );

    const carsForPricing = useAppSelector((state) => state.summaryStage.carsForPricing);

    const clientToken = useAppSelector((state) => state.auth.clientToken);

    // const isStationFlow = maxWalkDistance > 0;
    const stationToWayPoints = useAppSelector(
        (state) => optimizationsRootSelector(state).data.getStationsSimulationResult.data?.stationToWayPoints
    );

    const getAndValidateCreditForCalc = useGetCreditNeededForCalc();

    const globalCarInputsSync = useCarInputsUtils();

    const setOptimizationSimulationHook = useSetOptimizationSimulation();

    const { getWaypointsJsons } = useWaypointsForRunningOpti();

    const {
        passedMinimumCarsValidation,
        onCarNextStageClick: handleCarStageNext,
        allCarsValid,
    } = useCarsStageConfig();

    const onNextStage = async () => {
        switch (stage) {
            case Stages.Start:
                dispatch(setStageAction({ stage: Stages.DataLoading }));
                break;

            case Stages.DataLoading:
                if (!optimizationDescription) {
                    dispatchAlert('error', 'תיאור הרצה הינו שדה חובה', true);
                    return;
                }
                if (waypointsForOptiObject) {
                    if (isEditPrevOptOpen) {
                        // -- validating values
                        if (
                            editPrevOptWaypointsValues.some(
                                (val) => !val.city && !val.street && !val.houseNum
                            )
                        ) {
                            dispatchAlert(
                                'error',
                                'ישנן כתובות לא תקינות, הזן כתובת מלאה או מחק את השורה',
                                true
                            );
                            return;
                        }
                        if (editPrevOptWaypointsValues.some((val) => val.lng === 0 && val.lat === 0)) {
                            // has invalid coords
                            dispatchAlert('error', 'ישנן נקודות ללא מיקום', true);
                            return;
                        }
                        if (editPrevOptWaypointsValues.length === 0) {
                            dispatchAlert('error', 'לא נמצאו נקודות תקינות להרצה', true);
                            return;
                        }

                        // -- passed validation
                        // -- set valid waypoints to current values
                        dispatch(
                            setWaypointsForOptiObject({
                                waypointsForOptiObject: {
                                    ...waypointsForOptiObject,
                                    validWaypoints: editPrevOptWaypointsValues,
                                },
                            })
                        );

                        getAndValidateCreditForCalc.getAndValidateCreditNeededForCalc(
                            editPrevOptWaypointsValues
                        );

                        dispatch(
                            setEditPrevOptimizationAction({
                                isOpen: false,
                                hashId: null,
                            })
                        );
                    }
                    const unRecognizedWaypointsCount = getUnRecognizedWaypointsCount(waypointsForOptiObject);

                    if (waypointsForOptiObject.validWaypoints.length === 0) {
                        dispatchAlert('error', 'לא נמצאו נקודות תקינות להרצה, נא טען קובץ מחדש', true);
                        return;
                    }
                    if (unRecognizedWaypointsCount > 0) {
                        dispatch(setIsFixAddressModalOpenAction({ isOpen: true }));
                    } else {
                        dispatch(approveWaypointsAction());
                        getAndValidateCreditForCalc.getAndValidateCreditNeededForCalc();
                    }
                } else {
                    dispatchAlert('error', 'נדרש לטעון קובץ או להזין נוסעים/כתובות ידנית', true);
                }
                break;

            case Stages.TargetSelection:
                if (!targetWaypoint) {
                    dispatchAlert('error', 'נא להגדיר נ.צ יעד', true);
                } else {
                    dispatch(setStageAction({ stage: Stages.ChooseFlowType }));
                }
                break;
            case Stages.ChooseFlowType: {
                dispatch(setStageAction({ stage: Stages.Cars }));
                syncCarInputsWithCurrFlow.syncCarInputsWithSelectedFlow();
                break;
            }

            case Stages.Cars:
                if (!allCarsValid) {
                    dispatchAlert('error', 'ישנם רכבים לא תקינים', true);
                    return;
                }
                if (!passedMinimumCarsValidation) {
                    dispatchAlert('error', 'נדרש סוג רכב אחד לפחות', true);
                } else {
                    handleCarStageNext();
                }
                break;

            case Stages.Parameters: {
                const { defaultWaypointsArr = [], waypointsJsonForRoutesByStationsOpti = [] } =
                    getWaypointsJsons();

                if (waypointsForOptiObject && waypointsForOptiObject.validWaypoints && targetWaypoint) {
                    dispatch(setDisplaySimulationCalcLoaderAction({ display: true }));
                    dispatch(optiSliceActions.unselectAllRoutes({}));

                    // switch to loader component

                    // if there is a value in one of max walk from stations value => station optimization flow
                    setOptimizationSimulationHook.setOptimizationSimulation({
                        waypointsJson: optiFlowType === OptiFlowTypes.WithStations ? [] : defaultWaypointsArr,
                        wayPointsJsonForStations: defaultWaypointsArr,
                        isQuickRefetch: false,
                        carsForPricing,
                        magicWandValue: undefined,
                        isStationsOptimization: optiFlowType === OptiFlowTypes.WithStations,
                    });
                }
                break;
            }

            case Stages.StationConfirmation:
                dispatch(optiSliceActions.unselectAllStations());

                // logic that takes the stations and does routes optimization
                if (targetWaypoint) {
                    const { defaultWaypointsArr = [], waypointsJsonForRoutesByStationsOpti = [] } =
                        getWaypointsJsons();

                    dispatch(setDisplaySimulationCalcLoaderAction({ display: true }));

                    setOptimizationSimulationHook.setOptimizationSimulation({
                        waypointsJson: waypointsJsonForRoutesByStationsOpti,
                        wayPointsJsonForStations: defaultWaypointsArr,
                        isQuickRefetch: false,
                        carsForPricing,
                        magicWandValue: undefined,
                        isStationsOptimization: false,
                        stationToWayPoints,
                    });
                }
                break;
        }
    };

    const onPrevStage = () => {
        switch (stage) {
            case Stages.DataLoading:
                dispatch(resetWaypointsForOptiObject());
                dispatch(
                    setEditPrevOptimizationAction({
                        isOpen: false,
                        hashId: null,
                    })
                );
                dispatch(setStageAction({ stage: Stages.Start }));
                break;
            case Stages.TargetSelection:
                dispatch(resetTargetWaypointAction());
                dispatch(resetWaypointsForOptiObject());
                dispatch(setStageAction({ stage: Stages.DataLoading }));
                break;
            case Stages.ChooseFlowType:
                dispatch(setStageAction({ stage: Stages.TargetSelection }));
                break;
            case Stages.Cars:
                dispatch(setCarInputsValues(carInputsValues));
                globalCarInputsSync.saveCarInputsToLocalStorage(carInputsValues);
                dispatch(setStageAction({ stage: Stages.ChooseFlowType }));

                break;
            case Stages.Parameters:
                dispatch(setStageAction({ stage: Stages.Cars }));
                syncCarInputsWithCurrFlow.syncCarInputsWithSelectedFlow();

                break;
            case Stages.StationConfirmation:
                //
                dispatch(optiSliceActions.unselectAllStations());

                dispatch(setStageAction({ stage: Stages.Parameters }));
                break;
            case Stages.Summary:
                dispatch(optiSliceActions.setRouteType('pickup'));
                if (optiFlowType === OptiFlowTypes.WithStations) {
                    dispatch(setStageAction({ stage: Stages.StationConfirmation }));
                } else {
                    dispatch(setStageAction({ stage: Stages.Parameters }));
                }

                dispatch(optiSliceActions.unselectAllRoutes({}));

                dispatch(closeConfigModal());
                break;
        }
    };

    const isStationsStageOptiLoading =
        stage === Stages.StationConfirmation && (displaySimulationCalcLoader || stationsOptiIsLoading);

    return (
        <StyledFooter>
            <ActionConfirmModal {...modalState} />
            <Button
                sx={{ height: '43px', width: '160px', fontSize: '18px', fontWeight: 400 }}
                variant="outlined"
                style={
                    stage === Stages.Start || (clientToken === DEMO && stage === Stages.Cars)
                        ? { visibility: 'hidden' }
                        : {}
                }
                onClick={onPrevStage}
                disabled={isStationsStageOptiLoading}
            >
                <span style={{ marginLeft: '11px' }}>&#8594;</span>
                לשלב הקודם
            </Button>
            <Button
                sx={{
                    height: '43px',
                    width: '160px',
                    fontSize: '18px',
                    backgroundColor: '#2196F3',
                    fontWeight: 400,
                }}
                disabled={
                    isStationsStageOptiLoading || (stage === Stages.Parameters && displaySimulationCalcLoader)
                }
                variant="contained"
                style={
                    stage === Stages.Summary || (clientToken === DEMO && stage === Stages.Start)
                        ? { visibility: 'hidden' }
                        : {}
                }
                onClick={onNextStage}
            >
                לשלב הבא
                <span style={{ marginRight: '11px' }}>&#8592;</span>
            </Button>
            {stage === Stages.Summary && (
                <div style={{ display: 'flex', gap: '18px' }}>
                    <Exports />
                    <EditOptiWaypointsBtn />
                </div>
            )}
        </StyledFooter>
    );
};

export default Footer;

import { CarInputItem } from 'src/types/globalTypes';

export const mobilePhoneRegex = new RegExp(/^05[0-9]{1}(-)?\d{7}$/);

export const digitRegex = new RegExp('[0-9]');
export const onlyDigitRegex = new RegExp('^[0-9]*$');
export const isoDateRegex = new RegExp(/^\d{4}–\d{2}–\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)Z$/);
export const positiveNumberRegex = /^[0-9]*$/;
export const fleetDateFormat = new RegExp(/^\d{4}[.|-]\d{2}[.|-]\d{2}\s\d{2}:\d{2}$/);
export const digitOrPlusSymbolRegex = /^$|^([+]?\d{1,32})$/;
export const digitOrCharRegex = /[A-Za-z0-9]/;

// 'hh:mm'
export const timeDigit = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const hasValidCoords = <T>(address: T): address is T & { lat: number; lng: number } => {
    return address && 'lat' in address && 'lng' in address && (address as any).lat && (address as any).lng;
};

export enum EnvKeys {
    GoogleMapsApiKey = 'REACT_APP_MAPS_JS_API_KEY',
    NodeEnvironment = 'NODE_ENV',
    GoogleMapsApiKeyNoRestrict = 'REACT_APP_MAPS_JS_API_KEY_NO_RESTRICT',
}

export const isDevEnv = process.env[EnvKeys.NodeEnvironment] === 'development';

export type BaseError = {
    message?: string;
};

export const MAPS_API_KEY = process.env[EnvKeys.GoogleMapsApiKey] || '';
export const MAPS_API_KEY_NO_RESTRICT = process.env[EnvKeys.GoogleMapsApiKeyNoRestrict] || '';

// eslint-disable-next-line no-var
export var CARS_INPUTS_LOCAL_STORAGE_KEY = 'carsInputItems';

export function getCarsForPricingFromLocalStorage(): CarInputItem[] | null {
    try {
        const data = JSON.parse(localStorage.getItem(CARS_INPUTS_LOCAL_STORAGE_KEY) || '');
        if (Array.isArray(data)) {
            return data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const toFixed = (num: number | undefined, digits = 1): string => {
    try {
        if (!num) {
            return '0';
        }

        if (num % 1 === 0) {
            return String(num);
        }
        return num.toFixed(digits);
    } catch (error) {
        console.log(error);
        return String(num);
    }
};

const getTextWithSeparator = (text: string | undefined | null | number, separator = ','): string => {
    return text ? `${text}${separator}` : '';
};

export const stringifyAddressV2 = (
    rawAddress: { city?: string; street?: string; houseNum?: string } | undefined,
    cityFirst?: boolean
): string => {
    try {
        if (!rawAddress) return '';

        return `${rawAddress.street || ''} ${getTextWithSeparator(rawAddress.houseNum)} ${
            rawAddress.city || ''
        }`.trim();
    } catch (error) {
        console.log(error);
        return 'לא ניתן להציג את הכתובת';
    }
};

// generate random color
export const getRandomColor = (index?: number): string => {
    if (typeof index === 'number' && index < colorPalette.length) {
        return colorPalette[index];
    }

    const randomIndex = Math.floor(Math.random() * colorPalette.length);
    return colorPalette[randomIndex];
};

export const colorPalette: string[] = [
    '#1E90FF',
    '#FF4500',
    '#8A2BE2',
    '#ffd900', // Primary and Secondary colors
    '#008000',
    '#0000FF',
    '#6495ED',
    '#ffb300', // Primary and Secondary colors
    '#FF00FF',
    '#800000',
    '#00FF00',
    '#000080',
    '#800080',
    '#008080', // Dark versions of primary and secondary colors
    '#FF0000',
    '#32CD32',
    '#7FFFD4',
    '#B22222',
    '#7FFF00',
    '#D2691E',
    '#00FFFF',
    '#DC143C',
    '#228B22',
    '#8B0000',
    '#20B2AA',
    '#ADFF2F',
    '#6B8E23',
    '#F08080',
    '#4682B4',
    '#3CB371',
    '#CD5C5C',
    '#CD853F',
    '#8B4513',
    '#00BFFF',
    '#DAA520',
    '#BC8F8F',
    '#1166d5',
    '#2E8B57',
    '#66CDAA',
    '#483D8B',
    '#006400',
    '#9ACD32',
    '#6A5ACD',
    '#4169E1',
    '#556B2F',
];

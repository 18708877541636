import React from 'react';
import './Loader.css';
import { colors } from 'src/styles/defaultTheme';
import { t } from 'src/utils/lang';
import styled from 'styled-components';

const PercText = styled.p<{ percentage: number }>`
    position: absolute;
    top: 82px;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #494949;
    ${({ percentage }) => {
        if (percentage < 10) {
            return `right: 73px;`;
        }
        if (percentage < 100) {
            return `right: 57px;`;
        }

        return `right: 41px;`;
    }}
`;

const Loader = ({ percentage }: { percentage: number }) => {
    const radius = 100; // define the radius
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;

    return (
        <div className="cont">
            <svg className="progress-ring" width="240" height="240" viewBox="0 0 240 240">
                <defs>
                    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style={{ stopColor: colors.primary, stopOpacity: 0.5 }} />
                        <stop offset="100%" style={{ stopColor: colors.primary, stopOpacity: 1 }} />
                    </linearGradient>
                </defs>
                <circle
                    className="progress-ring__circle"
                    stroke="#EEEE"
                    strokeWidth="16"
                    fill="transparent"
                    r={radius}
                    cx="120"
                    cy="120"
                />
                <circle
                    className="progress-ring__circle"
                    stroke="url(#gradient)"
                    strokeWidth="16"
                    fill="transparent"
                    r={radius}
                    cx="120"
                    cy="120"
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={offset}
                    strokeLinecap="round"
                />
            </svg>
            <PercText percentage={percentage}>{percentage}%</PercText>
            <p className="calculating">{t.calculating}</p>
        </div>
    );
};

export default Loader;

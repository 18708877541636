import { red } from '@mui/material/colors';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 6%;
    align-items: center;
`;

const MainContainer = styled.div`
    width: 50%;
    height: 90%;
`;

const Title = styled.div`
    color: #494949;
    font-size: 36px;
    font-weight: 700;
    word-wrap: break-word;
    margin-bottom: 16px;
`;

const Subtitle = styled.div`
    color: #494949;
    font-size: 20px;
    word-wrap: break-word;
    margin-bottom: 35px;
`;

const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`;

const TotalCount = styled.div`
    color: #494949;
    font-size: 14px;
`;

const MapContainer = styled.div`
    width: 30%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const WaypointsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 54%;
`;

const WaypointContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const Label = styled.div`
    text-align: right;
    color: #494949;
    font-size: 14px;
`;

const ActionIcon = styled.div`
    position: relative;
    top: 10px;
    cursor: pointer;
`;

const FieldContainer = styled.div<{ width: string }>`
    width: ${({ width }) => width};
`;

const LoaderContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
`;

export const editOptimizationStyles = {
    Container,
    MainContainer,
    Title,
    Subtitle,
    TotalCount,
    MapContainer,
    TopBar,
    WaypointsContainer,
    WaypointContainer,
    Label,
    ActionIcon,
    FieldContainer,
    LoaderContainer,
};

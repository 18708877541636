import { IOptimumRouteData, ISimulationStationToWaypoint } from 'src/types/optimization/types';
import styled from 'styled-components';
import PdfRouteMap from '../Map/PdfRouteMap';
import PdfRouteBox from './PdfRouteBox';
import * as React from 'react';
import { YitLogo } from 'src/components/InfoFooter';
import PdfWaypointsNotInRoutes from './PdfWaypointsNotInRoutes';
import { useAppSelector } from 'src/store/hooks';
import { optimizationsDataSelector } from 'src/store/slices/optimizationsSlice';

export const A4PageDiv = styled.div<{}>`
    width: 20.5cm;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 20px 20px 20px 0px;
`;

export const WaypointsNotInRoutePDFElement: React.FC<{
    stationsList: ISimulationStationToWaypoint[];
}> = ({ stationsList }) => {
    const wayPointNotInRoutes =
        useAppSelector(
            (state) => optimizationsDataSelector(state).getSimulationResult.data?.wayPointNotInRoutes
        ) || [];

    return wayPointNotInRoutes.length ? (
        <A4PageDiv>
            <div style={{ height: '100%', display: 'flex', gap: '6px', justifyContent: 'space-between' }}>
                <PdfWaypointsNotInRoutes
                    wayPointNotInRoutes={wayPointNotInRoutes}
                    stationsList={stationsList}
                />
                <PdfRouteMap waypoints={wayPointNotInRoutes} routesOverviewPath={[]} />
            </div>
        </A4PageDiv>
    ) : null;
};

const RouteElementPDF: React.FC<{
    route: IOptimumRouteData;
    routesOverviewPath: {
        lat: number;
        lng: number;
    }[];
    pageRef: React.MutableRefObject<HTMLDivElement | null>;
    isWaypointsNotInRoutes?: boolean;
    stationsList: ISimulationStationToWaypoint[];
}> = ({ route, routesOverviewPath, pageRef, stationsList }) => {
    return (
        <A4PageDiv ref={pageRef}>
            <div style={{ height: '100%', display: 'flex', gap: '6px', justifyContent: 'space-between' }}>
                {route ? <PdfRouteBox route={route} stationsList={stationsList} /> : null}
                <PdfRouteMap waypoints={route.optimumRoute} routesOverviewPath={routesOverviewPath} />
            </div>
        </A4PageDiv>
    );
};

export default RouteElementPDF;

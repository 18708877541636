import React from 'react';
import { CARS_INPUTS_LOCAL_STORAGE_KEY } from 'src/utils/utilis';

export const useOldLocalStorageDataHandler = () => {
    React.useEffect(() => {
        const key = 'last-used-app-ver';

        const currVer = process.env['REACT_APP_APP_VERSION'] || '';

        if (!currVer) {
            console.log('error, missing REACT_APP_APP_VERSION');
        }

        const lastUsedAppVer = localStorage.getItem(key) || '';

        if (
            !lastUsedAppVer || // feature wasnt implemented yet for example
            (lastUsedAppVer && lastUsedAppVer !== currVer) // versions dont match
        ) {
            console.log('version changed!');
            localStorage.setItem(CARS_INPUTS_LOCAL_STORAGE_KEY, JSON.stringify([]));
        }

        localStorage.setItem(key, currVer);
    }, []);
};

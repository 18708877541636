import React from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import EmptyDocuments from '../../../Icons/EmptyDocuments';
import { useFetchClientsOptiHistory } from 'src/hooks/useFetchClientsOptiHistory';
import { flexCenter } from 'src/styles/commonStyles';
import { uuid } from 'src/store/helpers';

export const SelectPrevOpti: React.FC<{ onClickPrevOptiItem: (hashId: string) => void }> = ({
    onClickPrevOptiItem,
}) => {
    const clientsOptiHistory = useFetchClientsOptiHistory();

    const previousOptimizations = useAppSelector(
        (state) => optimizationsRootSelector(state).data.prevOpti.history
    );

    const hasPrev = previousOptimizations.length > 0;

    const getSubTitle = () => {
        if (clientsOptiHistory.isLoading) {
            return '';
        }
        return hasPrev ? 'באפשרותך לבחור הרצה קודמת' : 'לא נמצאו הרצות קודמות עבורך';
    };

    return (
        <>
            <Container>
                <MainTitle>רשימת הרצות קודמות</MainTitle>
                {/* <Subtitle>{getSubTitle()}</Subtitle> */}
                <div
                    style={{
                        color: '#494949',
                        fontSize: '14px',
                        marginBottom: '5px',
                        marginTop: '25px',
                        width: '80%',
                    }}
                >
                    {getSubTitle()}
                </div>

                {!hasPrev && !clientsOptiHistory.isLoading && (
                    <div style={{ marginTop: '16%' }}>
                        <EmptyDocuments />
                    </div>
                )}
            </Container>
            {clientsOptiHistory.isLoading && (
                <div style={flexCenter}>
                    <CircularProgress color="inherit" />
                </div>
            )}

            {hasPrev && !clientsOptiHistory.isLoading && (
                <Select
                    size="small"
                    sx={{ width: '80%', height: '40px', marginRight: '10%' }}
                    MenuProps={{ style: { maxHeight: '20rem' }, autoFocus: false }}
                >
                    {previousOptimizations.map((prevOpti) => (
                        <MenuItem
                            key={uuid()}
                            onClick={() => onClickPrevOptiItem(prevOpti.hashId)}
                            sx={menuItemSx}
                        >
                            <MenuItemDiv>
                                <div style={{ display: 'flex', gap: '18px' }}>
                                    <span>{prevOpti.dateTime}</span>
                                    <span>{prevOpti.description}</span>
                                </div>
                            </MenuItemDiv>
                        </MenuItem>
                    ))}
                </Select>
            )}
        </>
    );
};

const MainTitle = styled.div`
    font-size: 28px;
    font-weight: 700;
    color: #494949;
`;

const Container = styled.div`
    width: 60%;
    margin-right: 10%;
`;

const MenuItemDiv = styled.div`
    font-size: 18px;
    font-weight: 400;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const menuItemSx = {
    backgroundColor: '#fff',
    '&.MuiMenuItem-root': {
        backgroundColor: '#fff',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#2196F31A',
    },
};

import React from 'react';
import { reqGetAdvancedSettingsList } from 'src/api/optimizationApi';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { advancedParamsSelector, summaryActions } from 'src/store/slices/summaryStageSlice';
import { useDispatchAlert } from './useAlert';
import { getErrWithCode } from 'src/utils/lang';
import { simulationResultSelector } from 'src/store/slices/optimizationsSlice';
import { logger } from 'src/api/loggerApi';

const INVALID_RESPONSE_DATA_STRUCTURE = 'Invalid data object structure';

export const useAdvancedParamsApi = () => {
    const dispatch = useAppDispatch();
    const dispatchAlert = useDispatchAlert();

    const [isLoading, setIsLoading] = React.useState(false);

    const api = useAppSelector((state) => advancedParamsSelector(state).api);
    const optiResult = useAppSelector((state) => simulationResultSelector(state));

    React.useEffect(() => {
        if (!optiResult || api.isSuccess) return;

        const asyncHandler = async () => {
            try {
                setIsLoading(true);

                const res = await reqGetAdvancedSettingsList();

                if (!Array.isArray(res.data)) {
                    throw new Error(INVALID_RESPONSE_DATA_STRUCTURE);
                }

                // success
                dispatch(summaryActions.setAdvancedParamsApi({ data: res.data, isSuccess: true }));

                if (optiResult.requestBody.advancedSettingsList?.length) {
                    // current opti used the advanced params ft
                    dispatch(
                        summaryActions.setAllAdvancedParamValues(optiResult.requestBody.advancedSettingsList)
                    );
                    dispatch(summaryActions.setIsOnAdvancedParamsMode(true));

                    return;
                }

                dispatch(summaryActions.setAllAdvancedParamValues(res.data));

                return;
            } catch (error: any) {
                if (error.name === 'AxiosError') {
                    logger({
                        type: 'apiReqError',
                        feature: 'Equalizer advanced params - Error with reqGetAdvancedSettingsList request',
                        data: error,
                    });
                }

                console.log(error);

                dispatch(summaryActions.setAdvancedParamsApi({ data: [], isSuccess: false }));
                dispatch(summaryActions.setIsOnAdvancedParamsMode(false));

                let errorCode = 53431;

                if (error.message === INVALID_RESPONSE_DATA_STRUCTURE) {
                    errorCode = 52423;
                }

                dispatchAlert(
                    'error',
                    getErrWithCode('תקלה במשיכת נתוני מצב מתקדם', errorCode, { withSuffix: true }),
                    true
                );
            } finally {
                setIsLoading(false);
            }
        };

        asyncHandler();
    }, []);

    return {
        isLoading,
    };
};

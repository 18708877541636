import React, { useEffect, useState } from 'react';
import BusProcessBar from 'src/components/BusProcessBar/BusProcessBar';
import DynamicBody from 'src/components/DynamicBody';
import BtnsFooter from 'src/components/BtnsFooter/Footer';
import Header from 'src/components/Header';
import { useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';
import { heights } from 'src/styles/commonStyles';
import styled from 'styled-components';
import bgSvg from '../../images/bg.svg';
import InfoFooter from 'src/components/InfoFooter';
import useNavAuth from 'src/hooks/useNavAuth';
import { authRootSelector } from 'src/store/slices/authSlice';
import PDF from 'src/components/stages/Summary/PDF/PDF';
import { useClientTokenExpirationAuth } from 'src/hooks/authUtilHooks';
import { isDevEnv } from 'src/utils/utilis';
import { useOldLocalStorageDataHandler } from 'src/hooks/useOldLocalStorageDataHandler';

const Home: React.FC = (props: {}) => {
    useNavAuth();
    useClientTokenExpirationAuth();
    useOldLocalStorageDataHandler();

    const clientToken = useAppSelector((state) => state.auth.clientToken);
    const stage = useAppSelector((state) => state.common.stage);

    React.useEffect(() => {
        if (isDevEnv) {
            console.log(clientToken);
            console.log(process.env);
        }
    }, []);

    return (
        <HomeContainer className="HomeContainer">
            <Header />
            <div
                style={{
                    maxHeight: `${heights.stepper}`,
                    minHeight: `${heights.stepper}`,
                    width: '90%',
                    alignSelf: 'center',
                }}
            >
                <BusProcessBar currStageNum={stage} />
            </div>
            <div
                style={{
                    flex: 1,
                    maxHeight: `${heights.body}`,
                    minHeight: `${heights.body}`,
                    overflow: 'hidden',
                    marginBottom: '20px',
                    backgroundColor: '#fff',
                    width: '90%',
                    alignSelf: 'center',
                    boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.1)',
                }}
            >
                <DynamicBody stage={stage} />
                <BtnsFooter />
            </div>
            <InfoFooter />
            <PDF />
        </HomeContainer>
    );
};

const HomeContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 1376px;
    min-height: 768px;
    overflow: auto;
`;

export default Home;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function CreateCoursesSvg({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="280" height="184" viewBox="0 0 280 184" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_10755_123302)">
<path d="M285 29C273.062 32.0098 280.445 30.5764 255.172 35.7844C223.58 42.2944 180.617 25.4805 160.433 58.5967C140.25 91.7129 215.017 115.909 182.233 144.879C145.9 176.984 76.8692 103.243 40.5362 127.823C14.7666 145.257 30.1563 166.449 34.8299 182" stroke="#F1F1F1" stroke-width="32" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M285 29C273.062 32.0098 280.445 30.5764 255.172 35.7844C223.58 42.2944 180.617 25.4805 160.433 58.5967C140.25 91.7129 215.017 115.909 182.233 144.879C145.9 176.984 76.8692 103.243 40.5362 127.823C14.7666 145.257 30.1563 166.449 34.8299 182" stroke="#DEDEDE" stroke-width="20" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M285 29C273.062 32.0098 280.445 30.5764 255.172 35.7844C223.58 42.2944 180.617 25.4805 160.433 58.5967C140.25 91.7129 215.017 115.909 182.233 144.879C145.9 176.984 76.8692 103.243 40.5362 127.823C14.7666 145.257 30.1563 166.449 34.8299 182" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="4 4"/>
<path d="M258.315 58.0003H220.692C220.459 58 220.235 57.9075 220.07 57.7432C219.906 57.5788 219.813 57.3559 219.812 57.1234V31.5175C219.813 31.285 219.906 31.0621 220.07 30.8977C220.235 30.7334 220.459 30.6409 220.692 30.6406H258.315C258.548 30.6409 258.772 30.7334 258.936 30.8977C259.101 31.0621 259.194 31.285 259.194 31.5175V57.1234C259.194 57.3559 259.101 57.5788 258.936 57.7432C258.772 57.9075 258.548 58 258.315 58.0003Z" fill="#494949"/>
<path d="M256.259 25.1841H251.285V16.8983C251.285 16.6659 251.378 16.443 251.543 16.2786C251.708 16.1142 251.932 16.0218 252.165 16.0215H255.38C255.613 16.0218 255.837 16.1142 256.001 16.2786C256.166 16.443 256.259 16.6659 256.259 16.8983V25.1841Z" fill="#B4BEC9"/>
<path d="M234.767 45.7109H228.214C227.953 45.7106 227.702 45.5908 227.518 45.3779C227.333 45.1649 227.229 44.8762 227.229 44.575V38.3507C227.229 38.0496 227.333 37.7608 227.518 37.5479C227.702 37.335 227.953 37.2152 228.214 37.2148H234.767C235.028 37.2152 235.279 37.335 235.464 37.5479C235.648 37.7608 235.752 38.0496 235.753 38.3507V44.575C235.752 44.8762 235.648 45.1649 235.464 45.3778C235.279 45.5908 235.028 45.7106 234.767 45.7109Z" fill="white"/>
<path d="M251.392 45.7109H244.839C244.578 45.7106 244.327 45.5908 244.143 45.3779C243.958 45.1649 243.854 44.8762 243.854 44.575V38.3507C243.854 38.0496 243.958 37.7608 244.143 37.5479C244.327 37.335 244.578 37.2152 244.839 37.2148H251.392C251.653 37.2152 251.904 37.335 252.088 37.5479C252.273 37.7608 252.377 38.0496 252.378 38.3507V44.575C252.377 44.8762 252.273 45.1649 252.089 45.3779C251.904 45.5908 251.653 45.7106 251.392 45.7109Z" fill="white"/>
<path d="M217 31.1675V27.2278C217 26.9252 217.08 26.6279 217.233 26.3661C217.385 26.1044 217.604 25.8874 217.868 25.7373L239.829 13.2263C240.102 13.071 240.412 12.9929 240.726 13.0005C241.04 13.0081 241.346 13.1011 241.61 13.2694L261.204 25.7254C261.448 25.8806 261.648 26.0945 261.788 26.3475C261.927 26.6005 262 26.8844 262 27.1729V31.1675C262 31.6228 261.818 32.0595 261.495 32.3815C261.172 32.7034 260.734 32.8843 260.277 32.8843H218.722C218.266 32.8843 217.828 32.7034 217.505 32.3815C217.181 32.0595 217 31.6228 217 31.1675Z" fill="#2196F3"/>
<path opacity="0.2" d="M260.277 31.1289H218.722C218.266 31.1289 217.828 30.9481 217.505 30.6261C217.181 30.3041 217 29.8674 217 29.4121V30.9709C217 31.4263 217.181 31.863 217.505 32.1849C217.828 32.5069 218.266 32.6878 218.722 32.6878H260.277C260.734 32.6878 261.172 32.5069 261.495 32.1849C261.818 31.863 262 31.4263 262 30.9709V29.4121C262 29.8674 261.818 30.3041 261.495 30.6261C261.172 30.9481 260.734 31.1289 260.277 31.1289Z" fill="#494949"/>
<path d="M220.954 166.317H176.378C176.102 166.316 175.837 166.207 175.642 166.012C175.446 165.817 175.336 165.553 175.336 165.278V134.939C175.336 134.664 175.446 134.4 175.642 134.205C175.837 134.01 176.102 133.901 176.378 133.9H220.954C221.23 133.901 221.495 134.01 221.691 134.205C221.886 134.4 221.996 134.664 221.996 134.939V165.278C221.996 165.553 221.886 165.817 221.691 166.012C221.495 166.207 221.23 166.316 220.954 166.317Z" fill="#494949"/>
<path d="M218.515 127.436H212.621V117.619C212.621 117.344 212.731 117.079 212.927 116.885C213.122 116.69 213.387 116.58 213.663 116.58H217.472C217.749 116.58 218.014 116.69 218.209 116.885C218.405 117.079 218.514 117.344 218.515 117.619V127.436Z" fill="#B4BEC9"/>
<path d="M193.051 151.752H185.287C184.977 151.751 184.681 151.61 184.462 151.357C184.243 151.105 184.119 150.763 184.119 150.406V143.031C184.119 142.675 184.243 142.332 184.462 142.08C184.681 141.828 184.977 141.686 185.287 141.686H193.051C193.36 141.686 193.657 141.828 193.876 142.08C194.095 142.332 194.218 142.675 194.219 143.031V150.406C194.218 150.763 194.095 151.105 193.876 151.357C193.657 151.61 193.36 151.751 193.051 151.752Z" fill="white"/>
<path d="M212.746 151.752H204.982C204.673 151.751 204.376 151.61 204.157 151.357C203.938 151.105 203.815 150.763 203.814 150.406V143.031C203.815 142.675 203.938 142.332 204.157 142.08C204.376 141.828 204.673 141.686 204.982 141.686H212.746C213.056 141.686 213.352 141.828 213.571 142.08C213.79 142.332 213.914 142.675 213.914 143.031V150.406C213.914 150.763 213.79 151.105 213.571 151.357C213.352 151.61 213.056 151.751 212.746 151.752Z" fill="white"/>
<path d="M172 134.525V129.857C172 129.499 172.095 129.147 172.276 128.836C172.456 128.526 172.716 128.269 173.028 128.091L199.049 113.268C199.372 113.084 199.739 112.992 200.111 113.001C200.483 113.01 200.845 113.12 201.159 113.319L224.373 128.077C224.662 128.261 224.9 128.515 225.065 128.814C225.23 129.114 225.317 129.45 225.317 129.792V134.525C225.317 135.065 225.102 135.582 224.719 135.964C224.336 136.345 223.817 136.559 223.276 136.559H174.041C173.5 136.559 172.98 136.345 172.598 135.964C172.215 135.582 172 135.065 172 134.525Z" fill="#2196F3"/>
<path opacity="0.2" d="M223.276 134.481H174.041C173.5 134.481 172.98 134.267 172.598 133.886C172.215 133.504 172 132.987 172 132.447V134.294C172 134.834 172.215 135.351 172.598 135.733C172.98 136.114 173.5 136.328 174.041 136.328H223.276C223.817 136.328 224.336 136.114 224.719 135.733C225.102 135.351 225.317 134.834 225.317 134.294V132.447C225.317 132.987 225.102 133.504 224.719 133.886C224.336 134.267 223.817 134.481 223.276 134.481Z" fill="#494949"/>
<path d="M81.9541 110.317H37.3783C37.1019 110.316 36.837 110.207 36.6416 110.012C36.4462 109.817 36.3363 109.553 36.3359 109.278V78.9393C36.3363 78.6639 36.4462 78.3998 36.6416 78.205C36.837 78.0103 37.1019 77.9007 37.3783 77.9004H81.9541C82.2305 77.9007 82.4954 78.0103 82.6908 78.205C82.8862 78.3998 82.9961 78.6639 82.9964 78.9393V109.278C82.9961 109.553 82.8862 109.817 82.6908 110.012C82.4954 110.207 82.2305 110.316 81.9541 110.317Z" fill="#494949"/>
<path d="M79.5147 71.4362H73.6211V61.619C73.6214 61.3436 73.7313 61.0795 73.9267 60.8847C74.1221 60.69 74.3871 60.5804 74.6634 60.5801H78.4724C78.7487 60.5804 79.0137 60.69 79.2091 60.8847C79.4045 61.0795 79.5144 61.3436 79.5147 61.619V71.4362Z" fill="#B4BEC9"/>
<path d="M54.0487 95.7519H46.2852C45.9755 95.7515 45.6786 95.6096 45.4597 95.3573C45.2407 95.1049 45.1175 94.7629 45.1172 94.406V87.0313C45.1175 86.6745 45.2407 86.3325 45.4597 86.0802C45.6787 85.8279 45.9755 85.6859 46.2852 85.6855H54.0487C54.3583 85.686 54.6552 85.8279 54.8742 86.0802C55.0931 86.3325 55.2163 86.6745 55.2167 87.0313V94.406C55.2163 94.7629 55.0932 95.1049 54.8742 95.3572C54.6552 95.6096 54.3583 95.7515 54.0487 95.7519Z" fill="white"/>
<path d="M73.744 95.7519H65.9805C65.6708 95.7515 65.3739 95.6096 65.155 95.3573C64.936 95.1049 64.8128 94.7629 64.8125 94.406V87.0313C64.8129 86.6745 64.936 86.3325 65.155 86.0802C65.374 85.8279 65.6708 85.6859 65.9805 85.6855H73.744C74.0536 85.6859 74.3505 85.8279 74.5695 86.0802C74.7884 86.3325 74.9116 86.6745 74.912 87.0313V94.406C74.9116 94.7629 74.7885 95.1049 74.5695 95.3573C74.3505 95.6096 74.0536 95.7515 73.744 95.7519Z" fill="white"/>
<path d="M33 78.5252V73.8574C33 73.4988 33.0951 73.1466 33.2756 72.8365C33.4562 72.5263 33.7158 72.2693 34.0281 72.0914L60.0486 57.2681C60.3716 57.0841 60.7389 56.9916 61.1108 57.0006C61.4827 57.0096 61.8451 57.1197 62.1588 57.3191L85.3733 72.0773C85.6624 72.2612 85.9004 72.5147 86.0653 72.8144C86.2302 73.1141 86.3166 73.4505 86.3166 73.7923V78.5252C86.3166 79.0647 86.1016 79.5821 85.7189 79.9636C85.3362 80.345 84.8171 80.5593 84.2758 80.5593H35.0408C34.4996 80.5593 33.9805 80.345 33.5977 79.9636C33.215 79.5821 33 79.0647 33 78.5252Z" fill="#2196F3"/>
<path opacity="0.2" d="M84.2758 78.4814H35.0408C34.4996 78.4814 33.9805 78.2671 33.5977 77.8856C33.215 77.5041 33 76.9867 33 76.4473V78.2942C33 78.8337 33.215 79.3511 33.5977 79.7325C33.9805 80.114 34.4996 80.3283 35.0408 80.3283H84.2758C84.8171 80.3283 85.3362 80.114 85.7189 79.7325C86.1016 79.3511 86.3167 78.8337 86.3167 78.2942V76.4473C86.3167 76.9867 86.1016 77.5041 85.7189 77.8856C85.3362 78.2671 84.8171 78.4814 84.2758 78.4814Z" fill="#494949"/>
<path d="M25 42C34.3888 42 42 34.3888 42 25C42 15.6112 34.3888 8 25 8C15.6111 8 8 15.6112 8 25C8 34.3888 15.6111 42 25 42Z" fill="#FEB42B"/>
<path d="M99 95.9297C99 88.2422 105.224 70 112.901 70C120.578 70 126.801 88.2422 126.801 95.9297C126.801 99.6214 125.337 103.162 122.73 105.772C120.123 108.383 116.587 109.849 112.901 109.849C109.214 109.849 105.678 108.383 103.071 105.772C100.465 103.162 99 99.6214 99 95.9297Z" fill="#B4BEC9"/>
<path d="M112.9 129.698C112.834 129.698 112.771 129.672 112.725 129.625C112.678 129.579 112.652 129.516 112.652 129.45V79.4686C112.652 79.4029 112.678 79.3398 112.725 79.2933C112.771 79.2468 112.834 79.2207 112.9 79.2207C112.966 79.2207 113.029 79.2468 113.075 79.2933C113.121 79.3398 113.147 79.4029 113.147 79.4686V129.45C113.147 129.516 113.121 129.579 113.075 129.625C113.029 129.672 112.966 129.698 112.9 129.698Z" fill="#3F3D56"/>
<path d="M112.9 101.634C112.851 101.634 112.804 101.62 112.764 101.593C112.723 101.566 112.691 101.528 112.672 101.484C112.653 101.439 112.648 101.39 112.656 101.342C112.665 101.294 112.687 101.25 112.721 101.215L119.787 93.7898C119.809 93.7662 119.836 93.7473 119.866 93.7341C119.896 93.7209 119.928 93.7137 119.96 93.713C119.993 93.7122 120.025 93.7178 120.055 93.7295C120.086 93.7413 120.114 93.7589 120.137 93.7813C120.161 93.8038 120.179 93.8307 120.193 93.8605C120.206 93.8902 120.213 93.9223 120.214 93.9548C120.215 93.9874 120.209 94.0198 120.197 94.0501C120.185 94.0805 120.168 94.1083 120.145 94.1318L113.079 101.557C113.056 101.581 113.028 101.601 112.997 101.614C112.967 101.627 112.933 101.634 112.9 101.634Z" fill="#3F3D56"/>
<path d="M112.902 89.1203C112.84 89.1204 112.78 89.0972 112.735 89.0553L106.628 83.4645C106.604 83.4425 106.584 83.416 106.57 83.3865C106.557 83.357 106.549 83.3251 106.547 83.2925C106.546 83.2599 106.551 83.2273 106.562 83.1967C106.573 83.166 106.59 83.1379 106.612 83.1138C106.634 83.0897 106.66 83.0702 106.69 83.0564C106.719 83.0427 106.751 83.0348 106.784 83.0334C106.816 83.032 106.849 83.0371 106.879 83.0483C106.91 83.0595 106.938 83.0766 106.962 83.0986L113.069 88.6894C113.105 88.7232 113.131 88.7673 113.143 88.8159C113.154 88.8646 113.15 88.9156 113.132 88.9621C113.114 89.0087 113.083 89.0488 113.041 89.077C113 89.1052 112.951 89.1203 112.902 89.1203Z" fill="#3F3D56"/>
</g>
<defs>
<clipPath id="clip0_10755_123302">
<rect width="280" height="184" fill="white"/>
</clipPath>
</defs>
</svg>


    );
}

export default CreateCoursesSvg;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function CreateStationsSvg({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="280" height="184" viewBox="0 0 280 184" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M256.249 129.732H23.345C20.4106 129.732 17.505 129.153 14.7939 128.029C12.0829 126.904 9.61963 125.256 7.5447 123.179C5.46978 121.101 3.82385 118.634 2.70091 115.919C1.57797 113.205 1 110.295 1 107.357C1 104.418 1.57797 101.509 2.70091 98.794C3.82385 96.0793 5.46978 93.6127 7.5447 91.5349C9.61963 89.4572 12.0829 87.809 14.7939 86.6845C17.505 85.5601 20.4106 84.9813 23.345 84.9813H255.733C258.012 84.9813 260.198 84.0746 261.81 82.4607C263.422 80.8468 264.327 78.6578 264.327 76.3754C264.327 74.093 263.422 71.904 261.81 70.2901C260.198 68.6762 258.012 67.7695 255.733 67.7695H15.6102V54H255.733C261.659 54 267.343 56.3574 271.533 60.5536C275.724 64.7498 278.078 70.4411 278.078 76.3754C278.078 82.3097 275.724 88.001 271.533 92.1972C267.343 96.3934 261.659 98.7508 255.733 98.7508H23.345C21.0657 98.7508 18.8797 99.6575 17.268 101.271C15.6562 102.885 14.7508 105.074 14.7508 107.357C14.7508 109.639 15.6562 111.828 17.268 113.442C18.8797 115.056 21.0657 115.963 23.345 115.963H256.249V129.732Z" fill="#E6E6E6"/>
<path d="M34.86 60.541H24.2031V61.2286H34.86V60.541Z" fill="white"/>
<path d="M65.11 60.541H54.4531V61.2286H65.11V60.541Z" fill="white"/>
<path d="M95.3639 60.541H84.707V61.2286H95.3639V60.541Z" fill="white"/>
<path d="M125.618 60.541H114.961V61.2286H125.618V60.541Z" fill="white"/>
<path d="M155.868 60.541H145.211V61.2286H155.868V60.541Z" fill="white"/>
<path d="M186.118 60.541H175.461V61.2286H186.118V60.541Z" fill="white"/>
<path d="M216.372 60.541H205.715V61.2286H216.372V60.541Z" fill="white"/>
<path d="M246.626 60.541H235.969V61.2286H246.626V60.541Z" fill="white"/>
<path d="M34.86 91.5215H24.2031V92.209H34.86V91.5215Z" fill="white"/>
<path d="M65.11 91.5215H54.4531V92.209H65.11V91.5215Z" fill="white"/>
<path d="M95.3639 91.5215H84.707V92.209H95.3639V91.5215Z" fill="white"/>
<path d="M125.618 91.5215H114.961V92.209H125.618V91.5215Z" fill="white"/>
<path d="M155.868 91.5215H145.211V92.209H155.868V91.5215Z" fill="white"/>
<path d="M186.118 91.5215H175.461V92.209H186.118V91.5215Z" fill="white"/>
<path d="M216.372 91.5215H205.715V92.209H216.372V91.5215Z" fill="white"/>
<path d="M246.626 91.5215H235.969V92.209H246.626V91.5215Z" fill="white"/>
<path d="M34.86 122.504H24.2031V123.191H34.86V122.504Z" fill="white"/>
<path d="M65.11 122.504H54.4531V123.191H65.11V122.504Z" fill="white"/>
<path d="M95.3639 122.504H84.707V123.191H95.3639V122.504Z" fill="white"/>
<path d="M125.618 122.504H114.961V123.191H125.618V122.504Z" fill="white"/>
<path d="M155.868 122.504H145.211V123.191H155.868V122.504Z" fill="white"/>
<path d="M186.118 122.504H175.461V123.191H186.118V122.504Z" fill="white"/>
<path d="M216.372 122.504H205.715V123.191H216.372V122.504Z" fill="white"/>
<path d="M246.626 122.504H235.969V123.191H246.626V122.504Z" fill="white"/>
<path d="M49 163L75.5 138" stroke="#B4BEC9" stroke-width="2" stroke-linecap="round"/>
<path d="M132.5 39.5L171.5 45" stroke="#B4BEC9" stroke-width="2" stroke-linecap="round"/>
<path d="M181 45L218 37" stroke="#B4BEC9" stroke-width="2" stroke-linecap="round"/>
<path d="M125 166L79 137.5" stroke="#B4BEC9" stroke-width="2" stroke-linecap="round"/>
<path d="M146.543 169.001H121.461C121.306 169.001 121.157 168.94 121.047 168.83C120.937 168.72 120.875 168.572 120.875 168.417V151.346C120.875 151.191 120.937 151.043 121.047 150.933C121.157 150.824 121.306 150.762 121.461 150.762H146.543C146.699 150.762 146.848 150.824 146.958 150.933C147.068 151.043 147.129 151.191 147.13 151.346V168.417C147.129 168.572 147.068 168.72 146.958 168.83C146.848 168.94 146.699 169.001 146.543 169.001Z" fill="#494949"/>
<path d="M145.176 147.122H141.859V141.598C141.86 141.443 141.921 141.295 142.031 141.185C142.141 141.075 142.29 141.014 142.446 141.014H144.589C144.745 141.014 144.894 141.075 145.004 141.185C145.114 141.295 145.175 141.443 145.176 141.598V147.122Z" fill="#B4BEC9"/>
<path d="M130.842 160.807H126.474C126.299 160.806 126.132 160.727 126.009 160.585C125.886 160.443 125.817 160.25 125.816 160.049V155.9C125.817 155.699 125.886 155.507 126.009 155.365C126.132 155.223 126.299 155.143 126.474 155.143H130.842C131.016 155.143 131.183 155.223 131.306 155.365C131.43 155.507 131.499 155.699 131.499 155.9V160.049C131.499 160.25 131.43 160.443 131.306 160.585C131.183 160.727 131.016 160.806 130.842 160.807Z" fill="white"/>
<path d="M141.924 160.807H137.556C137.381 160.806 137.214 160.727 137.091 160.585C136.968 160.443 136.899 160.25 136.898 160.049V155.9C136.899 155.699 136.968 155.507 137.091 155.365C137.214 155.223 137.381 155.143 137.556 155.143H141.924C142.098 155.143 142.265 155.223 142.388 155.365C142.512 155.507 142.581 155.699 142.581 155.9V160.049C142.581 160.25 142.512 160.443 142.388 160.585C142.265 160.727 142.098 160.806 141.924 160.807Z" fill="white"/>
<path d="M119 151.112V148.485C119 148.283 119.054 148.085 119.155 147.911C119.257 147.736 119.403 147.592 119.579 147.491L134.219 139.151C134.401 139.047 134.608 138.995 134.817 139C135.026 139.005 135.23 139.067 135.407 139.18L148.469 147.484C148.632 147.587 148.766 147.73 148.858 147.898C148.951 148.067 149 148.256 149 148.449V151.112C149 151.415 148.879 151.706 148.663 151.921C148.448 152.136 148.156 152.256 147.851 152.256H120.148C119.844 152.256 119.552 152.136 119.336 151.921C119.121 151.706 119 151.415 119 151.112Z" fill="#2196F3"/>
<path opacity="0.2" d="M147.851 151.086H120.148C119.844 151.086 119.552 150.965 119.336 150.751C119.121 150.536 119 150.245 119 149.941V150.981C119 151.284 119.121 151.575 119.336 151.79C119.552 152.005 119.844 152.125 120.148 152.125H147.851C148.156 152.125 148.448 152.005 148.663 151.79C148.879 151.575 149 151.284 149 150.981V149.941C149 150.245 148.879 150.536 148.663 150.751C148.448 150.965 148.156 151.086 147.851 151.086Z" fill="#494949"/>
<path d="M132.379 41.0006H105.626C105.46 41.0004 105.301 40.9346 105.183 40.8177C105.066 40.7008 105 40.5423 105 40.377V22.1685C105 22.0031 105.066 21.8447 105.183 21.7278C105.301 21.6109 105.46 21.5451 105.626 21.5449H132.379C132.545 21.5451 132.704 21.6109 132.821 21.7278C132.939 21.8447 133.005 22.0031 133.005 22.1685V40.377C133.005 40.5423 132.939 40.7008 132.821 40.8177C132.704 40.9346 132.545 41.0004 132.379 41.0006Z" fill="#494949"/>
<path d="M130.92 17.6641H127.383V11.772C127.383 11.6067 127.449 11.4482 127.566 11.3313C127.684 11.2144 127.843 11.1486 128.008 11.1484H130.294C130.46 11.1486 130.619 11.2144 130.737 11.3313C130.854 11.4482 130.92 11.6067 130.92 11.772V17.6641Z" fill="#B4BEC9"/>
<path d="M115.63 32.2604H110.971C110.785 32.2601 110.607 32.175 110.475 32.0235C110.344 31.8721 110.27 31.6668 110.27 31.4526V27.0265C110.27 26.8123 110.344 26.607 110.475 26.4556C110.607 26.3042 110.785 26.219 110.971 26.2188H115.63C115.816 26.219 115.994 26.3042 116.125 26.4556C116.257 26.607 116.331 26.8123 116.331 27.0265V31.4526C116.331 31.6668 116.257 31.8721 116.125 32.0235C115.994 32.1749 115.816 32.2601 115.63 32.2604Z" fill="white"/>
<path d="M127.454 32.2604H122.795C122.609 32.2601 122.431 32.175 122.299 32.0235C122.168 31.8721 122.094 31.6668 122.094 31.4526V27.0265C122.094 26.8123 122.168 26.607 122.299 26.4556C122.431 26.3042 122.609 26.219 122.795 26.2188H127.454C127.64 26.219 127.818 26.3042 127.95 26.4556C128.081 26.607 128.155 26.8123 128.155 27.0265V31.4526C128.155 31.6668 128.081 31.8721 127.95 32.0235C127.818 32.175 127.64 32.2601 127.454 32.2604Z" fill="white"/>
<path d="M103 21.919V19.1175C103 18.9023 103.057 18.6909 103.165 18.5048C103.274 18.3186 103.43 18.1643 103.617 18.0575L119.234 9.16091C119.428 9.05048 119.648 8.99496 119.872 9.00036C120.095 9.00576 120.312 9.07187 120.501 9.19155L134.433 18.0491C134.607 18.1595 134.75 18.3116 134.849 18.4915C134.948 18.6714 135 18.8733 135 19.0784V21.919C135 22.2428 134.871 22.5533 134.641 22.7823C134.411 23.0112 134.1 23.1399 133.775 23.1399H104.225C103.9 23.1399 103.588 23.0112 103.359 22.7823C103.129 22.5533 103 22.2428 103 21.919Z" fill="#2196F3"/>
<path opacity="0.2" d="M133.775 21.8908H104.225C103.9 21.8908 103.588 21.7621 103.359 21.5332C103.129 21.3042 103 20.9937 103 20.6699V21.7784C103 22.1022 103.129 22.4127 103.359 22.6417C103.588 22.8706 103.9 22.9993 104.225 22.9993H133.775C134.1 22.9993 134.411 22.8706 134.641 22.6417C134.871 22.4127 135 22.1022 135 21.7784V20.6699C135 20.9937 134.871 21.3042 134.641 21.5332C134.411 21.7621 134.1 21.8908 133.775 21.8908Z" fill="#494949"/>
<path d="M238.953 41.0006H218.051C217.922 41.0005 217.797 40.9491 217.706 40.8578C217.614 40.7664 217.563 40.6426 217.562 40.5135V26.2879C217.563 26.1588 217.614 26.0349 217.706 25.9436C217.797 25.8523 217.922 25.8009 218.051 25.8008H238.953C239.082 25.8009 239.206 25.8523 239.298 25.9436C239.39 26.0349 239.441 26.1588 239.441 26.2879V40.5135C239.441 40.6426 239.39 40.7664 239.298 40.8578C239.206 40.9491 239.082 41.0005 238.953 41.0006Z" fill="#494949"/>
<path d="M237.81 22.7681H235.047V18.1649C235.047 18.0357 235.099 17.9119 235.19 17.8206C235.282 17.7293 235.406 17.6779 235.536 17.6777H237.322C237.451 17.6779 237.575 17.7293 237.667 17.8206C237.759 17.9119 237.81 18.0357 237.81 18.1649V22.7681Z" fill="#B4BEC9"/>
<path d="M225.868 34.1732H222.227C222.082 34.173 221.943 34.1064 221.84 33.9881C221.738 33.8698 221.68 33.7094 221.68 33.5421V30.0842C221.68 29.9169 221.738 29.7565 221.84 29.6382C221.943 29.5199 222.082 29.4533 222.227 29.4531H225.868C226.013 29.4533 226.152 29.5199 226.255 29.6382C226.357 29.7565 226.415 29.9169 226.415 30.0842V33.5421C226.415 33.7094 226.357 33.8698 226.255 33.9881C226.152 34.1064 226.013 34.173 225.868 34.1732Z" fill="white"/>
<path d="M235.106 34.1732H231.466C231.32 34.173 231.181 34.1064 231.079 33.9881C230.976 33.8698 230.918 33.7094 230.918 33.5421V30.0842C230.918 29.9169 230.976 29.7565 231.079 29.6382C231.181 29.5199 231.32 29.4533 231.466 29.4531H235.106C235.251 29.4533 235.39 29.5199 235.493 29.6382C235.596 29.7565 235.653 29.9169 235.654 30.0842V33.5421C235.653 33.7094 235.596 33.8698 235.493 33.9881C235.39 34.1064 235.251 34.173 235.106 34.1732Z" fill="white"/>
<path d="M216 26.0931V23.9043C216 23.7362 216.045 23.5711 216.129 23.4256C216.214 23.2802 216.336 23.1597 216.482 23.0763L228.683 16.1257C228.834 16.0394 229.007 15.9961 229.181 16.0003C229.355 16.0045 229.525 16.0561 229.672 16.1496L240.558 23.0697C240.693 23.1559 240.805 23.2747 240.882 23.4153C240.959 23.5558 241 23.7135 241 23.8738V26.0931C241 26.346 240.899 26.5886 240.72 26.7675C240.54 26.9464 240.297 27.0469 240.043 27.0469H216.957C216.703 27.0469 216.46 26.9464 216.28 26.7675C216.101 26.5886 216 26.346 216 26.0931Z" fill="#2196F3"/>
<path opacity="0.2" d="M240.043 26.071H216.957C216.703 26.071 216.46 25.9705 216.28 25.7916C216.101 25.6127 216 25.3701 216 25.1172V25.9832C216 26.2362 216.101 26.4788 216.28 26.6576C216.46 26.8365 216.703 26.937 216.957 26.937H240.043C240.297 26.937 240.54 26.8365 240.72 26.6576C240.899 26.4788 241 26.2362 241 25.9832V25.1172C241 25.3701 240.899 25.6127 240.72 25.7916C240.54 25.9705 240.297 26.071 240.043 26.071Z" fill="#494949"/>
<path d="M49.707 169H26.2974C26.1523 169 26.0131 168.943 25.9105 168.84C25.8079 168.738 25.7502 168.599 25.75 168.455V152.522C25.7502 152.378 25.8079 152.239 25.9105 152.137C26.0131 152.034 26.1523 151.977 26.2974 151.977H49.707C49.8522 151.977 49.9913 152.034 50.0939 152.137C50.1965 152.239 50.2543 152.378 50.2544 152.522V168.455C50.2543 168.599 50.1965 168.738 50.0939 168.84C49.9913 168.943 49.8522 169 49.707 169Z" fill="#494949"/>
<path d="M48.4272 148.58H45.332V143.425C45.3322 143.28 45.3899 143.141 45.4925 143.039C45.5952 142.937 45.7343 142.879 45.8794 142.879H47.8798C48.0249 142.879 48.164 142.937 48.2667 143.039C48.3693 143.141 48.427 143.28 48.4272 143.425V148.58Z" fill="#B4BEC9"/>
<path d="M35.0538 161.353H30.9767C30.8141 161.353 30.6581 161.278 30.5431 161.146C30.4281 161.013 30.3635 160.833 30.3633 160.646V156.773C30.3635 156.586 30.4282 156.406 30.5431 156.274C30.6581 156.141 30.8141 156.067 30.9767 156.066H35.0538C35.2164 156.067 35.3723 156.141 35.4873 156.274C35.6023 156.406 35.667 156.586 35.6672 156.773V160.646C35.667 160.833 35.6023 161.013 35.4873 161.146C35.3723 161.278 35.2164 161.353 35.0538 161.353Z" fill="white"/>
<path d="M45.3975 161.353H41.3204C41.1578 161.353 41.0019 161.278 40.8869 161.146C40.7719 161.013 40.7072 160.833 40.707 160.646V156.773C40.7072 156.586 40.7719 156.406 40.8869 156.274C41.0019 156.141 41.1578 156.067 41.3204 156.066H45.3975C45.5601 156.067 45.7161 156.141 45.831 156.274C45.946 156.406 46.0107 156.586 46.0109 156.773V160.646C46.0107 160.833 45.9461 161.013 45.8311 161.146C45.7161 161.278 45.5601 161.353 45.3975 161.353Z" fill="white"/>
<path d="M24 152.304V149.853C24 149.665 24.0499 149.48 24.1448 149.317C24.2396 149.154 24.3759 149.019 24.5399 148.925L38.205 141.141C38.3746 141.044 38.5675 140.996 38.7628 141C38.9581 141.005 39.1484 141.063 39.3131 141.168L51.5046 148.918C51.6564 149.015 51.7814 149.148 51.868 149.305C51.9546 149.463 52 149.639 52 149.819V152.304C52 152.588 51.8871 152.859 51.6861 153.06C51.4851 153.26 51.2125 153.373 50.9282 153.373H25.0718C24.7875 153.373 24.5149 153.26 24.3139 153.06C24.1129 152.859 24 152.588 24 152.304Z" fill="#2196F3"/>
<path opacity="0.2" d="M50.9282 152.279H25.0718C24.7875 152.279 24.5149 152.167 24.3139 151.966C24.1129 151.766 24 151.494 24 151.211V152.181C24 152.464 24.1129 152.736 24.3139 152.936C24.5149 153.137 24.7875 153.249 25.0718 153.249H50.9282C51.2125 153.249 51.4851 153.137 51.6861 152.936C51.8871 152.736 52 152.464 52 152.181V151.211C52 151.494 51.8871 151.766 51.6861 151.966C51.4851 152.167 51.2125 152.279 50.9282 152.279Z" fill="#494949"/>
<path d="M254.5 135.042C263.06 135.042 270 128.093 270 119.521C270 110.949 263.06 104 254.5 104C245.94 104 239 110.949 239 119.521C239 128.093 245.94 135.042 254.5 135.042Z" fill="#B4BEC9"/>
<path d="M255.995 166.272H252.82L254.267 117.184L255.995 166.272Z" fill="#494949"/>
<path d="M254.545 126.487L257.953 121.766L254.498 127.656L254.125 127.001L254.545 126.487Z" fill="#494949"/>
<path d="M254.174 131.254L250.766 126.533L254.221 132.423L254.594 131.769L254.174 131.254Z" fill="#494949"/>
<g clip-path="url(#clip0_10827_123115)">
<rect x="68.3984" y="119" width="19.2" height="18" fill="white"/>
<path d="M72.5078 131.806C72.5078 132.365 72.9626 132.819 73.5215 132.819H82.42C82.9789 132.819 83.4337 132.365 83.4337 131.806V129.443H72.5078V131.806ZM81.108 130.241C81.6151 130.241 82.0287 130.655 82.0287 131.162C82.0287 131.669 81.6151 132.083 81.108 132.083C80.6009 132.083 80.1873 131.669 80.1873 131.162C80.1874 130.655 80.6009 130.241 81.108 130.241ZM74.8334 130.241C75.3406 130.241 75.7541 130.655 75.7541 131.162C75.7541 131.669 75.3406 132.083 74.8334 132.083C74.3263 132.083 73.9127 131.669 73.9127 131.162C73.9128 130.655 74.3264 130.241 74.8334 130.241Z" fill="#FEB42B"/>
<path d="M82.42 121.893H73.5215C72.9626 121.893 72.5078 122.347 72.5078 122.906V127.601H83.4337V122.906C83.4338 122.347 82.979 121.893 82.42 121.893Z" fill="#FEB42B"/>
<path d="M87.1766 116H68.7622C67.2391 116 66 117.239 66 118.762V137.177C66 138.7 67.2391 139.939 68.7622 139.939H87.1766C88.6996 139.939 89.9387 138.7 89.9387 137.177V118.762C89.9387 117.239 88.6996 116 87.1766 116ZM85.2737 131.805C85.2737 132.775 84.7874 133.632 84.0461 134.149V135.527C84.0461 136.035 83.6339 136.448 83.1254 136.448C82.6169 136.448 82.2047 136.035 82.2047 135.527V134.66H73.734V135.527C73.734 136.035 73.3218 136.448 72.8133 136.448C72.3048 136.448 71.8925 136.035 71.8925 135.527V134.149C71.1514 133.632 70.6649 132.775 70.6649 131.805V122.906C70.6649 121.332 71.9458 120.051 73.5201 120.051H82.4185C83.9928 120.051 85.2737 121.332 85.2737 122.906V131.805H85.2737Z" fill="#FEB42B"/>
</g>
<g clip-path="url(#clip1_10827_123115)">
<rect x="165.398" y="37" width="19.2" height="18" fill="white"/>
<path d="M169.508 49.8056C169.508 50.3645 169.963 50.8193 170.522 50.8193H179.42C179.979 50.8193 180.434 50.3646 180.434 49.8056V47.4434H169.508V49.8056ZM178.108 48.2413C178.615 48.2413 179.029 48.6548 179.029 49.1619C179.029 49.669 178.615 50.0826 178.108 50.0826C177.601 50.0826 177.187 49.669 177.187 49.1619C177.187 48.6548 177.601 48.2413 178.108 48.2413ZM171.833 48.2413C172.341 48.2413 172.754 48.6548 172.754 49.1619C172.754 49.669 172.341 50.0826 171.833 50.0826C171.326 50.0826 170.913 49.669 170.913 49.1619C170.913 48.6548 171.326 48.2413 171.833 48.2413Z" fill="#FEB42B"/>
<path d="M179.42 39.8926H170.522C169.963 39.8926 169.508 40.3474 169.508 40.9063V45.6011H180.434V40.9063C180.434 40.3474 179.979 39.8926 179.42 39.8926Z" fill="#FEB42B"/>
<path d="M184.177 34H165.762C164.239 34 163 35.2391 163 36.7622V55.1766C163 56.6997 164.239 57.9388 165.762 57.9388H184.177C185.7 57.9388 186.939 56.6997 186.939 55.1766V36.7622C186.939 35.2391 185.7 34 184.177 34ZM182.274 49.8048C182.274 50.7746 181.787 51.6324 181.046 52.1486V53.527C181.046 54.0355 180.634 54.4477 180.125 54.4477C179.617 54.4477 179.205 54.0355 179.205 53.527V52.66H170.734V53.527C170.734 54.0355 170.322 54.4477 169.813 54.4477C169.305 54.4477 168.893 54.0355 168.893 53.527V52.1486C168.151 51.6324 167.665 50.7746 167.665 49.8048V40.9063C167.665 39.332 168.946 38.0512 170.52 38.0512H179.419C180.993 38.0512 182.274 39.332 182.274 40.9063V49.8048H182.274Z" fill="#FEB42B"/>
</g>
<defs>
<clipPath id="clip0_10827_123115">
<rect width="24" height="24" fill="white" transform="translate(66 116)"/>
</clipPath>
<clipPath id="clip1_10827_123115">
<rect width="24" height="24" fill="white" transform="translate(163 34)"/>
</clipPath>
</defs>
</svg>



    );
}

export default CreateStationsSvg;

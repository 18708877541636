/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function CheckIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.32 14.16L7.74 11.58C7.52 11.36 7.24 11.25 6.9 11.25C6.56 11.25 6.28 11.36 6.06 11.58C5.84 11.8 5.73 12.08 5.73 12.42C5.73 12.76 5.84 13.04 6.06 13.26L9.48 16.68C9.72 16.92 10 17.04 10.32 17.04C10.64 17.04 10.92 16.92 11.16 16.68L17.94 9.9C18.16 9.68 18.27 9.4 18.27 9.06C18.27 8.72 18.16 8.44 17.94 8.22C17.72 8 17.44 7.89 17.1 7.89C16.76 7.89 16.48 8 16.26 8.22L10.32 14.16ZM12 24C10.34 24 8.78 23.685 7.32 23.055C5.86 22.425 4.59 21.57 3.51 20.49C2.43 19.41 1.575 18.14 0.945 16.68C0.315 15.22 0 13.66 0 12C0 10.34 0.315 8.78 0.945 7.32C1.575 5.86 2.43 4.59 3.51 3.51C4.59 2.43 5.86 1.575 7.32 0.945C8.78 0.315 10.34 0 12 0C13.66 0 15.22 0.315 16.68 0.945C18.14 1.575 19.41 2.43 20.49 3.51C21.57 4.59 22.425 5.86 23.055 7.32C23.685 8.78 24 10.34 24 12C24 13.66 23.685 15.22 23.055 16.68C22.425 18.14 21.57 19.41 20.49 20.49C19.41 21.57 18.14 22.425 16.68 23.055C15.22 23.685 13.66 24 12 24Z" fill="#00BE41"/>
</svg>
    );
}

export default CheckIcon;

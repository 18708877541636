import { useState, useEffect } from 'react';

export const useInnerWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            return setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        // Call handleResize once to ensure width is updated after component is mounted
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
};

const useInnerHeight = () => {
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            return setHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);

        // Call handleResize once to ensure width is updated after component is mounted
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return height;
};

export const useHeights = () => {
    const screenHeight = useInnerHeight();

    const headerHeight = 80;
    const footerHeight = 26;
    const stepperHeight = 100;
    const bodyHeight = screenHeight - headerHeight - footerHeight - stepperHeight;

    const heights = {
        header: headerHeight,
        infoFooter: footerHeight,
        body: bodyHeight,
        stepper: stepperHeight,
    };

    return heights;
};

import React, { FC, useState } from 'react';
import { heights } from 'src/styles/commonStyles';
import styled from 'styled-components';
import yitLogo from '../images/yitLogo.png';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    setIsSendHashIdManuallyModalOpenAction,
    setIsUsingGoogleAction,
} from 'src/store/slices/optimizationsSlice';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, Tooltip } from '@mui/material';
import BaseTooltip from './BaseTooltip';
import BaseTextField from './TextInput';
import { useGetOptimizationSimulation } from 'src/hooks/useGetOptimizationSimulationResult';
import { setClientToken } from 'src/store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { DEMO } from 'src/store/helpers';
import { Stages } from 'src/constants/common';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

interface Props {}

const InfoFooterDiv = styled.div<{}>`
    background: #ffffff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    height: ${heights.infoFooter}px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
`;

export const YitLogo: React.FC<{
    style?: React.CSSProperties;
}> = ({ style = {} }) => {
    return (
        <div
            style={{
                backgroundImage: `url(${yitLogo})`,
                width: '50px',
                height: '20px',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                ...style,
            }}
        />
    );
};

const InfoFooter: FC<Props> = ({}) => {
    const hashID = useAppSelector(
        (state) => optimizationsRootSelector(state).data.getSimulationResult.data?.hashID
    );

    const isUsingGoogle = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm.isUsingGoogle
    );

    const clientToken = useAppSelector((state) => state.auth.clientToken);

    const stage = useAppSelector((state) => state.common.stage);
    const dispatch = useAppDispatch();
    const getOptimizationSimulation = useGetOptimizationSimulation();
    const navigate = useNavigate();

    const onOpenSendHashIdManuallyModalOpen = () => {
        dispatch(setIsSendHashIdManuallyModalOpenAction(true));
    };

    const handleIsUsingGoogleSwitchChange = () => {
        dispatch(setIsUsingGoogleAction({ isUsingGoogle: !isUsingGoogle }));
    };

    return (
        <InfoFooterDiv>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <YitLogo />
                <div style={{ display: 'flex', gap: '20px', fontSize: '12px' }}>
                    <a href="https://www.y-it.co.il/" target="_blank" rel="noreferrer">
                        מי אנחנו
                    </a>
                    <a
                        href="https://www.y-it.co.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8"
                        target="_blank"
                        rel="noreferrer"
                    >
                        יצירת קשר ותמיכה
                    </a>
                    <a href="https://www.y-it.co.il/products/" target="_blank" rel="noreferrer">
                        מוצרים נוספים
                    </a>
                </div>
            </div>

            <div
                style={{
                    fontSize: '12px',
                    paddingLeft: '10px',
                    fontWeight: 400,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                }}
            >
                <div style={{ color: '#494949' }}>Ver {process.env.REACT_APP_APP_VERSION}</div>

                {/* <Switch onChange={handleIsUsingGoogleSwitchChange} checked={isUsingGoogle} size="small" /> */}

                {!clientToken ? (
                    <a
                        href={'https://www.y-it.co.il/products/my-planner/'}
                        target="_blank"
                        rel="noreferrer"
                        style={{ cursor: 'pointer', color: '#2196F3' }}
                    >
                        Demo
                    </a>
                ) : null}
                {hashID && stage !== Stages.DataLoading ? (
                    <BaseTooltip title={hashID} placement="top-end">
                        <InfoOutlinedIcon fontSize="small" htmlColor="#494949" />
                    </BaseTooltip>
                ) : null}
                {stage === Stages.DataLoading ? (
                    <BaseTooltip title={'הכנס קוד ריצה ידנית'} placement="top-end">
                        <div style={{ cursor: 'pointer' }} onClick={onOpenSendHashIdManuallyModalOpen}>
                            <InfoOutlinedIcon fontSize="small" htmlColor="#494949" />
                        </div>
                    </BaseTooltip>
                ) : null}
            </div>
        </InfoFooterDiv>
    );
};

export default InfoFooter;

import axios, { AxiosResponse } from 'axios';
import { IReverseGeoResult } from 'src/types/optimization/types';
import { convertParamsToXFormUrlEncoded } from './utilis';
import { OPTIMIZATION_SERVER_URL } from './optimizationApi';
import { Coords } from 'google-map-react';
import moment from 'moment';

export type GetGoogleDirectionsByListDataItem = {
    origin: string;
    originId: string;
    destination: string;
    destinationId: string;
    wayPointsInRoute: Coords[];
};

/**
    get polyline data 
*/
const getGoogleDirectionsByList = async ({
    params,
    data,
    mode,
}: {
    params: { clientToken: string };
    data: GetGoogleDirectionsByListDataItem[];
    mode: number;
}): Promise<
    {
        isSuccess: boolean;
        message: string;
        origin: string;
        originId: string;
        destination: string;
        destinationId: string;
        polyLine: Coords[];
        legs: unknown[];
        totalDistanceInRouteMeters: number;
        totalTimeInRouteMin: 3;
    }[]
> => {
    const url = `${OPTIMIZATION_SERVER_URL}/GoogleApi/getGoogleDirectionsByList?${convertParamsToXFormUrlEncoded(
        params
    )}`;

    const r = await axios.post(
        url,
        data.map((d) => ({
            ...d,
            language: 'he',
            region: 'il',
            mode,
        }))
    );

    return r.data;
};
const today = new Date();
export const departureTime = moment(today).add(1, 'day').toDate();

export const directionsApi = {
    getGoogleDirectionsByList,
};

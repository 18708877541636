import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { alertConfigSelector, setAlertConfigAction, setStageAction } from '../store/slices/commonSlice';
import { useDispatchAlert } from './useAlert';
import { ENDPOINTS, getCreditNeededForCalculation } from 'src/api/optimizationApi';
import { Stages } from 'src/constants/common';
import {
    optimizationsRootSelector,
    setIsNotEnoughCreditModalOpenAction,
} from 'src/store/slices/optimizationsSlice';
import { IOptimizationWaypoint } from 'src/types/optimization/types';
import { logger } from 'src/api/loggerApi';
// import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export const useGetCreditNeededForCalc = () => {
    const dispatch = useAppDispatch();
    const dispatchAlert = useDispatchAlert();
    const clientToken = useAppSelector((state) => state.auth.clientToken);
    const waypointsForOptiObject = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.waypointsForOptiObject
    );

    const currentMonthCredit = useAppSelector((state) => state.auth.currentMonthCredit);
    const generalAdditionalCredit = useAppSelector((state) => state.auth.generalAdditionalCredit);

    const getAndValidateCreditNeededForCalc = async (waypointsToCalc?: IOptimizationWaypoint[]) => {
        if (clientToken && (waypointsForOptiObject || waypointsToCalc)) {
            try {
                const res = await getCreditNeededForCalculation({
                    clientToken,
                    waypoints: waypointsToCalc || waypointsForOptiObject?.validWaypoints || [], // shouldnt reach the empty arr ([])
                });

                if (res && res.data && res.data.isSuccess) {
                    if (res.data.creditNeeded > currentMonthCredit + generalAdditionalCredit) {
                        dispatch(setIsNotEnoughCreditModalOpenAction({ isOpen: true }));
                    } else {
                        dispatch(setStageAction({ stage: Stages.TargetSelection }));
                    }
                }
            } catch (error: any) {
                if (error.name === 'AxiosError') {
                    logger({
                        type: 'apiReqError',
                        feature: `Footer / Data loading - Error with ${ENDPOINTS.GET_CREDIT_NEEDED_FOR_CALCULATION} request`,
                        data: error,
                    });
                }
                dispatchAlert('error', 'שגיאת תקשורת', true);
            }
        }
    };

    return { getAndValidateCreditNeededForCalc };
};

export default useGetCreditNeededForCalc;

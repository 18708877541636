import Chart from 'react-google-charts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setChartImgUrl, summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import styles from './styles';
import { useMemo } from 'react';
import { uuid } from 'src/store/helpers';
import * as React from 'react';
import { colorPalette } from 'src/utils/utilis';
import { t } from 'src/utils/lang';
import html2canvas from 'html2canvas';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { IOptimumRouteData } from 'src/types/optimization/types';
import { allRoutesDataSelector } from 'src/store/slices/optimizationsSlice';
import { CircularProgress } from '@mui/material';
import { flexCenter } from 'src/styles/commonStyles';

const LegendItem = ({ color, text }: { color: string; text: string }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <div style={{ width: '12px', height: '12px', backgroundColor: color, borderRadius: '50%' }} />
            <p style={{ fontWeight: 500, fontSize: '1rem' }}>{text}</p>
        </div>
    );
};

type PieChartConfig = {
    pieColors: string[];
    data: [string, number | string][];
};

const Legend = ({ config }: { config: PieChartConfig }) => {
    const processedItems: { color: string; text: string }[] = [];

    config.data.forEach((dataItem, index) => {
        if (index !== 0) {
            processedItems.push({
                color: config.pieColors[index - 1],
                text: dataItem[0],
            });
        }
    });

    return (
        <div style={{ width: '120px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {processedItems.map((item) => (
                <LegendItem key={uuid()} color={item.color} text={item.text} />
            ))}
        </div>
    );
};

const getPieSize = () => {
    const screenHeight = window.screen.height;

    if (screenHeight < 800) {
        return 150;
    }

    if (screenHeight < 900) {
        return 200;
    }

    return 200;
};

const DEFAULT_PIE_SIZE = getPieSize();

const PieChart: React.FC<{
    config: PieChartConfig;
    setChartLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ config: { pieColors, data }, setChartLoaded }) => {
    // console.log('PieChart');
    const options = {
        colors: pieColors,
        legend: 'none',
        pieSliceTextStyle: {
            color: '#030303',
            fontSize: 16,
            bold: true,
        },
        pieSliceText: 'value',
        tooltip: {
            trigger: 'none',
            textStyle: {
                color: '#030303',
                fontSize: 16,
                bold: true,
            },
        },
    };
    const isExportingToPdf = useAppSelector((state) => summaryStageSliceSelector(state).isExportingToPdf);

    const size = isExportingToPdf ? 200 : DEFAULT_PIE_SIZE;

    return (
        <Chart
            chartType="PieChart"
            data={data}
            options={{
                ...options,
                legend: 'none',
                backgroundColor: 'transparent',
                chartArea: { left: 0, top: 20, bottom: 20, right: 0 },
                height: size,
            }}
            height={`${size}px`}
            width={`${size}px`}
            onLoad={(e) => {
                // console.log('loaded');
                setChartLoaded(true);
            }}
            style={{
                padding: 0,
                margin: 0,
            }}
        />
    );
};

const getPieColors = (carsUsed: unknown[]) => {
    const pieColors = ['#21A6F3', '#BCEFFF', '#EDCD41', '#FFFDB1', '#80B1D8', ...colorPalette];

    return pieColors;
};

const getConfig = (allRoutesData: IOptimumRouteData[]) => {
    const carsUsed: { carName: string; count: number }[] = [];

    const pieColors = getPieColors(carsUsed);

    const data: PieChartConfig['data'] = [['Car type', 'Count']];

    allRoutesData.forEach((route) => {
        if (carsUsed.some((car) => car.carName === route.assignedCar?.carName)) {
            const result = carsUsed.find((car) => car.carName === route.assignedCar?.carName);
            if (result) {
                result.count += 1;
            }
        } else {
            carsUsed.push({ carName: route.assignedCar?.carName || '', count: 1 });
        }
    });

    carsUsed.forEach((car) => {
        data.push([car.carName, car.count]);
    });

    return { pieColors, data };
};

const createText = (totalCarsCount: number) => {
    return `${t.total} ${totalCarsCount} ${t.requiredCars}`;
};
export const ChartBox: React.FC<{
    isPDFOrigin?: boolean;
}> = ({ isPDFOrigin = false }) => {
    const dispatch = useAppDispatch();

    const routesStatistics = useAppSelector((state) => summaryStageSliceSelector(state).routesStatistics);
    const allRoutesData = useAppSelector((state) => allRoutesDataSelector(state));
    const isExportingToPdf = useAppSelector((state) => summaryStageSliceSelector(state).isExportingToPdf);
    const chartImgUrl = useAppSelector((state) => summaryStageSliceSelector(state).chartImgUrl);

    const config = useMemo(() => getConfig(allRoutesData), [allRoutesData]);

    const chartRef = React.useRef<HTMLDivElement | null>(null);

    const [chartLoaded, setChartLoaded] = React.useState(false);

    React.useEffect(() => {
        try {
            setTimeout(() => {
                if (chartLoaded && chartRef.current && !isPDFOrigin) {
                    html2canvas(chartRef.current, { logging: false }).then((canvas) => {
                        const dataUrl = canvas.toDataURL('image/png');
                        if (dataUrl.length > 10) {
                            dispatch(setChartImgUrl(dataUrl));
                        } else {
                            console.log('dataUrl.length < 10');
                        }
                    });
                } else {
                    if (!isPDFOrigin) {
                        if (!chartLoaded) {
                            console.log('chart not loaded ', chartLoaded);
                        } else if (!chartRef.current) {
                            console.log('chartRef.current is null', chartRef.current);
                        }
                    }
                }
            }, 10);
        } catch (error) {
            console.log(error);
        }
    }, [chartLoaded, routesStatistics]);

    return (
        <div style={{ ...styles.box, display: 'flex', flexDirection: 'column', maxHeight: '17.5rem' }}>
            <p style={{ fontWeight: 600, textAlign: 'center' }}>
                {createText(routesStatistics?.totalCarsCount || 0)}
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {isExportingToPdf && isPDFOrigin && (
                    <img
                        src={chartImgUrl}
                        style={{
                            width: '200px',
                            height: '200px',
                            objectFit: 'contain',
                            display: 'block',
                        }}
                    />
                )}
                <div ref={chartRef}>
                    {!isPDFOrigin && !isExportingToPdf ? (
                        <PieChart setChartLoaded={setChartLoaded} config={config} />
                    ) : null}
                    {isExportingToPdf && !isPDFOrigin && (
                        <div
                            style={{
                                height: `${DEFAULT_PIE_SIZE}px`,
                                width: `${DEFAULT_PIE_SIZE}px`,
                                ...flexCenter,
                            }}
                        >
                            <CircularProgress color="primary" />
                        </div>
                    )}
                </div>
                <Legend config={config} />
            </div>
        </div>
    );
};

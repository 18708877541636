import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import useDebounce from 'src/hooks/useDebounce';
import { GoogleAddressComponents, PlaceTypes, AutocompleteAddress } from 'src/types/googleMapsTypes';
import { MAPS_API_KEY } from 'src/utils/utilis';
import styled from 'styled-components';
import { getPlaceDetails, getPlaceDetailsFromReverseGeo, getPlacePredictions } from 'src/api/optimizationApi';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import { colors } from 'src/styles/defaultTheme';
import { getErrWithCode } from 'src/utils/lang';
import { logger } from 'src/api/loggerApi';

const sx = {
    width: '100%',
    '& .MuiInputBase-root': {
        padding: '4px',
    },
} as const;

export const arrsHaveSomeMatchingElement = (arr1: unknown[], arr2: unknown[]): boolean =>
    arr1.some((element) => arr2.indexOf(element) >= 0);

const isPlaceOfInterest = (placeTypes: string[]): boolean => {
    const addressTypes: string[] = [
        GoogleAddressComponents.City,
        GoogleAddressComponents.Street,
        PlaceTypes.FullAddress,
    ];

    return !arrsHaveSomeMatchingElement(addressTypes, placeTypes);
};

const DEBOUNCE_MS = 300;

export const EMPTY_ADDRESS_DATA: AutocompleteAddress = {
    city: '',
    street: '',
    houseNum: '',
    placeName: '',
    lat: 0,
    lng: 0,
} as const;

type Props = {
    inputText: string;
    setInputText: (text: string) => void;
    currentAddress: AutocompleteAddress;
    setAddressData: (addressData: AutocompleteAddress) => void;
    onSelection?: (addressData: AutocompleteAddress) => void;
    isInitialValue?: boolean;
    errors?: string[];
    controlledError?: boolean;
};

const AddressAutoComplete: React.FunctionComponent<Props> = ({
    inputText,
    setInputText,
    currentAddress,
    setAddressData,
    onSelection,
    isInitialValue = false,
    errors = [],
    controlledError = false,
}) => {
    const dispatchAlert = useDispatchAlert();

    // ---------------------- SELECTORS ----------------------
    const clientToken = useAppSelector((state) => state.auth.clientToken);
    const language = useAppSelector((state) => state.common.language);

    // ------------------------------------------------------

    // ---------------------- STATE ----------------------
    const [didUserSelectOptionFromGoogle, setDidUserSelectOptionFromGoogle] = useState<boolean | null>(
        isInitialValue ? null : false
    );
    const [placePredictions, setPlacePredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
    const [isFocused, setIsFocused] = React.useState(false);
    // ------------------------------------------------------

    const textFieldRef = React.useRef<null | any>(null);

    const debouncedInputText = useDebounce(inputText, DEBOUNCE_MS);

    // -- helpers --------------------------------

    const setAddressWithPlaceApiDetails = async (
        addressDetails: google.maps.places.AutocompletePrediction
    ) => {
        try {
            if (clientToken) {
                const response = await getPlaceDetailsFromReverseGeo({
                    clientToken,
                    googlePlace_Id: [
                        { googlePlace_Id: addressDetails.place_id, city: addressDetails.description },
                    ],
                });

                if (response && response.data && response.data[0].isSuccess) {
                    const addressData: AutocompleteAddress = {
                        city: response.data[0].waypoint.city,
                        street: response.data[0].waypoint.street,
                        houseNum: response.data[0].waypoint.houseNum,
                        lat: response.data[0].waypoint.lat,
                        lng: response.data[0].waypoint.lng,
                        placeName: response.data[0].waypoint.stationName,
                    };
                    if (onSelection) {
                        onSelection(addressData);
                    }
                    if (addressData) setAddressData(addressData);
                } else {
                    dispatchAlert('error', 'שגיאה בבחירת כתובת, אנא נסה שוב מאוחר יותר', true);
                }
            } else {
                console.log('clientTokenError');
            }
        } catch (error: any) {
            if (error.name === 'AxiosError') {
                logger({
                    type: 'apiReqError',
                    feature:
                        'Error with getting place details from reverseGeo, AddressAutocomplete component',
                    data: error,
                });
            }
            dispatchAlert('error', 'שגיאה בבחירת כתובת, אנא נסה שוב מאוחר יותר', true);
        }
    };

    // -- event handlers --------------------------------
    const handleSelectionChange = (e: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
        if (newValue) {
            // console.log('newValue', newValue);
            const addressDetails = placePredictions.find((place) => place.description === newValue);
            // console.log('addressDetails', addressDetails);

            if (addressDetails) {
                setDidUserSelectOptionFromGoogle(true);
                setAddressWithPlaceApiDetails(addressDetails);

                // blur to avoid recalling the api due to the change in the input text
                if (textFieldRef.current && 'blur' in textFieldRef.current) {
                    textFieldRef.current.blur();
                }

                return;
            }
        } else {
            // reset address data if user is typing to avoid using old address data
            setAddressData({ ...EMPTY_ADDRESS_DATA });
            setDidUserSelectOptionFromGoogle(false);
        }
    };

    const callAutocompleteApi = async () => {
        if (!debouncedInputText) return;

        try {
            if (clientToken) {
                const response = await getPlacePredictions({
                    input: debouncedInputText,
                    clientToken,
                    language,
                    region: 'il',
                    components: 'country:il',
                });

                if (response && response.data && response.data.predictions) {
                    setPlacePredictions(response.data.predictions);
                }
                // else {
                //     dispatchAlert('error', 'שגיאה במשיכת כתובות, אנא נסה שוב מאוחר יותר', true);
                // }
            } else {
                console.log('clientTokenError');
            }
        } catch (error: any) {
            if (error.name === 'AxiosError') {
                logger({
                    type: 'apiReqError',
                    feature: 'Error with autocomplete request, AddressAutocomplete component',
                    data: error,
                });
            }
            // dispatchAlert('error', 'שגיאה במשיכת כתובות, אנא נסה שוב מאוחר יותר', true);
            console.log('שגיאה במשיכת כתובות asyncHandler');
            dispatchAlert('error', getErrWithCode('תקלה במשיכת כתובות', 6412, { withSuffix: true }), true);
        }
    };

    React.useEffect(() => {
        if (!isFocused) {
            // no need to call the api if the input is not focused
            return;
        }

        callAutocompleteApi();
    }, [debouncedInputText]);

    // -- JSX ---------------------------------------------------
    return (
        <div style={{ position: 'relative', width: '100%', padding: '2px' }}>
            <Autocomplete
                freeSolo
                disablePortal
                id="size-small-standard"
                size="small"
                sx={sx}
                options={placePredictions.map((place) => place.description)}
                filterOptions={(options) => options}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            variant="outlined"
                            inputRef={textFieldRef}
                            label=""
                            error={
                                controlledError || errors.length || didUserSelectOptionFromGoogle === false
                                    ? true
                                    : false
                            }
                            placeholder="שם רחוב, מספר בית, עיר"
                            onFocus={() => {
                                setIsFocused(true);
                                callAutocompleteApi();
                            }}
                            onBlur={() => {
                                setIsFocused(false);
                            }}
                        />
                    );
                }}
                inputValue={inputText}
                onInputChange={(evt, value) => {
                    setInputText(value);
                    setDidUserSelectOptionFromGoogle(false);
                    // reset address data if user is typing to avoid using old address data
                    if (currentAddress.city) {
                        setAddressData({ ...EMPTY_ADDRESS_DATA });
                    }
                }}
                onChange={handleSelectionChange}
            />
            {errors.map((e, i) => (
                <p key={i} style={{ color: colors.muiRed }}>
                    {e}
                </p>
            ))}
        </div>
    );
};

export default AddressAutoComplete;

/*
    {
        city...
        isDirtyByUser: false
    }

*/

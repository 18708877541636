import React, { FC, useState } from 'react';
import { IconButton, Popover, TextField, Tooltip } from '@mui/material';
import styled, { css } from 'styled-components';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { MAX_TRAVEL_TIME_VALUE } from './stages/OptimizationParameters/OptimizationParameters';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    optiSliceActions,
    optimizationsRootSelector,
    setDisplaySimulationCalcLoaderAction,
    setMagicWandValueAction,
    optiUiSelector,
} from 'src/store/slices/optimizationsSlice';
import { getOptimizationWaypointsForSet } from 'src/store/helpers';
import { setIsQuickDataLoading } from 'src/store/slices/summaryStageSlice';
import { IOptimumWaypoint } from 'src/types/optimization/types';
import { useSetOptimizationSimulation } from 'src/hooks/useSetOptimizationSimulation';
import MagicToolIcon from './Icons/MagicToolIcon';
import NumberSlider from './ParametersStage/NumberSlider';
import { useWaypointsForRunningOpti } from 'src/hooks/useWaypointsForRunningOpti';

interface Prop {}

const JiraBtn = styled.button<{ isDisabled?: boolean }>`
    :hover {
        background: #e3e3e3;
    }
    background: white;
    border: 1px solid #c4c4c4;
    height: 40px;
    width: 40px;
    border-radius: 3px;
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;

export const tooltipStylesV2 = {
    '.MuiTooltip-tooltipArrow': {
        backgroundColor: 'white',
        padding: '14px',
        fontSize: 14,
        boxShadow: '0px 5px 6px rgba(217, 226, 233, 0.5)',
        borderRadius: '9px',
        color: 'black',
        border: '1px solid rgba(180, 190, 201, 0.303017)',
        fontWeight: 400,
        textAlign: 'right',
        fontFamily: 'Rubik',
    },
    '.MuiTooltip-arrow': {
        color: 'white',
        '&:before': {
            border: '1px solid rgba(180, 190, 201, 0.303017)',
        },
    },
} as const;

const minTravelTime = 5;

const MagicWandPopOver = ({}: Prop) => {
    const dispatch = useAppDispatch();
    const carsForPricingOnSlice = useAppSelector((state) => state.summaryStage.carsForPricing);
    const setOptimizationSimulationHook = useSetOptimizationSimulation();
    const { getWaypointsJsons } = useWaypointsForRunningOpti();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const waypointsForOptiObject = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.waypointsForOptiObject
    );

    const selectAllRoutesChecked = useAppSelector(
        (state) => optiUiSelector(state).summaryStageExtra.selectAllRoutesChecked
    );

    const targetWaypoint = useAppSelector((state) => optimizationsRootSelector(state).ui.targetWaypoint);

    const magicWandValue = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm.magicWandValue
    );

    const stationToWayPoints = useAppSelector(
        (state) => optimizationsRootSelector(state).data.getStationsSimulationResult.data?.stationToWayPoints
    );

    const maxWalkDistance = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm.maxWalkDistance
    );

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const inputValue = event.target.value;
    //     setMagicWandValue(inputValue);
    // };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handlePopOverClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmMagicWand = () => {
        setAnchorEl(null);

        if (waypointsForOptiObject && waypointsForOptiObject.validWaypoints && targetWaypoint) {
            const isRoutesOptiWithoutStations = maxWalkDistance === 0;

            // switch to loader component
            dispatch(setDisplaySimulationCalcLoaderAction({ display: true }));
            dispatch(setIsQuickDataLoading(true));

            dispatch(optiSliceActions.unselectAllRoutes({}));

            const { defaultWaypointsArr = [], waypointsJsonForRoutesByStationsOpti = [] } =
                getWaypointsJsons();

            setOptimizationSimulationHook.setOptimizationSimulation({
                waypointsJson: isRoutesOptiWithoutStations
                    ? defaultWaypointsArr
                    : waypointsJsonForRoutesByStationsOpti,
                wayPointsJsonForStations: defaultWaypointsArr,
                carsForPricing: carsForPricingOnSlice,
                magicWandValue: +magicWandValue,
                isQuickRefetch: true,
                stationToWayPoints,
                successHandlerOptions: {
                    reselectAllRoutes: selectAllRoutesChecked === true,
                },
            });
        }
    };

    const handleMagicWandClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMaxTravelTimeSliderChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number
    ) => {
        if (typeof newValue === 'number')
            dispatch(
                setMagicWandValueAction({
                    magicWandValue: newValue,
                    maxValue: MAX_TRAVEL_TIME_VALUE,
                    minValue: minTravelTime,
                })
            );
    };

    const handleMaxTravelTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) > MAX_TRAVEL_TIME_VALUE || Number(event.target.value) < minTravelTime)
            return;
        dispatch(setMagicWandValueAction({ magicWandValue: Number(event.target.value) }));
    };

    return (
        <>
            <Tooltip
                PopperProps={{
                    sx: tooltipStylesV2,
                }}
                title={
                    <div>
                        <span style={{ fontSize: '16px', fontWeight: 750 }}> תן לנו לעזור לך!</span> <br />{' '}
                        לחץ להגדרת זמן הנסיעה המקסימלי <br /> ואנחנו נמצא עבורך את זמן תוכנית העבודה <br />
                        המשתלמת ביותר
                    </div>
                }
                arrow
            >
                <IconButton sx={{ padding: '2px' }} onClick={handleMagicWandClick}>
                    <MagicToolIcon />
                </IconButton>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', width: '400px', padding: '20px' }}>
                    {/* <TextField
                        type="number"
                        value={magicWandValue}
                        onChange={handleChange}
                        inputProps={{
                            min: 5,
                            max: maxTravelTimeValue,
                            step: 10,
                        }}
                        onBlur={() => {
                            const restrictedValue = getValue()?.toString();
                            if (restrictedValue !== magicWandValue) {
                                setMagicWandValue(restrictedValue || '');
                            }
                        }}
                        error={+magicWandValue < 5 || +magicWandValue > maxTravelTimeValue}
                    /> */}
                    <div
                        style={{
                            marginBottom: '10px',
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#242424',
                            marginRight: '15px',
                        }}
                    >
                        הגדר זמן מקסימלי{' '}
                    </div>
                    <NumberSlider
                        max={MAX_TRAVEL_TIME_VALUE}
                        min={minTravelTime}
                        handleInputChange={handleMaxTravelTimeInputChange}
                        handleSliderChange={handleMaxTravelTimeSliderChange}
                        step={10}
                        value={magicWandValue}
                    />
                    <div style={{ display: 'flex' }}>
                        <JiraBtn type="button" onClick={handlePopOverClose}>
                            <ClearIcon fontSize="small" htmlColor="#696969" />
                        </JiraBtn>
                        <JiraBtn
                            isDisabled={+magicWandValue < 5 || +magicWandValue > MAX_TRAVEL_TIME_VALUE}
                            type="button"
                            onClick={handleConfirmMagicWand}
                        >
                            <DoneIcon fontSize="small" htmlColor="#696969" />
                        </JiraBtn>
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default MagicWandPopOver;

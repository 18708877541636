import React from 'react';
import { useAppSelector } from 'src/store/hooks';
import { summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import { allRoutesDataSelector, simulationResultSelector } from 'src/store/slices/optimizationsSlice';
import { ISimulationStationToWaypoint } from 'src/types/optimization/types';

export const usePdfStationsPassengersCount = () => {
    const stationToWayPoints =
        useAppSelector((state) => simulationResultSelector(state)?.stationToWayPoints) || [];

    const allRoutesData = useAppSelector((state) => allRoutesDataSelector(state));
    const isExportingToPdf = useAppSelector((state) => summaryStageSliceSelector(state).isExportingToPdf);

    const [stationsPerRoute, setStationsPerRoute] = React.useState<
        Record<string, ISimulationStationToWaypoint[]>
    >({});
    const [stationsNotInRoutes, setStationsNotInRoutes] = React.useState<ISimulationStationToWaypoint[]>([]);

    React.useEffect(() => {
        if (!isExportingToPdf) return;

        // finding stations ids list for each route
        const updatedStationsPerRoute: typeof stationsPerRoute = {};
        const stationIdsInRoutes: Record<string, null> = {};

        allRoutesData.forEach((r) => {
            r.optimumRoute.forEach((ow) => {
                stationToWayPoints.forEach((s) => {
                    if (s.stationPlaceId === ow.place_id) {
                        if (!updatedStationsPerRoute[r.routeID]) updatedStationsPerRoute[r.routeID] = [];
                        updatedStationsPerRoute[r.routeID].push(s);

                        stationIdsInRoutes[s.stationId];
                    }
                });
            });
        });

        setStationsPerRoute(updatedStationsPerRoute);
        setStationsNotInRoutes(stationToWayPoints.filter((s) => !stationIdsInRoutes[s.stationId]));
    }, [isExportingToPdf]);

    return {
        stationsPerRoute,
        stationsNotInRoutes,
    };
};

import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    epoAddWaypointAction,
    optimizationsRootSelector,
    optiUiSelector,
    resetWaypointsForOptiObject,
    setEditPrevOptimizationAction,
    updateInputTextByWaypointId,
} from 'src/store/slices/optimizationsSlice';
import { editOptimizationStyles } from './EditPrevOptimization.styles';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Coords, fitBounds } from 'google-map-react';
import MapType2, { defaultCenter } from 'src/components/MapType2';
import { WaypointItem } from './WaypointItem';
import styles from 'src/styles/mapStyles/styles';
import { useGetOptimizationSimulation } from 'src/hooks/useGetOptimizationSimulationResult';
import { NewWaypointItem } from './NewWaypointItem';
import { CircularProgress } from '@mui/material';
import RightArrowVector from 'src/components/Icons/RightArrowVector';
import List from 'rc-virtual-list';
import ControlledSearchInput from 'src/components/ControlledSearchInput';
import { uuid } from 'src/store/helpers';
import { EMPTY_ADDRESS_DATA } from 'src/components/AddressAutoComplete';
import AddBtn from 'src/components/AddBtn/AddBtn';
import { t } from 'src/utils/lang';

export const EditPrevOptimization: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const optimizationDescription = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.optimizationDescription
    );

    const fieldsValues = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.editPrevOptimizationScreen.fieldsValues
    );

    const inputsTextByWaypointId = useAppSelector(
        (state) => optiUiSelector(state).editPrevOptimizationScreen.inputsTextByWaypointId
    );

    const [listHeight, setListHeight] = React.useState(200);
    const [freeSearchInputText, setFreeSearchInputText] = React.useState('');

    const [newWaypoint, setNewWaypoint] = useState<{ lat: number; lng: number } | null>(null);

    const [zoom, setZoom] = useState<number>(8);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [center, setCenter] = useState<Coords>(defaultCenter);

    const maxDivRef = useRef<HTMLInputElement | null>(null);
    const listContRef = React.useRef<HTMLDivElement | null>(null);

    const GoogleMapProps: GoogleMap['props'] = {
        center,
        onZoomChanged(this: google.maps.Map) {
            try {
                setZoom(zoom);
            } catch (error) {
                console.log(error);
            }
        },
        options: {
            fullscreenControl: false,
        },
        onLoad: (googleMap) => {
            // Saw this on official docs, not doing anything with it yet
            setMap(googleMap);
        },
    };

    const centerAllPointsOnMap = () => {
        const relevantWaypoints = fieldsValues.filter(
            (waypoint) => waypoint.lat && waypoint.lng && waypoint.lat !== 0 && waypoint.lng !== 0
        );

        if (relevantWaypoints) {
            if (relevantWaypoints.length === 1 && relevantWaypoints[0]) {
                setCenter({ lat: relevantWaypoints[0].lat, lng: relevantWaypoints[0].lng });
                setZoom(12);
            }

            if (relevantWaypoints.length > 1 && maxDivRef.current) {
                const bounds = relevantWaypoints.reduce((acc, { lat, lng }) => {
                    const latLng = new google.maps.LatLng(lat, lng);
                    const coords: Coords = { lat: latLng.lat(), lng: latLng.lng() };
                    return acc.extend(coords);
                }, new google.maps.LatLngBounds());

                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();

                const neCoords: Coords = {
                    lat: ne.lat(),
                    lng: ne.lng(),
                };

                const swCoords: Coords = {
                    lat: sw.lat(),
                    lng: sw.lng(),
                };

                const rect = maxDivRef.current.getBoundingClientRect();

                const fittedBounds = fitBounds(
                    { ne: neCoords, sw: swCoords },
                    { width: rect.width, height: rect.height }
                );

                setCenter(fittedBounds.center);
                map?.setZoom(fittedBounds.zoom - 1);
            }
        }
    };

    const onClickReturn = () => {
        dispatch(
            setEditPrevOptimizationAction({
                isOpen: false,
                hashId: null,
            })
        );
        dispatch(resetWaypointsForOptiObject());
    };

    const filteredData = React.useMemo(() => {
        return fieldsValues.filter(
            (waypoint) =>
                (waypoint.city && waypoint.city.toLowerCase().includes(freeSearchInputText)) ||
                (waypoint.street && waypoint.street.toLowerCase().includes(freeSearchInputText)) ||
                (waypoint.houseNum && waypoint.houseNum.toLowerCase().includes(freeSearchInputText)) ||
                waypoint.firstName?.toLowerCase().includes(freeSearchInputText) ||
                waypoint.lastName?.toLowerCase().includes(freeSearchInputText)
        );
    }, [fieldsValues, freeSearchInputText]);

    // ---------------------------------- Effects ----------------------------------
    React.useEffect(() => {
        centerAllPointsOnMap();
    }, [fieldsValues]);

    useEffect(() => {
        if (!listContRef.current || !fieldsValues.length) return;
        setListHeight(listContRef.current.clientHeight);
    }, [fieldsValues.length]);
    // ---------------------------------- / Effects ----------------------------------

    return (
        <>
            <editOptimizationStyles.Container>
                <editOptimizationStyles.MainContainer>
                    <div
                        onClick={onClickReturn}
                        style={{ display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer' }}
                    >
                        <RightArrowVector />
                        <div style={{ fontWeight: 400, color: '#494949' }}>חזרה</div>
                    </div>

                    <editOptimizationStyles.Title>
                        שם הרצה - {optimizationDescription}
                    </editOptimizationStyles.Title>
                    <editOptimizationStyles.Subtitle>
                        באפשרותך להוסיף נ.צ חדשים למסלול, לערוך ולמחוק
                    </editOptimizationStyles.Subtitle>

                    <editOptimizationStyles.TopBar>
                        <ControlledSearchInput
                            style={{ width: '30%' }}
                            onChange={(ev) => {
                                setFreeSearchInputText(ev.target.value);
                            }}
                            value={freeSearchInputText}
                        />
                        <editOptimizationStyles.TotalCount>
                            סה״כ {fieldsValues.length} נ.צ
                        </editOptimizationStyles.TotalCount>
                    </editOptimizationStyles.TopBar>

                    <editOptimizationStyles.WaypointsContainer ref={listContRef}>
                        <div style={{}}>
                            <AddBtn
                                disabled={false}
                                text={t.add}
                                handleClick={() => {
                                    dispatch(
                                        epoAddWaypointAction({
                                            waypoint: {
                                                ...EMPTY_ADDRESS_DATA,
                                                placeName: '',
                                                waypointId: uuid(),
                                                firstName: '',
                                                isTarget: false,
                                                lastName: '',
                                            },
                                        })
                                    );
                                }}
                                // style={{ fontSize: '20px' }}
                            />
                        </div>
                        <List
                            data={filteredData}
                            itemKey={'waypointId'}
                            itemHeight={61}
                            height={listHeight}
                            dir="rtl"
                        >
                            {(waypoint, i) => (
                                <div key={waypoint.waypointId} style={i !== 0 ? { paddingTop: 20 } : {}}>
                                    <WaypointItem
                                        waypoint={waypoint}
                                        setCenter={setCenter}
                                        map={map}
                                        centerAll={centerAllPointsOnMap}
                                        inputText={inputsTextByWaypointId[waypoint.waypointId] || ''}
                                        setInputText={(text) => {
                                            dispatch(
                                                updateInputTextByWaypointId({
                                                    text,
                                                    waypointId: waypoint.waypointId,
                                                })
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </List>
                    </editOptimizationStyles.WaypointsContainer>
                </editOptimizationStyles.MainContainer>

                <editOptimizationStyles.MapContainer>
                    <styles.Container className="Container">
                        <styles.MapDiv className="MapDiv" ref={maxDivRef} isOpen>
                            <MapType2 GoogleMapProps={GoogleMapProps}>
                                {fieldsValues
                                    .filter((w) => w.lat && w.lng)
                                    .map((waypoint) => (
                                        <Marker
                                            key={waypoint.waypointId}
                                            position={{
                                                lat: waypoint.lat,
                                                lng: waypoint.lng,
                                            }}
                                        />
                                    ))}
                                {newWaypoint && (
                                    <Marker
                                        key={uuid()}
                                        position={{
                                            lat: newWaypoint.lat,
                                            lng: newWaypoint.lng,
                                        }}
                                    />
                                )}
                            </MapType2>
                        </styles.MapDiv>
                    </styles.Container>
                </editOptimizationStyles.MapContainer>
            </editOptimizationStyles.Container>
        </>
    );
};

import * as React from 'react';
export const PersonIcon = ({ fill = '#0000ff00' }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_9611_132517" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_9611_132517)">
                <path
                    d="M20.2092 20.0902C20.5279 20.0902 20.795 19.9869 21.0106 19.7804C21.2262 19.5739 21.334 19.318 21.334 19.0128V17.4402C21.334 16.8258 21.1685 16.2776 20.8374 15.7954C20.5063 15.3133 20.0642 14.9381 19.5111 14.67C18.3284 14.1159 17.1392 13.6887 15.9434 13.3884C14.7476 13.0881 13.4334 12.938 12.0006 12.938C10.5679 12.938 9.25368 13.0881 8.05791 13.3884C6.86211 13.6887 5.67289 14.1159 4.49022 14.67C3.93704 14.9381 3.49493 15.3133 3.16389 15.7954C2.83284 16.2776 2.66732 16.8258 2.66732 17.4402V19.0128C2.66732 19.318 2.77511 19.5739 2.99069 19.7804C3.20627 19.9869 3.4734 20.0902 3.79209 20.0902H20.2092ZM12.0006 11.0123C13.1984 11.0123 14.2238 10.6037 15.0767 9.78671C15.9297 8.96965 16.3562 7.98746 16.3562 6.84013C16.3562 5.69278 15.9297 4.71058 15.0767 3.89355C14.2238 3.0765 13.1984 2.66797 12.0006 2.66797C10.8029 2.66797 9.77751 3.0765 8.92454 3.89355C8.0716 4.71058 7.64512 5.69278 7.64512 6.84013C7.64512 7.98746 8.0716 8.96965 8.92454 9.78671C9.77751 10.6037 10.8029 11.0123 12.0006 11.0123Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { colors } from 'src/styles/defaultTheme';
import { Button } from '@mui/material';
// import { styleIcon } from '../../../../Passengers/css-styles';

interface Props {
    handleClick?: () => void;
    disabled: boolean;
    style?: any;
    text: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AddBtn: React.FC<Props> = ({ handleClick, disabled, style, text }) => {
    return (
        <>
            <Button
                onClick={handleClick}
                disabled={disabled}
                style={{
                    color: disabled ? colors.gray : colors.primary,
                    ...style,
                }}
            >
                <AddIcon />
                {text}
            </Button>
        </>
    );
};
export default AddBtn;

import styled from 'styled-components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useAppDispatch } from 'src/store/hooks';
import { t } from 'src/utils/lang';
import { flexCenter } from 'src/styles/commonStyles';
import { toFixed } from 'src/utils/utilis';
import { RouteWaypointsList } from './PdfWaypointsNotInRoutes';
import { getIconByCarCode } from '../../CarTypesPricing/CarInput';
import * as React from 'react';
import { IOptimumRouteData, ISimulationStationToWaypoint } from 'src/types/optimization/types';

const styles = {
    footerAndHeaderBaseStyles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '30px',
    },
} as const;

const FooterDataItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 10px;
    font-size: 14px;

    * {
        font-size: 13px;
        font-weight: 400;
    }
`;

const StyledBoxWrapper = styled.div`
    width: 340px;
    /* min-height: 285px */
    display: flex;
    flex-direction: column;
    background: white;
    overflow: hidden;
    height: 100%;
    border: 1px solid rgba(156, 156, 156, 0.277);
`;

const Separator = styled.div`
    height: 20px;
    width: 2px;
    background-color: #e0e0e0;
    margin: 0 10px;
`;

export const EllipsisText = styled.p<{}>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const PdfRouteBox = ({
    route,
    stationsList,
}: {
    route: IOptimumRouteData;
    stationsList: ISimulationStationToWaypoint[];
}) => {
    const dispatch = useAppDispatch();

    const {
        routeID,
        routeName,
        assignedCar,
        maxDistanceInRoute,
        maxTimeInRoute,
        numberOfPassangersInRoute,
        optimumRoute: waypoints,
        numberOfWayPointsInRoute,
        routePrice,
        maxValueInRoute,
        color,
    } = route || {};

    return (
        <StyledBoxWrapper>
            <div
                style={{
                    ...styles.footerAndHeaderBaseStyles,
                    borderBottom: '1px solid rgba(180, 190, 201, 0.303017)',
                }}
            >
                <div style={{ gap: '8px', ...flexCenter }}>
                    <LocationOnIcon sx={{ color }} fontSize="small" />
                    <p style={{ fontSize: '1rem', fontWeight: 'bold', padding: '6px' }}>{routeName}</p>
                </div>
                <div style={{ gap: '8px', paddingLeft: '8px', ...flexCenter }}>
                    <PeopleAltOutlinedIcon fontSize="small" />
                    <p>{numberOfPassangersInRoute}</p>
                </div>
            </div>
            <RouteWaypointsList routeWaypoints={waypoints} stationsList={stationsList} />
            {/* <RouteWaypointsList routeWaypoints={passengers} /> */}
            <div style={{ ...styles.footerAndHeaderBaseStyles, backgroundColor: '#E6F6FE' }}>
                <FooterDataItem style={{}}>
                    <span>
                        {toFixed(maxDistanceInRoute)} {t.km}
                    </span>
                    <span>
                        {toFixed(maxTimeInRoute, 0)} {t.minutes}
                    </span>
                </FooterDataItem>
                <Separator />
                <FooterDataItem>
                    <p>{routePrice === null ? '0' : toFixed(routePrice)} ₪</p>
                </FooterDataItem>
                <Separator />
                <FooterDataItem>
                    {assignedCar?.carId ? getIconByCarCode(assignedCar?.carId, '#494949') : null}
                    <p>{assignedCar?.carDescription || assignedCar?.carName || 'לא נמצא רכב מתאים'}</p>
                </FooterDataItem>
            </div>
        </StyledBoxWrapper>
    );
};

export default PdfRouteBox;

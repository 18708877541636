import React from 'react';
import { useAppDispatch } from 'src/store/hooks';
import { Button, IconButton, ListItemIcon, ListItemText, MenuItem, Select, Tooltip } from '@mui/material';
import BaseTextField, { LabelStyled } from 'src/components/TextInput';
import { t } from 'src/utils/lang';
import { removeCarInputItemByInputId } from 'src/store/slices/carsStageSlice';
import { CarInputItem } from 'src/types/globalTypes';
import { defaultCarIds, defaultCarsTypes } from './utils';
import { CarType } from 'src/components/stages/Summary/types';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import TrashIcon from 'src/components/Icons/TrashIcon';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';
import { tooltipStylesV2 } from 'src/components/BaseTooltip';

const defaultSx = {
    backgroundColor: 'white',
    width: '140px',
    gap: '4px',
    display: 'flex',
    flexDirection: 'column',
} as const;

const onKeyDown = (e: any) => {
    try {
        // disable arrow keys and mouse wheel
        if ([38, 40, 37, 39].indexOf(e.keyCode) > -1) {
            e.preventDefault();
        }
    } catch (error) {
        console.log(error);
    }
};

export const valueLimits = {
    capacity: {
        min: 0,
        max: 100,
    },
    pricePerKm: {
        min: 0,
        max: 10_000,
    },
    pricePerHour: {
        min: 0,
        max: 10_000,
    },
};

export const getIconByCarCode = (carCode: string, htmlColor = '#777777') => {
    if (carCode === defaultCarIds.taxi) {
        return <LocalTaxiIcon htmlColor={htmlColor} />;
    } else if (
        carCode === defaultCarIds.minibus1 ||
        carCode === defaultCarIds.minibus2 ||
        carCode === defaultCarIds.minibus3 ||
        carCode === defaultCarIds.minibus4
    ) {
        return <AirportShuttleIcon htmlColor={htmlColor} />;
    } else if (carCode === defaultCarIds.bus) {
        return <DirectionsBusIcon htmlColor={htmlColor} />;
    } else {
        return <PanoramaFishEyeIcon htmlColor={htmlColor} />;
    }
};

const CarInput: React.FC<{
    menuItems: CarType[];
    value: CarType;
    setCarsInputValues: (newValues: CarType) => void;
    inputId: string;
}> = ({ menuItems, value: values, setCarsInputValues: setInput, inputId }) => {
    const areTextInputsDisabled = values.carId ? false : true;
    const dispatch = useAppDispatch();
    const { hasStationsFlow } = useSelectedOptiFlowType();

    // console.log('value', values);

    return (
        <div style={{ paddingBottom: '16px', width: '100%' }}>
            <div style={{ display: 'flex', gap: '14px', alignItems: 'center', paddingBottom: '4px' }}>
                <Tooltip
                    PopperProps={{
                        sx: tooltipStylesV2,
                    }}
                    title={hasStationsFlow ? <>בביצוע אופטימזציה של תחנות , אין משמעות לסוג הרכב</> : null}
                    arrow
                >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <LabelStyled disabled={values.isGeneric}>{t.carTypeName}</LabelStyled>
                        <Select
                            value={values.carId}
                            size="small"
                            sx={{
                                width: '200px',
                                height: '40px',
                                '.Mui-disabled': { opacity: 0.5 },
                            }}
                            disabled={values.isGeneric}
                            error={!values.carId ? true : false}
                            onChange={(e) => {
                                const newCar = defaultCarsTypes.find((item) => item.carId === e.target.value);

                                if (!newCar) {
                                    return;
                                }

                                const newValues: CarType = {
                                    ...values,
                                    carDescription: '',
                                    carName: newCar.carName,
                                    carId: newCar.carId,
                                    capacity: newCar.capacity,
                                };

                                setInput(newValues);
                            }}
                            renderValue={(selectedCarId) => {
                                const car = menuItems.find((item) => item.carId === selectedCarId);

                                if (!car) {
                                    console.log('car not found', selectedCarId, menuItems);
                                    return <div>{selectedCarId}</div>;
                                }

                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        {getIconByCarCode(selectedCarId)} {car.carName}
                                    </div>
                                );
                            }}
                        >
                            {menuItems.map((item) => (
                                <MenuItem key={item.carId} value={item.carId} sx={{ display: 'flex' }}>
                                    <ListItemIcon>{getIconByCarCode(item.carId)}</ListItemIcon>
                                    <ListItemText>{item.carName}</ListItemText>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </Tooltip>
                <Tooltip
                    PopperProps={{
                        sx: tooltipStylesV2,
                    }}
                    title={
                        hasStationsFlow ? (
                            <>בביצוע אופטימזציה של תחנות , אין משמעות לכמות הנוסעים ברכב</>
                        ) : null
                    }
                    arrow
                >
                    <div>
                        <BaseTextField
                            containerStyle={defaultSx}
                            label={t.passengersCapacity}
                            inputProps={{
                                value: values.isGeneric ? '' : values.capacity,
                                type: 'number',
                                onChange: (e) => {
                                    const target = e.target as HTMLInputElement;
                                    let { value } = target;

                                    if (value[0] === '0') {
                                        // remove the first zero because numbers can't start with zero
                                        value = target.value.slice(1);
                                    }

                                    const valueNum: number = +value;

                                    if (
                                        valueNum < valueLimits.capacity.min ||
                                        valueNum > valueLimits.capacity.max
                                    ) {
                                        return;
                                    }
                                    const newValues = {
                                        ...values,
                                        capacity: valueNum,
                                    };

                                    setInput(newValues);
                                },
                                onKeyDown,
                                disabled: values.isGeneric || areTextInputsDisabled,
                                error: values.carId && !values.capacity ? true : false,
                            }}
                        />
                    </div>
                </Tooltip>

                <BaseTextField
                    containerStyle={defaultSx}
                    label={`${t.pricePerKm} (₪)`}
                    inputProps={{
                        value: values.pricePerKm,
                        type: 'number',
                        onChange: (e) => {
                            const target = e.target as HTMLInputElement;

                            if (
                                +target.value < valueLimits.pricePerKm.min ||
                                +target.value > valueLimits.pricePerKm.max
                            ) {
                                return;
                            }

                            const newValues = {
                                ...values,
                                pricePerKm: +target.value,
                            };

                            setInput(newValues);
                        },
                        onKeyDown,
                        disabled: areTextInputsDisabled,
                    }}
                />
                <BaseTextField
                    label={`${t.pricePerHour} (₪)`}
                    containerStyle={defaultSx}
                    inputProps={{
                        value: values.pricePerHour,
                        type: 'number',
                        onChange: (e) => {
                            const target = e.target as HTMLInputElement;

                            if (
                                +target.value < valueLimits.pricePerHour.min ||
                                +target.value > valueLimits.pricePerHour.max
                            ) {
                                return;
                            }

                            const newValues = {
                                ...values,
                                pricePerHour: +target.value,
                            };

                            setInput(newValues);
                        },
                        onKeyDown,
                        disabled: areTextInputsDisabled,
                    }}
                />
                {values.isGeneric ? null : (
                    <IconButton
                        onClick={() => {
                            dispatch(removeCarInputItemByInputId(inputId));
                        }}
                        sx={{ position: 'relative', top: '8px' }}
                    >
                        <TrashIcon />
                    </IconButton>
                )}
            </div>
            <div style={{ display: 'flex' }}>
                {values.carId === defaultCarIds.other && (
                    <BaseTextField
                        containerStyle={{ ...defaultSx, width: '100%' }}
                        label={`תיאור`}
                        inputProps={{
                            value: values.carDescription || '',
                            onChange: (e) => {
                                const target = e.target as HTMLInputElement;
                                const { value } = target;
                                // validate value
                                if (value.length > 12) {
                                    return;
                                }

                                const newValues = {
                                    ...values,
                                    carDescription: value,
                                };

                                setInput(newValues);
                            },
                            onKeyDown,
                            disabled: areTextInputsDisabled,
                        }}
                    />
                )}
                <div className="filler" style={{ width: '50px' }} />
            </div>
        </div>
    );
};

export default CarInput;

import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import { t } from 'src/utils/lang';
import RoutesBoxesList from './RoutesBoxsList/RoutesBoxsList';
import {
    allRoutesDataSelector,
    optiSliceActions,
    summaryStageExtraSelector,
} from 'src/store/slices/optimizationsSlice';

const getText = (selectedRoutesCount: number) => {
    if (selectedRoutesCount === 0) {
        return t.noRoutesSelectedToDisplay;
    } else if (selectedRoutesCount === 1) {
        return 'נבחר מסלול אחד להצגה במפה';
    } else {
        return `נבחרו ${selectedRoutesCount} מסלולים להצגה במפה`;
    }
};

const RoutesListSection: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const allRoutesData = useAppSelector((state) => allRoutesDataSelector(state));

    const filteredRoutes = useAppSelector((state) => state.optimization.ui.summaryStageExtra.filteredRoutes);

    const selectAllRoutesChecked = useAppSelector(
        (state) => summaryStageExtraSelector(state).selectAllRoutesChecked
    );
    const selectedRouteUuids = useAppSelector((state) => summaryStageExtraSelector(state).selectedRouteUuids);
    const didSelectUnusableWaypoints = useAppSelector(
        (state) => summaryStageExtraSelector(state).didSelectUnusableWaypoints
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '6px 17px 6px 6px',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="checkbox"
                        checked={selectAllRoutesChecked}
                        onChange={() => {}}
                        onClick={() => {
                            if (selectAllRoutesChecked) {
                                dispatch(optiSliceActions.unselectAllRoutes({}));
                            } else {
                                dispatch(optiSliceActions.selectAllRoutes());
                            }
                        }}
                    />
                    <p style={{ margin: '0 10px', fontSize: '0.9rem' }}>{t.selectAll}</p>
                </div>
                <div style={{ display: 'flex', gap: '30px' }}>
                    <p style={{ fontSize: '0.9rem' }}>{`${t.routesList} (${allRoutesData.length})`}</p>
                    <p style={{ fontSize: '0.9rem' }}>{getText(selectedRouteUuids.length)}</p>
                </div>
            </div>
            <RoutesBoxesList
                allRoutesData={filteredRoutes}
                selectedRouteUuids={selectedRouteUuids}
                didSelectUnusableWaypoints={didSelectUnusableWaypoints}
            />
        </div>
    );
};

export default RoutesListSection;

import React from 'react';
import { ISimulationStationToWaypoint } from 'src/types/optimization/types';
import { stringifyPassengerWaypoint } from '../PDF/utils';
import styled from 'styled-components';

const PassengerItem = styled.div`
    display: flex;
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid rgba(180, 190, 201, 0.303017);
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    justify-content: space-between;
`;

export const passengersListContStyles = {
    distanceCont: {
        minWidth: '60px',
        paddingTop: 2,
        paddingBottom: 2,
        background: '#E6F6FE',
        borderRadius: 4,
        border: '0.50px #2196F3 solid',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
};

export const PassengersListCont: React.FC<{
    passengers: ISimulationStationToWaypoint['wayPoints'];
}> = ({ passengers: passengers }) => {
    return (
        <div
        // style={{ overflow: 'auto', maxHeight: '300px' }}
        >
            {passengers.map((pass, i) => (
                <PassengerItem key={pass.place_id} style={{ flex: 1 }}>
                    <div style={{ display: 'flex', gap: '7px' }}>
                        {pass.isTarget ? null : <span style={{ color: '#919191' }}>{i + 1} -</span>}
                        <div>{stringifyPassengerWaypoint(pass)}</div>
                    </div>
                    <div style={passengersListContStyles.distanceCont}>
                        <p style={{ color: '#494949', fontSize: 12, fontFamily: 'Rubik' }}>
                            {pass.distanceToStationInMeters} מ׳
                        </p>
                    </div>
                </PassengerItem>
            ))}
        </div>
    );
};

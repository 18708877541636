import { colors } from 'src/styles/defaultTheme';

const styles = {
    box: {
        padding: '12px 8px',
        alignItems: 'center',
        boxShadow: '0px 0px 8px rgba(106, 106, 106, 0.2)',
        borderRadius: '8px',
    },
    tabsSx: {
        marginBottom: '20px',
        width: '100%',
        '& .MuiTabs-indicator': {
            backgroundColor: colors.primary,
        },
        '& .MuiTab-root': {
            fontWeight: 700,
            width: '50%',
        },
    },
    dataLoadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        color: 'gray',
    },
} as const;

export default styles;

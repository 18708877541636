import querystring from 'querystring';
import axios from 'axios';
import { storageUtils, STORAGE_KEYS } from 'src/utils/storage.utils';

/*
a.	Token – מפתח לטובת ה לוגר
b.	loggerKey – תרשום בדיוק ככה -     MyWay:LogList
c.	logData – זה ה String שמייצג את ה JSON של המידע 
*/
const loggerInstance = axios.create();
loggerInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
loggerInstance.defaults.headers.common['Content-Encoding'] = 'gzip';

const logReq = async (logData: Record<string | number, unknown>, loggerKey: string) => {
    try {
        const body = querystring.stringify({
            token: 'YE@43-jfUY2-94KLS-129IY',
            loggerKey,
            logData: JSON.stringify(logData),
        });

        return /*loggerInstance.post('https://proxy.y-it.co.il:40000/Logger/Logger', body)*/;
    } catch (error) {
        console.log('Error in logReq', { error });
        return { error };
    }
};

export const logger = (data: { type: string } & Record<string | number, unknown>) => {
    const sessionStorage = storageUtils.sessionStorage.getItem(STORAGE_KEYS.AUTH) || {};

    return logReq(
        {
            clientToken: 'clientToken' in sessionStorage ? sessionStorage.clientToken : '',
            clientPhone: 'clientPhone' in sessionStorage ? sessionStorage.clientPhone : '',
            clientName: storageUtils.sessionStorage.getItem(STORAGE_KEYS.CLIENT_NAME),
            ...data,
        },
        'MyPlanner:LogList'
    );
};

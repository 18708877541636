/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function TrashIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.8077 16.8846C2.30257 16.8846 1.875 16.7096 1.525 16.3596C1.175 16.0096 1 15.582 1 15.0769V2.38458H0.75C0.537183 2.38458 0.358983 2.31278 0.2154 2.1692C0.0717999 2.02562 0 1.84742 0 1.6346C0 1.42178 0.0717999 1.24358 0.2154 1.1C0.358983 0.9564 0.537183 0.884599 0.75 0.884599H4.49997C4.49997 0.633333 4.58779 0.423083 4.76342 0.25385C4.93907 0.0846165 5.15254 0 5.40382 0H9.59613C9.84741 0 10.0609 0.0846165 10.2365 0.25385C10.4122 0.423083 10.5 0.633333 10.5 0.884599H14.25C14.4628 0.884599 14.641 0.9564 14.7845 1.1C14.9281 1.24358 15 1.42178 15 1.6346C15 1.84742 14.9281 2.02562 14.7845 2.1692C14.641 2.31278 14.4628 2.38458 14.25 2.38458H14V15.0769C14 15.582 13.825 16.0096 13.475 16.3596C13.125 16.7096 12.6974 16.8846 12.1922 16.8846H2.8077ZM2.49997 2.38458V15.0769C2.49997 15.1666 2.52882 15.2403 2.58652 15.298C2.64422 15.3557 2.71795 15.3846 2.8077 15.3846H12.1922C12.282 15.3846 12.3557 15.3557 12.4134 15.298C12.4711 15.2403 12.5 15.1666 12.5 15.0769V2.38458H2.49997ZM4.90385 12.6346C4.90385 12.8474 4.97564 13.0256 5.11922 13.1692C5.26281 13.3128 5.44101 13.3846 5.65382 13.3846C5.86664 13.3846 6.04484 13.3128 6.18842 13.1692C6.33202 13.0256 6.40382 12.8474 6.40382 12.6346V5.13455C6.40382 4.92173 6.33202 4.74353 6.18842 4.59995C6.04484 4.45637 5.86664 4.38458 5.65382 4.38458C5.44101 4.38458 5.26281 4.45637 5.11922 4.59995C4.97564 4.74353 4.90385 4.92173 4.90385 5.13455V12.6346ZM8.59613 12.6346C8.59613 12.8474 8.66792 13.0256 8.81152 13.1692C8.95511 13.3128 9.13331 13.3846 9.34613 13.3846C9.55894 13.3846 9.73714 13.3128 9.88072 13.1692C10.0243 13.0256 10.0961 12.8474 10.0961 12.6346V5.13455C10.0961 4.92173 10.0243 4.74353 9.88072 4.59995C9.73714 4.45637 9.55894 4.38458 9.34613 4.38458C9.13331 4.38458 8.95511 4.45637 8.81152 4.59995C8.66792 4.74353 8.59613 4.92173 8.59613 5.13455V12.6346ZM2.49997 2.38458V15.0769C2.49997 15.1666 2.52882 15.2403 2.58652 15.298C2.64422 15.3557 2.71795 15.3846 2.8077 15.3846H2.49997V2.38458Z"
                fill="#2196F3"
            />
        </svg>
    );
}

export default TrashIcon;

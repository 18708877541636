import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { optiSliceActions, optimizationsUiSelector } from 'src/store/slices/optimizationsSlice';
import styled, { css } from 'styled-components';
import useOptimizationRunner from './useOptimizationRunner';

export const PickupOrDropPicker: React.FC<{}> = () => {
    const dispatch = useAppDispatch();
    const routeType = useAppSelector((state) => optimizationsUiSelector(state).routeType);

    const { runOpt } = useOptimizationRunner();

    const handleChange = (newRouteType: typeof routeType) => {
        if (newRouteType === routeType) return;

        dispatch(optiSliceActions.setRouteType(newRouteType));
        runOpt({
            payloadOverride: { toTarget: newRouteType === 'pickup' ? true : false },
        });
    };

    return (
        <Container>
            <ButtonContainer
                isSelected={routeType === 'pickup'}
                onClick={() => {
                    handleChange('pickup');
                }}
            >
                <ButtonText>איסוף</ButtonText>
            </ButtonContainer>
            <ButtonContainer
                isSelected={routeType === 'drop'}
                onClick={() => {
                    handleChange('drop');
                }}
            >
                <ButtonText>פיזור</ButtonText>
            </ButtonContainer>
        </Container>
    );
};

const Container = styled.div`
    border-radius: 8px;
    border: 0.5px #c8cfd2 solid;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 42px;
`;
const ButtonContainer = styled.div<{ isSelected?: boolean }>`
    height: 100%;
    width: 80px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    :hover {
        opacity: 0.7;
    }
    ${(props) =>
        props.isSelected &&
        css`
            border-radius: 8px;
            border: 1px #2196f3 solid;
            background: #e6f6fe;
        `}
`;
const ButtonText = styled.div`
    text-align: center;
    color: #494949;
    font-size: 14px;
    font-family: 'Rubik';
    font-weight: 500;
`;

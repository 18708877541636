import React, { useState } from 'react';
import AddressAutoComplete, { EMPTY_ADDRESS_DATA } from './AddressAutoComplete';
import { IOptimizationWaypoint, IOptimumWaypoint } from 'src/types/optimization/types';
import { AutocompleteAddress } from 'src/types/googleMapsTypes';
import { useAppDispatch } from 'src/store/hooks';
import {
    invalidAddressBoxDeleteAction,
    updateNotApprovedWaypointCoordsAction,
} from 'src/store/slices/optimizationsSlice';
import { buildWaypointName } from 'src/store/helpers';
import WrenchIcon from './Icons/WrenchIcon';
import CheckIcon from './Icons/CheckIcon';
import TrashIcon from './Icons/TrashIcon';

export interface InvalidAddressBoxProps {
    waypoint: IOptimizationWaypoint;
}

export const hasValidCoords = (waypoint: Record<string, any>) => {
    return waypoint.lat && waypoint.lng && waypoint.lng !== 0 && waypoint.lat !== 0;
};

const InvalidAddressBox: React.FC<InvalidAddressBoxProps> = ({ waypoint }) => {
    const [inputText, setInputText] = useState(waypoint.stationName ? waypoint.stationName : '');
    const [addressData, setAddressData] = useState<AutocompleteAddress>({ ...EMPTY_ADDRESS_DATA });
    const dispatch = useAppDispatch();

    const onSelection = (addressData: AutocompleteAddress) => {
        dispatch(updateNotApprovedWaypointCoordsAction({ waypoint, address: addressData }));
    };
    const onRemoveAddress = () => {
        dispatch(invalidAddressBoxDeleteAction({ waypointId: waypoint.waypointId }));
    };
    return (
        <div style={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'center' }}>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    color: '#494949',
                    fontSize: '14px',
                }}
            >
                <div>{`${waypoint.firstName} ${waypoint.lastName}`}</div>

                <AddressAutoComplete
                    currentAddress={addressData}
                    inputText={inputText}
                    setInputText={setInputText}
                    setAddressData={setAddressData}
                    onSelection={onSelection}
                />
            </div>

            {hasValidCoords(addressData) ? (
                <div style={{ marginTop: '20px' }}>
                    <CheckIcon />
                </div>
            ) : (
                <div
                    onClick={onRemoveAddress}
                    style={{ marginTop: '20px', marginRight: '6px', marginLeft: '3px', cursor: 'pointer' }}
                >
                    <TrashIcon />
                </div>
            )}
        </div>
    );
};
export default InvalidAddressBox;

import { t } from 'src/utils/lang';
import React from 'react';
import MagicWandPopOver from 'src/components/MagicWandPopOver';
import { useAppSelector } from 'src/store/hooks';
import { PickupOrDropPicker } from './PickupOrDropPicker';
import SearchInput from 'src/components/SearchInput';
import { useDispatch } from 'react-redux';
import {
    allRoutesDataSelector,
    setSummaryStageFilteredRoutesAction,
    simulationResultSelector,
} from 'src/store/slices/optimizationsSlice';
import { EllipsisText } from './PDF/PdfWaypointsNotInRoutes';
import { summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';

const addDots = (text: string) => `${text}...`;

const sliceByWidth = (text: string) => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 1800) return text.slice(0, 20);
    if (screenWidth > 1700) return text.slice(0, 15);
    return text.slice(0, 10);
};

const SectionTop: React.FC = () => {
    const dispatch = useDispatch();

    const allRoutesData = useAppSelector((state) => allRoutesDataSelector(state));
    const { isOpen: isEqualizerOpen } = useAppSelector(
        (state) => summaryStageSliceSelector(state).configModal
    );
    const simDescription =
        useAppSelector((state) => simulationResultSelector(state)?.requestBody.description) || '';

    const onSearch = (ev: any) => {
        const val = ev.target.value.toLowerCase();

        const filteredRoutes = allRoutesData.filter(
            (route) =>
                route.routeName?.toLowerCase().includes(val) ||
                route.optimumRoute.some(
                    (waypoint) =>
                        waypoint.city.toLowerCase().includes(val) ||
                        waypoint.stationName.toLowerCase().includes(val) ||
                        waypoint.street.toLowerCase().includes(val) ||
                        waypoint.houseNum.toLowerCase().includes(val)
                )
        );

        dispatch(setSummaryStageFilteredRoutesAction({ routeList: filteredRoutes }));
    };

    const slicedDescription = sliceByWidth(simDescription);

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    {isEqualizerOpen ? null : (
                        <p style={{ fontWeight: 'bold', fontSize: '2rem' }}>סיכום מסלולים&nbsp;</p>
                    )}

                    <EllipsisText style={{ fontWeight: 'bold', fontSize: '2rem' }}>
                        {isEqualizerOpen ? null : ' - '}
                        {slicedDescription.length !== simDescription.length
                            ? addDots(slicedDescription)
                            : slicedDescription}
                    </EllipsisText>
                </div>

                <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                    <PickupOrDropPicker />
                    {/* <MagicWandPopOver /> */}
                </div>
            </div>
            <p style={{ fontSize: '1.2rem', marginBottom: '24px' }}>{t.displayingRoutesSummary}</p>
            <SearchInput style={{ width: '43%' }} onChange={(ev) => onSearch(ev)} />
        </div>
    );
};
export default SectionTop;

import { useState, useEffect } from 'react';

export const STAGES_TEXTS = {
    Start: 'בואו נתחיל',
    DataLoading: 'טעינת נתונים',
    TargetSelection: 'הגדרת יעד',
    Cars: 'קביעת מחירון רכבים',
    CarsStationsFlow: 'קביעת מחיר לנסיעה',
    Parameters: 'הגדרת חוקי אופטימיזציה',
    StationConfirmation: 'אימות תחנות',
    Summary: 'סיכום',
    ChooseOptiFlowType: 'בחירת פעולה',
};

export type StagesNum = number;

export enum ScreenSizes {
    small = 1400,
    medium = 1600,
}

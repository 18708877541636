import React, { useEffect } from 'react';
import { useNavigate, createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { authRootSelector } from '../store/slices/authSlice';
import Home from '../screens/Home/Home.index';
import Login from '../screens/Login/Login';
import styled from 'styled-components';
import Alert from '../components/Alert';
import useAlert from '../hooks/useAlert';
import Demo from 'src/components/Demo';

export const DEV_AUTH_PATH = 'http://localhost:3000/';

export const RESET_PASSWORD_ROUTE = `${DEV_AUTH_PATH}ResetPassword/`;

export const AUTH_TOKEN_KEY = 'authToken';
export const AUTH_CURRENT_USER = 'userName';

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <>
                <Home />
            </>
        ),
    },
    {
        path: '/login',
        element: <Login />,
    },

    {
        path: '/demo',
        element: <Demo />,
    },
    // {
    //     path: '/pdf/:id',
    //     // eslint-disable-next-line react/jsx-no-undef
    //     element: <PDF />,
    // },
    {
        path: '*',
        element: <Navigate to="/home" replace />,
    },
]);

function Routing() {
    const dispatch = useAppDispatch();

    const alertConfig = useAlert();

    return (
        <>
            <RouterProvider router={router} />
            <Alert {...alertConfig.props}>{alertConfig.message}</Alert>
        </>
    );
}

export default Routing;

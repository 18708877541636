import React, { FC } from 'react';
import styled from 'styled-components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { t } from 'src/utils/lang';
import { IOptimumRouteData, IOptimumWaypoint } from 'src/types/optimization/types';
import { toFixed } from 'src/utils/utilis';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colors } from 'src/styles/defaultTheme';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import StraightenIcon from '@mui/icons-material/Straighten';
import { getIconByCarCode } from '../../CarTypesPricing/CarInput';
import { summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import {
    optiSliceActions,
    optimizationsDataSelector,
    optimizationsUiSelector,
} from 'src/store/slices/optimizationsSlice';
import { addCommasToNumber, limitString } from 'src/store/helpers';
import { EllipsisText } from '../PDF/PdfRouteBox';
import FlagIcon from 'src/components/Icons/FlagIcon';
import { LeftArrow } from 'src/components/Icons/LeftArrow';
import { StationFlagIcon } from 'src/components/Icons/StationFlagIcon';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';
import { TimeFromPrev } from './TimeFromPrev';

const WaypointRowItem = styled.div`
    display: flex;
    padding: 10px 20px 10px 40px;
    border-bottom: 1px solid rgba(180, 190, 201, 0.303017);
    font-weight: 600;
    font-size: 14px;
    text-align: right;
`;

const cleanResult = (result: string) => {
    return result.replace(',,', ',').trim();
};

const SHORT_ROUTE_NAME_LIMIT = 42;
const LONG_ROUTE_NAME_LIMIT = window.screen.width > 1800 ? 100 : 70;

const stringifyWaypoint = (waypoint: IOptimumWaypoint) => {
    let addressPart = '';
    let result = '';

    const stationName = waypoint.stationName.trim();

    if (stationName) {
        result = stationName;
        return cleanResult(result);
    }

    if (waypoint.street) {
        addressPart += `${waypoint.street}`;
    }

    if (waypoint.houseNum) {
        addressPart += ` ${waypoint.houseNum}`;
    }

    if (waypoint.city) {
        addressPart += ` ${waypoint.city}`;
    }

    addressPart = addressPart.trim();

    if (stationName == addressPart) {
        result = addressPart;
    } else {
        result = `${stationName} ${addressPart}`;
    }

    return result || '* מסלול ללא שם';
};

const RouteWaypointsList: React.FC<{
    routeWaypoints: IOptimumRouteData['optimumRoute'];
    legs?: IOptimumRouteData['routeDirection']['legs'];
}> = ({ routeWaypoints, legs }) => {
    const routeType = useAppSelector((state) => optimizationsUiSelector(state).routeType);
    const { hasStationsFlow } = useSelectedOptiFlowType();

    return (
        <div style={{ overflow: 'auto', maxHeight: '300px' }}>
            {routeWaypoints.map((waypoint, i) => (
                <WaypointRowItem key={waypoint.place_id} style={{ flex: 1, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', gap: '7px' }}>
                        {waypoint.isTarget && (
                            <div style={{ height: '17px', paddingLeft: '3px' }}>
                                <FlagIcon />
                            </div>
                        )}
                        {waypoint.isTarget ? null : (
                            <span style={{ color: '#919191' }}>{routeType === 'drop' ? i : i + 1} -</span>
                        )}
                        {(waypoint.firstName || waypoint.lastName) && !hasStationsFlow ? (
                            <div>
                                {waypoint.firstName} {waypoint.lastName} - {stringifyWaypoint(waypoint)}
                            </div>
                        ) : (
                            <div>{stringifyWaypoint(waypoint)}</div>
                        )}
                    </div>
                    {legs && legs[i - 1] && <TimeFromPrev timeFromPrev={legs[i - 1].timeInLegMin} />}
                </WaypointRowItem>
            ))}
        </div>
    );
};

const styles = {
    summary: {
        display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'space-between',
        width: '100%',
        // padding: '0 10px',
        flexDirection: 'column',
    },
} as const;

export const BaseSeparator = styled.div`
    height: 20px;
    width: 2px;
    background-color: #e0e0e0;
    margin: 0 10px;
`;

const css = {
    icon: {
        color: '#494949',
    },
    dataItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        justifyContent: 'center',
        fontSize: window.innerWidth < 1800 ? '0.7rem' : '0.8rem',
        // border: '1px solid rgba(0, 123, 255, 1)',
    },

    priceBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        backgroundColor: 'rgba(33, 150, 243, 0.1)',
        padding: '6px 0px',
        borderRadius: '4px',
        fontSize: '16px',
        minWidth: '80px',
        fontWeight: 'bold',
        color: colors.primary,
        justifyContent: 'center',
        marginLeft: 12,
    },
    accordion: {
        border: '1px solid rgba(180, 190, 201, 0.303017)',
        padding: 0,
        margin: 0,
        borderRadius: '8px',
        boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.1)',
    },
    locationIcon: {
        paddingRight: '6px',
    },
} as const;

const getPriceStr = (price: number | null) => {
    if (price === null) return '0';

    let res = toFixed(price);

    if (res[0] === '0') {
        res = res.slice(1);
    }

    return `${res || 0} ₪`;
};

const RouteDescription: React.FC<{
    routeName: string;
    color: string;
    waypoints: IOptimumWaypoint[];
}> = ({ routeName, color }) => {
    const { isOpen: isEqualizerDrawerOpen } = useAppSelector(
        (state) => summaryStageSliceSelector(state).configModal
    );

    const routeNameLimit = isEqualizerDrawerOpen ? SHORT_ROUTE_NAME_LIMIT : LONG_ROUTE_NAME_LIMIT;

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <LocationOnIcon sx={{ ...css.locationIcon, color }} fontSize="small" />
            <EllipsisText
                style={{ fontSize: '18px', fontWeight: 'bold', paddingRight: '6px', flexShrink: 1 }}
            >
                {routeName.length < routeNameLimit ? routeName : limitString(routeName, routeNameLimit)}
            </EllipsisText>
            {/* <LeftArrow /> */}
        </div>
    );
};

const RouteSelectionCheckbox: React.FC<{ id: string; isSelected: boolean }> = ({ id, isSelected }) => {
    const dispatch = useAppDispatch();

    return (
        <input
            type="checkbox"
            checked={isSelected}
            onChange={() => {}}
            onClick={(e) => {
                e.stopPropagation();

                if (isSelected) {
                    dispatch(optiSliceActions.unselectRoute(id));
                } else {
                    dispatch(optiSliceActions.selectRoute(id));
                }
            }}
        />
    );
};

// index
export const RouteAccordion = ({
    boxData,
    expanded,
    handleChange,
    isSelected,
}: {
    boxData: IOptimumRouteData;
    expanded: boolean;
    handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    isSelected: boolean;
}) => {
    const dispatch = useAppDispatch();
    const { hasStationsFlow } = useSelectedOptiFlowType();

    const {
        routeID,
        routeName,
        assignedCar,
        maxDistanceInRoute,
        maxTimeInRoute,
        numberOfPassangersInRoute,
        optimumRoute: waypoints,
        numberOfWayPointsInRoute,
        routePrice,
        maxValueInRoute,
        color,
        routeDirection,
    } = boxData || {};
    // console.log(routeName, routeDirection);

    // const d = {
    //     km: toFixed(maxDistanceInRoute / 1000),
    //     minutes: toFixed(maxTimeInRoute, 0),
    //     price: getPriceStr(routePrice),
    //     car: assignedCar?.carDescription || assignedCar?.carName || 'לא נמצא רכב מתאים',
    // };
    const NUM_OF_NON_STATION_WAYPOINTS = 1;

    return (
        <Accordion expanded={expanded} onChange={handleChange(routeID)} disableGutters sx={css.accordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ ...styles.summary, gap: '8px' }}>
                    <>
                        <div className="flex-rows" style={{ width: '50%' }}>
                            <RouteSelectionCheckbox id={routeID} isSelected={isSelected} />
                            <RouteDescription
                                waypoints={waypoints}
                                routeName={routeName || ''}
                                color={color}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                overflow: 'auto',
                            }}
                        >
                            <div style={{ display: 'flex', paddingRight: 28, alignItems: 'center', gap: 5 }}>
                                <div style={{ paddingLeft: '6px', ...css.dataItem, width: '90px' }}>
                                    <StraightenIcon fontSize="small" sx={css.icon} />
                                    {toFixed(maxDistanceInRoute)} {t.km}
                                </div>
                                <div style={{ ...css.dataItem }}>
                                    <AccessTimeFilledIcon fontSize="small" sx={css.icon} />
                                    {toFixed(maxTimeInRoute, 0)} {t.minutes}
                                </div>
                                <BaseSeparator />
                                <div style={{ ...css.dataItem }}>
                                    <PeopleAltOutlinedIcon fontSize="small" sx={css.icon} />
                                    <p style={{ ...css.dataItem }}>{numberOfPassangersInRoute}</p>
                                </div>
                                <BaseSeparator />
                                <div style={{ ...css.dataItem }}>
                                    <StationFlagIcon />
                                    <p style={{ ...css.dataItem }}>
                                        {numberOfWayPointsInRoute - NUM_OF_NON_STATION_WAYPOINTS}
                                    </p>
                                </div>
                                {hasStationsFlow ? null : (
                                    <>
                                        <BaseSeparator />
                                        <div style={{ ...css.dataItem }}>
                                            {getIconByCarCode(assignedCar?.carId || '', '#494949')}
                                            <span>
                                                {assignedCar?.carDescription ||
                                                    assignedCar?.carName ||
                                                    'לא נמצא רכב מתאים'}
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div style={{ ...css.priceBox }}>
                                <span>₪{addCommasToNumber(String(routePrice))}</span>
                            </div>
                        </div>
                    </>
                </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, margin: 0, borderTop: '1px solid #e5e5e5' }}>
                <RouteWaypointsList
                    routeWaypoints={waypoints}
                    legs={routeDirection.isSuccess ? routeDirection.legs : undefined}
                />
            </AccordionDetails>
        </Accordion>
    );
};

export const UNUSABLE_WAYPOINTS_ACCORDION_ID = 'UnusableWaypoints';

export const UnusableWaypoints: React.FC<{
    expanded: boolean;
    handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    isSelected: boolean;
}> = ({ expanded, handleChange, isSelected }) => {
    const dispatch = useAppDispatch();
    const wayPointNotInRoutes =
        useAppSelector(
            (state) => optimizationsDataSelector(state).getSimulationResult.data?.wayPointNotInRoutes
        ) || [];

    return wayPointNotInRoutes.length ? (
        <Accordion
            expanded={expanded}
            onChange={handleChange(UNUSABLE_WAYPOINTS_ACCORDION_ID)}
            disableGutters
            sx={{ ...css.accordion, backgroundColor: '#FFF6D8' }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ ...styles.summary }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => {}}
                            onClick={(e) => {
                                e.stopPropagation();

                                if (isSelected) {
                                    dispatch(optiSliceActions.changeDidSelectUnusableWaypoints(false));
                                } else {
                                    dispatch(optiSliceActions.changeDidSelectUnusableWaypoints(true));
                                }
                            }}
                        />
                        <LocationOnIcon sx={{ ...css.locationIcon, color: 'orange' }} fontSize="small" />
                        <p
                            style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                paddingRight: '6px',
                                color: 'red',
                            }}
                        >
                            {t.unusedWaypoints}
                        </p>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, margin: 0, borderTop: '1px solid #e5e5e5' }}>
                <RouteWaypointsList routeWaypoints={wayPointNotInRoutes} />
            </AccordionDetails>
        </Accordion>
    ) : null;
};

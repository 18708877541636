import { IconButton, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import ConfigNakedIcon from 'src/components/Icons/ConfigNakedIcon';
import { useAppDispatch } from 'src/store/hooks';
import { CSSProperties } from 'styled-components';
import { tooltipStylesV2 } from 'src/components/MagicWandPopOver';

interface Props {
    onClick: () => void;
    tooltipComponent?: React.ReactNode;
    sx?: CSSProperties & { [key: string]: any };
}

const ToggleConfigBtn: FC<Props> = ({ onClick, sx, tooltipComponent }) => {
    const dispatch = useAppDispatch();

    return (
        <IconButton
            sx={{
                background: '#E6F6FE',
                padding: '8px',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
                ...sx,
            }}
            onClick={() => {
                onClick();
            }}
        >
            <Tooltip PopperProps={{ sx: tooltipStylesV2 }} title={tooltipComponent} arrow>
                <div style={{ borderRadius: '50%', display: 'flex' }}>
                    <ConfigNakedIcon />
                </div>
            </Tooltip>
            {/* <TuneIcon /> */}
        </IconButton>
    );
};

export default ToggleConfigBtn;

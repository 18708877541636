import React from 'react';

export const TimeFromPrev: React.FC<{ timeFromPrev: number }> = ({ timeFromPrev }) => {
    return (
        <div
            style={{
                paddingLeft: 4,
                paddingRight: 4,
                paddingTop: 2,
                paddingBottom: 2,
                background: '#FFEDB1',
                borderRadius: 4,
                alignItems: 'center',
                display: 'inline-flex',
            }}
        >
            <div style={{ color: '#494949', fontSize: 12, fontFamily: 'Rubik' }}>
                {timeFromPrev} דק׳ מהתחנה הקודמת
            </div>
        </div>
    );
};

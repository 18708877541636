/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function RightArrowVector({ width = '28', height = '28', color = '#494949' }) {
    return (
       <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.142865 10.7857L4.42857 6.5L0.142864 2.21429L1.00001 0.500001L7 6.5L1.00001 12.5L0.142865 10.7857Z" fill="#494949"/>
</svg>


    );
}

export default RightArrowVector;

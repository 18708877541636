import React from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    advancedParamsSelector,
    closeConfigModal,
    summaryActions,
    summaryStageSliceSelector,
} from 'src/store/slices/summaryStageSlice';
import ConfigNakedIcon from 'src/components/Icons/ConfigNakedIcon';
import EqualizerBody from './EqualizerBody';
import ToggleConfigBtn from '../ConfigIcon';
import { Switch } from '@mui/material';

interface Props {}

const drawerWidth = (() => {
    const screenInnerWidth = window.innerWidth;
    if (screenInnerWidth < 1400) {
        return '350px';
    }
    if (screenInnerWidth < 1500) {
        return '375px';
    }
    if (screenInnerWidth < 1600) {
        return '400px';
    }
    // if (screenInnerWidth < 1700) {
    //     return '400px';
    // }
    return '550px';
})();

const Cont = styled.div<{ isOpen: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #f9f8f8;
    align-self: center;
    justify-self: center;
    box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.1);
    color: black;
    z-index: 9999;
    box-sizing: border-box;
    height: 100%;
    padding: 20px 12px 0 12px;
    ${({ isOpen }) => (isOpen ? `width: ${drawerWidth};` : 'width: 0px; display: none;')}
`;

const CircleModal = styled.div`
    min-width: 52px;
    min-height: 52px;
    border-radius: 50%;
    background: #ebb835;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BodyCont = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 20px;
    width: 80%;
    height: 400px;
    overflow: auto;
    height: 100%;
    /* border box */
`;
// eslint-disable-next-line react/display-name
const EqualizerDrawer = React.forwardRef(({}, ref: React.Ref<HTMLDivElement>) => {
    const dispatch = useAppDispatch();

    const isOnAdvancedMode = useAppSelector((state) => advancedParamsSelector(state).isOnAdvancedMode);
    const clientName = useAppSelector((state) => state.auth.clientName);

    const { isOpen } = useAppSelector((state) => summaryStageSliceSelector(state).configModal);

    return (
        <Cont isOpen={isOpen} ref={ref}>
            <CircleModal>
                <ConfigNakedIcon />
            </CircleModal>
            <ToggleConfigBtn
                onClick={() => {
                    dispatch(closeConfigModal());
                }}
                sx={{ position: 'absolute', bottom: '46.8%', zIndex: 9999, left: '-22px' }}
            />

            <p style={{ fontSize: '20px', textAlign: 'center', padding: '20px 0' }}>
                באפשרותך לערוך חוקי אופטימיזציה והגדרת כלי רכב
            </p>

            <BodyCont>
                <EqualizerBody />
            </BodyCont>

            {clientName === 'aws' && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'flex-end',
                    }}
                >
                    <span style={{ fontSize: '14px' }}>הגדרות מתקדמות</span>
                    <Switch
                        checked={isOnAdvancedMode}
                        onClick={() => {
                            dispatch(summaryActions.setIsOnAdvancedParamsMode(!isOnAdvancedMode));
                        }}
                    />
                </div>
            )}
        </Cont>
    );
});

export default EqualizerDrawer;

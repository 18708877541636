/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function PickupStarIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="34" height="34" rx="17" fill="#EDBD41" />
            <path d="M18.0005 25.0633C18.3025 25.0633 18.6044 25.1551 18.8719 25.3265L23.275 28.0581C23.5922 28.2575 23.9897 28.2375 24.2802 28.0142C24.5898 27.7789 24.7274 27.3961 24.6433 27.0133L23.5158 21.7895C23.3782 21.1514 23.5769 20.5134 24.0471 20.0787L27.9036 16.5417C28.1902 16.2825 28.2934 15.8957 28.1711 15.5248C28.0526 15.138 27.7621 14.8988 27.3685 14.8549L22.2698 14.3684C21.643 14.3086 21.1193 13.9098 20.8671 13.3037L18.8414 8.38689C18.6885 8.02401 18.3674 7.80469 17.9929 7.80469C17.6107 7.80469 17.2934 8.02002 17.1444 8.3829L15.1186 13.3037C14.8664 13.9138 14.3428 14.3126 13.7159 14.3684L8.60961 14.8549C8.23122 14.8948 7.93692 15.138 7.81079 15.5248C7.69613 15.8957 7.80315 16.2825 8.09363 16.5536L11.9463 20.0787C12.4202 20.5134 12.619 21.1554 12.4814 21.7934L11.3424 27.0173C11.2583 27.4041 11.3959 27.7829 11.7017 28.0182C12.0036 28.2455 12.4011 28.2654 12.7222 28.0661L17.1329 25.3305C17.3966 25.1551 17.6986 25.0633 18.0005 25.0633Z" fill="white" />
            <rect x="1" y="1" width="34" height="34" rx="17" stroke="white" stroke-width="2" />
        </svg>
    );
}

export default PickupStarIcon;

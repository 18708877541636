import moment from 'moment';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { optiSliceActions } from 'src/store/slices/optimizationsSlice';
import { getErrWithCode } from 'src/utils/lang';
import { useDispatchAlert } from './useAlert';
import { setClientName } from 'src/store/slices/authSlice';
import { logger } from 'src/api/loggerApi';
import { storageUtils, STORAGE_KEYS } from 'src/utils/storage.utils';
import { getClientHistory, PrevOpti } from 'src/api/optimizationApi';

const parseDateTime = (dateTime: string): string => {
    return moment.utc(dateTime, 'YYYY-MM-DD HH:mm').local().format('DD/MM/YY HH:mm');
};

const sortByDateTime = (prevOptiArray: PrevOpti[]): PrevOpti[] => {
    return prevOptiArray.sort((a, b) => {
        const dateTimeA = new Date(a.dateTime);
        const dateTimeB = new Date(b.dateTime);

        // Compare the dates
        if (dateTimeA > dateTimeB) {
            return -1;
        } else if (dateTimeA < dateTimeB) {
            return 1;
        } else {
            return 0; // Dates are equal
        }
    });
};

export const useFetchClientsOptiHistory = () => {
    const dispatch = useAppDispatch();

    const dispatchAlert = useDispatchAlert();

    const [isLoading, setIsLoading] = React.useState(false);
    const clientToken = useAppSelector((state) => state.auth.clientToken) || '';

    const fetch = () => {
        if (!clientToken || clientToken === 'Demo') return null;

        // fetching client's hash id history (prev optimizations)
        setIsLoading(true);
        getClientHistory({ params: { token: clientToken } })
            .then(({ data }) => {
                const sortedHistory = sortByDateTime(data.history);
                dispatch(setClientName(data.clientName));
                storageUtils.sessionStorage.setItem(STORAGE_KEYS.CLIENT_NAME, data.clientName);
                dispatch(
                    optiSliceActions.setPrevOpti({
                        history: sortedHistory.map((ele) => {
                            return { ...ele, dateTime: parseDateTime(ele.dateTime) };
                        }),
                        isSuccess: true,
                        message: '',
                    })
                );
            })
            .catch((error) => {
                console.log(error);
                dispatch(
                    optiSliceActions.setPrevOpti({
                        history: [],
                        isSuccess: false,
                        message: 'message' in error ? error.message : 'Error',
                    })
                );
                dispatchAlert(
                    'error',
                    getErrWithCode('תקלה במשיכת היסטוריית הרצות', 2342, { withSuffix: true }),
                    true
                );
                logger({
                    type: 'apiReqError',
                    feature:
                        'Attempting to fetch client`s previous optimizations history, Data loading screen',
                    data: error,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        fetch();
    }, [clientToken]);

    return {
        isLoading,
    };
};

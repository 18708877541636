import React, { useState } from 'react';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export const initialModalState = {
    isOpen: false,
    Body: () => <div />,
    onConfirm: () => {},
    onClose: () => {},
    Img: () => <PriorityHighIcon fontSize="medium" />,
    withBodyContainer: true,
};

export const useModal = () => {
    const [modalState, setModalState] = useState({ ...initialModalState });
    const resetModal = () => setModalState({ ...initialModalState });
    return {
        modalState,
        setModalState,
        resetModal,
    };
};

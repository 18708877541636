import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { AutocompleteAddress } from 'src/types/googleMapsTypes';
import {
    DataState,
    EditPrevOptiFieldValues,
    OptimizationsSliceState,
    UiState,
} from 'src/types/optimization/sliceTypes';
import {
    IOptimizationWaypoint,
    IOptimumRouteData,
    ISimulationResult,
    ISimulationStationToWaypoint,
} from 'src/types/optimization/types';
import { stationsConfiReducers } from '../reducers/stationsConfiReducers';
import { OptiFlowTypes } from 'src/constants/common';

const COLOR_PLATE = ['lightGreen', 'black', 'gray', 'orange', 'red', 'blue', 'green', 'purple'];
export const DEFAULT_MAX_VEHICLE_CAPACITY = 60;
const DEFAULT_WAX_DISTANCE = 200;

const initialUiState: UiState = {
    optimizationDescription: '',
    routeType: 'pickup',
    isParameterFormOpen: false,
    waypointsForOptiObject: null,
    isCsvLoadDoneModalOpen: false,
    isCsvImageModalOpen: false,
    isCsvLoading: false,
    targetWaypoint: null,
    isFixAddressModalOpen: false,
    isNotEnoughCreditModalOpen: false,
    optimizationSimulationScreen: {
        isOpen: false,
        colorPlate: COLOR_PLATE,
    },
    parametersForm: {
        maxTravelTime: 45,
        vehicleCapacity: 0,
        maxTravelTimeMagicWander: 0,
        magicWandValue: 60,
        maxStationsCountVal: 30,
        maxVehicleCapacity: DEFAULT_MAX_VEHICLE_CAPACITY,
        maxWalkDistance: DEFAULT_WAX_DISTANCE,
        maxTierTwoWalkDistance: DEFAULT_WAX_DISTANCE,
        isUsingGoogle: false,
    },
    displaySimulationCalcLoader: false,
    isSkippedToSummary: false,
    isSendHashIdManuallyModalOpen: false,
    stationsConfiStage: {
        isEqualizerDrawerOpen: false,
        selectedStationsIds: [],
        didSelectUnusableStations: false,
        selectAllStationsChecked: false,
        isQuickDataLoading: false,
        filteredStations: [],
    },
    summaryStageExtra: {
        selectedRouteUuids: [],
        selectAllRoutesChecked: false,
        didSelectUnusableWaypoints: false,
        filteredRoutes: [],
    },
    editPrevOptimizationScreen: {
        isOpen: false,
        hashId: null,
        fieldsValues: [],
        isError: false,
        inputsTextByWaypointId: {},
    },
    selectedOptiFlowType: OptiFlowTypes.RoutesOnlyCreation,
};

const initialDataState: DataState = {
    calculationPercentage: 1,
    getSimulationResult: {
        data: null,
        firstRingData: null,
        status: 'ON_STAND_BY',
        simulationHashId: null,
    },
    getStationsSimulationResult: {
        data: null,
        simulationHashId: null,
        status: 'ON_STAND_BY',
    },
    prevOpti: {
        isSuccess: false,
        message: '',
        history: [],
    },
};

const initialState: OptimizationsSliceState = {
    ui: initialUiState,
    data: initialDataState,
};

export const optimizationsSlice = createSlice({
    name: 'optimizations',
    initialState,
    reducers: {
        // ^ UI reducers ---------------------------------------------------------------------------
        // * Common\
        setOptiFlowType: (state: OptimizationsSliceState, action: PayloadAction<OptiFlowTypes>): void => {
            const prevVal = state.ui.selectedOptiFlowType;
            const newVal = action.payload;

            state.ui.selectedOptiFlowType = newVal;

            if (prevVal !== OptiFlowTypes.RoutesOnlyCreation && newVal === OptiFlowTypes.RoutesOnlyCreation) {
                state.ui.parametersForm.maxWalkDistance = 0;
                state.ui.parametersForm.maxTierTwoWalkDistance = 0;
            }

            if (prevVal !== OptiFlowTypes.WithStations && newVal === OptiFlowTypes.WithStations) {
                state.ui.parametersForm.maxWalkDistance = DEFAULT_WAX_DISTANCE;
                state.ui.parametersForm.maxTierTwoWalkDistance = DEFAULT_WAX_DISTANCE;
            }
        },
        setAllInputsTextByWaypointId: (
            state: OptimizationsSliceState,
            action: PayloadAction<UiState['editPrevOptimizationScreen']['inputsTextByWaypointId']>
        ) => {
            state.ui.editPrevOptimizationScreen.inputsTextByWaypointId = action.payload;
        },
        updateInputTextByWaypointId: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ waypointId: string; text: string }>
        ) => {
            const { waypointId, text } = action.payload;
            state.ui.editPrevOptimizationScreen.inputsTextByWaypointId[waypointId] = text;
        },

        invalidAddressBoxDelete: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ waypointId: string }>
        ): void => {
            const { waypointsForOptiObject: waypointsForOptiObject } = state.ui;

            if (!waypointsForOptiObject) return;

            const { waypointId } = action.payload;
            const { unRecognizedWaypoints, invalidWaypoints } = waypointsForOptiObject;

            const removeWaypoint = (waypoints: IOptimizationWaypoint[], id: string) => {
                const index = waypoints.findIndex((waypoint) => waypoint.waypointId === id);
                if (index !== -1) {
                    waypoints.splice(index, 1);
                }
            };

            removeWaypoint(unRecognizedWaypoints, waypointId);
            removeWaypoint(invalidWaypoints, waypointId);
        },
        setOptimizationDescription: (state: OptimizationsSliceState, action: PayloadAction<string>): void => {
            state.ui.optimizationDescription = action.payload;
        },
        setIsUsingGoogle: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isUsingGoogle: boolean }>
        ): void => {
            state.ui.parametersForm.isUsingGoogle = action.payload.isUsingGoogle;
        },
        setIsEditPrevOptError: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isError: boolean }>
        ): void => {
            state.ui.editPrevOptimizationScreen.isError = action.payload.isError;
        },
        // editSetPrevOptFilteredData: (
        //     state: OptimizationsSliceState,
        //     action: PayloadAction<{ waypoints: IOptimizationWaypoint[] }>
        // ): void => {
        //     state.ui.editPrevOptimizationScreen.editPrevOptFilteredData = action.payload.waypoints;
        // },
        epoSetFieldValues: (
            state: OptimizationsSliceState,
            action: PayloadAction<EditPrevOptiFieldValues>
        ): void => {
            state.ui.editPrevOptimizationScreen.fieldsValues = action.payload;
        },
        setPrevOpti: (state: OptimizationsSliceState, action: PayloadAction<DataState['prevOpti']>): void => {
            state.data.prevOpti = action.payload;
        },
        editPrevOptimizationEditWaypoint: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ waypoint: EditPrevOptiFieldValues[number] }>
        ): void => {
            const newWaypoints = [...state.ui.editPrevOptimizationScreen.fieldsValues];
            const indexToReplace = newWaypoints.findIndex(
                (waypoint) => waypoint.waypointId === action.payload.waypoint.waypointId
            );

            if (indexToReplace !== -1) {
                const updatedWaypoints = newWaypoints.map((waypoint, index) =>
                    index === indexToReplace ? action.payload.waypoint : waypoint
                );
                state.ui.editPrevOptimizationScreen.fieldsValues = updatedWaypoints;
            }
        },
        editPrevOptimizationAddWaypoint: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ waypoint: EditPrevOptiFieldValues[number] }>
        ): void => {
            const currItems = state.ui.editPrevOptimizationScreen.fieldsValues;
            const newWaypoints = [...currItems];
            newWaypoints.unshift(action.payload.waypoint);
            state.ui.editPrevOptimizationScreen.fieldsValues = newWaypoints;
        },
        editPrevOptimizationDeleteWaypoint: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                waypointId: string;
            }>
        ): void => {
            const newWaypoints = [...state.ui.editPrevOptimizationScreen.fieldsValues];
            const index = newWaypoints.findIndex(
                (waypoint) => waypoint.waypointId === action.payload.waypointId
            );
            if (index !== -1) {
                newWaypoints.splice(index, 1);
                state.ui.editPrevOptimizationScreen.fieldsValues = newWaypoints;
            }
        },
        setEditPrevOptimization: (
            state: OptimizationsSliceState,
            action: PayloadAction<
                {
                    isOpen: boolean;
                    hashId: string | null;
                } & Partial<UiState['editPrevOptimizationScreen']>
            >
        ): void => {
            state.ui.editPrevOptimizationScreen = {
                ...state.ui.editPrevOptimizationScreen,
                ...action.payload,
            };
        },
        selectRoute: (state, action: PayloadAction<string>) => {
            const totalSelectedCount = state.ui.summaryStageExtra.selectedRouteUuids.length + 1;
            state.ui.summaryStageExtra.selectedRouteUuids.push(action.payload);
            state.ui.summaryStageExtra.selectAllRoutesChecked =
                totalSelectedCount === state.data.getSimulationResult.data?.optimumRouteResult.length;
        },
        selectAllRoutes: (state) => {
            state.ui.summaryStageExtra.selectedRouteUuids =
                state.data.getSimulationResult.data?.optimumRouteResult.map((route) => route.routeID) || [];
            state.ui.summaryStageExtra.didSelectUnusableWaypoints = true;
            state.ui.summaryStageExtra.selectAllRoutesChecked = true;
        },
        unselectAllRoutes: (state, action: PayloadAction<Record<never, never>>) => {
            state.ui.summaryStageExtra.selectedRouteUuids = [];
            state.ui.summaryStageExtra.didSelectUnusableWaypoints = false;
            state.ui.summaryStageExtra.selectAllRoutesChecked = false;
        },
        changeDidSelectUnusableWaypoints: (state, action: PayloadAction<boolean>) => {
            state.ui.summaryStageExtra.didSelectUnusableWaypoints = action.payload;
        },
        unselectRoute: (state, action: PayloadAction<string>) => {
            state.ui.summaryStageExtra.selectedRouteUuids =
                state.ui.summaryStageExtra.selectedRouteUuids.filter((uuid) => uuid !== action.payload);
            state.ui.summaryStageExtra.selectAllRoutesChecked = false;
        },
        setStationConfirmStageFilteredStations: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                stationList: ISimulationStationToWaypoint[];
            }>
        ): void => {
            state.ui.stationsConfiStage.filteredStations = action.payload.stationList;
        },
        setSummaryStageFilteredRoutes: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                routeList: IOptimumRouteData[];
            }>
        ): void => {
            state.ui.summaryStageExtra.filteredRoutes = action.payload.routeList;
        },
        setStationsSimulationResult: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                simulationResult: ISimulationResult;
            }>
        ): void => {
            state.data.getStationsSimulationResult.data = action.payload.simulationResult;
            state.ui.stationsConfiStage.filteredStations = action.payload.simulationResult.stationToWayPoints;
        },
        setGetStationsSimulationResultStatus: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ status: string }>
        ): void => {
            state.data.getStationsSimulationResult.status = action.payload.status;
        },

        setIsParametersFormOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isParameterFormOpen: boolean }>
        ): void => {
            state.ui.isParameterFormOpen = action.payload.isParameterFormOpen;
        },
        setWaypointsForOptiObject: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                waypointsForOptiObject: {
                    validWaypoints: IOptimizationWaypoint[];
                    invalidWaypoints: IOptimizationWaypoint[];
                    unRecognizedWaypoints: IOptimizationWaypoint[];
                } | null;
            }>
        ): void => {
            state.ui.waypointsForOptiObject = action.payload.waypointsForOptiObject;
            // state.ui.editPrevOptimizationScreen.fieldsValues =
            //     action.payload.waypointsForOptiObject?.validWaypoints || [];
        },
        setIsCsvLoadDoneModalOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isOpen: boolean }>
        ): void => {
            state.ui.isCsvLoadDoneModalOpen = action.payload.isOpen;
        },
        setOptimumRoutes: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ route: IOptimumRouteData[] }>
        ): void => {
            if (state.data.getSimulationResult.data) {
                state.data.getSimulationResult.data.optimumRouteResult = action.payload.route;
            }
        },
        setGetSimulationResult: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ status: string }>
        ): void => {
            state.data.getSimulationResult.status = action.payload.status;
        },
        setIsOptimizationSimulationScreenOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{ isOpen: boolean }>
        ): void => {
            state.ui.optimizationSimulationScreen.isOpen = action.payload.isOpen;
        },
        setSimulationResult: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                simulationResult: ISimulationResult;
            }>
        ): void => {
            state.data.getSimulationResult.data = action.payload.simulationResult;
            state.ui.summaryStageExtra.filteredRoutes = action.payload.simulationResult.optimumRouteResult;
        },
        setSimulationResultEditPrevious: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                simulationResult: ISimulationResult;
            }>
        ): void => {
            const waypointsUsedForOpt = action.payload.simulationResult.requestBody.wayPointsJson.filter(
                (waypoint) => !waypoint.isTarget
            );

            // setting the target
            if (
                action.payload.simulationResult.requestBody.optimizationType ===
                OptiFlowTypes.RoutesOnlyCreation
            ) {
                const target = action.payload.simulationResult.requestBody.wayPointsJson.find(
                    (waypoint) => waypoint.isTarget
                );

                if (target) {
                    state.ui.targetWaypoint = target;
                }
            }

            state.ui.waypointsForOptiObject = {
                invalidWaypoints: [],
                unRecognizedWaypoints: [],
                validWaypoints: waypointsUsedForOpt,
            };

            // state.data.getSimulationResultPreviousOptimization.data = action.payload.simulationResult;

            // state.ui.editPrevOptimizationScreen.editPrevOptFilteredData = waypointsUsedForOpt;
            state.ui.editPrevOptimizationScreen.fieldsValues = waypointsUsedForOpt;
        },
        setSimulationResultFirstRing: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                simulationResult: ISimulationResult | null;
            }>
        ): void => {
            state.data.getSimulationResult.firstRingData = action.payload.simulationResult;
        },
        setSimulationHashId: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                hashId: string | null;
            }>
        ): void => {
            state.data.getSimulationResult.simulationHashId = action.payload.hashId;
        },
        setMaxTravelTime: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                maxTravelTime: number;
                maxValue?: number;
                minValue?: number;
            }>
        ): void => {
            if (action.payload.maxValue && action.payload.minValue) {
                state.ui.parametersForm.maxTravelTime =
                    action.payload.maxValue - action.payload.maxTravelTime + action.payload.minValue;
            } else {
                state.ui.parametersForm.maxTravelTime = action.payload.maxTravelTime;
            }
        },
        setMaxWalkDistance: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                maxWalkDistanceTierOne: number;
                maxValue?: number;
                minValue?: number;
            }>
        ): void => {
            // using aliases for readability
            const currState = state.ui.parametersForm;
            const { payload } = action;

            if (payload.maxValue && (payload.minValue === 0 || payload.minValue)) {
                currState.maxWalkDistance =
                    payload.maxValue - payload.maxWalkDistanceTierOne + payload.minValue; // turning to rtl
            } else {
                currState.maxWalkDistance = payload.maxWalkDistanceTierOne;
            }
        },
        setMaxWalkDistanceTierTwo: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                maxWalkDistanceTierTwo: number;
                maxValue?: number;
                minValue?: number;
            }>
        ): void => {
            if (action.payload.maxValue && typeof action.payload.minValue === 'number') {
                state.ui.parametersForm.maxTierTwoWalkDistance =
                    action.payload.maxValue - action.payload.maxWalkDistanceTierTwo + action.payload.minValue; // to rtl
            } else {
                state.ui.parametersForm.maxTierTwoWalkDistance = action.payload.maxWalkDistanceTierTwo;
            }
        },

        setVehicleCapacity: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                vehicleCapacity: number;
                maxValue?: number;
                minValue?: number;
            }>
        ): void => {
            if (action.payload.maxValue && action.payload.minValue) {
                state.ui.parametersForm.vehicleCapacity =
                    action.payload.maxValue - action.payload.vehicleCapacity + action.payload.minValue;
            } else {
                state.ui.parametersForm.vehicleCapacity = action.payload.vehicleCapacity;
            }
        },
        setTargetWaypoint: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                waypoint: IOptimizationWaypoint;
            }>
        ): void => {
            state.ui.targetWaypoint = action.payload.waypoint;
        },
        setIsFixAddressModalOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                isOpen: boolean;
            }>
        ): void => {
            state.ui.isFixAddressModalOpen = action.payload.isOpen;
        },
        setIsNotEnoughCreditModalOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                isOpen: boolean;
            }>
        ): void => {
            state.ui.isNotEnoughCreditModalOpen = action.payload.isOpen;
        },
        setIsCsvImageModalOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                isOpen: boolean;
            }>
        ): void => {
            state.ui.isCsvImageModalOpen = action.payload.isOpen;
        },
        resetWaypointsForOptiObject: (state: OptimizationsSliceState): void => {
            state.ui.waypointsForOptiObject = null;
        },
        resetTargetWaypoint: (state: OptimizationsSliceState): void => {
            state.ui.targetWaypoint = null;
        },
        approveWaypoints: (state: OptimizationsSliceState): void => {
            if (state.ui.waypointsForOptiObject) {
                const isValidWaypoint = (waypoint: IOptimizationWaypoint) =>
                    waypoint.lat && waypoint.lng && waypoint.lat !== 0 && waypoint.lng !== 0;

                const { waypointsForOptiObject: waypointsForOptiObject } = state.ui;

                const newValidWaypoints = waypointsForOptiObject.invalidWaypoints.filter((waypoint) =>
                    isValidWaypoint(waypoint)
                );

                const newInvalidWaypoints = waypointsForOptiObject.invalidWaypoints.filter(
                    (waypoint) => !isValidWaypoint(waypoint)
                );

                const newValidWaypoints2 = waypointsForOptiObject.unRecognizedWaypoints.filter((waypoint) =>
                    isValidWaypoint(waypoint)
                );

                const newUnrecognizedWaypoints = waypointsForOptiObject.unRecognizedWaypoints.filter(
                    (waypoint) => !isValidWaypoint(waypoint)
                );

                const res = [
                    ...state.ui.waypointsForOptiObject.validWaypoints,
                    ...newValidWaypoints,
                    ...newValidWaypoints2,
                ];

                const updatedValidWaypoints = res;

                state.ui.waypointsForOptiObject.validWaypoints = updatedValidWaypoints;
                state.ui.waypointsForOptiObject.invalidWaypoints = newInvalidWaypoints;
                state.ui.waypointsForOptiObject.unRecognizedWaypoints = newUnrecognizedWaypoints;

                // saving data for editing prev opt feature
                state.ui.editPrevOptimizationScreen.fieldsValues = updatedValidWaypoints;
            }
        },
        updateNotApprovedWaypointCoords: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                waypoint: IOptimizationWaypoint;
                address: AutocompleteAddress;
            }>
        ): void => {
            if (state.ui.waypointsForOptiObject) {
                const invalidIndex = state.ui.waypointsForOptiObject.invalidWaypoints.findIndex(
                    (invalidWaypoint) => invalidWaypoint.waypointId === action.payload.waypoint.waypointId
                );

                if (invalidIndex !== -1) {
                    state.ui.waypointsForOptiObject.invalidWaypoints[invalidIndex] = {
                        ...action.payload.address,
                        waypointId: action.payload.waypoint.waypointId,
                        waypointName: action.payload.waypoint.waypointName,
                        firstName: action.payload.waypoint.firstName,
                        lastName: action.payload.waypoint.lastName,
                        isTarget: false,
                    };
                } else {
                    const unRecognizedIndex = state.ui.waypointsForOptiObject.unRecognizedWaypoints.findIndex(
                        (unRecognizedWaypoint) =>
                            unRecognizedWaypoint.waypointId === action.payload.waypoint.waypointId
                    );

                    if (unRecognizedIndex !== -1) {
                        state.ui.waypointsForOptiObject.unRecognizedWaypoints[unRecognizedIndex] = {
                            ...action.payload.address,
                            waypointId: action.payload.waypoint.waypointId,
                            waypointName: action.payload.waypoint.waypointName,
                            firstName: action.payload.waypoint.firstName,
                            lastName: action.payload.waypoint.lastName,
                            isTarget: false,
                        };
                    } else {
                        return;
                    }
                }
            }
        },
        setDisplaySimulationCalcLoader: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                display: boolean;
            }>
        ): void => {
            state.ui.displaySimulationCalcLoader = action.payload.display;
        },
        setMaxVehicleCapacity: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                maxVehicleCapacity: number;
            }>
        ): void => {
            state.ui.parametersForm.maxVehicleCapacity = action.payload.maxVehicleCapacity;
        },
        setCalculationPercentage: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                percentage: number;
            }>
        ): void => {
            state.data.calculationPercentage = action.payload.percentage;
        },
        restartOptimizationSlice: () => {
            return initialState;
        },
        setIsCsvLoading: (state: OptimizationsSliceState, action: PayloadAction<boolean>): void => {
            state.ui.isCsvLoading = action.payload;
        },

        setIsSendHashIdManuallyModalOpen: (
            state: OptimizationsSliceState,
            action: PayloadAction<boolean>
        ): void => {
            state.ui.isSendHashIdManuallyModalOpen = action.payload;
        },
        setIsSkippedToSummary: (state: OptimizationsSliceState, action: PayloadAction<boolean>): void => {
            state.ui.isSkippedToSummary = action.payload;
        },
        setMagicWandValue: (
            state: OptimizationsSliceState,
            action: PayloadAction<{
                magicWandValue: number;
                maxValue?: number;
                minValue?: number;
            }>
        ): void => {
            // state.ui.parametersForm.magicWandValue = action.payload.magicWandValue;

            if (action.payload.maxValue && action.payload.minValue) {
                state.ui.parametersForm.magicWandValue =
                    action.payload.maxValue - action.payload.magicWandValue + action.payload.minValue;
            } else {
                state.ui.parametersForm.magicWandValue = action.payload.magicWandValue;
            }
        },

        setRouteType: (state: OptimizationsSliceState, action: PayloadAction<UiState['routeType']>) => {
            state.ui.routeType = action.payload;
        },
        setMaxStationsCount: (state: OptimizationsSliceState, action: PayloadAction<number>) => {
            state.ui.parametersForm.maxStationsCountVal = action.payload;
        },

        ...stationsConfiReducers,
    },
});

// * Exports
// ^ Actions
export const optiSliceActions = optimizationsSlice.actions;

export const {
    setStationConfirmStageFilteredStations: setStationConfirmStageFilteredStationsAction,
    invalidAddressBoxDelete: invalidAddressBoxDeleteAction,
    setOptimizationDescription: setOptimizationDescriptionAction,
    setIsUsingGoogle: setIsUsingGoogleAction,
    // -- edit prev opt actions
    setIsEditPrevOptError: setIsEditPrevOptErrorAction,
    editPrevOptimizationEditWaypoint: epoEditWaypointAction,
    // editSetPrevOptFilteredData: epoFilteredDataAction,
    editPrevOptimizationAddWaypoint: epoAddWaypointAction,
    editPrevOptimizationDeleteWaypoint: epoDeleteWaypointAction,
    epoSetFieldValues,
    setSimulationResultEditPrevious: setSimulationResultEditPreviousAction,
    setEditPrevOptimization: setEditPrevOptimizationAction,
    setAllInputsTextByWaypointId,
    updateInputTextByWaypointId,
    // -- waypoints for opti
    setWaypointsForOptiObject,
    resetWaypointsForOptiObject,
    setIsCsvLoadDoneModalOpen: setIsCsvLoadDoneModalOpen,
    setIsCsvImageModalOpen: setIsCsvImageModalOpenAction,
    setIsCsvLoading: setIsCsvLoadingAction,
    // -- rest
    setSummaryStageFilteredRoutes: setSummaryStageFilteredRoutesAction,
    setStationsSimulationResult: setStationsSimulationResultAction,
    setGetStationsSimulationResultStatus: setGetStationsSimulationResultStatusAction,
    setMaxWalkDistanceTierTwo: setMaxWalkDistanceTierTwoAction,
    setMaxWalkDistance: setMaxWalkDistanceAction,
    setIsParametersFormOpen: setIsParametersFormOpenAction,
    setIsOptimizationSimulationScreenOpen: setIsOptimizationSimulationScreenOpenAction,
    setSimulationResult: setSimulationResultAction,
    setSimulationResultFirstRing: setSimulationResultFirstRingAction,
    setGetSimulationResult: setGetSimulationResultAction,
    setSimulationHashId: setSimulationHashIdAction,
    setMaxTravelTime: setMaxTravelTimeAction,
    setVehicleCapacity: setVehicleCapacityAction,
    setMaxVehicleCapacity: setMaxVehicleCapacityAction,
    setOptimumRoutes: setOptimumRoutesAction,
    approveWaypoints: approveWaypointsAction,
    updateNotApprovedWaypointCoords: updateNotApprovedWaypointCoordsAction,
    setIsFixAddressModalOpen: setIsFixAddressModalOpenAction,
    setTargetWaypoint: setTargetWaypointAction,
    resetTargetWaypoint: resetTargetWaypointAction,
    setDisplaySimulationCalcLoader: setDisplaySimulationCalcLoaderAction,
    setCalculationPercentage: setCalculationPercentageAction,
    setIsNotEnoughCreditModalOpen: setIsNotEnoughCreditModalOpenAction,
    restartOptimizationSlice: restartOptimizationSliceAction,
    setMagicWandValue: setMagicWandValueAction,
    setIsSkippedToSummary: setIsSkippedToSummaryAction,
    setIsSendHashIdManuallyModalOpen: setIsSendHashIdManuallyModalOpenAction,
    setOptiFlowType,
    setMaxStationsCount,
} = optimizationsSlice.actions;

export const optimizationsActions = optimizationsSlice.actions;

// ^ Selectors

export const optimizationsRootSelector = (state: RootState) => state.optimization;
export const optimizationsUiSelector = (state: RootState) => state.optimization.ui;
export const optiUiSelector = (state: RootState) => state.optimization.ui;
export const optimizationsDataSelector = (state: RootState) => state.optimization.data;
export const optiDataSelector = (state: RootState) => state.optimization.data;
export const allRoutesDataSelector = (state: RootState) =>
    state.optimization.data.getSimulationResult.data?.optimumRouteResult || [];

export const simulationResultSelector = (state: RootState) =>
    state.optimization.data.getSimulationResult.data;

export const stationsToWaypointsSelector = (state: RootState) =>
    state.optimization.data.getStationsSimulationResult.data?.stationToWayPoints || [];
export const stationsConfiStageSelector = (state: RootState) => state.optimization.ui.stationsConfiStage;
export const summaryStageExtraSelector = (state: RootState) => state.optimization.ui.summaryStageExtra;

// ^ Reducer export
const optimizationsReducer = optimizationsSlice.reducer;
export default optimizationsReducer;

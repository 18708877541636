import React from 'react';
import personSvg from '../../images/personWithComputer.svg';
import PersonWithComputer from '../Icons/PersonSvg';
import { useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';
import { CircularProgress } from '@mui/material';

const explanationRowStyle = { fontSize: '20px', color: '#494949', fontWeight: 400 };

const Start: React.FC = () => {
    const clientToken = useAppSelector((state) => state.auth.clientToken);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            {clientToken !== 'Demo' ? (
                <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}
                >
                    <div
                        style={{
                            fontSize: '36px',
                            color: '#494949',
                            fontWeight: 700,
                            marginTop: '40px',
                            marginBottom: '16px',
                        }}
                    >
                        בואו נתחיל!
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            alignItems: 'center',
                        }}
                    >
                        <div style={explanationRowStyle}>
                            ברוכים הבאים לאשף תכנון המסלולים והערכת העלויות​ ​
                        </div>

                        <div style={explanationRowStyle}>
                            במסכים הבאים תעברו מספר שלבים להגדרת הפרמטרים לטובת החישוב​
                        </div>
                        <div style={explanationRowStyle}>
                            נא הכינו מבעוד מועד את רשימת הכתובות שעבורם נדרש לחשב מסלולי הסעות
                        </div>
                    </div>

                    <div style={{ marginTop: '0px' }}>
                        <PersonWithComputer />
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#2196F3',
                    }}
                >
                    <CircularProgress color="inherit" />
                </div>
            )}
        </div>
    );
};
export default Start;

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    setMaxWalkDistanceAction,
    setMaxWalkDistanceTierTwoAction,
} from 'src/store/slices/optimizationsSlice';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { isStringNumber } from 'src/store/helpers';
import NumberSlider from './ParametersStage/NumberSlider';

const MAXIMUM_MAX_WALK_DISTANCE_IN_METERS = 500;
const MIN_MAX_WALK_DISTANCE_IN_METERS = 25;
const VALIDATION_TIMEOUT = 500;

export const StationsOptiParams: React.FC<{
    styles?: {
        tierTwoCont?: React.CSSProperties;
    };
}> = ({ styles }) => {
    const dispatch = useAppDispatch();

    const dispatchAlert = useDispatchAlert();

    const { maxTierTwoWalkDistance, maxWalkDistance } = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm
    );

    const toRTL = (ltrVal: number) =>
        MAXIMUM_MAX_WALK_DISTANCE_IN_METERS - ltrVal + MIN_MAX_WALK_DISTANCE_IN_METERS;

    const handleMaxWalkDistanceSliderChange = (e: Event, valLTR: number | number[]) => {
        if (typeof valLTR === 'number') {
            dispatch(
                setMaxWalkDistanceAction({
                    maxWalkDistanceTierOne: valLTR,
                    maxValue: MAXIMUM_MAX_WALK_DISTANCE_IN_METERS,
                    minValue: MIN_MAX_WALK_DISTANCE_IN_METERS,
                })
            );

            // validating compared to tier TWO
            const rtlVal = toRTL(valLTR);
            if (rtlVal > maxTierTwoWalkDistance) {
                dispatch(setMaxWalkDistanceTierTwoAction({ maxWalkDistanceTierTwo: rtlVal }));
            }
        }
    };

    const handleMaxWalkDistanceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isStringNumber(event.target.value)) return;

        dispatch(
            setMaxWalkDistanceAction({
                maxWalkDistanceTierOne: Number(event.target.value),
            })
        );
    };

    const handleTierTwoMaxWalkDistanceSliderChange = (e: Event, valLTR: number | number[]) => {
        if (typeof valLTR === 'number') {
            dispatch(
                setMaxWalkDistanceTierTwoAction({
                    maxWalkDistanceTierTwo: valLTR,
                    maxValue: MAXIMUM_MAX_WALK_DISTANCE_IN_METERS,
                    minValue: MIN_MAX_WALK_DISTANCE_IN_METERS,
                })
            );

            // validating compared to tier ONE
            const rtlVal = toRTL(valLTR);
            if (rtlVal < maxWalkDistance) {
                dispatch(setMaxWalkDistanceAction({ maxWalkDistanceTierOne: rtlVal }));
            }
        }
    };

    const handleTierTwoMaxWalkDistanceInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isStringNumber(event.target.value)) return;

        dispatch(
            setMaxWalkDistanceTierTwoAction({
                maxWalkDistanceTierTwo: Number(event.target.value),
            })
        );
    };

    useEffect(() => {
        const validateMaxTravelTimeTierOne = () => {
            if (maxWalkDistance > MAXIMUM_MAX_WALK_DISTANCE_IN_METERS) {
                dispatch(
                    setMaxWalkDistanceAction({ maxWalkDistanceTierOne: MAXIMUM_MAX_WALK_DISTANCE_IN_METERS })
                );
                dispatchAlert(
                    'error',
                    'מרחק ההליכה המקסימלי הינו' + ' ' + MAXIMUM_MAX_WALK_DISTANCE_IN_METERS + ' ' + 'מטרים',
                    true
                );
            }

            if (maxWalkDistance < MIN_MAX_WALK_DISTANCE_IN_METERS) {
                dispatch(
                    setMaxWalkDistanceAction({ maxWalkDistanceTierOne: MIN_MAX_WALK_DISTANCE_IN_METERS })
                );
                dispatchAlert(
                    'error',
                    'מרחק ההליכה המינימלי הינו' + ' ' + MIN_MAX_WALK_DISTANCE_IN_METERS + ' ' + 'מטרים',
                    true
                );
            }

            // validating and fixing compared to tier TWO
            if (maxWalkDistance > maxTierTwoWalkDistance) {
                dispatch(setMaxWalkDistanceTierTwoAction({ maxWalkDistanceTierTwo: maxWalkDistance }));
            }
        };

        const timeout = setTimeout(validateMaxTravelTimeTierOne, VALIDATION_TIMEOUT);
        return () => clearTimeout(timeout);
    }, [maxWalkDistance]);

    useEffect(() => {
        const validateMaxTravelTimeTierTwo = () => {
            if (maxTierTwoWalkDistance > MAXIMUM_MAX_WALK_DISTANCE_IN_METERS) {
                dispatch(
                    setMaxWalkDistanceTierTwoAction({
                        maxWalkDistanceTierTwo: MAXIMUM_MAX_WALK_DISTANCE_IN_METERS,
                    })
                );
                dispatchAlert(
                    'error',
                    'מרחק ההליכה המקסימלי הינו' + ' ' + MAXIMUM_MAX_WALK_DISTANCE_IN_METERS + ' ' + 'מטרים',
                    true
                );
            }

            if (maxTierTwoWalkDistance < MIN_MAX_WALK_DISTANCE_IN_METERS) {
                dispatch(
                    setMaxWalkDistanceTierTwoAction({
                        maxWalkDistanceTierTwo: MIN_MAX_WALK_DISTANCE_IN_METERS,
                    })
                );
                dispatchAlert(
                    'error',
                    'מרחק ההליכה המינימלי הינו' + ' ' + MIN_MAX_WALK_DISTANCE_IN_METERS + ' ' + 'מטרים',
                    true
                );
            }

            // validating compared to tier ONE
            if (maxTierTwoWalkDistance < maxWalkDistance) {
                dispatch(setMaxWalkDistanceAction({ maxWalkDistanceTierOne: maxTierTwoWalkDistance }));
            }
        };

        const timeout = setTimeout(validateMaxTravelTimeTierTwo, VALIDATION_TIMEOUT);
        return () => clearTimeout(timeout);
    }, [maxTierTwoWalkDistance]);

    return (
        <>
            <div>
                <div
                    style={{
                        marginBottom: '10px',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#242424',
                        display: 'flex',
                        gap: '5px',
                    }}
                >
                    <div>מרחק הליכה מקסימלי לנוסע עד לתחנה (במטרים)</div>
                    {/* {maxWalkDistance === 0 && (
                        <div style={{ color: 'red' }}> מרחק הליכה 0 משמעותו איסוף מהבית</div>
                    )} */}
                </div>

                <NumberSlider
                    handleInputChange={handleMaxWalkDistanceInputChange}
                    handleSliderChange={handleMaxWalkDistanceSliderChange}
                    value={maxWalkDistance}
                    max={MAXIMUM_MAX_WALK_DISTANCE_IN_METERS}
                    min={MIN_MAX_WALK_DISTANCE_IN_METERS}
                    step={100}
                />
            </div>

            {
                <div style={styles?.tierTwoCont || {}}>
                    <div
                        style={{ marginBottom: '10px', fontSize: '14px', fontWeight: 400, color: '#242424' }}
                    >
                        מרחק הליכה מקסימלי לנוסע עד לתחנה (במטרים) - מעגל שני{' '}
                    </div>
                    <NumberSlider
                        handleInputChange={handleTierTwoMaxWalkDistanceInputChange}
                        handleSliderChange={handleTierTwoMaxWalkDistanceSliderChange}
                        value={maxTierTwoWalkDistance}
                        max={MAXIMUM_MAX_WALK_DISTANCE_IN_METERS}
                        min={MIN_MAX_WALK_DISTANCE_IN_METERS}
                        step={100}
                    />
                </div>
            }
        </>
    );
};

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { carsStageSliceSelector } from 'src/store/slices/carsStageSlice';
import { setMaxVehicleCapacityAction, setVehicleCapacityAction } from 'src/store/slices/optimizationsSlice';
import { setCarsForPricing, summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import { Stages } from 'src/constants/common';
import { useCarInputsUtils } from '../../hooks/useCarInputsUtils';
import { setStageAction } from 'src/store/slices/commonSlice';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';

export const useCarsStageConfig = () => {
    const dispatch = useAppDispatch();

    const carInputsValues = useAppSelector(
        (state) => carsStageSliceSelector(state).carsForPricingInputsItems
    );

    const { hasStationsFlow } = useSelectedOptiFlowType();

    const { isOpen: isEqualizerDrawerOpen } = useAppSelector(
        (state) => summaryStageSliceSelector(state).configModal
    );

    const globalCarInputsSync = useCarInputsUtils();

    const handleInputsStateSaving = () => {
        globalCarInputsSync.handleCarInputsSave(carInputsValues);

        if (!isEqualizerDrawerOpen) {
            // because this shouldn't be done in the equalizer drawer
            let maxCapacity = 1;
            carInputsValues.forEach((carInput) => {
                if (carInput.capacity > maxCapacity) maxCapacity = carInput.capacity;
            });

            if (maxCapacity > 1) {
                dispatch(setVehicleCapacityAction({ vehicleCapacity: maxCapacity }));
                dispatch(setMaxVehicleCapacityAction({ maxVehicleCapacity: maxCapacity }));
            }
        }

        return {
            carInputsValues,
        };
    };

    const onCarNextStageClick = () => {
        handleInputsStateSaving();
        dispatch(setStageAction({ stage: Stages.Parameters }));
    };

    return {
        onCarNextStageClick,
        carInputsValues,
        passedMinimumCarsValidation: carInputsValues.length > 0,
        allCarsValid: hasStationsFlow
            ? true
            : carInputsValues.every((car) => {
                  return car.capacity > 0;
              }),
        handleInputsStateSaving,
    };
};

import { CircularProgress, Tabs, Tab } from '@mui/material';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { t } from 'src/utils/lang';
import SectionTop from './SectionTop';
import StationsSection from './StationsSection';
import StationsResultMap from './Map/StationsResultMap';
import StationsDataDisplay from './StationsDataDisplay';
import styles from '../../Summary/styles';
import {
    optimizationsUiSelector,
    stationsConfiStageSelector,
    stationsToWaypointsSelector,
} from 'src/store/slices/optimizationsSlice';
import { StationsConfiEqualizerSection } from './Equalizer/StationsConfiEqualizerSection';

export const StationsResultDisplay: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const selectedStationsIds = useAppSelector(
        (state) => stationsConfiStageSelector(state).selectedStationsIds
    );
    const stationsWithWaypoints = useAppSelector((state) => stationsToWaypointsSelector(state));
    const isQuickLoading = useAppSelector(
        (state) => optimizationsUiSelector(state).stationsConfiStage.isQuickDataLoading
    );

    const [currTab, setCurrTab] = useState(0);

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ width: '60%', display: 'flex' }}>
                <StationsConfiEqualizerSection />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        padding: '20px 30px 0px 0px',
                    }}
                >
                    <SectionTop />
                    {isQuickLoading ? (
                        <div style={styles.dataLoadingContainer}>
                            <CircularProgress color="inherit" />
                        </div>
                    ) : (
                        <StationsSection />
                    )}
                </div>
            </div>

            <div
                className="widget-cont"
                style={{
                    width: '40%',
                    padding: '0 20px 20px 20px',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Tabs
                    centered
                    value={currTab}
                    onChange={() => {
                        setCurrTab(currTab === 0 ? 1 : 0);
                    }}
                    sx={styles.tabsSx}
                >
                    <Tab sx={{ fontSize: '18px' }} label={t.map} />
                    <Tab sx={{ fontSize: '18px' }} label={t.costStats} />
                </Tabs>
                {currTab === 0 ? (
                    <StationsResultMap
                        selectedStationsIds={selectedStationsIds}
                        selectedStations={stationsWithWaypoints.filter((s) => {
                            return selectedStationsIds.includes(s.stationId);
                        })}
                    />
                ) : (
                    <StationsDataDisplay />
                )}
            </div>
        </div>
    );
};

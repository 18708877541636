import Routing from './Routing/Routing';
import bgSvg from './images/bg.svg';
import * as React from 'react';

function App() {
    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${bgSvg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: -1,
                    opacity: '1',
                }}
            />
            <Routing />
        </>
    );
}

export default App;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import OptimizationBusIcon from 'src/components/Icons/OptimizationBusIcon';
import styled from 'styled-components';
import { StagesNum, STAGES_TEXTS } from './utils';
import { useInnerWidth } from 'src/hooks/dimensions';
import { useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';
import { Stages } from 'src/constants/common';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';

const StyledBarContainer = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`;

const CompletedLine = styled.div<{
    width: number;
}>`
    height: 8px;
    width: ${(props) => props.width}px;
    background-color: #feb42b;
    transition: width 0.5s ease-in-out;
    border-radius: 16px;
`;

const UncompletedLine = styled.div<{ width: number }>`
    height: 4px;
    width: ${(props) => props.width}px;
    background-color: #ffeac2;
    transition: width 0.5s ease-in-out;
    border-radius: 8px;
`;

const Circle = styled.div<{
    isStageCompleted: boolean;
}>`
    background: ${(props) => (props.isStageCompleted ? '#ffeac2' : '#fff')};
    border: 4px solid ${(props) => (props.isStageCompleted ? '#feb42b' : '#ffeac2')};
    border-radius: 50%;
    width: 16px;
    height: 16px;
    z-index: 1;
    transition: background 0.5s ease-in-out, border 0.5s ease-in-out;
`;

const StyledTextItem = styled.p<{ isCurr: boolean }>`
    font-weight: ${(props) => (props.isCurr ? 'bold' : 'normal')};
    width: 100px;
    text-align: center;
    height: 50px;
    transition: font-weight 0.5s ease-in-out;
    ${(props) => (props.isCurr ? 'position: relative; top: 6px' : '')}
`;

const StyledStageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const StyledBusCont = styled.div<{ right: number }>`
    position: absolute;
    top: -14px;
    z-index: 2;
    right: ${(props) => props.right}px;
    transition: right 0.5s ease-in-out;
`;

const getElementWidth = (ele: HTMLDivElement) => {
    const rect = ele.getBoundingClientRect();
    const width = rect.width;
    return width;
};

// get distance between start of stage4ref and start of stage5ref elements
const getDistanceBetweenStages = (stage1: HTMLDivElement | null, stage2: HTMLDivElement | null) => {
    if (!stage1 || !stage2) return 0;

    const rect1 = stage1.getBoundingClientRect();
    const rect2 = stage2.getBoundingClientRect();
    const distance = rect2.left - rect1.left;
    return Math.abs(distance);
};

const BUS_CENTER_WIDTH = 40;

const BusProcessBar = ({ currStageNum }: { currStageNum: StagesNum }) => {
    const { hasStationsFlow } = useSelectedOptiFlowType();

    const innerWidth = useInnerWidth();

    const calculationPercentage = useAppSelector(
        (state) => optimizationsRootSelector(state).data.calculationPercentage
    );

    const getElementDistanceFromRight = (ele: HTMLDivElement) => {
        const rect = ele.getBoundingClientRect();
        const distanceFromRight = innerWidth - rect.right;
        return distanceFromRight;
    };

    // refs for each stage - 6
    const stage1Ref = useRef<HTMLDivElement | null>(null);
    const stage2Ref = useRef<HTMLDivElement | null>(null);
    const stage3Ref = useRef<HTMLDivElement | null>(null);
    const stage4Ref = useRef<HTMLDivElement | null>(null);
    const stage5Ref = useRef<HTMLDivElement | null>(null);
    const stage6Ref = useRef<HTMLDivElement | null>(null);
    const stage7Ref = useRef<HTMLDivElement | null>(null);
    const stage8Ref = useRef<HTMLDivElement | null>(null);

    const completedLineRef = useRef<HTMLDivElement | null>(null);
    const uncompletedLineRef = useRef<HTMLDivElement | null>(null);
    const barContainerRef = useRef<HTMLDivElement | null>(null);

    const busDistanceFromPrevStage = useMemo(
        () => getDistanceBetweenStages(stage1Ref.current, stage2Ref.current) * (calculationPercentage / 100),
        [calculationPercentage]
    );

    const calcBusDisFromRight = (stageRef: HTMLDivElement) => {
        if (!completedLineRef.current) return 0;
        return (
            getElementDistanceFromRight(stageRef) - getElementDistanceFromRight(completedLineRef.current) + 10
        );
    };

    const getBusRight = () => {
        switch (currStageNum) {
            case 1:
                if (!stage1Ref.current) return 0;
                return calcBusDisFromRight(stage1Ref.current);

            case 2:
                if (!stage2Ref.current) return 0;
                return calcBusDisFromRight(stage2Ref.current);

            case 3:
                if (!stage3Ref.current) return 0;
                return calcBusDisFromRight(stage3Ref.current);
            case 4:
                if (!stage4Ref.current) return 0;
                return calcBusDisFromRight(stage4Ref.current);

            case 5:
                if (!stage5Ref.current) return 0;
                return calcBusDisFromRight(stage5Ref.current) + busDistanceFromPrevStage;

            case 6:
                if (!stage6Ref.current) return 0;
                return calcBusDisFromRight(stage6Ref.current);

            case 8:
                if (!stage8Ref.current) return 0;
                return calcBusDisFromRight(stage8Ref.current);

            default:
                if (!stage7Ref.current) return 0;
                return calcBusDisFromRight(stage7Ref.current);
        }
    };

    const [busRightVal, setBusRightVal] = useState(0);

    useEffect(() => {
        setBusRightVal(getBusRight());
    }, [currStageNum, innerWidth, busDistanceFromPrevStage]);

    const completedLineWidth = busRightVal + BUS_CENTER_WIDTH;

    const unCompletedLineWidth = useMemo(() => {
        if (!barContainerRef.current) return 0;
        const bw = getElementWidth(barContainerRef.current);
        return bw - completedLineWidth;
    }, [completedLineWidth]);

    return (
        <>
            <StyledBarContainer ref={barContainerRef}>
                <StyledBusCont right={busRightVal}>
                    <OptimizationBusIcon />
                </StyledBusCont>
                <CompletedLine width={completedLineWidth} ref={completedLineRef} />
                <UncompletedLine width={unCompletedLineWidth} ref={uncompletedLineRef} />
            </StyledBarContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-15px' }}>
                <StyledStageContainer ref={stage1Ref}>
                    <Circle isStageCompleted={currStageNum >= 1} />
                    <StyledTextItem isCurr={currStageNum === 1}>{STAGES_TEXTS.Start}</StyledTextItem>
                </StyledStageContainer>
                <StyledStageContainer ref={stage2Ref}>
                    <Circle isStageCompleted={currStageNum >= 2} />
                    <StyledTextItem isCurr={currStageNum === 2}>{STAGES_TEXTS.DataLoading}</StyledTextItem>
                </StyledStageContainer>
                <StyledStageContainer ref={stage3Ref}>
                    <Circle isStageCompleted={currStageNum >= 3} />
                    <StyledTextItem isCurr={currStageNum === 3}>
                        {STAGES_TEXTS.TargetSelection}
                    </StyledTextItem>
                </StyledStageContainer>
                <StyledStageContainer ref={stage8Ref}>
                    <Circle isStageCompleted={[4, 5, 6, 7].includes(currStageNum)} />
                    <StyledTextItem isCurr={currStageNum === 8}>
                        {STAGES_TEXTS.ChooseOptiFlowType}
                    </StyledTextItem>
                </StyledStageContainer>
                <StyledStageContainer ref={stage4Ref}>
                    <Circle isStageCompleted={currStageNum >= 4 && currStageNum !== 8} />
                    <StyledTextItem isCurr={currStageNum === 4}>
                        {hasStationsFlow ? STAGES_TEXTS.CarsStationsFlow : STAGES_TEXTS.Cars}
                    </StyledTextItem>
                </StyledStageContainer>
                <StyledStageContainer ref={stage5Ref}>
                    <Circle isStageCompleted={currStageNum >= 5 && currStageNum !== 8} />
                    <StyledTextItem isCurr={currStageNum === 5}>{STAGES_TEXTS.Parameters}</StyledTextItem>
                </StyledStageContainer>
                <StyledStageContainer ref={stage6Ref}>
                    <Circle isStageCompleted={currStageNum >= 6 && currStageNum !== 8} />
                    <StyledTextItem isCurr={currStageNum === 6}>
                        {STAGES_TEXTS.StationConfirmation}
                    </StyledTextItem>
                </StyledStageContainer>
                <StyledStageContainer ref={stage7Ref}>
                    <Circle isStageCompleted={currStageNum >= 7 && currStageNum !== 8} />
                    <StyledTextItem isCurr={currStageNum === 7}>{STAGES_TEXTS.Summary}</StyledTextItem>
                </StyledStageContainer>
            </div>
        </>
    );
};

export default BusProcessBar;

// const [calculationPercentage, setC] = useState(1);
// // inc calc percentage every 1s
// useEffect(() => {
//     const interval = setInterval(() => {
//         setC((prev) => {
//             if (prev >= 100) return prev;
//             return prev + 5;
//         });
//     }, 1000);
//     return () => clearInterval(interval);
// }, []);

import React, { useMemo, useState } from 'react';
import { openConfigModal, summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import DataDisplay from './DataDisplay';
import { CircularProgress, Slide, Tab, Tabs } from '@mui/material';
import { t } from 'src/utils/lang';
import RoutesResultMap from './Map/RoutesResultMap';
import SectionTop from './SectionTop';
import RoutesListSection from './RoutesList';
import styles from './styles';
import {
    allRoutesDataSelector,
    optimizationsRootSelector,
    summaryStageExtraSelector,
} from 'src/store/slices/optimizationsSlice';
import Loader from 'src/components/Loader/Loader';
import { toFixed } from 'src/utils/utilis';
import EqualizerDrawer from './Equalizer/EqualizerDrawer';
import ToggleConfigBtn from './ConfigIcon';
import PaceIcon from 'src/components/Icons/PaceIcon';
import GroupIcon from 'src/components/Icons/GroupIcon';
import VehicleCapacityIcon from 'src/components/Icons/VehicleCapacityIcon';
import { useAdvancedParamsApi } from 'src/hooks/useAdvancedParamsApi';
import BaseTooltip from 'src/components/BaseTooltip';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';
import MaxStationsIcon from 'src/components/Icons/maxStationsIcon';

const ClosedEqualizerDrawer: React.FC<{}> = () => {
    const dispatch = useAppDispatch();
    const { hasStationsFlow } = useSelectedOptiFlowType();

    const { maxTravelTime, vehicleCapacity, maxStationsCountVal } = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm
    );

    return (
        <div
            style={{
                height: '100%',
                minWidth: '50px',
                background: '#f9f8f8',
                boxShadow: '-2px 0px 6px rgba(0, 0, 0, 0.1)',
                zIndex: 2,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <ToggleConfigBtn
                sx={{ position: 'absolute', right: '31px', bottom: '46.8%' }}
                onClick={() => {
                    dispatch(openConfigModal());
                }}
                tooltipComponent={<div>לחץ כאן לשינוי פרמטרים לאופטימיזציה</div>}
            />

            <BaseTooltip title="משך נסיעה מקס` (בדקות)">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '3px',
                        position: 'absolute',
                        bottom: '55%',
                    }}
                >
                    <PaceIcon />
                    <div style={{ color: '#494949' }}>{maxTravelTime}</div>
                </div>
            </BaseTooltip>

            <BaseTooltip title={hasStationsFlow ? 'מקסימום תחנות במסלול' : 'מקסימום נוסעים ברכב'}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '3px',
                        position: 'absolute',
                        bottom: '36%',
                    }}
                >
                    {hasStationsFlow ? <MaxStationsIcon /> : <VehicleCapacityIcon />}
                    <div style={{ color: '#494949' }}>
                        {hasStationsFlow ? maxStationsCountVal : vehicleCapacity}
                    </div>
                </div>
            </BaseTooltip>
        </div>
    );
};

const Summary: React.FC = () => {
    const dispatch = useAppDispatch();

    const displaySimulationCalcLoader = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.displaySimulationCalcLoader
    );

    const calculationPercentage = useAppSelector(
        (state) => optimizationsRootSelector(state).data.calculationPercentage
    );

    const { isOpen: isEqualizerOpen } = useAppSelector(
        (state) => summaryStageSliceSelector(state).configModal
    );

    const isDataLoading = useAppSelector((state) => summaryStageSliceSelector(state).isQuickDataLoading);

    const allRoutesData = useAppSelector((state) => allRoutesDataSelector(state));

    const selectedRouteUuids = useAppSelector((state) => summaryStageExtraSelector(state).selectedRouteUuids);

    const selectedRoutes = useMemo(
        () => allRoutesData.filter((route) => selectedRouteUuids.includes(route.routeID)),
        [selectedRouteUuids, allRoutesData]
    );

    const containerRef = React.useRef(null);

    const [currTab, setCurrTab] = useState<0 | 1>(1);

    return (
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column', position: 'relative' }}>
            {displaySimulationCalcLoader ? (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Loader percentage={+toFixed(calculationPercentage, 0)} />
                </div>
            ) : (
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ width: '60%', display: 'flex', overflow: 'auto' }}>
                        {!isEqualizerOpen && <ClosedEqualizerDrawer />}
                        <Slide
                            direction="left"
                            in={isEqualizerOpen}
                            container={containerRef.current}
                            unmountOnExit
                            mountOnEnter
                        >
                            <EqualizerDrawer />
                        </Slide>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                padding: '20px 30px 0px 0px',
                            }}
                        >
                            <SectionTop />
                            {isDataLoading ? (
                                <div style={styles.dataLoadingContainer}>
                                    <CircularProgress color="inherit" />
                                </div>
                            ) : (
                                <RoutesListSection />
                            )}
                        </div>
                    </div>

                    <div
                        className="widget-cont"
                        style={{ width: '40%', padding: '0 20px 20px 20px', overflow: 'auto' }}
                    >
                        <Tabs
                            centered
                            value={currTab}
                            onChange={() => {
                                setCurrTab(currTab === 0 ? 1 : 0);
                            }}
                            sx={styles.tabsSx}
                        >
                            <Tab sx={{ fontSize: '18px' }} label={t.map} />
                            <Tab sx={{ fontSize: '18px' }} label={t.costStats} />
                        </Tabs>
                        {currTab === 0 ? (
                            <RoutesResultMap
                                selectedRouteUuids={selectedRouteUuids}
                                selectedRoutes={selectedRoutes}
                            />
                        ) : (
                            <DataDisplay />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
export default Summary;

/* eslint-disable prefer-const */
import { StaticGoogleMap, Path, Marker } from 'react-static-google-map';
import { MAPS_API_KEY_NO_RESTRICT } from 'src/utils/utilis';
import { IOptimumRouteData, IOptimumWaypoint } from 'src/types/optimization/types';
import * as React from 'react';

function PdfRouteMap({
    routesOverviewPath,
    waypoints,
}: {
    waypoints: IOptimumWaypoint[];
    routesOverviewPath: {
        lat: number;
        lng: number;
    }[];
}) {
    if (!waypoints.length) return null;

    const stops = {
        origin: waypoints[0],
        destination: waypoints[waypoints.length - 1],
        waypoints: waypoints.slice(1, waypoints.length - 1),
    };

    const points = [stops.origin, ...stops.waypoints, stops.destination].map((point) => ({
        lat: point.lat,
        lng: point.lng,
    }));

    return (
        <div style={{ maxHeight: '800px' }}>
            <StaticGoogleMap size="400x400" apiKey={MAPS_API_KEY_NO_RESTRICT}>
                {routesOverviewPath.length ? <Path points={routesOverviewPath} color={'blue'} /> : null}
                {points.map((point) => (
                    <Marker
                        key={`${point.lat},${point.lng}`}
                        location={{ lat: point.lat, lng: point.lng }}
                        color={'red'}
                    />
                ))}
            </StaticGoogleMap>
        </div>
    );
}

export default PdfRouteMap;

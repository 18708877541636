/*
        {
            subText: 'avgRoutePassengersCount',
            mainText: `${routesStatistics?.avgRoutePassengersCount.toFixed(1)}₪`,
        },
        {
            subText: 'avgRoutePrice',
            mainText: `${routesStatistics?.avgRoutePrice.toFixed(0)}₪`,
        },
        {
            subText: 'pricePerPassenger',
            mainText: routesStatistics?.pricePerPassenger.toFixed(0),
        },
        {
            subText: 'totalPassengersCount',
            mainText: routesStatistics?.totalPassengersCount.toFixed(1),
        },
*/

export const t = {
    avgPassCountPerStation: 'כמות נוסעים ממוצעת לתחנה',
    stationsList: 'רשימת תחנות',
    displayingStationsList: 'לפנייך מוצגות התחנות שנקבעו לפי בקשותייך',
    avgRoutePassengersCount: 'כמות נוסעים ממוצעת ברכב',
    avgRoutePrice: 'עלות ממוצעת לנסיעה',
    avgLinePrice: 'עלות ממוצעת לקו',
    pricePerPassenger: 'עלות לנוסע',
    totalPassengersCount: "סה''כ נוסעים",
    linePriceDisclaimer: 'העלות מתייחסת להרצה של כל קו פעם אחת בלבד',
    km: "ק''מ",
    m: 'מטרים',
    hours: 'שעות',
    minutes: "דק'",
    carTypeName: 'סוג רכב',
    passengersCapacity: 'כמות נוסעים ברכב',
    pricePerKm: "מחיר לק''מ",
    pricePerHour: 'מחיר לשעה',
    add: 'הוספה',
    remove: 'הסרה',
    totalOpPrice: "סה''כ עלות תפעולית",
    map: 'מפה',
    costStats: 'ניתוח סטטיסטי',
    displayingRoutesSummary: 'לפנייך מוצגים המסלולים על פי ההגדרות שנבחרו',
    stationsConfi: 'אימות תחנות',
    unusedWaypoints: 'כתובות שלא נכנסו למסלולים ולא יוצגו בחישוב הסופי',
    selectAll: 'בחר הכל',
    noRoutesSelectedToDisplay: ' לא נבחרו מסלולים להצגה במפה',
    noStationsSelectedToDisplay: 'לא נבחרו תחנות להצגה במפה',
    calculating: 'מבצע חישוב...',
    summary: 'סיכום',
    total: 'סה"כ',
    requiredCars: 'רכבים נדרשים',
    oopsSomethingWentWrongTryAgain: 'משהו השתבש, אנא נסה שוב במועד מאוחר יותר',
    generalOptimizationError: 'תקלה זמנית בהרצת התהליך. ניתן ליצור קשר עם התמיכה בטלפון 08-9464288',
    waypointsNotInRoutes: 'כתובות שלא נכנסו למסלולים',
    routesList: 'רשימת מסלולים',
    avgWalkDistance: 'מרחק הליכה ממוצע לנוסע',
    totalStationsCount: 'סה"כ תחנות',
    errDrawingRoute: 'תקלה זמנית בציור המסלול, ניתן ליצור קשר עם התמיכה בטלפון 08-9464288',
    avgStationAmountInRoute: 'מס תחנות ממוצע למסלול',
    stationAmount: 'סה"כ תחנות',
} as const;

export const getErrWithCode = (
    err: string,
    code: number,
    options?: {
        withPrefix?: boolean;
        withSuffix?: boolean;
    }
) => {
    return `${options?.withPrefix ? 'שגיאה - ' : ''}
    ${err}
    ${options?.withSuffix ? '. ניתן ליצור קשר עם התמיכה בטלפון 08-9464288' : ''}
      ( קוד שגיאה ${code} )`;
};

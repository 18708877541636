import { PayloadAction } from '@reduxjs/toolkit';
import { OptimizationsSliceState } from 'src/types/optimization/sliceTypes';

const getRootState = (state: OptimizationsSliceState) => state.ui.stationsConfiStage;

export const stationsConfiReducers = {
    // --------------------- equalizer
    setIsEqualizerDrawerOpen: (state: OptimizationsSliceState, action: PayloadAction<boolean>) => {
        getRootState(state).isEqualizerDrawerOpen = action.payload;
    },
    // ---------------------  stations selection
    selectStation: (state: OptimizationsSliceState, action: PayloadAction<string>) => {
        const totalSelectedCount = getRootState(state).selectedStationsIds.length + 1;
        getRootState(state).selectedStationsIds.push(action.payload);
        getRootState(state).selectAllStationsChecked =
            totalSelectedCount === state.data.getStationsSimulationResult.data?.stationToWayPoints.length;
    },
    selectAllStations: (state: OptimizationsSliceState) => {
        getRootState(state).selectedStationsIds =
            state.data.getStationsSimulationResult.data?.stationToWayPoints.map((route) => route.stationId) ||
            [];
        getRootState(state).didSelectUnusableStations = true;
        getRootState(state).selectAllStationsChecked = true;
    },
    unselectAllStations: (state: OptimizationsSliceState) => {
        getRootState(state).selectedStationsIds = [];
        getRootState(state).didSelectUnusableStations = false;
        getRootState(state).selectAllStationsChecked = false;
    },
    unselectStation: (state: OptimizationsSliceState, action: PayloadAction<string>) => {
        getRootState(state).selectedStationsIds = getRootState(state).selectedStationsIds.filter(
            (stationId) => stationId !== action.payload
        );
        getRootState(state).selectAllStationsChecked = false;
    },
    scSetIsQuickDataLoading: (state: OptimizationsSliceState, action: PayloadAction<boolean>) => {
        getRootState(state).isQuickDataLoading = action.payload;
    },
};

import { GoogleMap } from '@react-google-maps/api';
import React, { useRef, useState } from 'react';
import { defaultCenter } from 'src/components/MapType2';
import { ISimulationStationToWaypoint } from 'src/types/optimization/types';
import { Coords, fitBounds } from 'google-map-react';

export const useStationsConfiMap = (
    selectedStations: ISimulationStationToWaypoint[],
    selectedStationsIds: string[],
    options?: {
        onZoomChange?: (newZoom: number) => void;
    }
) => {
    // const [zoom, setZoom] = useState<number>(8);
    const mapDivRef = useRef<HTMLDivElement | null>(null);
    // const zoomRef = React.useRef(8);
    const [zoom, setZoom] = React.useState(8);
    const boundsRef = React.useRef<{ ne: google.maps.LatLngLiteral; sw: google.maps.LatLngLiteral } | null>(
        null
    );

    const [center, setCenter] = useState<google.maps.LatLngLiteral>(defaultCenter);
    const [map, setMap] = useState<google.maps.Map | null>(null);

    // const { rendererProps } = useSimulationDirections(selectedStations);
    const GoogleMapProps: GoogleMap['props'] = {
        center,
        onZoomChanged(this: google.maps.Map) {
            // onZoomChange(this.getZoom())
            try {
                const newZoom = this.getZoom();
                if (typeof newZoom === 'number') {
                    // console.log('newZoom', newZoom);
                    // zoomRef.current = newZoom;
                    setZoom(newZoom);
                    options?.onZoomChange && options.onZoomChange(newZoom);
                }
            } catch (error) {
                console.log(error);
            }
        },
        onBoundsChanged: () => {
            const b = map?.getBounds();
            if (!b) {
                console.log('no bounds');
                return;
            }

            boundsRef.current = {
                ne: b.getNorthEast().toJSON(),
                sw: b.getSouthWest().toJSON(),
            };
        },

        options: {
            fullscreenControl: false,
        },
        onLoad: (googleMap) => {
            setMap(googleMap);
        },
        onUnmount: () => {
            setMap(null);
        },

        zoom,
    };

    React.useEffect(() => {
        // PURPOSE - zooming and centering the map based on displayed data

        if (selectedStations.length === 1) {
            setCenter({ lat: selectedStations[0].station.lat, lng: selectedStations[0].station.lng });
            setZoom(16);
            return;
        }

        if (selectedStations.length && mapDivRef.current) {
            // Calculate the bounding box of the selected waypoints
            const bounds = selectedStations.reduce((acc, curr) => {
                const {
                    station: { lat, lng },
                } = curr;
                const latLng = new google.maps.LatLng(lat, lng);
                const coords: Coords = { lat: latLng.lat(), lng: latLng.lng() };
                return acc.extend(coords);
            }, new google.maps.LatLngBounds());

            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            const neCoords: Coords = {
                lat: ne.lat(),
                lng: ne.lng(),
            };

            const swCoords: Coords = {
                lat: sw.lat(),
                lng: sw.lng(),
            };

            const rect = mapDivRef.current.getBoundingClientRect();

            const fittedBounds = fitBounds(
                { ne: neCoords, sw: swCoords },
                { width: rect.width, height: rect.height }
            );

            setCenter(fittedBounds.center);
            // zoomRef.current = fittedBounds.zoom - 1;
            setZoom(fittedBounds.zoom - 1);
        }
    }, [selectedStationsIds]);

    return {
        GoogleMapProps,
        map,
        mapDivRef,
        zoom,
        bounds: boundsRef.current,
    };
};

import React, { DragEvent, useRef, useState } from 'react';
import { useCsvReverseGeo } from 'src/hooks/useCsvReverseGeo';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    approveWaypointsAction,
    optimizationsRootSelector,
    setWaypointsForOptiObject,
    setEditPrevOptimizationAction,
    setIsCsvImageModalOpenAction,
    setIsCsvLoadingAction,
    setIsFixAddressModalOpenAction,
    setIsSendHashIdManuallyModalOpenAction,
    setOptimizationDescriptionAction,
} from 'src/store/slices/optimizationsSlice';
import InvalidAddressBox, { hasValidCoords } from '../../InvalidAddressBox';
import styles from 'src/styles/mapStyles/styles';
import MapType2, { defaultCenter } from '../../MapType2';
import { Coords, fitBounds } from 'google-map-react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import ActionConfirmModal from '../../Modals/ActionConfirmModal';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Button from '@mui/material/Button';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import ImageModal from '../../Modals/ImageModal';
import csvExample from '../../../images/csv file example.png';
import RefreshIcon from '../../Icons/RefreshIcon';
import PersonCheckListSvg from '../../Icons/PersonCheckListSvg';
import CloudUploadIcon from '../../Icons/CloudUploadIcon';
import { IOptimizationWaypoint } from 'src/types/optimization/types';
import { CircularProgress, TextField } from '@mui/material';
import { useGetOptimizationSimulation } from 'src/hooks/useGetOptimizationSimulationResult';
import BaseTextField from '../../TextInput';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { setStageAction } from 'src/store/slices/commonSlice';
import { Stages } from 'src/constants/common';
import { EditPrevOptimization } from './EditPrevOptimization/EditPrevOptimization';
import { SelectPrevOpti } from './components/SelectPrevOpti';
import { inputSX } from './EditPrevOptimization/WaypointItem';
import useGetCreditNeededForCalc from 'src/hooks/useGetCreditNeededForCalc';

const dataLoadingContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: 'gray',
};

export const getUnRecognizedWaypointsCount = (waypointsForOptiObject: {
    validWaypoints: IOptimizationWaypoint[];
    invalidWaypoints: IOptimizationWaypoint[];
    unRecognizedWaypoints: IOptimizationWaypoint[];
}) => {
    if (waypointsForOptiObject) {
        let counter = 0;

        const relevantWaypoints = [
            ...waypointsForOptiObject.invalidWaypoints,
            ...waypointsForOptiObject.unRecognizedWaypoints,
        ];

        relevantWaypoints.forEach((waypoint) => {
            if (!hasValidCoords(waypoint)) counter++;
        });

        return counter;
    }
    return 0;
};

const uploadAreaBaseStyle = {
    height: '300px',
    width: '75%',
    border: '2px dashed #49494997',
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '25px',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.35)',
};

const DataLoading: React.FC = () => {
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const onLoadCsv = useCsvReverseGeo();
    const dispatch = useAppDispatch();
    const getAndValidateCreditForCalc = useGetCreditNeededForCalc();

    const waypointsForOptiObject = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.waypointsForOptiObject
    );

    const hashId = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.editPrevOptimizationScreen.hashId
    );

    const isFixAddressModalOpen = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.isFixAddressModalOpen
    );
    const isCsvLoading = useAppSelector((state) => optimizationsRootSelector(state).ui.isCsvLoading);
    const isCsvImageModalOpen = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.isCsvImageModalOpen
    );

    const isSendHashIdManuallyModalOpen = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.isSendHashIdManuallyModalOpen
    );

    const optimizationDescription = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.optimizationDescription
    );

    const { isOpen: isEditPrevOptimizationOpen } = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.editPrevOptimizationScreen
    );

    const getOptimizationSimulation = useGetOptimizationSimulation();

    const currentMonthCredit = useAppSelector((state) => state.auth.currentMonthCredit);
    const generalAdditionalCredit = useAppSelector((state) => state.auth.generalAdditionalCredit);

    const [zoom, setZoom] = useState<number>(8);

    const [center, setCenter] = useState<Coords>(defaultCenter);

    const [hashIdInput, setHashIdInput] = useState('');

    const [dragging, setDragging] = useState(false);

    const onManualDataLoading = () => {
        dispatch(setEditPrevOptimizationAction({ hashId: null, isOpen: true }));
        // dispatch(epoFilteredDataAction({ waypoints: [] }));
        dispatch(
            setWaypointsForOptiObject({
                waypointsForOptiObject: {
                    invalidWaypoints: [],
                    unRecognizedWaypoints: [],
                    validWaypoints: [],
                },
            })
        );
    };

    const handleDragEnter = (ev: DragEvent<HTMLDivElement>) => {
        console.log('dragEnter');
        ev.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (ev: DragEvent<HTMLDivElement>) => {
        console.log('dragLeave');
        ev.preventDefault();
        setDragging(false);
    };

    const handleDrop = (ev: DragEvent<HTMLDivElement>) => {
        console.log('drop');
        ev.preventDefault();
        setDragging(false);

        // Handle dropped files here
        const files = ev.dataTransfer.files;

        dispatch(setIsCsvLoadingAction(true));

        onLoadCsv.changeHandler(null, files[0]);

        // Process the files or trigger the upload

        // Reset the file input value
    };

    const onFileUpload = (ev: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setIsCsvLoadingAction(true));
        onLoadCsv.changeHandler(ev);
        resetFileInput();
    };

    const GoogleMapProps: GoogleMap['props'] = {
        center,
        onZoomChanged(this: google.maps.Map) {
            try {
                setZoom(zoom);
            } catch (error) {
                console.log(error);
            }
        },
        options: {
            fullscreenControl: false,
        },
    };

    if (zoom) {
        try {
            // Is supposedly readonly, however the top-view button is not working without this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            GoogleMapProps.zoom = zoom;
        } catch (error) {
            console.log(error);
        }
    }

    const myRef = useRef<HTMLInputElement | null>(null);

    const onLoadWaypointsFromCsv = () => {
        if (inputFileRef.current) {
            // `current` points to the mounted file input element
            inputFileRef.current.click();
        }
    };

    const resetFileInput = () => {
        if (inputFileRef.current) {
            // `current` points to the mounted file input element
            inputFileRef.current.value = '';
        }
    };

    React.useEffect(() => {
        if (waypointsForOptiObject) {
            const notApprovedWaypoints = [
                ...waypointsForOptiObject.unRecognizedWaypoints,
                ...waypointsForOptiObject.invalidWaypoints,
            ];

            const relevantWaypoints = notApprovedWaypoints.filter(
                (waypoint) => waypoint.lat && waypoint.lng && waypoint.lat !== 0 && waypoint.lng !== 0
            );

            if (relevantWaypoints) {
                if (relevantWaypoints.length === 1 && relevantWaypoints[0]) {
                    setCenter({ lat: relevantWaypoints[0].lat, lng: relevantWaypoints[0].lng });
                    setZoom(12);
                }

                if (relevantWaypoints.length > 1 && myRef.current) {
                    const bounds = relevantWaypoints.reduce((acc, { lat, lng }) => {
                        const latLng = new google.maps.LatLng(lat, lng);
                        const coords: Coords = { lat: latLng.lat(), lng: latLng.lng() };
                        return acc.extend(coords);
                    }, new google.maps.LatLngBounds());

                    const ne = bounds.getNorthEast();
                    const sw = bounds.getSouthWest();

                    const neCoords: Coords = {
                        lat: ne.lat(),
                        lng: ne.lng(),
                    };

                    const swCoords: Coords = {
                        lat: sw.lat(),
                        lng: sw.lng(),
                    };

                    const rect = myRef.current.getBoundingClientRect();

                    const fittedBounds = fitBounds(
                        { ne: neCoords, sw: swCoords },
                        { width: rect.width, height: rect.height }
                    );

                    setCenter(fittedBounds.center);
                    setZoom(fittedBounds.zoom - 1);
                }
            }
        }
    }, [waypointsForOptiObject]);

    const createCSVTemplate = () => {
        const header = ['firstName', 'lastName', 'city', 'street', 'houseNum', '']; // Header row
        const rows = [
            [
                'ישראל',
                'ישראלי',
                'תל אביב',
                'הרצל',
                '10',
                'שים לב! שורה זו היא שורת דוגמא - מחק אותה לפני הרצה <<<',
            ],
        ]; // Data rows

        // Convert header and rows to CSV string
        const csvString = Papa.unparse({
            fields: header,
            data: rows,
        });

        // Add Byte Order Mark (BOM) to indicate UTF-8 encoding
        const csvWithBom = '\uFEFF' + csvString;

        // Convert the CSV string to a Blob
        const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8' });

        // Save the Blob as a CSV file
        saveAs(blob, 'תבנית לדוגמא.csv');
    };

    const onConfirmFixAddressModal = () => {
        dispatch(approveWaypointsAction());
        dispatch(setIsFixAddressModalOpenAction({ isOpen: false }));
        getAndValidateCreditForCalc.getAndValidateCreditNeededForCalc();
    };

    const onCloseFixAddressModal = () => {
        dispatch(setIsFixAddressModalOpenAction({ isOpen: false }));
    };

    const onImageClose = () => {
        dispatch(setIsCsvImageModalOpenAction({ isOpen: false }));
    };

    const openCsvImageModal = () => {
        dispatch(setIsCsvImageModalOpenAction({ isOpen: true }));
    };

    const onApproveWaypoints = () => {
        dispatch(approveWaypointsAction());
    };

    const isZeroInvalidAddresses = () => {
        if (waypointsForOptiObject) {
            return (
                [...waypointsForOptiObject.invalidWaypoints, ...waypointsForOptiObject.unRecognizedWaypoints]
                    .length === 0
            );
        }

        return false;
    };

    const onPrevOptimization = (hashId: string) => {
        if (hashId) {
            dispatch(setIsCsvLoadingAction(true));
            // getOptimizationSimulation.getOptimizationSimulation(hashId, false, true);
            getOptimizationSimulation.getOptimizationSimulation({
                hashID: hashId,
                isQuickRefetch: false,
                isPrevOptimization: true,
            });
        }
    };

    const onCloseSendHashIdManuallyModal = () => {
        dispatch(setIsSendHashIdManuallyModalOpenAction(false));

        // close modal
    };

    const onConfirmSendHashIdManuallyModal = () => {
        // close modal
        dispatch(setIsSendHashIdManuallyModalOpenAction(false));
        onPrevOptimization(hashIdInput);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 50) return;
        dispatch(setOptimizationDescriptionAction(event.target.value));
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
            <ImageModal isOpen={isCsvImageModalOpen} onClose={onImageClose} ImgSrc={csvExample} />
            <ActionConfirmModal
                isOpen={isFixAddressModalOpen}
                Body={() => (
                    <div style={{ textAlign: 'center', padding: '10px' }}>
                        <div style={{ fontWeight: 400, fontSize: '20px', color: '#494949' }}>שים לב!</div>
                        <div
                            style={{
                                fontWeight: 400,
                                fontSize: '16px',
                                color: '#494949',
                                padding: '10px',
                            }}
                        >
                            ישנן כתובות לא מזוהות ברשימה. כתובת לא מזוהה, לא תיכנס לתהליך האופטימיזציה
                        </div>
                    </div>
                )}
                onConfirm={onConfirmFixAddressModal}
                onClose={onCloseFixAddressModal}
                Img={() => <PriorityHighIcon fontSize="medium" />}
                height={220}
            />

            <ActionConfirmModal
                isOpen={isSendHashIdManuallyModalOpen}
                Body={() => (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <label htmlFor="">הכנס קוד ריצה</label>
                        <BaseTextField
                            inputProps={{
                                value: hashIdInput,
                                onChange: (e) => {
                                    if ('value' in e.target) {
                                        const val =
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            'value' in e.target ? (e.target.value as string) : '';
                                        setHashIdInput(val);
                                    }
                                },
                            }}
                            containerStyle={{ width: '360px' }}
                        />
                    </div>
                )}
                onConfirm={onConfirmSendHashIdManuallyModal}
                onClose={onCloseSendHashIdManuallyModal}
                Img={() => <InfoOutlinedIcon fontSize="large" htmlColor="#494949" />}
                height={200}
                width={420}
            />
            {!isEditPrevOptimizationOpen ? (
                <>
                    {isCsvLoading ? (
                        <div style={{ ...dataLoadingContainer, flexDirection: 'column', gap: '10px' }}>
                            <CircularProgress color="inherit" />
                            <p>אנא המתן , מחפש כתובות</p>
                        </div>
                    ) : (
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {waypointsForOptiObject ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        height: '100%',
                                        alignItems: 'center',
                                        width: '70%',
                                    }}
                                >
                                    <div
                                        style={{
                                            height: '90%',
                                            width: '60%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: '#494949',
                                                fontWeight: 700,
                                                fontSize: '36px',
                                                marginBottom: '15px',
                                            }}
                                        >
                                            טעינת נתונים{' '}
                                        </div>
                                        {isZeroInvalidAddresses() ? (
                                            <div>
                                                <div
                                                    style={{
                                                        color: '#494949',
                                                        fontWeight: 400,
                                                        fontSize: '20px',
                                                        marginBottom: '5rem',
                                                    }}
                                                >
                                                    כל הכתובות מזוהות, ניתן להמשיך לשלב הבא!
                                                </div>
                                                <PersonCheckListSvg />
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    color: '#494949',
                                                    fontWeight: 400,
                                                    fontSize: '20px',
                                                }}
                                            >
                                                נותרו {getUnRecognizedWaypointsCount(waypointsForOptiObject)}{' '}
                                                כתובות לא מזוהות, אנא הכנס אותן באופן ידני
                                            </div>
                                        )}
                                        {isZeroInvalidAddresses() ? null : (
                                            <Button
                                                sx={{
                                                    display: 'flex',
                                                    alignSelf: 'self-start',
                                                    padding: 0,
                                                    marginTop: '15px',
                                                    color: '#2196F3',
                                                    fontSize: '18px',
                                                    fontWeight: 400,
                                                }}
                                                onClick={onApproveWaypoints}
                                            >
                                                <RefreshIcon />
                                                <div style={{ marginRight: '10px' }}> הסתר כתובות שתוקנו</div>
                                            </Button>
                                        )}

                                        <div
                                            style={{
                                                height: '80%',
                                                width: '90%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '1.3rem',
                                                overflow: 'auto',
                                                marginTop: '15px',
                                            }}
                                        >
                                            {[
                                                ...waypointsForOptiObject.invalidWaypoints,
                                                ...waypointsForOptiObject.unRecognizedWaypoints,
                                            ].map((waypoint) => {
                                                return (
                                                    <div
                                                        key={waypoint.waypointId}
                                                        style={{ width: '90%', display: 'flex', gap: '10px' }}
                                                    >
                                                        <InvalidAddressBox waypoint={waypoint} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ height: '90%', width: '50%', borderRadius: '15%' }}>
                                        <styles.Container className="Container">
                                            <styles.MapDiv className="MapDiv" ref={myRef} isOpen>
                                                <MapType2 GoogleMapProps={GoogleMapProps}>
                                                    {[
                                                        ...waypointsForOptiObject.invalidWaypoints,
                                                        ...waypointsForOptiObject.unRecognizedWaypoints,
                                                    ].map(
                                                        (waypoint) =>
                                                            waypoint &&
                                                            waypoint.lat &&
                                                            waypoint.lng && (
                                                                <Marker
                                                                    key={waypoint.waypointId}
                                                                    position={{
                                                                        lat: waypoint.lat,
                                                                        lng: waypoint.lng,
                                                                    }}
                                                                />
                                                            )
                                                    )}
                                                </MapType2>
                                            </styles.MapDiv>
                                        </styles.Container>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row-reverse',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '50%',
                                            height: '90%',
                                            borderRight: '1px solid #B4BEC9',
                                            display: 'flex',
                                            // alignItems: 'center',
                                            // justifyContent: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            <div
                                                style={{
                                                    alignSelf: 'flex-end',
                                                    display: 'flex',
                                                    marginLeft: '25px',
                                                    fontWeight: 400,
                                                    fontSize: '18px',
                                                    flexDirection: 'column',
                                                    position: 'absolute',
                                                    left: 0,
                                                }}
                                            >
                                                <div
                                                    style={
                                                        currentMonthCredit <= 200
                                                            ? {
                                                                  backgroundColor: '#F3050566',
                                                                  borderRadius: '8px',
                                                                  padding: '8px',
                                                                  fontWeight: 700,
                                                                  fontSize: '16px',
                                                              }
                                                            : {
                                                                  backgroundColor: '#2196F31A',
                                                                  borderRadius: '8px',
                                                                  padding: '8px',
                                                                  fontWeight: 700,
                                                                  fontSize: '16px',
                                                              }
                                                    }
                                                >
                                                    יתרה חודשית: {currentMonthCredit} כתובות לחישוב
                                                </div>
                                                {generalAdditionalCredit ? (
                                                    <div
                                                        style={{
                                                            backgroundColor: '#2196F31A',
                                                            borderRadius: '8px',
                                                            padding: '8px',
                                                            fontWeight: 700,
                                                            fontSize: '18px',
                                                            marginTop: '10px',
                                                            alignSelf: 'flex-end',
                                                        }}
                                                    >
                                                        יתרה נוספת: {generalAdditionalCredit} כתובות לחישוב
                                                    </div>
                                                ) : null}
                                                <div
                                                    style={{
                                                        alignSelf: 'flex-end',
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                        marginLeft: '5px',
                                                        marginTop: '6px',
                                                        textUnderlinePosition: 'under',
                                                    }}
                                                >
                                                    לרכישת בנק כתובות{' '}
                                                    <a
                                                        href="https://www.y-it.co.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8/#contact-sales"
                                                        target="_blank"
                                                        style={{
                                                            color: '#2196F3',
                                                            alignSelf: 'flex-end',
                                                            fontSize: '16px',
                                                            fontWeight: 600,
                                                            marginLeft: '5px',
                                                            marginTop: '6px',
                                                            textUnderlinePosition: 'under',
                                                        }}
                                                        rel="noreferrer"
                                                    >
                                                        לחץ כאן
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <SelectPrevOpti onClickPrevOptiItem={onPrevOptimization} />
                                    </div>
                                    <div
                                        style={{
                                            width: '50%',
                                            height: '90%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            // justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ width: '75%' }}>
                                            <div
                                                style={{
                                                    fontSize: '28px',
                                                    fontWeight: '700',
                                                    color: '#494949',
                                                }}
                                            >
                                                התחל הרצה חדשה{' '}
                                            </div>
                                            <div
                                                style={{
                                                    color: '#494949',
                                                    fontSize: '14px',
                                                    marginBottom: '5px',
                                                    marginTop: '25px',
                                                }}
                                            >
                                                *תיאור הרצה
                                            </div>
                                            <TextField
                                                onChange={handleDescriptionChange}
                                                size="small"
                                                sx={inputSX}
                                                variant="outlined"
                                                placeholder="הכנס שם/תיאור הרצה"
                                                required
                                                value={optimizationDescription}
                                                error={!optimizationDescription}
                                            />
                                            {/* <div
                                                style={{
                                                    fontSize: '24px',
                                                    fontWeight: '400',
                                                    marginTop: '24px',
                                                    color: '#494949',
                                                }}
                                            >
                                                {' '}
                                                העלה את רשימת הכתובות שלך באמצעות קובץ csv
                                            </div> */}
                                        </div>
                                        <input
                                            onChange={(ev) => {
                                                onFileUpload(ev);
                                            }}
                                            type="file"
                                            id="file"
                                            ref={inputFileRef}
                                            style={{ display: 'none' }}
                                            accept="text/csv"
                                        />
                                        {optimizationDescription && (
                                            <div
                                                onDragEnter={handleDragEnter}
                                                onDragLeave={handleDragLeave}
                                                onDragOver={(e) => e.preventDefault()}
                                                onDrop={handleDrop}
                                                style={
                                                    dragging
                                                        ? {
                                                              height: '160x',
                                                              width: '75%',
                                                              border: '2px dashed #49494997',
                                                              marginTop: '30px',
                                                              display: 'flex',
                                                              alignItems: 'center',
                                                              justifyContent: 'center',
                                                              borderRadius: '10px',
                                                              flexDirection: 'column',
                                                              gap: '10px',
                                                              marginBottom: '25px',
                                                              boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.35)',
                                                          }
                                                        : {
                                                              height: '160px',
                                                              width: '75%',
                                                              border: '2px dashed #49494997',
                                                              marginTop: '30px',
                                                              display: 'flex',
                                                              alignItems: 'center',
                                                              justifyContent: 'center',
                                                              borderRadius: '10px',
                                                              flexDirection: 'column',
                                                              gap: '10px',
                                                              marginBottom: '25px',
                                                          }
                                                }
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    <CloudUploadIcon />
                                                    <div
                                                        style={{
                                                            fontSize: '24px',
                                                            color: '#494949',
                                                            fontWeight: 400,
                                                        }}
                                                    >
                                                        גרור/העלה קובץ csv
                                                    </div>
                                                </div>
                                                <Button
                                                    sx={{ backgroundColor: '#2196F3' }}
                                                    variant="contained"
                                                    onClick={onLoadWaypointsFromCsv}
                                                >
                                                    בחר קובץ
                                                </Button>
                                            </div>
                                        )}
                                        {optimizationDescription && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: '30px',
                                                    width: '650px',
                                                    marginBottom: '20px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: '#0E723A',
                                                            fontSize: '16px',
                                                            fontWeight: 600,
                                                            cursor: 'pointer',
                                                            textDecoration: 'underline',
                                                            textAlign: 'center',
                                                        }}
                                                        onClick={createCSVTemplate}
                                                    >
                                                        הורדת תבנית קובץ csv לדוגמה
                                                    </div>
                                                    {/* <Button
                                                    sx={{ color: '#0E723A', border: '1px solid #0E723A' }}
                                                    variant="outlined"
                                                    onClick={createCSVTemplate}
                                                >
                                                    <div style={{ marginLeft: '10px' }}>
                                                        הורדת תבנית קובץ csv לדוגמה
                                                    </div>
                                                    <img src="excelIcon.png" alt="" />
                                                </Button> */}
                                                    <div
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '400',
                                                            marginTop: '12px',
                                                            color: '#494949',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        ניתן להוריד תבנית קובץ csv לדוגמה, למלא בה את הנתונים
                                                        ולהעלות אותה לכאן
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: '#2196F3',
                                                            fontSize: '16px',
                                                            fontWeight: 600,
                                                            cursor: 'pointer',
                                                            textDecoration: 'underline',
                                                            textAlign: 'center',
                                                        }}
                                                        onClick={openCsvImageModal}
                                                    >
                                                        הצג תבנית לדוגמה
                                                    </div>
                                                    {/* <Button
                                                    sx={{
                                                        color: '#2196F3',
                                                        border: '1px solid #2196F3',
                                                        width: '100%',
                                                    }}
                                                    variant="outlined"
                                                    onClick={openCsvImageModal}
                                                >
                                                    הצג תבנית לדוגמה
                                                    <div style={{ marginLeft: '10px' }}></div>
                                                </Button> */}
                                                    <div
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: '400',
                                                            marginTop: '12px',
                                                            color: '#494949',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        ניתן לצפות בתבנית וליצור קובץ משלכם בהתאם
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {optimizationDescription && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '75%',
                                                    gap: '5px',
                                                    marginBottom: '20px',
                                                }}
                                            >
                                                <div
                                                    style={{ border: '1px solid #B4BEC9', width: '50%' }}
                                                ></div>
                                                <div style={{ fontSize: '18px', color: '#494949' }}>או</div>
                                                <div
                                                    style={{ border: '1px solid #B4BEC9', width: '50%' }}
                                                ></div>
                                                <div></div>
                                            </div>
                                        )}
                                        {optimizationDescription && (
                                            <Button
                                                sx={{ backgroundColor: '#2196F3' }}
                                                variant="contained"
                                                onClick={onManualDataLoading}
                                            >
                                                בצע הזנה ידנית של רשימת הכתובות שלך
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <EditPrevOptimization />
            )}
        </div>
    );
};
export default DataLoading;

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useDispatchAlert } from './useAlert';
import { generalOptimizationError, useGetOptimizationSimulation } from './useGetOptimizationSimulationResult';
import {
    optiSliceActions,
    optimizationsRootSelector,
    optimizationsUiSelector,
    setDisplaySimulationCalcLoaderAction,
    setGetSimulationResultAction,
    setIsNotEnoughCreditModalOpenAction,
} from 'src/store/slices/optimizationsSlice';
import {
    IOptimumWaypoint,
    ISimulationResult,
    ISimulationStationToWaypoint,
} from 'src/types/optimization/types';
import {
    SetOptimizationSimulationConfig,
    setMagicWandWayPointsForOptimization,
    setWayPointsForOptimization,
    setWayPointsForStationOptimization,
    OptimizationHistory,
} from 'src/api/optimizationApi';
import { advancedParamsSelector, setIsQuickDataLoading } from 'src/store/slices/summaryStageSlice';
import { AxiosError, AxiosResponse } from 'axios';
import { CarType } from 'src/components/stages/Summary/types';
import { t } from 'src/utils/lang';
import { useLogout } from './authUtilHooks';
import {
    BackendOptiTypes,
    OptiFlowTypes,
    OptimizationErrorCodes,
    OptimizationMethods,
} from 'src/constants/common';
import { logger } from 'src/api/loggerApi';

export const useSetOptimizationSimulation = () => {
    const dispatch = useAppDispatch();

    const dispatchAlert = useDispatchAlert();
    const getOptimizationSimulation = useGetOptimizationSimulation();
    const { handleForceUserLogout } = useLogout();

    const { isOnAdvancedMode, values } = useAppSelector((state) => advancedParamsSelector(state));
    const routeType = useAppSelector((state) => optimizationsUiSelector(state).routeType);
    const { vehicleCapacity, maxTravelTime, maxTierTwoWalkDistance, maxWalkDistance, maxStationsCountVal } =
        useAppSelector((state) => optimizationsRootSelector(state).ui.parametersForm);
    const clientToken = useAppSelector((state) => state.auth.clientToken);
    const isUsingGoogle = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm.isUsingGoogle
    );
    const optiFlowType = useAppSelector((state) => optimizationsRootSelector(state).ui.selectedOptiFlowType);

    const optimizationDescription = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.optimizationDescription
    );

    // const carsForPricingOnSlice = useAppSelector((state) => state.summaryStage.carsForPricing);

    const handleSimulationResponse = ({
        res,
        isQuickRefetch,
        isStationsOptimization,
        successHandlerOptions = {},
    }: {
        res: ISimulationResult;
        isQuickRefetch: boolean;
        isStationsOptimization: boolean;
        successHandlerOptions?: {
            reselectAllRoutes?: boolean | undefined;
        };
    }) => {
        if (res && res.isSuccess) {
            getOptimizationSimulation.getOptimizationSimulation({
                hashID: res.hashID,
                isQuickRefetch,
                isPrevOptimization: false,
                isStationsOptimization,
                successHandlerOptions,
            });
            return;
        }

        // handling errors
        if (res && res.errorCode === OptimizationErrorCodes.ClientTokenNotValid) {
            // invalid Client Token
            // log out
            handleForceUserLogout();
        } else if (res && res.errorCode === OptimizationErrorCodes.NotEnoughCredit) {
            // not enough credit
            dispatch(setIsNotEnoughCreditModalOpenAction({ isOpen: true }));
        } else if (res && res.errorCode === OptimizationErrorCodes.NoCommunicationToTransit) {
            // transit error
            dispatchAlert('error', 'תקלת תקשורת, אנא נסה שוב מאוחר יותר', true);
            logger({
                type: 'apiReqError',
                feature: `Error with setOptimizationSimulation request`,
                data: {
                    message: 'NoCommunicationToTransit',
                    response: res,
                },
            });
        } else {
            dispatch(setGetSimulationResultAction({ status: 'FAILURE' }));
            dispatchAlert('error', generalOptimizationError, true);
            logger({
                type: 'apiReqError',
                feature: `Error with setOptimizationSimulation request`,
                data: {
                    message: 'invalid response object',
                    isQuickRefetch,
                    isStationsOptimization,
                    response: res,
                },
            });
        }

        dispatch(setDisplaySimulationCalcLoaderAction({ display: false }));
        dispatch(setIsQuickDataLoading(false));
        if (isStationsOptimization) {
            dispatch(optiSliceActions.scSetIsQuickDataLoading(false));
        }
    };

    const setOptimizationSimulation = async ({
        waypointsJson,
        wayPointsJsonForStations,
        carsForPricing,
        magicWandValue,
        isQuickRefetch = false,
        isStationsOptimization = false,
        payloadOverride = {},
        stationToWayPoints = undefined,
        successHandlerOptions = {},
    }: {
        waypointsJson: IOptimumWaypoint[];
        wayPointsJsonForStations: IOptimumWaypoint[];
        carsForPricing: CarType[];
        magicWandValue?: number;
        isQuickRefetch?: boolean;
        isStationsOptimization?: boolean;
        payloadOverride?: Partial<SetOptimizationSimulationConfig>;
        stationToWayPoints?: ISimulationStationToWaypoint[];
        successHandlerOptions?: {
            reselectAllRoutes?: boolean | undefined;
            reselectAllStations?: boolean;
        };
    }) => {
        try {
            // Get Optimization Token
            let res: AxiosResponse<ISimulationResult>;

            const payload: SetOptimizationSimulationConfig = {
                clientToken: clientToken ? clientToken : '',
                maxValueLimitInRoute: magicWandValue ? magicWandValue : maxTravelTime,
                passengerLimitInVehicle:
                    optiFlowType === OptiFlowTypes.RoutesOnlyCreation ? vehicleCapacity : maxStationsCountVal,
                toTarget: routeType === 'pickup' ? true : false,
                wayPointsJson: waypointsJson,
                wayPointsJsonForStations,
                carsType: carsForPricing,
                maxWalkingDistanceFromWayPoint: 0,
                maxWalkingDistanceFromWayPointMeters:
                    optiFlowType === OptiFlowTypes.RoutesOnlyCreation ? 0 : maxWalkDistance,
                maxWalkingDistanceFromWayPointTier2Meters:
                    optiFlowType === OptiFlowTypes.RoutesOnlyCreation ? 0 : maxTierTwoWalkDistance,
                optimizationHistory:
                    optiFlowType === OptiFlowTypes.RoutesOnlyCreation
                        ? OptimizationHistory.OnlyRoutesOpt
                        : isStationsOptimization
                        ? OptimizationHistory.OnlyStationsOpt
                        : OptimizationHistory.BothStationsAndRoutesOpt,
                description: optimizationDescription,
                optimizationMethod: isUsingGoogle
                    ? OptimizationMethods.google
                    : OptimizationMethods.geometric,
                stationToWayPoints,
                advancedSettingsList: isOnAdvancedMode ? values : [],
                ...payloadOverride,
            };

            if (magicWandValue) {
                res = await setMagicWandWayPointsForOptimization(payload);
            } else if (isStationsOptimization) {
                res = await setWayPointsForStationOptimization(payload);
            } else {
                res = await setWayPointsForOptimization(payload);
            }

            handleSimulationResponse({
                res: res.data,
                isQuickRefetch,
                isStationsOptimization,
                successHandlerOptions,
            });
        } catch (error) {
            if (error instanceof AxiosError) {
                logger({
                    type: 'apiReqError',
                    feature: `Error with setOptimizationSimulation request`,
                    data: error,
                });
            }
            if (error instanceof Error) {
                console.error(error.message);
            }
            dispatchAlert('error', t.generalOptimizationError, true);
            dispatch(setIsQuickDataLoading(false));
            if (isStationsOptimization) {
                dispatch(optiSliceActions.scSetIsQuickDataLoading(false));
            }
            dispatch(setDisplaySimulationCalcLoaderAction({ display: false }));
        }
    };

    return {
        setOptimizationSimulation,
    };
};

/* eslint-disable no-debugger */
import { useDispatch } from 'react-redux';
import { useDispatchAlert } from './useAlert';
import {
    optiSliceActions,
    optimizationsRootSelector,
    setCalculationPercentageAction,
    setWaypointsForOptiObject,
    setDisplaySimulationCalcLoaderAction,
    setGetSimulationResultAction,
    setGetStationsSimulationResultStatusAction,
    setIsCsvLoadingAction,
    setIsSkippedToSummaryAction,
    setMaxTravelTimeAction,
    setMaxVehicleCapacityAction,
    setMaxWalkDistanceAction,
    setMaxWalkDistanceTierTwoAction,
    setOptimizationDescriptionAction,
    setSimulationResultAction,
    setSimulationResultEditPreviousAction,
    setStationsSimulationResultAction,
    setTargetWaypointAction,
    setVehicleCapacityAction,
    setOptiFlowType,
    summaryStageExtraSelector,
} from 'src/store/slices/optimizationsSlice';
import { ISimulationResult } from 'src/types/optimization/types';
import { buildRouteName, getBiggestCapacity } from 'src/store/helpers';
import {
    SetOptimizationSimulationConfig,
    getOptimizationSimulationResult,
    OptimizationHistory,
    ENDPOINTS,
} from 'src/api/optimizationApi';
import { useAppSelector } from 'src/store/hooks';
import { getRandomColor } from 'src/utils/utilis';
import summaryUtils from 'src/components/stages/Summary/utils';
import { setCarsForPricing, setRoutesStatistics } from 'src/store/slices/summaryStageSlice';
import { setIsQuickDataLoading } from 'src/store/slices/summaryStageSlice';
import { useCarInputsUtils } from 'src/hooks/useCarInputsUtils';
import { useNavigate } from 'react-router-dom';
import { setStageAction } from 'src/store/slices/commonSlice';
import {
    BackendOptiTypes,
    OptiFlowTypes,
    OptimizationErrorCodes,
    PrevOptimizationTypes,
    Stages,
} from 'src/constants/common';
import { useLogout } from './authUtilHooks';
import { getPrevOptimizationType } from 'src/utils/utilis.opti';
import { logger } from 'src/api/loggerApi';

export const generalOptimizationError = 'תקלה זמנית בהרצת התהליך. ניתן ליצור קשר עם התמיכה בטלפון 08-9464288';

export const useGetOptimizationSimulation = () => {
    const dispatch = useDispatch();
    const dispatchAlert = useDispatchAlert();

    const { handleForceUserLogout } = useLogout();

    const globalCarInputsSync = useCarInputsUtils();

    // const handleEditPrevOptimization = (res: ISimulationResult) => {
    //     dispatch(setGetStationsSimulationResultEditOptimizationStatusAction({ status: 'SUCCESS' }));

    //     const optimizationDescription = res.requestBody.description;

    //     dispatch(setOptimizationDescriptionAction(optimizationDescription));

    //     dispatch(setOptiFlowType(res.optimizationType));

    //     dispatch(setSimulationResultEditPreviousAction({ simulationResult: res }));
    // };

    const handleStationsOptimization = (res: ISimulationResult, isQuickRefetch: boolean) => {
        dispatch(setGetStationsSimulationResultStatusAction({ status: 'SUCCESS' }));

        dispatch(setOptiFlowType(OptiFlowTypes.WithStations));

        dispatch(
            setStationsSimulationResultAction({
                simulationResult: {
                    ...res,
                    stationToWayPoints: res.stationToWayPoints.map((r, i) => ({
                        ...r,
                        color: getRandomColor(i),
                    })),
                    optimumRouteResult: res.optimumRouteResult,
                },
            })
        );
        dispatch(setDisplaySimulationCalcLoaderAction({ display: false }));

        dispatch(setStageAction({ stage: Stages.StationConfirmation }));
        dispatch(setCalculationPercentageAction({ percentage: 0 }));

        if (isQuickRefetch) {
            dispatch(optiSliceActions.scSetIsQuickDataLoading(false));
        }
    };

    const handleGeneralOptimization = (
        res: ISimulationResult,
        isPrevOptimization: boolean,
        isQuickRefetch: boolean
    ) => {
        const prevOptimizationType = getPrevOptimizationType(res);

        if (prevOptimizationType === PrevOptimizationTypes.RoutesOnly) {
            dispatch(setGetSimulationResultAction({ status: 'SUCCESS' }));

            const parsedOptimumRouteResult = res.optimumRouteResult.map((route, i) => ({
                ...route,
                routeName: buildRouteName(route),
                color: getRandomColor(i),
            }));

            dispatch(
                setSimulationResultAction({
                    simulationResult: {
                        ...res,
                        optimumRouteResult: [...parsedOptimumRouteResult],
                    },
                })
            );

            dispatch(setRoutesStatistics(summaryUtils.calcRoutesStatistics(parsedOptimumRouteResult)));

            if (isQuickRefetch) {
                dispatch(setIsQuickDataLoading(false));
                dispatch(setMaxTravelTimeAction({ maxTravelTime: res.maxValueLimitInRoute }));
            } else if (isPrevOptimization) {
                // Handle on skip optimization logic
                if (res.requestBody && res.requestBody.wayPointsJson) {
                    const prevTargetWaypoint = res.requestBody.wayPointsJson.find(
                        (waypoint) => waypoint.isTarget
                    );

                    if (prevTargetWaypoint) {
                        dispatch(setOptiFlowType(OptiFlowTypes.RoutesOnlyCreation));

                        const maxTravelTime = res.requestBody.maxValueLimitInRoute;
                        const maxVehicleCapacity = res.requestBody.passengerLimitInVehicle;
                        const maxVehicleCapacityValue = getBiggestCapacity(res.requestBody.carsType);
                        const carsType = res.requestBody.carsType;
                        const optimizationDescription = res.requestBody.description;

                        dispatch(setOptimizationDescriptionAction(optimizationDescription));
                        dispatch(setTargetWaypointAction({ waypoint: prevTargetWaypoint }));
                        dispatch(
                            setWaypointsForOptiObject({
                                waypointsForOptiObject: {
                                    validWaypoints: res.requestBody.wayPointsJson.filter((w) => !w.isTarget),
                                    invalidWaypoints: [],
                                    unRecognizedWaypoints: [],
                                },
                            })
                        );
                        dispatch(setCarsForPricing(carsType));

                        // Sync the car inputs with the cars for pricing
                        const generatedCarInputs =
                            globalCarInputsSync.dispatchSetCarsInputsByCarsForPricing(carsType);
                        globalCarInputsSync.saveCarInputsToLocalStorage(generatedCarInputs);
                        dispatch(setIsSkippedToSummaryAction(true));
                        dispatch(setMaxTravelTimeAction({ maxTravelTime }));
                        dispatch(setVehicleCapacityAction({ vehicleCapacity: maxVehicleCapacity }));
                        dispatch(
                            setMaxVehicleCapacityAction({ maxVehicleCapacity: maxVehicleCapacityValue })
                        );
                        dispatch(setStageAction({ stage: Stages.Summary }));
                    } else {
                        dispatchAlert('error', 'לא קיים נ.צ יעד', true);
                    }
                }
            } else {
                dispatch(setStageAction({ stage: Stages.Summary }));
            }
        }
        if (prevOptimizationType === PrevOptimizationTypes.Stations) {
            dispatch(setOptiFlowType(OptiFlowTypes.WithStations));

            dispatch(
                setStationsSimulationResultAction({
                    simulationResult: {
                        ...res,
                        stationToWayPoints: res.stationToWayPoints.map((r, i) => ({
                            ...r,
                            color: getRandomColor(i),
                        })),
                        optimumRouteResult: res.optimumRouteResult,
                    },
                })
            );
            if (isPrevOptimization) {
                // Handle on skip optimization logic
                if (res.requestBody && res.requestBody.wayPointsJsonForStations) {
                    const maxTravelTime = res.requestBody.maxValueLimitInRoute;
                    const maxVehicleCapacity = res.requestBody.passengerLimitInVehicle;
                    const maxVehicleCapacityValue = getBiggestCapacity(res.requestBody.carsType);
                    const prevTargetWaypoint = res.requestBody.wayPointsJsonForStations.find(
                        (waypoint) => waypoint.isTarget
                    );
                    const carsType = res.requestBody.carsType;

                    const maxWalkingDistance = res.requestBody.maxWalkingDistanceFromWayPointMeters;
                    const maxWalkingDistanceTier2 = res.requestBody.maxWalkingDistanceFromWayPointTier2Meters;
                    const optimizationDescription = res.requestBody.description;

                    if (prevTargetWaypoint) {
                        dispatch(setOptimizationDescriptionAction(optimizationDescription));

                        dispatch(setTargetWaypointAction({ waypoint: prevTargetWaypoint }));
                        dispatch(
                            setWaypointsForOptiObject({
                                waypointsForOptiObject: {
                                    validWaypoints: res.requestBody.wayPointsJsonForStations.filter(
                                        (w) => !w.isTarget
                                    ),
                                    invalidWaypoints: [],
                                    unRecognizedWaypoints: [],
                                },
                            })
                        );
                        dispatch(setCarsForPricing(carsType));

                        // Sync the car inputs with the cars for pricing
                        const generatedCarInputs =
                            globalCarInputsSync.dispatchSetCarsInputsByCarsForPricing(carsType);
                        globalCarInputsSync.saveCarInputsToLocalStorage(generatedCarInputs);
                        dispatch(setIsSkippedToSummaryAction(true));
                        dispatch(setMaxTravelTimeAction({ maxTravelTime }));
                        dispatch(setVehicleCapacityAction({ vehicleCapacity: maxVehicleCapacity }));
                        dispatch(
                            setMaxVehicleCapacityAction({ maxVehicleCapacity: maxVehicleCapacityValue })
                        );
                        dispatch(
                            setMaxWalkDistanceAction({
                                maxWalkDistanceTierOne: maxWalkingDistance,
                            })
                        );
                        dispatch(
                            setMaxWalkDistanceTierTwoAction({
                                maxWalkDistanceTierTwo: maxWalkingDistanceTier2,
                            })
                        );

                        dispatch(setStageAction({ stage: Stages.StationConfirmation }));
                    } else {
                        dispatchAlert('error', 'לא קיים נ.צ יעד', true);
                    }
                }
            } else {
                dispatch(setStageAction({ stage: Stages.StationConfirmation }));
            }
        }
        if (prevOptimizationType === PrevOptimizationTypes.StationAndRoutes) {
            dispatch(setOptiFlowType(OptiFlowTypes.WithStations));

            dispatch(setGetSimulationResultAction({ status: 'SUCCESS' }));

            const parsedOptimumRouteResult = res.optimumRouteResult.map((route, i) => ({
                ...route,
                routeName: buildRouteName(route),
                color: getRandomColor(i),
            }));
            dispatch(
                setSimulationResultAction({
                    simulationResult: {
                        ...res,
                        optimumRouteResult: [...parsedOptimumRouteResult],
                    },
                })
            );
            dispatch(setRoutesStatistics(summaryUtils.calcRoutesStatistics(parsedOptimumRouteResult)));

            dispatch(
                setStationsSimulationResultAction({
                    simulationResult: {
                        ...res,
                        stationToWayPoints: res.stationToWayPoints.map((r, i) => ({
                            ...r,
                            color: getRandomColor(i),
                        })),
                        optimumRouteResult: res.optimumRouteResult,
                    },
                })
            );

            if (isQuickRefetch) {
                dispatch(setIsQuickDataLoading(false));
                dispatch(setMaxTravelTimeAction({ maxTravelTime: res.maxValueLimitInRoute }));
            } else if (isPrevOptimization) {
                // Handle on skip optimization logic

                if (
                    res.requestBody &&
                    res.requestBody.wayPointsJson &&
                    res.requestBody.wayPointsJsonForStations
                ) {
                    const maxTravelTime = res.requestBody.maxValueLimitInRoute;
                    const maxVehicleCapacity = res.requestBody.passengerLimitInVehicle;
                    const maxVehicleCapacityValue = getBiggestCapacity(res.requestBody.carsType);

                    const waypointsListUsedForOpti = res.requestBody.wayPointsJsonForStations.length
                        ? res.requestBody.wayPointsJsonForStations
                        : res.requestBody.wayPointsJson;
                    const prevTargetWaypoint = waypointsListUsedForOpti.find((waypoint) => waypoint.isTarget);

                    const carsType = res.requestBody.carsType;

                    const maxWalkingDistance = res.requestBody.maxWalkingDistanceFromWayPointMeters;
                    const maxWalkingDistanceTier2 = res.requestBody.maxWalkingDistanceFromWayPointTier2Meters;
                    const optimizationDescription = res.requestBody.description;

                    if (prevTargetWaypoint) {
                        dispatch(setOptimizationDescriptionAction(optimizationDescription));

                        dispatch(setTargetWaypointAction({ waypoint: prevTargetWaypoint }));
                        dispatch(
                            setWaypointsForOptiObject({
                                waypointsForOptiObject: {
                                    validWaypoints: res.requestBody.wayPointsJsonForStations.filter(
                                        (w) => !w.isTarget
                                    ),
                                    invalidWaypoints: [],
                                    unRecognizedWaypoints: [],
                                },
                            })
                        );
                        dispatch(setCarsForPricing(carsType));

                        // Sync the car inputs with the cars for pricing
                        const generatedCarInputs =
                            globalCarInputsSync.dispatchSetCarsInputsByCarsForPricing(carsType);
                        globalCarInputsSync.saveCarInputsToLocalStorage(generatedCarInputs);
                        dispatch(setIsSkippedToSummaryAction(true));
                        dispatch(setMaxTravelTimeAction({ maxTravelTime }));
                        dispatch(setVehicleCapacityAction({ vehicleCapacity: maxVehicleCapacity }));
                        dispatch(
                            setMaxVehicleCapacityAction({ maxVehicleCapacity: maxVehicleCapacityValue })
                        );
                        dispatch(
                            setMaxWalkDistanceAction({
                                maxWalkDistanceTierOne: maxWalkingDistance,
                            })
                        );
                        dispatch(
                            setMaxWalkDistanceTierTwoAction({
                                maxWalkDistanceTierTwo: maxWalkingDistanceTier2,
                            })
                        );
                        dispatch(setStageAction({ stage: Stages.Summary }));
                    } else {
                        dispatchAlert('error', 'לא קיים נ.צ יעד', true);
                    }
                }
            } else {
                dispatch(setStageAction({ stage: Stages.Summary }));
            }
        }

        dispatch(setDisplaySimulationCalcLoaderAction({ display: false }));
        dispatch(setCalculationPercentageAction({ percentage: 0 }));
    };

    const handleSuccess = ({
        intervalId,
        isStationsOptimization,
        isQuickRefetch,
        res,
        isPrevOptimization,
        options = {},
    }: {
        res: ISimulationResult;
        intervalId: NodeJS.Timer;
        isQuickRefetch: boolean;
        isPrevOptimization: boolean;
        isStationsOptimization: boolean;
        options?: {
            reselectAllRoutes?: boolean;
            reselectAllStations?: boolean;
        };
    }) => {
        clearInterval(intervalId);
        // debugger;

        if (isStationsOptimization) {
            handleStationsOptimization(res, isQuickRefetch);

            if (options?.reselectAllStations) {
                dispatch(optiSliceActions.selectAllStations());
            }
        } else {
            handleGeneralOptimization(res, isPrevOptimization, isQuickRefetch);

            if (options?.reselectAllRoutes) {
                dispatch(optiSliceActions.selectAllRoutes());
            }
        }
    };

    const handleSimulationResponse = (
        res: ISimulationResult,
        intervalId: NodeJS.Timer,
        isQuickRefetch: boolean,
        isPrevOptimization: boolean,
        isStationsOptimization: boolean,
        successHandlerOptions: {
            reselectAllRoutes?: boolean;
        }
    ) => {
        dispatch(setIsCsvLoadingAction(false));

        if (res && res.isSuccess) {
            // debugger;
            if (res.errorCode === OptimizationErrorCodes.ErrorWithGeoServer) {
                dispatchAlert(
                    'error',
                    'ישנה בעיה בשרת מיקומי תחנות האוטובוס. החישוב בוצע ללא שימוש בתחנות אוטובוס. יש ליצור קשר עם התמיכה בטלפון – 08-9464288',
                    true
                );
            }
            handleSuccess({
                res,
                intervalId,
                isQuickRefetch,
                isPrevOptimization,
                isStationsOptimization,
                options: successHandlerOptions,
            });
        } else if (res && res.errorCode === OptimizationErrorCodes.ClientTokenNotValid) {
            // invalid Client Token
            // log out
            handleForceUserLogout();
        } else if (res && res.errorCode === OptimizationErrorCodes.NoHashID) {
            // deleteOptimizationFromLocalStorage(res.hashID);
            clearInterval(intervalId);
            dispatchAlert('error', 'הרצה זו לא קיימת או שפג תוקפה', true);
            dispatch(setDisplaySimulationCalcLoaderAction({ display: false }));
            dispatch(setIsQuickDataLoading(false));
            if (isStationsOptimization) {
                dispatch(optiSliceActions.scSetIsQuickDataLoading(false));
            }
            dispatch(setCalculationPercentageAction({ percentage: 0 }));
        } else if (res && res.errorCode === 6) {
            clearInterval(intervalId);
            dispatchAlert('error', 'לא נמצא מסלול אופטימלי', true);
            dispatch(setDisplaySimulationCalcLoaderAction({ display: false }));
            dispatch(setIsQuickDataLoading(false));
            if (isStationsOptimization) {
                dispatch(optiSliceActions.scSetIsQuickDataLoading(false));
            }
            dispatch(setCalculationPercentageAction({ percentage: 0 }));
        } else if (res && res.errorCode === OptimizationErrorCodes.OptimizationInProgress) {
            dispatch(setCalculationPercentageAction({ percentage: res.calculationPercentage }));
        } else {
            clearInterval(intervalId);
            dispatch(setGetSimulationResultAction({ status: 'FAILURE' }));
            dispatch(setDisplaySimulationCalcLoaderAction({ display: false }));
            dispatch(setCalculationPercentageAction({ percentage: 0 }));

            dispatchAlert('error', generalOptimizationError, true);
            dispatch(setIsQuickDataLoading(false));
            if (isStationsOptimization) {
                dispatch(optiSliceActions.scSetIsQuickDataLoading(false));
            }
            logger({
                type: 'apiReqError',
                feature: `Error with getOptimizationSimulation request`,
                data: {
                    message: 'invalid response object',
                    response: res,
                    isQuickRefetch,
                    isPrevOptimization,
                    isStationsOptimization,
                },
            });
        }
    };

    const handleGetOptiSimErr = (error: unknown, interval: unknown) => {
        clearInterval(interval as NodeJS.Timer);
        dispatch(setIsQuickDataLoading(false));
        dispatch(setDisplaySimulationCalcLoaderAction({ display: false }));
        dispatch(setCalculationPercentageAction({ percentage: 0 }));
        dispatchAlert('error', generalOptimizationError, true);
        console.log('error', error);
    };

    const getOptimizationSimulation = async ({
        hashID,
        isQuickRefetch,
        isPrevOptimization = false,
        isStationsOptimization = false,
        successHandlerOptions = {},
    }: {
        hashID: string;
        isQuickRefetch: boolean;
        isPrevOptimization?: boolean;
        isStationsOptimization?: boolean;
        successHandlerOptions?: {
            reselectAllRoutes?: boolean;
        };
    }) => {
        let interval: NodeJS.Timer | null = null;
        const INTERVAL_TIME = 1000;
        const MAXIMUM_GET_OPTIMIZATIONS_REPEATS = 60 * 45; // will try for 45 minutes

        try {
            let counter = 0;

            interval = setInterval(async () => {
                try {
                    if (counter > MAXIMUM_GET_OPTIMIZATIONS_REPEATS) {
                        clearInterval(interval as NodeJS.Timer);
                        dispatchAlert('error', generalOptimizationError, true);

                        return;
                    }

                    const res = await getOptimizationSimulationResult(hashID);

                    handleSimulationResponse(
                        res.data,
                        interval as NodeJS.Timer,
                        isQuickRefetch,
                        isPrevOptimization,
                        isStationsOptimization,
                        successHandlerOptions
                    );

                    counter++;
                } catch (error: any) {
                    if (error.name === 'AxiosError') {
                        logger({
                            type: 'apiReqError',
                            feature: `Error with ${ENDPOINTS.GET_OPTIMUM_ROUTE_RESULT} request`,
                            data: {
                                isQuickRefetch,
                                isPrevOptimization,
                                isStationsOptimization,
                                error,
                            },
                        });
                    }
                    handleGetOptiSimErr(error, interval);
                    if (isStationsOptimization) {
                        dispatch(optiSliceActions.scSetIsQuickDataLoading(false));
                    }
                }

                counter++;
            }, INTERVAL_TIME);
        } catch (error) {
            handleGetOptiSimErr(error, interval);
            if (isStationsOptimization) {
                dispatch(optiSliceActions.scSetIsQuickDataLoading(false));
            }
        }
    };

    return {
        getOptimizationSimulation,
    };
};

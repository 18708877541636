import { getRandomColor } from 'src/utils/utilis';
import { IOptimumRouteData } from 'src/types/optimization/types';
import { uuid } from 'src/store/helpers';
import { colors } from 'src/styles/defaultTheme';
import moment from 'moment';

export type RouteStatistics = {
    totalPrice: number;
    avgRoutePrice: number;
    pricePerPassenger: number;
    totalPassengersCount: number;
    avgRoutePassengersCount: number;
    totalCarsCount: number;
    stationsAmount: number;
    avgStationsAmountInRoute: number;
    avgPassCountPerStation: number;
};

const calcRoutesStatistics = (allRoutesData: IOptimumRouteData[]): RouteStatistics => {
    const totalPassengersCount = allRoutesData.reduce((acc, curr) => acc + curr.numberOfPassangersInRoute, 0);

    const routesWithCars = allRoutesData.filter((route) => (route.assignedCar?.carId ? true : false));
    const totalCarsCount = routesWithCars.length;

    const routesWithPrice = allRoutesData.filter((route) => (route.routePrice !== null ? true : false));
    const totalPrice = routesWithPrice.reduce((acc, curr) => acc + (curr.routePrice ?? 0), 0);

    const NON_STATION_WAYPOINTS_COUNT = 1;
    const stationsAmount = allRoutesData.reduce(
        (acc, curr) => acc + (curr.numberOfWayPointsInRoute - NON_STATION_WAYPOINTS_COUNT ?? 0),
        0
    );

    return {
        totalPrice,
        avgRoutePrice: totalPrice / allRoutesData.length,
        pricePerPassenger: totalPassengersCount ? totalPrice / totalPassengersCount : 0,
        totalPassengersCount,
        avgRoutePassengersCount: totalPassengersCount / allRoutesData.length,
        totalCarsCount,
        stationsAmount,
        avgStationsAmountInRoute: stationsAmount / allRoutesData.length,
        avgPassCountPerStation: totalPassengersCount ? totalPassengersCount / stationsAmount : 0,
    };
};

export const getColorByRoute = (route: IOptimumRouteData) => {
    return route.color || colors.primary;
};

const today = new Date();
export const departureTime = moment(today).add(1, 'day').toDate();

// const getFinalizedRoutesWithPricingAndSelectedCar = (rawRoutes: IOptimumRouteData[]): IOptimumRouteData[] => {
//     const parsedRoutes: IOptimumRouteData[] = [];

//     rawRoutes.forEach((rawRoute) => {
//         parsedRoutes.push({
//             id: rawRoute.uuid,
//             routeName: rawRoute.routeName || 'מסלול ללא שם',
//             color: rawRoute.color,
//             routeWaypoints: rawRoute.optimumRoute,
//             distanceInKm: rawRoute.maxDistanceInRoute,
//             durationInMinutes: rawRoute.maxTimeInRoute,
//             carId: rawRoute.assignedCar?.carId || '',
//             carName: rawRoute.assignedCar?.carName || '',
//             price: rawRoute.routePrice,
//             carDescription: rawRoute.assignedCar?.carDescription || '',
//             numberOfPassengersInRoute: rawRoute.numberOfPassangersInRoute,
//         });
//     });

//     return parsedRoutes;
// };

const summaryUtils = {
    calcRoutesStatistics,
    // getFinalizedRoutesWithPricingAndSelectedCar,
};

export default summaryUtils;

// export function createMockRoutes(count: number): IOptimumRouteData[] {
//     const mockRoutes: IOptimumRouteData[] = [];

//     for (let i = 0; i < count; i++) {
//         const waypointCount = 20; // 1 to 3 waypoints
//         const optimumRoute: any[] = [];

//         for (let j = 0; j < waypointCount; j++) {
//             optimumRoute.push({
//                 city: `Some City ${i}-${j}`,
//                 street: '',
//                 houseNum: '',
//                 lat: 31.7 + Math.random() * 0.1, // 32.2xxxxx
//                 lng: 34.7 + Math.random() * 0.1, // 34.8xxxxx
//                 place_id: `some_place_id_${i}-${j}`,
//                 waypointId: `waypointId-${i}-${j}`,
//                 stationName: `Some Station Name ${i}-${j}`,
//                 isStation: true,
//                 isTarget: j === waypointCount - 1, // make last waypoint the target
//                 language: 'he',
//             });
//         }

//         mockRoutes.push({
//             uuid: `route-id-${i}`,
//             color: getRandomColor(),
//             optimumRoute: optimumRoute,
//             maxTimeInRoute: 30,
//             maxDistanceInRoute: 34.9,
//             routeID: 638215547025930000 + i,
//             numberOfWayPointsInRoute: waypointCount,
//             numberOfPassangersInRoute: 1,
//             assignedCar: {
//                 carName: `מונית-${i}`,
//                 carId: `taxi-id-${i}`,
//                 capacity: 4,
//                 pricePerKm: 10,
//                 pricePerHour: 30,
//             },
//             routePrice: 1249,
//             routeName: 'בית עוזיאל הרצל 12 בית 3 - אשדוד רחוב כלשהו ארוך',
//         });
//     }

//     return mockRoutes;
// }

// export const mockRoutes: IOptimumRouteData[] = createMockRoutes(5);
// export const mockRoutes: IOptimumRouteData[] = [
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
//     {
//         uuid: 'route-id-2',

//         color: getRandomColor(),

//         optimumRoute: [
//             {
//                 city: 'גבעת ברנר',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.858223,
//                 lng: 34.797281,
//                 place_id: '31.858223,34.797281',
//                 waypointId: 'ac8f6a12-2419-41c0-8299-ac93b8831845',
//                 stationName: '  גבעת ברנר',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'רחובות',
//                 street: 'נתן אלתרמן',
//                 houseNum: '18',
//                 lat: 31.881493,
//                 lng: 34.811483,
//                 place_id: '31.881493,34.811483',
//                 waypointId: '686a288d-05a3-4e94-b20f-e112a62fd26f',
//                 stationName: 'נתן אלתרמן 18 רחובות',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בית גמליאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.862888,
//                 lng: 34.776668,
//                 place_id: '31.862888,34.776668',
//                 waypointId: '60268786-70f3-4f13-96b7-33e446d1e3a6',
//                 stationName: '  בית גמליאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 70,
//         maxDistanceInRoute: 42.3,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 2523,
//         routeName: 'גבעת ברנר - אשדוד',
//     },
//     {
//         uuid: 'route-id-3',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'בית עוזיאל',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.867554,
//                 lng: 34.897598,
//                 place_id: '31.867554,34.897598',
//                 waypointId: '41e51920-808d-4d6a-9eb8-206bf29cc8a9',
//                 stationName: '  בית עוזיאל',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 30,
//         maxDistanceInRoute: 34.9,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 2,
//         numberOfPassangersInRoute: 1,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1249,
//         routeName: 'בית עוזיאל - אשדוד',
//     },
//     {
//         uuid: 'route-id-1',
//         color: getRandomColor(),
//         optimumRoute: [
//             {
//                 city: 'תל אביב-יפו',
//                 street: 'עולי ציון',
//                 houseNum: '7',
//                 lat: 32.0534,
//                 lng: 34.7567048,
//                 place_id: '32.0534,34.7567048',
//                 waypointId: '2da232ae-5779-43f1-a64d-d8d6f1a16b0f',
//                 stationName: 'עולי ציון 7 תל אביב-יפו',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: '',
//                 houseNum: '',
//                 lat: 32.024237,
//                 lng: 34.7213048,
//                 place_id: '32.024237,34.7213048',
//                 waypointId: 'b365eb18-c407-47fe-822b-21118f867ac2',
//                 stationName: '  בת ים',
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'בת ים',
//                 street: "ז'בוטינסקי",
//                 houseNum: '17',
//                 lat: 32.021376,
//                 lng: 34.743048,
//                 place_id: '32.021376,34.743048',
//                 waypointId: 'a320a3ce-b012-4494-af1f-78ad6247df3d',
//                 stationName: "ז'בוטינסקי 17 בת ים",
//                 isStation: true,
//                 isTarget: false,
//                 language: 'he',
//             },
//             {
//                 city: 'אשדוד',
//                 street: '',
//                 houseNum: '',
//                 lat: 31.804381,
//                 lng: 34.655314,
//                 place_id: '31.804381,34.655314',
//                 waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
//                 stationName: '  אשדוד',
//                 isStation: true,
//                 isTarget: true,
//                 language: 'he',
//             },
//         ],
//         maxTimeInRoute: 47,
//         maxDistanceInRoute: 35.8,
//         routeID: 638215547025930000,
//         numberOfWayPointsInRoute: 4,
//         numberOfPassangersInRoute: 3,
//         assignedCar: {
//             carName: 'מונית',
//             carId: 'taxi-id-1',
//             capacity: 4,
//             pricePerKm: 10,
//             pricePerHour: 30,
//         },
//         routePrice: 1768,
//         routeName: 'תל אביב-יפו, עולי ציון, 7 - אשדוד',
//     },
// ];

export const MOCK_UNUSED_WAYPOINTS = [
    {
        city: 'תל אביב-יפו',
        street: '',
        houseNum: '',
        lat: 32.123123,
        lng: 34.746456,
        place_id: '32.123123,34.746456',
        waypointId: '885d85ed-cf9e-41b7-b39e-22a5c4e72e53',
        stationName: '  תל אביב-יפו',
        isStation: true,
        isTarget: false,
        language: 'he',
    },
    {
        city: '',
        street: '',
        houseNum: '',
        lat: 32.34534,
        lng: 34.654654,
        place_id: '32.34534,34.654654',
        waypointId: '5784d6a8-a0ee-4207-92e1-dbbc7ebc860e',
        stationName: '  ',
        isStation: true,
        isTarget: false,
        language: 'he',
    },
    {
        city: '',
        street: '',
        houseNum: '',
        lat: 32.3453,
        lng: 34.5464,
        place_id: '32.3453,34.5464',
        waypointId: '628ad70f-2da9-4c89-bac2-e6ef7e6a946a',
        stationName: '  ',
        isStation: true,
        isTarget: false,
        language: 'he',
    },
    {
        city: '',
        street: '',
        houseNum: '',
        lat: 32.5756,
        lng: 34.654654,
        place_id: '32.5756,34.654654',
        waypointId: 'c09992af-821b-479c-a844-863ce581c40e',
        stationName: '  ',
        isStation: true,
        isTarget: false,
        language: 'he',
    },
    {
        city: 'אשדוד',
        street: '',
        houseNum: '',
        lat: 31.804381,
        lng: 34.655314,
        place_id: '31.804381,34.655314',
        waypointId: '48b3df89-bee2-45f3-bf33-ab7953b2d36f',
        stationName: '  אשדוד',
        isStation: true,
        isTarget: true,
        language: 'he',
    },
];

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function FlagIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
<svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.24965 16.4153C1.03707 16.4153 0.858983 16.3433 0.7154 16.1994C0.5718 16.0553 0.5 15.8769 0.5 15.664V1.04216C0.5 0.785628 0.586625 0.570591 0.759875 0.397052C0.933108 0.223496 1.14777 0.136719 1.40385 0.136719H7.84802C8.06141 0.136719 8.25193 0.203829 8.4196 0.338048C8.58727 0.472251 8.69289 0.646592 8.73648 0.861069L8.9961 2.14025H13.5961C13.8522 2.14025 14.0668 2.22666 14.2401 2.39948C14.4133 2.57228 14.5 2.78641 14.5 3.04186V10.2547C14.5 10.5101 14.4133 10.7242 14.2401 10.8969C14.0668 11.0697 13.8522 11.1561 13.5961 11.1561H9.15193C8.93854 11.1561 8.74802 11.089 8.58035 10.9547C8.41268 10.8205 8.30706 10.6462 8.26347 10.4317L8.00385 9.15254H1.99997V15.664C1.99997 15.8769 1.92807 16.0553 1.78425 16.1994C1.64045 16.3433 1.46225 16.4153 1.24965 16.4153Z" fill="#494949"/>
</svg>



    );
}

export default FlagIcon;

import React from 'react';

// const dummyPolylines = createDummyPolylines();
export const DistanceMarker: React.FC<{
    distance: number;
}> = ({ distance }) => {
    return (
        <div
            style={{
                padding: '3px 6px',
                background: '#E6F6FE',
                borderRadius: 4,
                border: '1px #2196F3 solid',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <div style={{ color: '#494949', fontSize: 12 }}>{distance} מ׳</div>
        </div>
    );
};

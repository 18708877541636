export const flexCenter = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
} as const;

export const cs = {
    flexCenter,
    alc: {
        display: 'flex',
        alignItems: 'center',
    },
    jsc: {
        display: 'flex',
        justifyContent: 'center',
    },
} as const;

const screenHeight = window.screen.height;

const headerHeight = 80;
const footerHeight = 26;
const stepperHeight = 100;
const bodyHeight = screenHeight - headerHeight - footerHeight - stepperHeight;

export const heights = {
    header: headerHeight,
    infoFooter: footerHeight,
    body: bodyHeight,
    stepper: stepperHeight,
};

export enum Stages {
    Start = 1,
    DataLoading = 2,
    TargetSelection = 3,
    Cars = 4,
    Parameters = 5,
    StationConfirmation = 6,
    Summary = 7,
    ChooseFlowType = 8,
}

export enum BackendOptiTypes {
    NoOptimization = 0,
    OptimumRoute = 1,
    OptimumMultiTierStations = 2,
    OptimumRouteMagicStick = 3,
    OptimumStations = 4,
}

export enum OptiFlowTypes {
    RoutesOnlyCreation = 1,
    WithStations = 2,
}

export enum OptimizationMethods {
    google = 0,
    geometric = 1,
}

export enum PrevOptimizationTypes {
    Stations = 0,
    RoutesOnly = 1,
    StationAndRoutes = 2,
}

export enum OptimizationErrorCodes {
    NoHashID = 1, // not in DB
    ReachMaxTaskLimit = 2,
    OptimizationNotStarted = 3,
    OptimizationInProgress = 4,
    NoTarget = 5, // no target waypoint
    NoOptimumRouteInResult = 6, // no routes found
    NoLatLngInTarget = 7, // target without coordinates
    NoLatLngInPassenger = 8, // passenger without coordinates
    ClientTokenNotValid = 10,
    NotEnoughCredit = 11,
    NoCommunicationToTransit = 12, // transit not responding
    ErrorWithGeoServer = 13, // geo server not responding
    MaxWalkingDistanceExceedsLimit = 14,
}

import { uuid } from 'src/store/helpers';
import { useAppDispatch } from 'src/store/hooks';
import { setCarInputsValues } from 'src/store/slices/carsStageSlice';
import { CarType } from '../components/stages/Summary/types';
import * as React from 'react';
import { CarInputItem } from 'src/types/globalTypes';
import { CARS_INPUTS_LOCAL_STORAGE_KEY } from 'src/utils/utilis';
import { setCarsForPricing } from 'src/store/slices/summaryStageSlice';

// eslint-disable-next-line no-var

export const useCarInputsUtils = () => {
    const dispatch = useAppDispatch();

    // const syncTo = (second) => { third }
    const saveCarInputsToLocalStorage = (carInputs: CarInputItem[]) => {
        localStorage.setItem(CARS_INPUTS_LOCAL_STORAGE_KEY, JSON.stringify(carInputs));
    };

    const convertCarTypesToCarInputs = (carsTypes: CarType[]) => {
        return carsTypes.map((car) => {
            return {
                ...car,
                inputId: uuid(),
            };
        });
    };

    const dispatchSetCarsInputsByCarsForPricing = (carsForPricing: CarType[]) => {
        const carInputs: CarInputItem[] = carsForPricing.map((car) => {
            return {
                ...car,
                inputId: uuid(),
            };
        });

        dispatch(setCarInputsValues(carInputs));

        return carInputs;
    };

    const handleCarInputsSave = (carInputsValues: CarInputItem[]) => {
        dispatch(setCarsForPricing(carInputsValues));
        saveCarInputsToLocalStorage(carInputsValues);
    };

    return {
        dispatchSetCarsInputsByCarsForPricing,
        saveCarInputsToLocalStorage,
        getCarsInputsFromLocalStorage: () => {
            const value = localStorage.getItem(CARS_INPUTS_LOCAL_STORAGE_KEY);
            if (value) return JSON.parse(value) as CarInputItem[];

            return null;
        },
        convertCarTypesToCarInputs,
        handleCarInputsSave,
    };
};

// const CarsPricingLocalStorageHelper: FC<Props> = ({}) => {
//     const carsForPricing = useAppSelector((state) => summaryStageSliceSelector(state).carsForPricing);
//     const { syncCarInputsGlobally } = useGlobalCarInputsSync();

//     useEffect(() => {
//         syncCarInputsGlobally(carsForPricing);
//     }, [carsForPricing]);

//     return <></>;
// };

// export default CarsPricingLocalStorageHelper;

import { TextField } from '@mui/material';
import React, { useState } from 'react';
import AddressAutoComplete, { EMPTY_ADDRESS_DATA } from 'src/components/AddressAutoComplete';
import TrashIcon from 'src/components/Icons/TrashIcon';
import { editOptimizationStyles } from './EditPrevOptimization.styles';
import { IOptimizationWaypoint } from 'src/types/optimization/types';
import { AutocompleteAddress } from 'src/types/googleMapsTypes';
import { buildWaypointName, uuid } from 'src/store/helpers';
import { useAppDispatch } from 'src/store/hooks';
import {
    epoDeleteWaypointAction,
    epoEditWaypointAction,
    updateInputTextByWaypointId,
} from 'src/store/slices/optimizationsSlice';
import { Coords } from 'google-map-react';
import { hasValidCoords } from 'src/utils/utilis';
import { EditPrevOptiFieldValues } from 'src/types/optimization/sliceTypes';

export const inputSX = {
    width: '100%',
    '& .MuiInputBase-root': {
        height: '40px',
        padding: '4px',
    },
} as const;

const getCurrentWaypointAddress = (waypoint: EditPrevOptiFieldValues[number]) => {
    let address: AutocompleteAddress = EMPTY_ADDRESS_DATA;

    if (waypoint) {
        address = {
            city: waypoint.city,
            street: waypoint.street,
            houseNum: waypoint.houseNum,
            lat: waypoint.lat,
            lng: waypoint.lng,
            placeName: waypoint.stationName || '',
        };
    }
    return address;
};

export const WaypointItem: React.FC<{
    waypoint: IOptimizationWaypoint;
    setCenter: React.Dispatch<React.SetStateAction<Coords>>;
    map: google.maps.Map | null;
    centerAll: () => void;
    inputText: string;
    setInputText: (text: string) => void;
}> = ({ waypoint, inputText, setInputText }) => {
    // const [inputText, setInputText] = useState(buildWaypointName(waypoint));
    const dispatch = useAppDispatch();

    const [addressData, setAddressData] = useState<AutocompleteAddress>({
        ...getCurrentWaypointAddress(waypoint),
    });

    const onSelection = (addressData: AutocompleteAddress) => {
        dispatch(
            epoEditWaypointAction({
                waypoint: {
                    ...waypoint,
                    city: addressData.city,
                    street: addressData.street,
                    houseNum: addressData.houseNum,
                    lat: addressData.lat,
                    lng: addressData.lng,
                    waypointName: buildWaypointName(waypoint),
                    stationName: undefined,
                },
            })
        );
        // setCenter({ lat: addressData.lat, lng: addressData.lng });
        // map?.setZoom(12);
    };

    const onDeleteWaypoint = () => {
        dispatch(epoDeleteWaypointAction({ waypointId: waypoint.waypointId }));
        // centerAll();
    };

    const handleInputChange = (field: string, value: string) => {
        if (field === 'firstName') {
            dispatch(epoEditWaypointAction({ waypoint: { ...waypoint, firstName: value } }));
        } else if (field === 'lastName') {
            dispatch(
                epoEditWaypointAction({
                    waypoint: { ...waypoint, lastName: value },
                })
            );
        }
    };

    return (
        <editOptimizationStyles.WaypointContainer>
            <editOptimizationStyles.FieldContainer width="20%">
                <editOptimizationStyles.Label>שם פרטי</editOptimizationStyles.Label>
                <TextField
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                    size="small"
                    sx={inputSX}
                    variant="outlined"
                    label=""
                    value={waypoint.firstName || ''}
                />
            </editOptimizationStyles.FieldContainer>
            <editOptimizationStyles.FieldContainer width="20%">
                <editOptimizationStyles.Label>שם משפחה</editOptimizationStyles.Label>
                <TextField
                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                    size="small"
                    sx={inputSX}
                    variant="outlined"
                    label=""
                    value={waypoint.lastName || ''}
                />
            </editOptimizationStyles.FieldContainer>
            <editOptimizationStyles.FieldContainer width="50%">
                <editOptimizationStyles.Label>כתובת</editOptimizationStyles.Label>
                <AddressAutoComplete
                    inputText={inputText}
                    setAddressData={setAddressData}
                    setInputText={(text) => {
                        if (waypoint.lat && waypoint.lat) {
                            // resetting data because if text changed its not a valid station anymore

                            const updatedWaypoint = {
                                ...waypoint,
                                ...EMPTY_ADDRESS_DATA,
                                city: text,
                                stationName: undefined,
                            };

                            dispatch(
                                epoEditWaypointAction({
                                    waypoint: {
                                        ...updatedWaypoint,
                                        waypointName: buildWaypointName(updatedWaypoint),
                                    },
                                })
                            );
                        }
                        setInputText(text);
                    }}
                    currentAddress={addressData}
                    onSelection={onSelection}
                    isInitialValue
                    controlledError={!hasValidCoords(addressData)}
                />
            </editOptimizationStyles.FieldContainer>
            <editOptimizationStyles.ActionIcon onClick={onDeleteWaypoint}>
                <TrashIcon />
            </editOptimizationStyles.ActionIcon>
        </editOptimizationStyles.WaypointContainer>
    );
};

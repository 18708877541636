import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BaseButtonIconStyled } from 'src/components/BtnsFooter/ExportsBtns';
import { Tooltip } from '@mui/material';
import { tooltipStylesV2 } from 'src/components/BaseTooltip';

const menuItems = [{ title: 'הצג זמן בין תחנות', key: 'timeFromPrev' }];

export const ActivatedDisplaysPicker: React.FC<{
    setActivatedDisplays: React.Dispatch<React.SetStateAction<string[]>>;
    activatedDisplays: string[];
}> = ({ setActivatedDisplays, activatedDisplays }) => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    return (
        <div
            style={{
                position: 'absolute',
                zIndex: 999,
                left: 15,
                top: 15,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Tooltip
                PopperProps={{
                    sx: tooltipStylesV2,
                }}
                title={isMenuOpen ? null : <>שכבות מידע</>}
                arrow
            >
                <BaseButtonIconStyled
                    onClick={() => {
                        setIsMenuOpen(!isMenuOpen);
                    }}
                    style={{ alignSelf: 'flex-end' }}
                >
                    <MoreVertIcon htmlColor="white" />
                </BaseButtonIconStyled>
            </Tooltip>
            {isMenuOpen && (
                <div
                    style={{
                        minWidth: '180px',
                        maxWidth: '180px',
                        background: 'white',
                        borderRadius: '9px',
                        overflow: 'hidden',
                        position: 'relative',
                        top: '10px',
                    }}
                >
                    {menuItems.map((item) => (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '5px',
                                borderBottom: '1px solid #eee',
                                height: '35px',
                            }}
                            key={item.key}
                        >
                            <input
                                id={item.key}
                                type="checkbox"
                                checked={activatedDisplays.includes(item.key)}
                                onChange={() => {
                                    setActivatedDisplays((prev) => {
                                        if (prev.includes(item.key)) {
                                            return prev.filter((key) => key !== item.key);
                                        }
                                        return [...prev, item.key];
                                    });
                                }}
                            />
                            <label htmlFor={item.key} style={{ marginRight: '5px', cursor: 'pointer' }}>
                                {item.title}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

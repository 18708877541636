import axios from 'axios';
import { logger } from './loggerApi';
import { AxiosRes } from './optimizationApi';

export interface SendSmsParams {
    $phoneNumber: string;
}

export interface SendSmsResponse {
    errorCode: number | null;
    isSuccess: boolean;
    message: string;
    data: {
        twoFactorToken: string;
        expirationByMinutes: number;
    };
}

export interface LoginParams {
    $phoneNumber: string;
    $twoFactorToken: string;
    $twoFactorCode: string;
}

export interface LoginResponse {
    errorCode: number | null;
    isSuccess: boolean;
    message: string;
    data: {
        expirationByMinutes: number;
        clientToken: string;
        currentMonthCredit: number;
        generalAdditionalCredit: number;
    };
}

const MAIN_URL_DEV =
    'http://interfaceserviceapi.yit.co.il/OptimizationApplicationService/OptimizationApplicationService.svc';
const MAIN_URL_STAGE =
    'https://interfaceserviceapi.ebstage.y-it.co.il/OptimizationApplicationService/OptimizationApplicationService.svc';
const MAIN_URL_PROD =
    'https://interfaceserviceapi.y-it.co.il/OptimizationApplicationService/OptimizationApplicationService.svc';

const createUrl = (endpoint: string) => `${MAIN_URL_PROD}/${endpoint}`;

const ENDPOINTS = {
    PHONE_LOGIN: 'RoutePlannerPhoneLogin',
    CODE_LOGIN: 'RoutePlannerCodeLogin',
} as const;

export const sendSms = async (reqBody: SendSmsParams): Promise<AxiosRes<SendSmsResponse>> => {
    const url = createUrl(ENDPOINTS.PHONE_LOGIN);
    const params = new URLSearchParams();

    // Convert request body to x-www-form-urlencoded
    for (const key in reqBody) {
        if (Object.prototype.hasOwnProperty.call(reqBody, key)) {
            params.append(key, reqBody[key as keyof SendSmsParams]);
        }
    }

    try {
        const response = await axios.post(url, params.toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return response;
    } catch (error) {
        logger({
            type: 'apiReqError',
            feature: `Attempting to send sms, Login screen`,
            data: error,
        });
        throw new Error(`Error sending POST request: ${error}`);
    }
};

export const login = async (reqBody: LoginParams): Promise<AxiosRes<LoginResponse>> => {
    const url = createUrl(ENDPOINTS.CODE_LOGIN);
    const params = new URLSearchParams();

    // Convert request body to x-www-form-urlencoded
    for (const key in reqBody) {
        if (Object.prototype.hasOwnProperty.call(reqBody, key)) {
            params.append(key, reqBody[key as keyof LoginParams]);
        }
    }

    try {
        const response = await axios.post(url, params.toString(), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return response;
    } catch (error) {
        logger({
            type: 'apiReqError',
            feature: `Attempting to log in, ${ENDPOINTS.CODE_LOGIN} Login screen`,
            data: error,
        });
        throw new Error(`Error sending POST request: ${error}`);
    }
};

export const sendSmsMock = (
    reqBody: SendSmsParams
): Promise<{
    data: SendSmsResponse;
}> => {
    const responseMock: {
        data: SendSmsResponse;
    } = {
        data: {
            errorCode: null,
            isSuccess: true,
            message: '',
            data: {
                expirationByMinutes: 720,
                twoFactorToken: '1234',
            },
        },
    };
    return Promise.resolve(responseMock);
};

export const loginMock = (reqBody: LoginParams): Promise<{ data: LoginResponse }> => {
    const responseMock: { data: LoginResponse } = {
        data: {
            errorCode: null,

            isSuccess: true,
            message: '',
            data: {
                expirationByMinutes: 720,
                clientToken: '111111',
                currentMonthCredit: 200,
                generalAdditionalCredit: 100,
            },
        },
    };
    return Promise.resolve(responseMock);
};

import React from 'react';
// import { styleIcon } from '../../../../Passengers/css-styles';

interface Props {
    markerColor: string;
    textColor: string;
    markerIndex: number;
    isStationMarker?: boolean;
}

const StationOrWayPointMarker: React.FC<Props> = ({
    markerColor = '#2196F3',
    textColor,
    markerIndex,
    isStationMarker,
}) => {
    return (
        <>
            {isStationMarker ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                        fill={markerColor}
                        d="M488 128h-8V80c0-44.8-99.2-80-224-80S32 35.2 32 80v48h-8c-13.25 0-24 10.74-24 24v80c0 13.25 10.75 24 24 24h8v160c0 17.67 14.33 32 32 32v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h192v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h6.4c16 0 25.6-12.8 25.6-25.6V256h8c13.25 0 24-10.75 24-24v-80c0-13.26-10.75-24-24-24zM112 400c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm16-112c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h256c17.67 0 32 14.33 32 32v128c0 17.67-14.33 32-32 32H128zm272 112c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"
                    />
                </svg>
            ) : (
                <svg
                    width="37"
                    height="47"
                    viewBox="0 0 37 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.5013 46.4163C12.352 41.1837 7.7595 36.3231 4.7238 31.8345C1.68658 27.3474 0.167969 23.1941 0.167969 19.3747C0.167969 13.6455 2.01123 9.08127 5.69776 5.68197C9.38276 2.28266 13.6506 0.583008 18.5013 0.583008C23.352 0.583008 27.6198 2.28266 31.3048 5.68197C34.9914 9.08127 36.8346 13.6455 36.8346 19.3747C36.8346 23.1941 35.3168 27.3474 32.2811 31.8345C29.2439 36.3231 24.6506 41.1837 18.5013 46.4163Z"
                        fill={markerColor}
                    />
                    <text
                        fontSize="20"
                        y="50%"
                        x="50%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fill={textColor}
                    >
                        {markerIndex + 1}
                    </text>
                </svg>
            )}
        </>
    );
};

export default StationOrWayPointMarker;

/* eslint-disable no-debugger */
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { IOptimumWaypoint } from 'src/types/optimization/types';

export const createPdf = async (elements: HTMLDivElement[]) => {
    const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    const PAGE_HEIGHT = 297;
    const PAGE_WIDTH = 210;

    // debugger;

    for (let i = 0; i < elements.length; i++) {
        if (!elements[i]) continue;

        const canvas = await html2canvas(elements[i], { useCORS: true, scale: 2 });
        const imgData = canvas.toDataURL('image/png');

        const imgHeight = (canvas.height * PAGE_WIDTH) / canvas.width;
        let heightLeft = imgHeight;

        pdf.addImage(imgData, 'PNG', 0, 0, PAGE_WIDTH, imgHeight);
        heightLeft -= PAGE_HEIGHT;

        while (heightLeft > 0) {
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, heightLeft - imgHeight, PAGE_WIDTH, imgHeight);
            heightLeft -= PAGE_HEIGHT;
        }
    }

    pdf.save('output.pdf');
};

const cleanResult = (result: string) => {
    return result.replace(',,', ',').trim();
};

export const stringifyWaypoint = (waypoint: Record<string, any>) => {
    let addressPart = '';
    let result = '';

    const stationName = waypoint.stationName.trim();

    if (stationName) {
        result = stationName;
        return cleanResult(result);
    }

    if (waypoint.street) {
        addressPart += `${waypoint.street}`;
    }

    if (waypoint.houseNum) {
        addressPart += ` ${waypoint.houseNum}`;
    }

    if (waypoint.city) {
        addressPart += ` ${waypoint.city}`;
    }

    addressPart = addressPart.trim();

    if (stationName == addressPart) {
        result = addressPart;
    } else {
        result = `${stationName} ${addressPart}`;
    }

    return result || '* נוסע ללא שם';
};

export const stringifyPassengerWaypoint = (waypoint: Record<string, any>) => {
    let addressPart = '';
    let result = '';
    if (waypoint.lastName || waypoint.firstName) {
        result = `${waypoint.firstName} ${waypoint.lastName} -`;
    }

    const stationName = waypoint.stationName.trim();

    if (stationName) {
        result = `${result} ${stationName}`;
        return cleanResult(result);
    }

    if (waypoint.street) {
        addressPart += `${waypoint.street}`;
    }

    if (waypoint.houseNum) {
        addressPart += ` ${waypoint.houseNum}`;
    }

    if (waypoint.city) {
        addressPart += ` ${waypoint.city}`;
    }

    addressPart = addressPart.trim();

    if (stationName == addressPart) {
        result = `${result} ${addressPart}`;
    } else {
        result = `${stationName} ${addressPart}`;
    }

    return result || '* נוסע ללא שם';
};

import React from 'react';
import DataLoading from './stages/DataLoading/DataLoading';
import Start from './stages/Start';
import TargetSelection from './stages/TargetSelection';
import VehicleTypesPricingStage from './stages/CarTypesPricing/CarTypesPricingStage';
import OptimizationParameters from './stages/OptimizationParameters/OptimizationParameters';
import Summary from './stages/Summary/Summary';
import { heights } from 'src/styles/commonStyles';
import ActionConfirmModal from './Modals/ActionConfirmModal';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    setIsNotEnoughCreditModalOpenAction,
} from 'src/store/slices/optimizationsSlice';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Stages } from 'src/constants/common';
import StationConfirmation from './stages/StationConfirmation/StationConfirmation';
import ChooseFlowTypeStage from './stages/ChooseFlowTypeStage';

export interface DynamicBodyProps {
    stage: number;
}

const DynamicBody: React.FC<DynamicBodyProps> = ({ stage }) => {
    const dispatch = useAppDispatch();

    const isNotEnoughCreditModalOpen = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.isNotEnoughCreditModalOpen
    );

    const onCloseNotEnoughCreditModal = () => {
        dispatch(setIsNotEnoughCreditModalOpenAction({ isOpen: false }));
    };

    // return <StationConfirmation />;
    return (
        <div
            style={{
                overflow: 'auto',
                backgroundColor: '#fff',
                maxHeight: '90%',
                minHeight: '90%',
                height: '90%',
            }}
        >
            <ActionConfirmModal
                height={235}
                isOpen={isNotEnoughCreditModalOpen}
                Body={() => (
                    <div style={{ textAlign: 'center', padding: '10px' }}>
                        <div style={{ fontWeight: 400, fontSize: '20px', color: '#494949' }}>שים לב!</div>
                        <div style={{ fontWeight: 400, fontSize: '16px', color: '#494949', padding: '10px' }}>
                            הרצת תהליך הדורש כמות כתובות החורגת מהמכסה שיש לך, עלייך לרכוש חבילה נוספת או
                            לצמצם נתונים. לרכישת בנק כתובות{' '}
                            <a
                                href="https://www.y-it.co.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8/#contact-sales"
                                target="_blank"
                                style={{
                                    color: '#2196F3',
                                    alignSelf: 'flex-end',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    marginLeft: '5px',
                                    marginTop: '6px',
                                    textUnderlinePosition: 'under',
                                }}
                                rel="noreferrer"
                            >
                                לחץ כאן
                            </a>
                        </div>
                    </div>
                )}
                onConfirm={onCloseNotEnoughCreditModal}
                Img={() => <PriorityHighIcon fontSize="medium" />}
            />
            {stage === Stages.Start && <Start />}
            {stage === Stages.ChooseFlowType && <ChooseFlowTypeStage />}
            {stage === Stages.DataLoading && <DataLoading />}
            {stage === Stages.TargetSelection && <TargetSelection />}
            {stage === Stages.Cars && <VehicleTypesPricingStage />}
            {stage === Stages.Parameters && <OptimizationParameters />}
            {stage === Stages.Summary && <Summary />}
            {stage === Stages.StationConfirmation && <StationConfirmation />}
        </div>
    );
};
export default DynamicBody;

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import commonSlice from './slices/commonSlice';
import authSlice from './slices/authSlice';
import optimizationsSlice from './slices/optimizationsSlice';
import summaryStageSlice from './slices/summaryStageSlice';
import carsStageSlice from './slices/carsStageSlice';

export const store = configureStore({
    reducer: {
        auth: authSlice,
        common: commonSlice,
        optimization: optimizationsSlice,
        summaryStage: summaryStageSlice,
        carsStage: carsStageSlice,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

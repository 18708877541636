import React, { CSSProperties, useEffect } from 'react';
import styled, { CSSObject, css } from 'styled-components';
import { CircularProgress } from '@mui/material';
import { Coords } from 'google-map-react';
import { MAPS_API_KEY } from 'src/utils/utilis';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

export const centeredFlexItem: CSSProperties = {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center ',
    alignItems: 'center',
};

export const defaultCenter: Coords = {
    lat: 31.896997821344108,
    lng: 34.80000972747803,
};

export const StyledMapWindow = styled.div<{ style?: CSSObject }>`
    background: #000000b5;
    border-radius: 3px;
    position: absolute;
    padding: 8px;
    display: flex;
    z-index: 10;
    color: white;
    ${({ style = {} }) => css(style)};
`;

export const MapWindow: React.FC<{ style?: CSSObject; children: React.ReactNode }> = ({
    children,
    style = {},
}) => {
    return <StyledMapWindow style={style}>{children}</StyledMapWindow>;
};

// Artificial delay for loading the map
const useLoadingDelay = () => {
    const [delay, setDelay] = React.useState(true);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setDelay(false);
        }, 1);
        return () => clearTimeout(timeout);
    }, []);

    return delay;
};

const Loader = () => {
    return (
        <div style={{ ...centeredFlexItem, backgroundColor: '#f2f3f5', zIndex: 333 }}>
            <CircularProgress
                sx={{
                    color: '#2196F3',
                }}
            />
        </div>
    );
};

interface Props {
    // options?: google.maps.MapOptions;
    GoogleMapProps?: React.ComponentProps<typeof GoogleMap>;
    children: React.ReactNode;
}

export const myStyles: google.maps.MapTypeStyle[] = [
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
];

const MapType2 = React.forwardRef((props: Props, ref) => {
    const { children, GoogleMapProps = {} } = props;

    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: MAPS_API_KEY,
    //     id: 'google-map-script',
    //     language: 'he',
    // });

    // const delay = useLoadingDelay();
    const delay = false;

    // if (!isLoaded) {
    //     return <Loader />;
    // }

    // if (loadError) {
    //     console.log('LoadError', loadError);
    // }

    const { zoom, center, options, ...restProps } = GoogleMapProps;

    return (
        <>
            {delay ? <Loader /> : null}
            <GoogleMap
                ref={ref as any}
                mapContainerStyle={delay ? { width: 0, height: 0 } : { width: '100%', height: '100%' }}
                center={GoogleMapProps.center || defaultCenter}
                zoom={GoogleMapProps.zoom || 8}
                options={{
                    mapTypeControl: false,
                    streetViewControl: false,
                    styles: myStyles,

                    ...GoogleMapProps.options,
                }}
                {...restProps}
            >
                {/* Child components, such as markers, info windows, etc. */}
                {children}
            </GoogleMap>
        </>
    );
});

// add display name for maptype2
MapType2.displayName = 'MapType2';

export default MapType2;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function PrintIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6673 6.33333V2.83333H7.33398V6.33333H5.00065V1.66667C5.00065 1.33611 5.11246 1.05903 5.33607 0.835417C5.55968 0.611806 5.83676 0.5 6.16732 0.5H17.834C18.1645 0.5 18.4416 0.611806 18.6652 0.835417C18.8888 1.05903 19.0007 1.33611 19.0007 1.66667V6.33333H16.6673ZM19.0007 11.5833C19.3312 11.5833 19.6083 11.4715 19.8319 11.2479C20.0555 11.0243 20.1673 10.7472 20.1673 10.4167C20.1673 10.0861 20.0555 9.80903 19.8319 9.58542C19.6083 9.3618 19.3312 9.25 19.0007 9.25C18.6701 9.25 18.393 9.3618 18.1694 9.58542C17.9458 9.80903 17.834 10.0861 17.834 10.4167C17.834 10.7472 17.9458 11.0243 18.1694 11.2479C18.393 11.4715 18.6701 11.5833 19.0007 11.5833ZM7.33398 19.1667H16.6673V14.5H7.33398V19.1667ZM7.33398 21.5C6.69232 21.5 6.14301 21.2715 5.68607 20.8146C5.22912 20.3576 5.00065 19.8083 5.00065 19.1667V16.8333H1.50065C1.1701 16.8333 0.893012 16.7215 0.669401 16.4979C0.44579 16.2743 0.333984 15.9972 0.333984 15.6667V9.83333C0.333984 8.84167 0.674262 8.01042 1.35482 7.33958C2.03537 6.66875 2.86176 6.33333 3.83398 6.33333H20.1673C21.159 6.33333 21.9902 6.66875 22.6611 7.33958C23.3319 8.01042 23.6673 8.84167 23.6673 9.83333V15.6667C23.6673 15.9972 23.5555 16.2743 23.3319 16.4979C23.1083 16.7215 22.8312 16.8333 22.5007 16.8333H19.0007V19.1667C19.0007 19.8083 18.7722 20.3576 18.3152 20.8146C17.8583 21.2715 17.309 21.5 16.6673 21.5H7.33398ZM21.334 14.5V9.83333C21.334 9.50278 21.2222 9.22569 20.9986 9.00208C20.775 8.77847 20.4979 8.66667 20.1673 8.66667H3.83398C3.50343 8.66667 3.22635 8.77847 3.00273 9.00208C2.77912 9.22569 2.66732 9.50278 2.66732 9.83333V14.5H5.00065V12.1667H19.0007V14.5H21.334Z"
                fill="white"
            />
        </svg>
    );
}

export default PrintIcon;

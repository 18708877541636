import { t } from 'src/utils/lang';
import React from 'react';
import MagicWandPopOver from 'src/components/MagicWandPopOver';
import { useAppSelector } from 'src/store/hooks';
import { summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import SearchInput from 'src/components/SearchInput';
import {
    setStationConfirmStageFilteredStationsAction,
    stationsToWaypointsSelector,
} from 'src/store/slices/optimizationsSlice';
import { useDispatch } from 'react-redux';

const SectionTop: React.FC = () => {
    const dispatch = useDispatch();

    const stationResultData = useAppSelector((state) => stationsToWaypointsSelector(state));
    const onSearch = (ev: any) => {
        const val = ev.target.value.toLowerCase();

        const filteredRoutes = stationResultData.filter(
            (station) =>
                station.station.stationName?.includes(val) ||
                station.wayPoints.some(
                    (waypoint) =>
                        waypoint.city.toLowerCase().includes(val) ||
                        waypoint.stationName.toLowerCase().includes(val) ||
                        waypoint.street.toLowerCase().includes(val) ||
                        waypoint.houseNum.toLowerCase().includes(val) ||
                        waypoint.firstName.toLowerCase().includes(val) ||
                        waypoint.lastName.toLowerCase().includes(val)
                )
        );

        dispatch(setStationConfirmStageFilteredStationsAction({ stationList: filteredRoutes }));
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p style={{ fontWeight: 'bold', fontSize: '2rem' }}>אימות תחנות</p>
                <div style={{ display: 'flex', gap: '5px' }}>{/* <MagicWandPopOver /> */}</div>
            </div>
            <p style={{ fontSize: '1.2rem', marginBottom: '24px' }}>{t.displayingStationsList}</p>
            <SearchInput style={{ width: '43%' }} onChange={(ev) => onSearch(ev)} />
        </div>
    );
};
export default SectionTop;

import React from 'react';
import NumberSlider from '../../ParametersStage/NumberSlider';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector, setVehicleCapacityAction } from 'src/store/slices/optimizationsSlice';
import { isStringNumber } from 'src/store/helpers';
import { MIN_VEHICLE_CAPACITY } from './OptimizationParameters';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';

export const PassCountSlider: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    const maxVehicleCapacity = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm.maxVehicleCapacity
    );

    const vehicleCapacity = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm.vehicleCapacity
    );

    const handleVehicleCapacitySliderChange = (event: Event, valLTR: number | number[]) => {
        if (typeof valLTR === 'number')
            dispatch(
                setVehicleCapacityAction({
                    vehicleCapacity: valLTR,
                    maxValue: maxVehicleCapacity,
                    minValue: MIN_VEHICLE_CAPACITY,
                })
            );
    };

    const handleVehicleCapacityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isStringNumber(event.target.value)) return;

        dispatch(setVehicleCapacityAction({ vehicleCapacity: Number(event.target.value) }));
    };

    return (
        <>
            <div
                style={{
                    marginBottom: '10px',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#242424',
                }}
            >
                מהי כמות הנוסעים המקסימלית שאתה מוכן שיהיו בכל נסיעה
            </div>
            <NumberSlider
                handleInputChange={handleVehicleCapacityInputChange}
                handleSliderChange={handleVehicleCapacitySliderChange}
                value={vehicleCapacity}
                max={maxVehicleCapacity}
                min={MIN_VEHICLE_CAPACITY}
                step={100}
            />
        </>
    );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDevEnv } from 'src/utils/utilis';
import { RootState } from '..';
import { ISODateString } from '../helpers';

export interface AuthSliceState {
    isAuth: boolean;
    clientToken: string | null;
    clientTokenExpirationDate: ISODateString | null;
    currentMonthCredit: number;
    clientPhone: string | null;
    twoFactorToken: string | null;
    generalAdditionalCredit: number;
    clientName: string;
}

const MOCK =
    process.env.NODE_ENV === 'development'
        ? ({
              //   clientToken: 'cf46751a-ca1d-4bfc-b318-f41ef5dd1cfb',
              //   isAuth: true,
          } as any)
        : {};

const initialState: AuthSliceState = {
    isAuth: false,
    clientToken: null,
    twoFactorToken: null,
    clientPhone: null,
    clientTokenExpirationDate: null,
    currentMonthCredit: 10000,
    generalAdditionalCredit: 0,
    clientName: '',
    ...MOCK,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setClientName: (state, action: PayloadAction<string>) => {
            state.clientName = action.payload;
        },
        setIsAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload;
        },
        setClientToken: (state, action: PayloadAction<string>) => {
            state.clientToken = action.payload;
        },
        setClientTokenExpirationByMinutes: (state, action: PayloadAction<ISODateString | null>) => {
            state.clientTokenExpirationDate = action.payload;
        },
        setTwoFactorToken: (state, action: PayloadAction<string>) => {
            state.twoFactorToken = action.payload;
        },
        setClientCurrentMonthCredit: (state, action: PayloadAction<number>) => {
            state.currentMonthCredit = action.payload;
        },
        setClientPhone: (state, action: PayloadAction<string>) => {
            state.clientPhone = action.payload;
        },
        setGeneralAdditionalCredit: (state, action: PayloadAction<number>) => {
            state.generalAdditionalCredit = action.payload;
        },
        restartAuthSlice: () => {
            return initialState;
        },
    },
});

export const {
    setClientName,
    setIsAuth,
    setClientToken,
    setClientCurrentMonthCredit,
    setTwoFactorToken,
    setClientPhone,
    restartAuthSlice,
    setGeneralAdditionalCredit,
    setClientTokenExpirationByMinutes,
} = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const authRootSelector = (state: RootState) => state.auth;

export default authSlice.reducer;

import React from 'react';
import styled from 'styled-components';
import { summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { t } from 'src/utils/lang';
import { colors } from 'src/styles/defaultTheme';
import { toFixed } from 'src/utils/utilis';
import { addCommasToNumber } from 'src/store/helpers';
import styles from '../../Summary/styles';
import { stationsToWaypointsSelector } from 'src/store/slices/optimizationsSlice';

const StyledMapContainer = styled.div`
    width: 30%;
    display: flex;
    padding: 20px 0;
    justify-content: center;
    border: 1px solid #4dff00;
`;

const DataBox = ({ mainText, subText }: { mainText: string | undefined; subText: string }) => {
    return (
        <div
            style={{
                ...styles.box,
                height: '80px',
                width: '50%',
                overflow: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                wordBreak: 'break-word',
            }}
        >
            <p style={{ fontWeight: 700, fontSize: '1.7rem' }}>{mainText}</p>
            <p style={{ fontWeight: 500, fontSize: '1.1rem', textAlign: 'center' }}>{subText}</p>
        </div>
    );
};

const StationsDataDisplay: React.FC<{
    isPDFOrigin?: boolean;
}> = ({ isPDFOrigin = false }) => {
    const dispatch = useAppDispatch();

    const stationsWithWaypoints = useAppSelector((state) => stationsToWaypointsSelector(state));

    const stats = React.useMemo(() => {
        const totalPassengers = stationsWithWaypoints.reduce((acc, curr) => {
            return acc + curr.wayPoints.length;
        }, 0);

        const totalStations = stationsWithWaypoints.length;

        const avgPassengersCountForStation = totalPassengers / totalStations;

        let totalWalkDistance = 0;

        stationsWithWaypoints.forEach((station) => {
            station.wayPoints.forEach((waypoint) => {
                totalWalkDistance += waypoint.distanceToStationInMeters;
            });
        });

        return {
            totalPassengers,
            totalStations,
            avgPassengersCountForStation,
            avgWalkDistance: totalWalkDistance / totalPassengers,
        };
    }, [stationsWithWaypoints]);

    const statsToShow = [
        [
            {
                subText: t.totalPassengersCount,
                mainText: String(stats.totalPassengers),
            },
            {
                subText: t.totalStationsCount,
                mainText: String(stats.totalStations),
            },
        ],
        [
            {
                subText: t.avgPassCountPerStation,
                mainText: String(toFixed(stats.avgPassengersCountForStation, 0)),
            },
            {
                subText: t.avgWalkDistance,
                mainText: `${Math.round(stats.avgWalkDistance)} מ'`,
            },
        ],
    ];

    return (
        <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
            {statsToShow.map((row, i) => (
                <div key={i} style={{ display: 'flex', gap: '16px' }}>
                    {row.map((stat) => (
                        <DataBox key={i} mainText={stat.mainText} subText={stat.subText} />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default StationsDataDisplay;

import React from 'react';
import { heights } from 'src/styles/commonStyles';
import { colors, msColors } from 'src/styles/defaultTheme';
import styled from 'styled-components';

const pxToRem = (px: number) => {
    return px / 16 + 'rem';
};

const StyledHeader = styled.div<{}>`
    border-top: 6px solid ${colors.primary};
    min-height: ${heights.header}px;
    max-height: ${heights.header}px;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    font-size: ${pxToRem(30)};
    color: #494949;
`;

const SpanStyled = styled.div<{}>`
    font-weight: bold;
    // salsa font family
    font-family: 'Salsa', cursive;
`;

const Header: React.FC = () => {
    return (
        <StyledHeader>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <SpanStyled style={{ paddingRight: 10, color: msColors.yellow }}>My</SpanStyled>
                <SpanStyled>Planner</SpanStyled>
                <SpanStyled
                    style={{
                        paddingLeft: 10,
                        fontSize: pxToRem(18),
                        paddingBottom: pxToRem(2.5),
                        alignSelf: 'flex-end',
                    }}
                >
                    Smart Routes, Easy Transportation
                </SpanStyled>
            </div>
        </StyledHeader>
    );
};
export default Header;

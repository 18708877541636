import { FC } from 'react';
import { RouteAccordion, UNUSABLE_WAYPOINTS_ACCORDION_ID, UnusableWaypoints } from './RouteBox';
import React from 'react';
import { IOptimumRouteData } from 'src/types/optimization/types';

interface Props {
    selectedRouteUuids: string[];
    didSelectUnusableWaypoints: boolean;
    allRoutesData: IOptimumRouteData[];
}

const RoutesBoxesList: FC<Props> = ({
    selectedRouteUuids,
    didSelectUnusableWaypoints,
    allRoutesData = [],
}) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', overflow: 'auto' }}>
            <UnusableWaypoints
                expanded={expanded === UNUSABLE_WAYPOINTS_ACCORDION_ID}
                handleChange={handleChange}
                isSelected={didSelectUnusableWaypoints}
            />

            {allRoutesData.map((route, i) => (
                <RouteAccordion
                    isSelected={selectedRouteUuids.includes(route.routeID)}
                    key={route.routeID}
                    boxData={route}
                    expanded={expanded === route.routeID}
                    handleChange={handleChange}
                />
            ))}
        </div>
    );
};

export default RoutesBoxesList;

import React, { useState } from 'react';
import Loader from 'src/components/Loader/Loader';
import { useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';
import { toFixed } from 'src/utils/utilis';

export const LoaderScreen: React.FC<{}> = () => {
    const calculationPercentage = useAppSelector(
        (state) => optimizationsRootSelector(state).data.calculationPercentage
    );

    return (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Loader percentage={+toFixed(calculationPercentage, 0)} />
        </div>
    );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function MagicToolIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="44" height="44" rx="8" fill="#E6F6FE" />
            <path
                d="M32.5 16L31.2333 13.2667L28.5 12L31.2333 10.7333L32.5 8L33.7667 10.7333L36.5 12L33.7667 13.2667L32.5 16ZM17.1667 16L15.9 13.2667L13.1667 12L15.9 10.7333L17.1667 8L18.4333 10.7333L21.1667 12L18.4333 13.2667L17.1667 16ZM32.5 31.3333L31.2333 28.6L28.5 27.3333L31.2333 26.0667L32.5 23.3333L33.7667 26.0667L36.5 27.3333L33.7667 28.6L32.5 31.3333ZM12.6333 35.6L8.9 31.8667C8.63333 31.6 8.5 31.2778 8.5 30.9C8.5 30.5222 8.63333 30.2 8.9 29.9333L23.7667 15.0667C24.0333 14.8 24.3556 14.6667 24.7333 14.6667C25.1111 14.6667 25.4333 14.8 25.7 15.0667L29.4333 18.8C29.7 19.0667 29.8333 19.3889 29.8333 19.7667C29.8333 20.1444 29.7 20.4667 29.4333 20.7333L14.5667 35.6C14.3 35.8667 13.9778 36 13.6 36C13.2222 36 12.9 35.8667 12.6333 35.6ZM13.6333 32.8L23.1667 23.2L21.3 21.3333L11.7 30.8667L13.6333 32.8Z"
                fill="#494949"
            />
        </svg>
    );
}

export default MagicToolIcon;

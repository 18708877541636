import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { t } from 'src/utils/lang';
import AddBtn from 'src/components/AddBtn/AddBtn';
import { CarType } from 'src/components/stages/Summary/types';
import {
    carsStageSliceSelector,
    createInitialCarInputItem,
    setCarInputsValues,
} from 'src/store/slices/carsStageSlice';
import { CarInputItem } from 'src/types/globalTypes';
import CarInput from './CarInput';
import { defaultCarsTypes } from './utils';

export const CarsInputsSection: React.FC<{
    hasStationsFlow: boolean;
}> = ({ hasStationsFlow }) => {
    const dispatch = useAppDispatch();

    const carInputsValues = useAppSelector(
        (state) => carsStageSliceSelector(state).carsForPricingInputsItems
    );

    const setCarsInputValues = (newValues: CarInputItem[]) => {
        dispatch(setCarInputsValues(newValues));
    };

    return (
        <div className="inputsContainer" style={{ paddingTop: '10px' }}>
            {hasStationsFlow ? (
                <div style={{ paddingTop: '12px' }} /> // layout placeholder
            ) : (
                <AddBtn
                    text={t.add}
                    handleClick={() => {
                        if (carInputsValues.length >= 50) return;

                        const updated = [createInitialCarInputItem(), ...carInputsValues];
                        setCarsInputValues(updated);
                    }}
                    style={{ fontSize: '20px' }}
                    disabled={false}
                />
            )}
            {carInputsValues.map((carInput) => {
                const { inputId, ...restValues } = carInput;

                return (
                    <CarInput
                        key={carInput.inputId}
                        menuItems={defaultCarsTypes.filter((c) =>
                            hasStationsFlow ? c.isGeneric : !c.isGeneric
                        )}
                        value={restValues}
                        setCarsInputValues={(newValues: CarType) => {
                            const index = carInputsValues.findIndex(
                                (item) => item.inputId === carInput.inputId
                            );
                            const updated = [...carInputsValues];
                            updated[index] = { inputId: carInput.inputId, ...newValues };
                            setCarsInputValues(updated);
                        }}
                        inputId={carInput.inputId}
                    />
                );
            })}
        </div>
    );
};

import React from 'react';
import { useAppSelector } from 'src/store/hooks';
import { IOptimumRouteData, IOptimumWaypoint } from 'src/types/optimization/types';
import { Coords } from 'google-map-react';
import { GetGoogleDirectionsByListDataItem, directionsApi } from 'src/api/directionsApi';
import { useDirectionsErrorHandler } from '../../StationConfirmation/components/Map/useDirectionsErrorHandler';
import { logger } from 'src/api/loggerApi';

const buildData = (routes: IOptimumRouteData[]) => {
    const ORIGIN_INDEX = 0;

    const result: GetGoogleDirectionsByListDataItem[] = [];
    // console.log({ data: routes });

    routes.forEach((route) => {
        const destinationIndex = route.optimumRoute.length - 1;

        const origin = route.optimumRoute[ORIGIN_INDEX];
        const destination = route.optimumRoute[destinationIndex];

        result.push({
            destination: destination.place_id || destination.stationName,
            destinationId: route.routeID, // used to identify the route for later use such as color
            origin: origin.place_id || origin.stationName,
            originId: origin.waypointId,
            wayPointsInRoute: route.optimumRoute
                .filter((_, i) => {
                    // filtering out origin and destination
                    return i !== ORIGIN_INDEX && i !== destinationIndex;
                })
                .map((w) => {
                    return { lat: w.lat, lng: w.lng };
                }),
        });
    });

    // console.log({ result });

    return result;
};

export const useRoutesMapPolylines = ({
    selectedRoutes,
    selectedRouteUuids,
    options,
}: {
    selectedRoutes: IOptimumRouteData[];
    selectedRouteUuids: string[];
    options?: {
        withPolylinesByRouteId?: boolean;
        skipEffectFetch?: boolean;
    };
}) => {
    const clientToken = useAppSelector((state) => state.auth.clientToken) || '';

    const [polylines, setPolylines] = React.useState<{ id: string; paths: Coords[]; color: string }[]>([]);
    const [polylinesByRouteId, setPolylinesByRouteId] = React.useState<Record<string, Coords[]>>({});
    const { handleDirectionsErr } = useDirectionsErrorHandler();

    const fetchPolylines = async () => {
        if (!selectedRouteUuids.length) {
            setPolylines([]);
            setPolylinesByRouteId({});
            return;
        }

        try {
            const response = await directionsApi.getGoogleDirectionsByList({
                params: { clientToken },
                data: buildData(selectedRoutes),
                mode: 0,
            });

            const validPaths: typeof polylines = [];
            const polylinesByRouteIdResult: typeof polylinesByRouteId = {};

            response
                .filter((section) => section.isSuccess)
                .map((section) => {
                    if (options?.withPolylinesByRouteId) {
                        polylinesByRouteIdResult[section.destinationId] = section.polyLine;
                        return;
                    } else {
                        validPaths.push({
                            id: section.destinationId,
                            paths: section.polyLine,
                            color:
                                selectedRoutes.find((r) => r.routeID === section.destinationId)?.color ||
                                '#aeaeae',
                        });
                    }
                });

            setPolylines(validPaths);
            setPolylinesByRouteId(polylinesByRouteIdResult);

            return { validPaths, polylinesByRouteIdResult };
        } catch (error: any) {
            if (error.name === 'AxiosError') {
                logger({
                    type: 'apiReqError',
                    feature: 'Routes Map - Error with getGoogleDirectionsByList request',
                    data: error,
                });
            }
            console.log(error);
            setPolylines([]);
            setPolylinesByRouteId({});
            handleDirectionsErr(error);

            return null;
        }
    };

    React.useEffect(() => {
        if (options?.skipEffectFetch) return;
        fetchPolylines();
    }, [selectedRouteUuids]);

    return {
        polylines,
        polylinesByRouteId,
        fetchPolylines,
    };
};

import { GetGoogleDirectionsByListDataItem } from 'src/api/directionsApi';
import { ISimulationStationToWaypoint } from 'src/types/optimization/types';

const buildDirectionsApiPayloadData = (selectedStations: ISimulationStationToWaypoint[]) => {
    const result: GetGoogleDirectionsByListDataItem[] = [];

    selectedStations.forEach((s) => {
        result.push(
            ...s.wayPoints.map((w) => {
                return {
                    destination:
                        s.stationPlaceId || `${s.station.city} ${s.station.street} ${s.station.houseNum}`,
                    destinationId: s.stationId,
                    origin: w.place_id || w.stationName,
                    originId: w.waypointId,
                    wayPointsInRoute: [],
                };
            })
        );
    });

    return result;
};

export const stationsConfiUtils = {
    buildDirectionsApiPayloadData,
};

import React from 'react';
import { useAppDispatch } from 'src/store/hooks';
import styled from 'styled-components';
import PrintIcon from '../Icons/PrintIcon';
import { setIsExportingToPdf, setRoutesOverviewPaths } from 'src/store/slices/summaryStageSlice';
import BaseTooltip from '../BaseTooltip';

export const BaseButtonIconStyled = styled.button<{}>`
    background-color: #2196f3;
    width: 40px;
    border: none;
    border-radius: 8px;
    height: 40px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
        opacity: 0.8;
    }
`;
export const Exports: React.FC<{}> = () => {
    const dispatch = useAppDispatch();

    return (
        <div style={{ display: 'flex', gap: '16px' }}>
            <BaseTooltip title="הדפסת מסלולים" placement="top">
                <BaseButtonIconStyled
                    onClick={() => {
                        dispatch(setRoutesOverviewPaths({}));
                        dispatch(setIsExportingToPdf(true));
                    }}
                >
                    <PrintIcon />
                </BaseButtonIconStyled>
            </BaseTooltip>
        </div>
    );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function ConfigNakedIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_8951_220303" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="white" />
            </mask>
            <g mask="url(#mask0_8951_220303)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.999 12.0039C11.1352 12.0039 9.56908 10.7291 9.12504 9.00391H2.99902C2.44674 9.00391 1.99902 8.55619 1.99902 8.00391C1.99902 7.45162 2.44674 7.00391 2.99902 7.00391H9.12504C9.56908 5.27868 11.1352 4.00391 12.999 4.00391C15.2082 4.00391 16.999 5.79477 16.999 8.00391C16.999 10.213 15.2082 12.0039 12.999 12.0039ZM14.999 8.00391C14.999 9.10848 14.1036 10.0039 12.999 10.0039C11.8945 10.0039 10.999 9.10848 10.999 8.00391C10.999 6.89934 11.8945 6.00391 12.999 6.00391C14.1036 6.00391 14.999 6.89934 14.999 8.00391C14.999 9.10848 14.999 8.00391 14.999 8.00391ZM20.999 7.00391C21.5513 7.00391 21.999 7.45162 21.999 8.00391C21.999 8.55619 21.5513 9.00391 20.999 9.00391H18.999C18.4467 9.00391 17.999 8.55619 17.999 8.00391C17.999 7.45162 18.4467 7.00391 18.999 7.00391H20.999ZM7.99902 20.0078C6.13518 20.0078 4.56908 18.733 4.12504 17.0078H2.99902C2.44674 17.0078 1.99902 16.5601 1.99902 16.0078C1.99902 15.4555 2.44674 15.0078 2.99902 15.0078H4.12504C4.56908 13.2826 6.13518 12.0078 7.99902 12.0078C10.2082 12.0078 11.999 13.7987 11.999 16.0078C11.999 18.217 10.2082 20.0078 7.99902 20.0078ZM9.99902 16.0039C9.99902 17.1085 9.10359 18.0039 7.99902 18.0039C6.89445 18.0039 5.99902 17.1085 5.99902 16.0039C5.99902 14.8993 6.89445 14.0039 7.99902 14.0039C9.10359 14.0039 9.99902 14.8993 9.99902 16.0039C9.99902 17.1085 9.99902 16.0039 9.99902 16.0039ZM20.999 17.0039C21.5513 17.0039 21.999 16.5562 21.999 16.0039C21.999 15.4516 21.5513 15.0039 20.999 15.0039H13.999C13.4467 15.0039 12.999 15.4516 12.999 16.0039C12.999 16.5562 13.4467 17.0039 13.999 17.0039H20.999Z"
                    fill="#494949"
                />
            </g>
        </svg>
    );
}

export default ConfigNakedIcon;

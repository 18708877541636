import React from 'react';
import { stageContBaseStyle } from 'src/components/StageContainer';
import { useAppSelector } from 'src/store/hooks';
import { LoaderScreen } from './components/LoaderScreen';
import { StationsResultDisplay } from './components/StationsResultDisplay';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';

const StationConfirmation: React.FC = () => {
    const displaySimulationCalcLoader = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.displaySimulationCalcLoader
    );

    const getBody = () => {
        if (displaySimulationCalcLoader) return <LoaderScreen />;
        return <StationsResultDisplay />;
    };

    return (
        <div
            style={{
                ...stageContBaseStyle,
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            {getBody()}
        </div>
    );
};

export default StationConfirmation;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function CarOnRoadIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
<svg width="400" height="197" viewBox="0 0 400 197" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M121.215 113.617C119.436 113.619 117.73 114.327 116.472 115.585C115.214 116.843 114.506 118.549 114.504 120.329V136.031C114.506 137.81 115.214 139.516 116.472 140.774C117.73 142.033 119.436 142.74 121.215 142.742H129.728C131.508 142.74 133.214 142.033 134.472 140.774C135.73 139.516 136.438 137.81 136.44 136.031V120.329C136.438 118.549 135.73 116.843 134.472 115.585C133.214 114.327 131.508 113.619 129.728 113.617H121.215Z" fill="#3F3D56"/>
<path d="M278.581 143.267H270.068C268.15 143.265 266.311 142.502 264.955 141.146C263.599 139.79 262.836 137.951 262.834 136.034V120.332C262.836 118.414 263.599 116.575 264.955 115.219C266.311 113.863 268.15 113.1 270.068 113.098H278.581C280.499 113.1 282.337 113.863 283.693 115.219C285.05 116.575 285.812 118.414 285.814 120.332V136.034C285.812 137.951 285.05 139.79 283.693 141.146C282.337 142.502 280.499 143.265 278.581 143.267Z" fill="#3F3D56"/>
<path d="M272.932 130.694H126.343C119.672 130.694 114.244 126.761 114.244 121.927V93.7388L121.52 86.0762H279.171L285.031 93.7549V121.927C285.031 126.761 279.604 130.694 272.932 130.694Z" fill="#2196F3"/>
<path d="M278.487 100.178H122.356L120.77 75.8496L121.779 73.3601L127.648 58.918H272.138L280.073 75.3179L278.487 100.178Z" fill="#2196F3"/>
<path d="M279.809 75.3677L278.644 93.6475H244.293V80.068L278.832 73.3516L279.809 75.3677Z" fill="white"/>
<path d="M156.025 80.0689V93.6484H122.191L121.031 75.8906L122.019 73.457L156.025 80.0689Z" fill="white"/>
<path d="M271.264 63.0948H128.514L143.739 12.344C144.36 10.2479 145.639 8.4073 147.386 7.09405C149.134 5.7808 151.258 5.06462 153.444 5.05136L247.982 4.15087C250.266 4.12872 252.492 4.87164 254.306 6.26127C256.119 7.65091 257.415 9.60734 257.987 11.8189L271.264 63.0948Z" fill="#2196F3"/>
<path d="M260.277 58.2974L139.482 56.8651C139.329 56.8633 139.179 56.8256 139.044 56.7552C138.908 56.6848 138.791 56.5836 138.702 56.4597C138.612 56.3358 138.553 56.1926 138.53 56.0418C138.506 55.891 138.518 55.7366 138.564 55.5912L150.144 19.4613C150.207 19.2644 150.331 19.0926 150.498 18.9708C150.665 18.8489 150.867 18.7832 151.074 18.7832H248.231C248.438 18.7832 248.64 18.8488 248.807 18.9706C248.974 19.0924 249.098 19.2641 249.161 19.461L261.218 57.0231C261.265 57.1704 261.277 57.3269 261.252 57.4796C261.227 57.6322 261.166 57.7768 261.074 57.9012C260.982 58.0257 260.862 58.1265 260.723 58.1954C260.585 58.2643 260.432 58.2993 260.277 58.2974Z" fill="white"/>
<path d="M216.17 10.3445H183.611C183.337 10.3445 183.088 10.0744 182.962 9.63935C182.836 9.19395 182.854 8.72016 183.013 8.28569L185.698 1.496C185.835 1.14993 186.059 0.943359 186.296 0.943359H213.485C213.722 0.943359 213.946 1.14993 214.082 1.496L216.767 8.28569C216.927 8.72016 216.945 9.19395 216.819 9.63935C216.693 10.0744 216.444 10.3445 216.17 10.3445Z" fill="#494949"/>
<path d="M128.607 62.0492H107.193V57.3425C107.195 55.821 107.8 54.3622 108.876 53.2863C109.952 52.2104 111.411 51.6052 112.932 51.6035H128.607V62.0492Z" fill="#2196F3"/>
<path d="M292.601 62.0492H271.188V51.6035H286.862C288.384 51.6052 289.842 52.2104 290.918 53.2863C291.994 54.3622 292.599 55.821 292.601 57.3425V62.0492Z" fill="#2196F3"/>
<path d="M104.686 135.423L0 173.029L0.444399 174.266L105.13 136.66L104.686 135.423Z" fill="#3F3D56"/>
<path d="M294.448 135.425L294.004 136.662L398.693 174.259L399.137 173.022L294.448 135.425Z" fill="#3F3D56"/>
<path d="M205.715 162.691H199.449V154.598H205.715V162.691ZM205.715 146.504H199.449V138.41H205.715V146.504Z" fill="#E6E6E6"/>
<path d="M205.715 196.218H199.449V188.125H205.715V196.218ZM205.715 180.031H199.449V171.938H205.715V180.031Z" fill="#E6E6E6"/>
</svg>

    );
}

export default CarOnRoadIcon;

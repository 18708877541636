import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEMO } from 'src/store/helpers';
import { useAppSelector } from 'src/store/hooks';
import { authRootSelector } from 'src/store/slices/authSlice';

const useNavAuth = () => {
    const isAuth = useAppSelector((state) => authRootSelector(state).isAuth);
    const clientToken = useAppSelector((state) => authRootSelector(state).clientToken);

    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuth && clientToken !== DEMO) {
            navigate('/login');
        }
    }, [window.location.href, isAuth, navigate]);

    return null;
};

export default useNavAuth;

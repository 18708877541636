import React, { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { StationsOptiParams } from 'src/components/StationsOptiParams';
import { optiSliceActions, optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';
import useOptimizationRunner from 'src/components/stages/Summary/useOptimizationRunner';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import { setStageAction } from 'src/store/slices/commonSlice';
import { Stages } from 'src/constants/common';

interface Props {}

const StationsConfiEqualizerBody: FC<Props> = ({}) => {
    const dispatch = useAppDispatch();

    const { maxTierTwoWalkDistance, maxWalkDistance } = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm
    );

    const isFirstRender = useIsFirstRender();

    const { runOpt } = useOptimizationRunner();

    React.useEffect(() => {
        if (isFirstRender) return;

        const timeout = setTimeout(async () => {
            if (maxWalkDistance === 0) {
                // should do routes optimization
                dispatch(setStageAction({ stage: Stages.Summary }));
                await runOpt({
                    isQuickRefetch: true,
                    isStationsOptimization: false,
                });
                return;
            }

            // stay in stations optimization
            dispatch(optiSliceActions.scSetIsQuickDataLoading(true));

            dispatch(optiSliceActions.unselectAllStations());

            await runOpt({
                isQuickRefetch: true,
                isStationsOptimization: true,
            });
        }, 1000);

        return () => clearTimeout(timeout);
    }, [maxTierTwoWalkDistance, maxWalkDistance]);

    return (
        <div style={{ width: '100%' }}>
            <StationsOptiParams styles={{ tierTwoCont: { marginTop: '12px' } }} />
        </div>
    );
};

export default StationsConfiEqualizerBody;

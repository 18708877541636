import React from 'react';

const StageContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            {children}
        </div>
    );
};

export const stageContBaseStyle = {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
} as const;

export default StageContainer;

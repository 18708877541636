import axios, { AxiosResponse } from 'axios';

export const createPayload = (data: any): object => {
    const res = Object.keys(data).reduce((acc: any, curr: any): any => {
        acc[`$${curr}`] = data[curr];
        return acc;
    }, {});
    return res;
};
export const temp = '';

// mock axios request, resolve after 2 seconds with the payload
export const mockAxios = <T>(
    dataMock: T
): Promise<{
    data: T;
}> => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({ data: dataMock });
        }, 2000);
    });
};

export const convertParamsToXFormUrlEncoded = <T extends Record<string, any>>(rawParams: T) => {
    const params = new URLSearchParams();

    // Convert parameters to x-www-form-urlencoded
    for (const key in rawParams) {
        if (Object.prototype.hasOwnProperty.call(rawParams, key)) {
            params.append(key, rawParams[key]);
        }
    }

    return params;
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from 'src/api/loginApi';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    setClientCurrentMonthCredit,
    setClientToken,
    setClientTokenExpirationByMinutes,
    setGeneralAdditionalCredit,
    setIsAuth,
} from 'src/store/slices/authSlice';
import { storageUtils, STORAGE_KEYS } from 'src/utils/storage.utils';
import { getExpirationDateByMinutes, texts } from './LoginBox';

export const useSubmitLogin = ({
    setIsSecondLoading,
    code,
}: {
    setIsSecondLoading: React.Dispatch<React.SetStateAction<boolean>>;
    code: string;
}) => {
    const dispatch = useAppDispatch();
    const clientPhone = useAppSelector((state) => state.auth.clientPhone);
    const twoFactorToken = useAppSelector((state) => state.auth.twoFactorToken);

    const dispatchAlert = useDispatchAlert();

    const navigate = useNavigate();

    const onLogin = async () => {
        setIsSecondLoading(true);

        if (clientPhone && twoFactorToken) {
            try {
                const res = await login({
                    $phoneNumber: clientPhone,
                    $twoFactorCode: code,
                    $twoFactorToken: twoFactorToken,
                });

                if (res && res.data) {
                    if (res.data.isSuccess) {
                        dispatch(setClientToken(res.data.data.clientToken));
                        dispatch(setClientCurrentMonthCredit(res.data.data.currentMonthCredit));
                        dispatch(
                            setClientTokenExpirationByMinutes(
                                getExpirationDateByMinutes(res.data.data.expirationByMinutes)
                            )
                        );
                        dispatch(setGeneralAdditionalCredit(res.data.data.generalAdditionalCredit));
                        dispatch(setIsAuth(true));
                        storageUtils.sessionStorage.setItem(STORAGE_KEYS.AUTH, {
                            ...res.data.data,
                            clientPhone,
                        });
                        navigate('/');
                        return;
                    }

                    // -- handling errors
                    if (res.data.errorCode === 1) {
                        // wrong parameter
                        dispatchAlert('error', 'פרמטר שגוי', true);
                    } else if (res.data.errorCode === 2) {
                        // phone not recognized
                        dispatchAlert('error', 'לא זוהה מספר הטלפון', true);
                    } else if (res.data.errorCode === 3) {
                        // interface not found or not active
                        dispatchAlert('error', 'ממשק לא נמצא או לא פעיל', true);
                    } else if (res.data.errorCode === 4) {
                        // 2FA CODE INVALID
                        dispatchAlert('error', 'קוד שגוי', true);
                    } else if (res.data.errorCode === 6) {
                        // general error
                        dispatchAlert('error', texts.commonError, true);
                    } else if (res.data.errorCode === 9) {
                        // 2FA CODE INVALID
                        dispatchAlert(
                            'error',
                            'הוקלד קוד שגוי מספר רב של פעמים, ניתן לנסות שוב בעוד 5 דקות',
                            true
                        );
                    } else {
                        dispatchAlert('error', texts.commonError, true);
                    }
                } else {
                    dispatchAlert('error', texts.commonError, true);
                }

                setIsSecondLoading(false);
            } catch (error) {
                console.log(error);

                dispatchAlert('error', texts.commonError, true);
                setIsSecondLoading(false);
            }
        }
    };

    return {
        onLogin,
    };
};

/* eslint-disable no-debugger */
import { uuid } from 'src/store/helpers';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { optimizationsDataSelector } from 'src/store/slices/optimizationsSlice';
import { setCarsForPricing } from 'src/store/slices/summaryStageSlice';
import { PrevOptimizationTypes } from 'src/constants/common';
import { useCarInputsUtils } from 'src/hooks/useCarInputsUtils';
import { createInitialCarInputItem, setCarInputsValues } from 'src/store/slices/carsStageSlice';
import { CarInputItem } from 'src/types/globalTypes';
import { genericStationsCar } from '../stages/CarTypesPricing/utils';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';
import { getPrevOptimizationType } from 'src/utils/utilis.opti';

export const useSyncCarInputsWithCurrFlow = () => {
    const dispatch = useAppDispatch();

    const { hasStationsFlow, isRoutesOnlyFlow } = useSelectedOptiFlowType();

    const routesSimResult = useAppSelector((state) => optimizationsDataSelector(state).getSimulationResult);

    const stationsSimResult = useAppSelector(
        (state) => optimizationsDataSelector(state).getStationsSimulationResult
    );

    const globalCarInputsSync = useCarInputsUtils();

    const setCarInputsValueOnAllSystem = (carInputsValues: CarInputItem[]) => {
        dispatch(setCarInputsValues(carInputsValues));
        dispatch(setCarsForPricing(carInputsValues));
        globalCarInputsSync.saveCarInputsToLocalStorage(carInputsValues);
    };

    const syncCarInputsWithSelectedFlow = () => {
        const carsInputsInLS = globalCarInputsSync.getCarsInputsFromLocalStorage();

        // -- handling prev opti --------------------------------------------------------
        if (routesSimResult.data || stationsSimResult.data) {
            let currSimOptiType = -1;
            const currOptiData = routesSimResult.data || stationsSimResult.data;

            // debugger;
            if (routesSimResult.data) {
                currSimOptiType = getPrevOptimizationType(routesSimResult.data);
            } else if (stationsSimResult.data) {
                currSimOptiType = getPrevOptimizationType(stationsSimResult.data);
            }

            if (hasStationsFlow && currSimOptiType !== PrevOptimizationTypes.RoutesOnly) {
                // stations opti with stations flow - match
                if (carsInputsInLS?.some((c) => !c.isGeneric)) {
                    // invalid cars on LS
                    const carInputsValues: CarInputItem[] = [{ ...genericStationsCar, inputId: uuid() }];

                    setCarInputsValueOnAllSystem(carInputsValues);
                }

                return;
            }

            if (isRoutesOnlyFlow && currSimOptiType === PrevOptimizationTypes.RoutesOnly) {
                if (carsInputsInLS?.some((c) => c.isGeneric)) {
                    // invalid cars on LS
                    const carTypesUsedOnCurrOpti = globalCarInputsSync.convertCarTypesToCarInputs(
                        currOptiData?.requestBody.carsType || []
                    );

                    setCarInputsValueOnAllSystem(carTypesUsedOnCurrOpti);
                }
                return;
            }

            //  handling mismatch between prev opt flow and new selected flow
            if (hasStationsFlow && currSimOptiType === PrevOptimizationTypes.RoutesOnly) {
                // routes to stations fix
                const carInputsValues: CarInputItem[] = [{ ...genericStationsCar, inputId: uuid() }];

                setCarInputsValueOnAllSystem(carInputsValues);
                return;
            }

            if (isRoutesOnlyFlow && currSimOptiType !== PrevOptimizationTypes.RoutesOnly) {
                // routes flow with stations actual opti
                if (carsInputsInLS?.some((c) => c.isGeneric)) {
                    const carInputsValues = [{ ...createInitialCarInputItem() }];
                    setCarInputsValueOnAllSystem(carInputsValues);
                }

                return;
            }
        }
        // ----------------------------------------------------------------------------

        // -- handling new opti ----------------------------------------------------------------------------
        if (!routesSimResult.data && !stationsSimResult.data) {
            if (hasStationsFlow) {
                const carInputsValues: CarInputItem[] = [{ ...genericStationsCar, inputId: uuid() }];

                setCarInputsValueOnAllSystem(carInputsValues);

                return;
            }

            if (isRoutesOnlyFlow) {
                if (carsInputsInLS && !carsInputsInLS.some((c) => c.isGeneric)) {
                    // values in LS are routes opti
                    return; // because we dont want to modify them
                }

                const carInputsValues = [{ ...createInitialCarInputItem() }];

                setCarInputsValueOnAllSystem(carInputsValues);
            }
        }
        // ----------------------------------------------------------------------------
    };

    return {
        syncCarInputsWithSelectedFlow,
    };
};

import React from 'react';
import useOptimizationRunner from '../useOptimizationRunner';
import {
    MIN_VEHICLE_CAPACITY,
    MAX_TRAVEL_TIME_VALUE,
    MIN_TRAVEL_TIME_VALUE,
} from '../../OptimizationParameters/OptimizationParameters';

export const useEqualizerOptRunner = ({
    maxValidCapacity,
    minValidCapacity,
}: {
    maxValidCapacity: number;
    minValidCapacity: number;
}) => {
    // ------------------------------- opt runner -------------------------------
    const { runOpt } = useOptimizationRunner();

    const [delayedRunOptTimeout, setDelayedRunOptTimeout] = React.useState<NodeJS.Timeout | null>(null);

    const runOptIfValid = (updatedMaxTravelTime: number, updatedCapacity: number) => {
        if (
            // is not valid maxTravelTime value
            updatedMaxTravelTime < MIN_TRAVEL_TIME_VALUE ||
            updatedMaxTravelTime > MAX_TRAVEL_TIME_VALUE ||
            // is not valid updatedVehicleCapacity value
            updatedCapacity < minValidCapacity ||
            updatedCapacity > maxValidCapacity
        )
            return;

        if (delayedRunOptTimeout) clearTimeout(delayedRunOptTimeout);

        const timeout = setTimeout(() => {
            runOpt({
                payloadOverride: {
                    maxValueLimitInRoute: updatedMaxTravelTime,
                    passengerLimitInVehicle: updatedCapacity,
                },
            });
        }, 500);

        setDelayedRunOptTimeout(timeout);
    };

    React.useEffect(() => {
        return () => {
            delayedRunOptTimeout && clearTimeout(delayedRunOptTimeout);
        };
    }, []);

    return {
        runOptIfValid,
    };
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function EmptyDocuments({ fill = '#2196F3' }) {
    return (
        <svg width="242" height="236" viewBox="0 0 242 236" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_9083_220619)">
<path d="M153.632 53.0754H88.424C86.938 53.0772 85.5134 53.6677 84.4627 54.7175C83.4119 55.7672 82.8208 57.1905 82.819 58.6751V203.465L82.0717 203.693L66.075 208.587C65.3169 208.818 64.498 208.739 63.7982 208.367C63.0983 207.996 62.5747 207.362 62.3422 206.605L14.7593 51.3245C14.5278 50.5671 14.6067 49.7488 14.9788 49.0495C15.3508 48.3502 15.9856 47.827 16.7435 47.5951L41.3944 40.0541L112.858 18.2003L137.509 10.6593C137.884 10.5439 138.278 10.5036 138.669 10.5407C139.06 10.5777 139.439 10.6914 139.786 10.8752C140.133 11.0589 140.44 11.3092 140.69 11.6117C140.939 11.9141 141.127 12.2629 141.242 12.6379L153.405 52.3287L153.632 53.0754Z" fill="#F2F2F2"/>
<path d="M167.861 52.3299L153.202 4.49328C152.958 3.69623 152.559 2.95501 152.028 2.31196C151.498 1.66892 150.845 1.13666 150.109 0.745595C149.372 0.354532 148.565 0.112327 147.735 0.03283C146.904 -0.046667 146.066 0.0381019 145.269 0.282284L110.611 10.8807L39.1512 32.7383L4.49366 43.3404C2.88365 43.8344 1.53557 44.9463 0.745319 46.4322C-0.0449332 47.9181 -0.212794 49.6565 0.27859 51.2659L50.3798 214.752C50.779 216.051 51.5845 217.188 52.678 217.996C53.7715 218.805 55.0955 219.241 56.4558 219.243C57.0853 219.243 57.7113 219.149 58.3128 218.963L82.0705 211.698L82.8179 211.467V210.686L82.0705 210.914L58.0924 218.25C56.6712 218.683 55.1363 218.535 53.8243 217.838C52.5122 217.142 51.5302 215.954 51.0936 214.535L0.996183 51.0457C0.779863 50.3427 0.704471 49.604 0.774318 48.8718C0.844166 48.1397 1.05788 47.4285 1.40323 46.779C1.74858 46.1295 2.21878 45.5545 2.7869 45.0868C3.35503 44.6191 4.00991 44.268 4.71406 44.0535L39.3716 33.4513L110.832 11.5975L145.489 0.995323C146.023 0.832482 146.579 0.749453 147.137 0.748933C148.335 0.751622 149.501 1.13761 150.464 1.85037C151.427 2.56313 152.137 3.5652 152.488 4.70981L167.08 52.3299L167.311 53.0765H168.089L167.861 52.3299Z" fill="#494949"/>
<path d="M45.8422 47.7171C45.122 47.7166 44.4209 47.4856 43.8418 47.0578C43.2627 46.63 42.8361 46.0281 42.6244 45.3404L37.8115 29.6343C37.6821 29.2124 37.6373 28.7692 37.6794 28.33C37.7215 27.8908 37.8499 27.4642 38.0571 27.0745C38.2643 26.6848 38.5463 26.3398 38.887 26.059C39.2277 25.7783 39.6204 25.5673 40.0427 25.4382L105.785 5.33003C106.638 5.07003 107.559 5.1587 108.347 5.57659C109.134 5.99448 109.724 6.70743 109.986 7.55898L114.798 23.2652C115.059 24.1172 114.97 25.0376 114.551 25.8243C114.133 26.6109 113.42 27.1998 112.567 27.4615L46.8247 47.5697C46.5064 47.6673 46.1752 47.717 45.8422 47.7171Z" fill="#2196F3"/>
<path d="M71.0534 16.7822C75.1808 16.7822 78.5267 13.4394 78.5267 9.31591C78.5267 5.19239 75.1808 1.84961 71.0534 1.84961C66.926 1.84961 63.5801 5.19239 63.5801 9.31591C63.5801 13.4394 66.926 16.7822 71.0534 16.7822Z" fill="#2196F3"/>
<path d="M71.0546 14.0437C73.6682 14.0437 75.787 11.9269 75.787 9.31578C75.787 6.70464 73.6682 4.58789 71.0546 4.58789C68.441 4.58789 66.3223 6.70464 66.3223 9.31578C66.3223 11.9269 68.441 14.0437 71.0546 14.0437Z" fill="white"/>
<path d="M225.184 217.334H98.8852C98.0431 217.333 97.2358 216.998 96.6403 216.403C96.0449 215.809 95.71 215.002 95.709 214.161V62.9681C95.7099 62.1268 96.0449 61.3202 96.6403 60.7254C97.2357 60.1305 98.0431 59.7959 98.8852 59.7949H225.184C226.027 59.7959 226.834 60.1305 227.429 60.7254C228.025 61.3203 228.36 62.1268 228.361 62.9681V214.161C228.36 215.002 228.025 215.808 227.429 216.403C226.834 216.998 226.027 217.333 225.184 217.334Z" fill="#EEEEEE"/>
<path d="M167.079 52.3281H88.4226C86.7386 52.3305 85.1243 52.9999 83.9335 54.1896C82.7427 55.3792 82.0727 56.9921 82.0703 58.6745V210.912L82.8176 210.685V58.6745C82.8195 57.1899 83.4106 55.7666 84.4613 54.7169C85.5121 53.6671 86.9367 53.0766 88.4226 53.0748H167.311L167.079 52.3281ZM235.647 52.3281H88.4226C86.7386 52.3305 85.1243 52.9999 83.9335 54.1896C82.7427 55.3792 82.0727 56.9921 82.0703 58.6745V229.653C82.0727 231.335 82.7427 232.948 83.9335 234.138C85.1243 235.327 86.7386 235.997 88.4226 235.999H235.647C237.331 235.997 238.946 235.327 240.136 234.138C241.327 232.948 241.997 231.335 242 229.653V58.6745C241.997 56.9921 241.327 55.3792 240.136 54.1896C238.946 52.9999 237.331 52.3305 235.647 52.3281ZM241.252 229.653C241.25 231.137 240.659 232.561 239.609 233.61C238.558 234.66 237.133 235.251 235.647 235.252H88.4226C86.9367 235.251 85.5121 234.66 84.4613 233.61C83.4106 232.561 82.8195 231.137 82.8176 229.653V58.6745C82.8195 57.1899 83.4106 55.7666 84.4613 54.7169C85.5121 53.6671 86.9367 53.0766 88.4226 53.0748H235.647C237.133 53.0766 238.558 53.6671 239.609 54.7169C240.659 55.7666 241.25 57.1899 241.252 58.6745V229.653Z" fill="#494949"/>
<path d="M196.413 68.7549H127.658C126.766 68.7539 125.911 68.3996 125.281 67.7697C124.651 67.1399 124.296 66.2858 124.295 65.3951V48.9692C124.296 48.0784 124.651 47.2244 125.281 46.5946C125.912 45.9647 126.766 45.6104 127.658 45.6094H196.413C197.304 45.6104 198.159 45.9647 198.789 46.5946C199.42 47.2244 199.775 48.0784 199.776 48.9692V65.3951C199.775 66.2858 199.42 67.1399 198.789 67.7697C198.159 68.3996 197.304 68.7539 196.413 68.7549Z" fill="#2196F3"/>
<path d="M162.036 46.7295C166.163 46.7295 169.509 43.3867 169.509 39.2632C169.509 35.1397 166.163 31.7969 162.036 31.7969C157.908 31.7969 154.562 35.1397 154.562 39.2632C154.562 43.3867 157.908 46.7295 162.036 46.7295Z" fill="#2196F3"/>
<path d="M162.036 43.8102C164.55 43.8102 166.588 41.7741 166.588 39.2625C166.588 36.7509 164.55 34.7148 162.036 34.7148C159.522 34.7148 157.484 36.7509 157.484 39.2625C157.484 41.7741 159.522 43.8102 162.036 43.8102Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_9083_220619">
<rect width="242" height="236" fill="white"/>
</clipPath>
</defs>
</svg>

    );
}

export default EmptyDocuments;

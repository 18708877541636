import { uuid } from 'src/store/helpers';
import { CarType } from 'src/components/stages/Summary/types';
import { DEFAULT_MAX_VEHICLE_CAPACITY } from 'src/store/slices/optimizationsSlice';

export const defaultCarIds = {
    taxi: 'taxi-id-1',
    minibus1: 'minibus-id-1', // 10 רכב
    minibus2: 'minibus-id-2', // 14 רכב
    minibus3: 'minibus-id-3', // 16 רכב
    minibus4: 'minibus-id-4', // 20 רכב
    bus: 'bus-id-1', // 50 רכב
    other: 'other-id-1',
    generic: 'generic-id-1',
} as const;

export const genericStationsCar = {
    capacity: DEFAULT_MAX_VEHICLE_CAPACITY,
    carName: 'כללי',
    pricePerKm: 0,
    pricePerHour: 0,
    carId: defaultCarIds.generic,
    isGeneric: true,
};

export const defaultCarsTypes: CarType[] = [
    { ...genericStationsCar },
    {
        capacity: 4,
        carName: 'מונית',
        pricePerKm: 0,
        pricePerHour: 0,
        carId: defaultCarIds.taxi,
    },
    {
        capacity: 20,
        carName: 'מיניבוס',
        pricePerKm: 0,
        pricePerHour: 0,
        carId: defaultCarIds.minibus1,
    },
    {
        capacity: 35,
        carName: 'מידיבוס',
        pricePerKm: 0,
        pricePerHour: 0,
        carId: defaultCarIds.minibus2,
    },
    {
        capacity: 55,
        carName: 'אוטובוס',
        pricePerKm: 0,
        pricePerHour: 0,
        carId: defaultCarIds.bus,
    },
    {
        capacity: 1,
        carName: 'אחר',
        pricePerKm: 0,
        pricePerHour: 0,
        carId: defaultCarIds.other,
    },
];

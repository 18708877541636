/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function CloudUploadIcon({ width = '28', height = '28', color = '#494949' }) {
    return (
<svg width="35" height="24.5" viewBox="0 0 57 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5325 40.4999C9.84706 40.4999 6.70349 39.2195 4.10175 36.6589C1.50006 34.0982 0.199219 30.9751 0.199219 27.2897C0.199219 24.0282 1.24366 21.1273 3.33255 18.5871C5.42144 16.047 8.0864 14.5445 11.3274 14.0795C12.1821 10.0932 14.1864 6.83333 17.3402 4.3C20.494 1.76667 24.1137 0.5 28.1992 0.5C33.0231 0.5 37.1179 2.18291 40.4837 5.54873C43.8495 8.91451 45.5324 13.0094 45.5324 17.8333V19.1667H46.353C49.1632 19.3855 51.5068 20.5154 53.3837 22.5564C55.2606 24.5974 56.1991 27.023 56.1991 29.8333C56.1991 32.8076 55.1649 35.3289 53.0966 37.3973C51.0282 39.4657 48.5068 40.4999 45.5325 40.4999H30.1991V21.0742L33.753 24.5871C34.1223 24.9563 34.5795 25.1409 35.1248 25.1409C35.6701 25.1409 36.141 24.9426 36.5376 24.5461C36.9239 24.1768 37.1128 23.7084 37.1042 23.1409C37.0957 22.5734 36.9068 22.1051 36.5376 21.7359L29.8863 15.0846C29.4042 14.6026 28.8419 14.3615 28.1992 14.3615C27.5564 14.3615 26.9941 14.6026 26.512 15.0846L19.8608 21.7359C19.4915 22.1051 19.2958 22.5666 19.2736 23.1205C19.2513 23.6743 19.4471 24.1495 19.8608 24.5461C20.23 24.9153 20.6915 25.1024 21.2453 25.1076C21.7992 25.1127 22.2744 24.9306 22.6709 24.5614L26.1992 21.0742V40.4999H13.5325Z" fill="#FEB42B"/>
</svg>

    );
}

export default CloudUploadIcon;

import { GoogleMap } from '@react-google-maps/api';
import React, { useRef, useState } from 'react';
import { defaultCenter } from 'src/components/MapType2';
import { IOptimumRouteData, IOptimumWaypoint } from 'src/types/optimization/types';
import { Coords, fitBounds } from 'google-map-react';

export const useRoutesSummaryMap = (selectedRoutes: IOptimumRouteData[], selectedRoutesUuids: string[]) => {
    const [zoom, setZoom] = useState<number>(8);
    const [center, setCenter] = useState<Coords>(defaultCenter);

    const [map, setMap] = useState<google.maps.Map | null>(null);

    const mapDivRef = useRef<HTMLDivElement | null>(null);

    // const { rendererProps } = useSimulationDirections(selectedRoutes);

    const GoogleMapProps: GoogleMap['props'] = {
        center,
        onZoomChanged(this: google.maps.Map) {
            try {
                const newZoom = this.getZoom();
                if (newZoom) {
                    setZoom(newZoom);

                }
            } catch (error) {
                console.log(error);
            }
        },
        options: {
            fullscreenControl: false,
        },
        onLoad: (googleMap) => {
            // Saw this on official docs, not doing anything with it yet
            setMap(googleMap);
        },
        onUnmount: () => {
            // Saw this on official docs, not doing anything with it yet
            setMap(null);
        },
    };

    if (zoom) {
        try {
            // Is supposedly readonly, however the top-view button is not working without this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            GoogleMapProps.zoom = zoom;
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        const allWayPointsSelected: IOptimumWaypoint[] = [];

        if (selectedRoutes) {
            selectedRoutes.forEach((route) => {
                allWayPointsSelected.push(...route.optimumRoute);
            });
        }

        if (allWayPointsSelected.length > 1 && selectedRoutes && mapDivRef.current) {
            // Calculate the bounding box of the selected waypoints
            const bounds = allWayPointsSelected.reduce((acc, { lat, lng }) => {
                const latLng = new google.maps.LatLng(lat, lng);
                const coords: Coords = { lat: latLng.lat(), lng: latLng.lng() };
                return acc.extend(coords);
            }, new google.maps.LatLngBounds());

            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            const neCoords: Coords = {
                lat: ne.lat(),
                lng: ne.lng(),
            };

            const swCoords: Coords = {
                lat: sw.lat(),
                lng: sw.lng(),
            };

            const rect = mapDivRef.current.getBoundingClientRect();

            const fittedBounds = fitBounds(
                { ne: neCoords, sw: swCoords },
                { width: rect.width, height: rect.height }
            );

            setCenter(fittedBounds.center);
            setZoom(fittedBounds.zoom - 1);
        }
    }, [selectedRoutesUuids]);

    return {
        GoogleMapProps,
        // rendererProps,
        map,
        mapDivRef,
        zoom
    };
};

import { OptimizationHistory } from 'src/api/optimizationApi';
import { PrevOptimizationTypes } from 'src/constants/common';
import { ISimulationResult } from 'src/types/optimization/types';

export const getPrevOptimizationType = (res: ISimulationResult) => {
    if (res.requestBody.optimizationHistory === OptimizationHistory.BothStationsAndRoutesOpt)
        return PrevOptimizationTypes.StationAndRoutes;
    if (res.requestBody.optimizationHistory === OptimizationHistory.OnlyStationsOpt)
        return PrevOptimizationTypes.Stations;
    if (res.requestBody.optimizationHistory === OptimizationHistory.OnlyRoutesOpt)
        return PrevOptimizationTypes.RoutesOnly;

    if (!res.requestBody.optimizationHistory) {
        // handling prev opti from old versions
        if (res.requestBody.wayPointsJson.length === 0) return PrevOptimizationTypes.Stations;
        if (res.requestBody.wayPointsJson.length === res.requestBody.wayPointsJsonForStations.length) {
            return PrevOptimizationTypes.RoutesOnly;
        }
        return PrevOptimizationTypes.StationAndRoutes;
    }

    return -1;
};

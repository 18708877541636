import React, { useEffect, useState } from 'react';
import NumberSlider from 'src/components/ParametersStage/NumberSlider';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    setVehicleCapacityAction,
    setMaxTravelTimeAction,
    setMaxStationsCount,
} from 'src/store/slices/optimizationsSlice';
import {
    MIN_VEHICLE_CAPACITY,
    MAX_TRAVEL_TIME_VALUE,
    MIN_TRAVEL_TIME_VALUE,
    MAX_STATIONS_COUNT,
} from '../../OptimizationParameters/OptimizationParameters';
import { isStringNumber } from 'src/store/helpers';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useIsFirstRender } from 'src/hooks/useIsFirstRender';
import { useEqualizerOptRunner } from './useEqualizerOptRunner';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';
import { AdvancedParamsSection } from './AdvancedParamsSection';

export const EqualizerParametersSliders: React.FC<{}> = () => {
    const dispatch = useAppDispatch();
    const dispatchAlert = useDispatchAlert();
    const isFirstRender = useIsFirstRender();

    const {
        maxTravelTime,
        vehicleCapacity,
        maxVehicleCapacity: maxValidVehicleCapacity,
        maxStationsCountVal,
    } = useAppSelector((state) => optimizationsRootSelector(state).ui.parametersForm);

    const { hasStationsFlow } = useSelectedOptiFlowType();

    const getCapacitySliderValAsRTL = hasStationsFlow ? true : false;

    // -- capacity logic
    const capacity = hasStationsFlow ? maxStationsCountVal : vehicleCapacity;
    const maxValidCapacity = hasStationsFlow ? MAX_STATIONS_COUNT : maxValidVehicleCapacity;
    const minValidCapacity = hasStationsFlow ? MIN_VEHICLE_CAPACITY : MIN_VEHICLE_CAPACITY;

    const dispatchSetCapacityActionByFlow = (updatedCapacity: number, restPayload = {}) => {
        if (hasStationsFlow) {
            dispatch(setMaxStationsCount(updatedCapacity));
            return;
        }

        dispatch(
            setVehicleCapacityAction({
                vehicleCapacity: updatedCapacity,
                ...restPayload,
            })
        );
    };
    const [capacityInputText, setCapacityInputText] = useState<number>(capacity);
    const capacityToRTL = (ltrVal: number) => maxValidCapacity - ltrVal + minValidCapacity;
    // -----------------

    const [maxTravelTimeInputText, setMaxTravelTimeInputText] = useState<number>(maxTravelTime);

    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

    const { runOptIfValid } = useEqualizerOptRunner({ maxValidCapacity, minValidCapacity });

    const travelTimeToRTL = (ltrVal: number) => MAX_TRAVEL_TIME_VALUE - ltrVal + MIN_TRAVEL_TIME_VALUE;

    const handleCapacitySliderChange = (event: Event, sliderVal: number | number[], activeThumb: number) => {
        if (typeof sliderVal === 'number') {
            const sliderValAsRTL = getCapacitySliderValAsRTL ? sliderVal : capacityToRTL(sliderVal);

            setCapacityInputText(sliderValAsRTL);

            dispatchSetCapacityActionByFlow(sliderVal, {
                maxValue: maxValidCapacity,
                minValue: minValidCapacity,
            });
        }
    };

    const handleCapacityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (!isStringNumber(inputValue)) return;
        setCapacityInputText(Number(inputValue));

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const newTimeout = setTimeout(() => {
            dispatchSetCapacityActionByFlow(Number(inputValue));

            runOptIfValid(maxTravelTime, Number(inputValue));
        }, 1000); // Adjust the delay as needed

        setTypingTimeout(newTimeout);
    };

    // -------------------------- max travel time input handlers -------------------------------
    const handleMaxTravelTimeSliderChange = (
        event: Event,
        valLTR: number | number[],
        activeThumb: number
    ) => {
        // setIsSliderFocused(true);
        if (typeof valLTR === 'number') {
            const valRTL = travelTimeToRTL(valLTR);

            setMaxTravelTimeInputText(valRTL);
            dispatch(
                setMaxTravelTimeAction({
                    maxTravelTime: valLTR,
                    maxValue: MAX_TRAVEL_TIME_VALUE,
                    minValue: MIN_TRAVEL_TIME_VALUE,
                })
            );
        }
    };

    const handleMaxTravelTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (!isStringNumber(inputValue)) return;

        setMaxTravelTimeInputText(Number(inputValue));

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const newTimeout = setTimeout(() => {
            dispatch(setMaxTravelTimeAction({ maxTravelTime: Number(inputValue) }));
            runOptIfValid(Number(inputValue), capacity);
        }, 1000); // Adjust the delay as needed

        setTypingTimeout(newTimeout);
    };
    // -------------------------- / Max travel time input handlers -------------------------------
    useEffect(() => {
        // input validation debounce
        return () => {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }
        };
    }, [typingTimeout]);

    useEffect(() => {
        if (!isFirstRender) {
            const validateMaxTravelTime = () => {
                if (maxTravelTime < MIN_TRAVEL_TIME_VALUE) {
                    dispatch(setMaxTravelTimeAction({ maxTravelTime: MIN_TRAVEL_TIME_VALUE }));
                    setMaxTravelTimeInputText(MIN_TRAVEL_TIME_VALUE);
                    dispatchAlert(
                        'error',
                        'זמן הנסיעה המינימלי הינו' + ' ' + MIN_TRAVEL_TIME_VALUE + ' ' + 'דקות',
                        true
                    );
                    runOptIfValid(MIN_TRAVEL_TIME_VALUE, capacity);
                }

                if (maxTravelTime > MAX_TRAVEL_TIME_VALUE) {
                    dispatch(setMaxTravelTimeAction({ maxTravelTime: MAX_TRAVEL_TIME_VALUE }));
                    setMaxTravelTimeInputText(MAX_TRAVEL_TIME_VALUE);
                    dispatchAlert(
                        'error',
                        'זמן הנסיעה המקסימלי הינו' + ' ' + MAX_TRAVEL_TIME_VALUE + ' ' + 'דקות',
                        true
                    );
                    runOptIfValid(MAX_TRAVEL_TIME_VALUE, capacity);
                }
            };

            const timeout = setTimeout(validateMaxTravelTime, 500);
            return () => clearTimeout(timeout);
        }
    }, [maxTravelTime]);

    //
    useEffect(() => {
        if (!isFirstRender) {
            const getAlertText = (type: 'min' | 'max') => {
                if (type === 'min') {
                    const prefix = hasStationsFlow
                        ? 'כמות החתנות המינימלית הינה'
                        : 'כמות הנוסעים המינימלית הינה';
                    return `${prefix} ${minValidCapacity}`;
                }
                const prefix = hasStationsFlow ? 'כמות החתנות המקסימלית הינה' : 'כמות הנוסעים המקסימלית הינה';
                return `${prefix} ${maxValidCapacity}`;
            };

            const validateCapacity = () => {
                if (capacity < minValidCapacity) {
                    dispatchSetCapacityActionByFlow(minValidCapacity);
                    setCapacityInputText(minValidCapacity);
                    dispatchAlert('error', `${getAlertText('min')}`, true);
                    runOptIfValid(maxTravelTime, minValidCapacity);
                }

                if (capacity > maxValidCapacity) {
                    dispatchSetCapacityActionByFlow(maxValidCapacity);
                    setCapacityInputText(maxValidCapacity);
                    dispatchAlert('error', getAlertText('max'), true);
                    runOptIfValid(maxTravelTime, maxValidCapacity);
                }
            };

            const timeout = setTimeout(validateCapacity, 500);
            return () => clearTimeout(timeout);
        }
    }, [capacity]);

    return (
        <>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <div
                    style={{
                        marginBottom: '10px',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#242424',
                        marginRight: '15px',
                    }}
                >
                    משך נסיעה מקס` (בדקות)
                </div>
                <NumberSlider
                    handleInputChange={handleMaxTravelTimeInputChange}
                    handleSliderChange={handleMaxTravelTimeSliderChange}
                    value={maxTravelTimeInputText}
                    handleSliderDragEnd={(e, ltrVal) => {
                        if (typeof ltrVal === 'number') runOptIfValid(travelTimeToRTL(ltrVal), capacity);
                    }}
                    max={MAX_TRAVEL_TIME_VALUE}
                    min={10}
                    step={5}
                    containerStyle={{ width: '100%' }}
                />
            </div>

            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    padding: '0 0 10px 0',
                    borderBottom: '1px solid #e0e0e0',
                }}
            >
                <div
                    style={{
                        marginBottom: '10px',
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#242424',
                        marginRight: '15px',
                    }}
                >
                    {hasStationsFlow ? 'מקסימום תחנות במסלול' : 'מקסימום נוסעים ברכב'}
                </div>
                <NumberSlider
                    handleInputChange={handleCapacityInputChange}
                    handleSliderChange={handleCapacitySliderChange}
                    handleSliderDragEnd={(e, val) => {
                        if (typeof val === 'number')
                            runOptIfValid(
                                maxTravelTime,
                                getCapacitySliderValAsRTL ? val : capacityToRTL(val)
                            );
                    }}
                    value={capacityInputText}
                    max={maxValidCapacity}
                    min={minValidCapacity}
                    step={1}
                    containerStyle={{ width: '100%' }}
                    parseToRTL={getCapacitySliderValAsRTL}
                />
                <AdvancedParamsSection />
            </div>
        </>
    );
};

// useEffect(() => {
//     // PURPOSE - re-running opt once inputs change
//     // the reason the deps have the input text instead of the value on the store is
//     // because of bugs, they change unexpectedly
//     console.log('isSliderFocus', someSliderFocused);
//     // we dont want to call the api if user still changing the slider, only after released
//     if (isFirstRender || someSliderFocused) return;
//     const timeout = setTimeout(() => {
//         runOpt({});
//     }, 500);
//     return () => clearTimeout(timeout);
// }, [maxTravelTimeInputText, maxVehicleInputText, someSliderFocused]);
// useEffect(() => {
//     setMaxVehicleInputText(vehicleCapacity);
//     setMaxTravelTimeInputText(maxTravelTime);
// }, []);

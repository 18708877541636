import React from 'react';
import { StageTitleStyled } from 'src/components/StageStyles';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';
import { CarsInputsSection } from './CarsInputsSection';

const VehicleTypesPricingStage: React.FC = () => {
    const { hasStationsFlow } = useSelectedOptiFlowType();

    return (
        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StageTitleStyled>
                {hasStationsFlow ? 'קביעת מחירון' : 'קביעת מחירון לסוגי רכבים'}
            </StageTitleStyled>
            <CarsInputsSection hasStationsFlow={hasStationsFlow} />
        </div>
    );
};

export default VehicleTypesPricingStage;

import React, { FC } from 'react';
import styled from 'styled-components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { useAppDispatch } from 'src/store/hooks';
import { t } from 'src/utils/lang';
import { flexCenter } from 'src/styles/commonStyles';
import {
    IOptimumRouteData,
    IOptimumWaypoint,
    ISimulationStationToWaypoint,
} from 'src/types/optimization/types';
import FlagIcon from '@mui/icons-material/Flag';
import { uuid } from 'src/store/helpers';
import { stringifyWaypoint } from './utils';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';

const WaypointRowItem = styled.tr`
    display: flex;
    padding: 10px 10px 10px 6px;
    border-bottom: 1px solid rgba(180, 190, 201, 0.303017);
    justify-content: space-between;
`;

const Div1 = styled.div`
    display: flex;
    font-size: 14px;
    text-align: right;
    font-weight: 400;
    align-items: center;
    gap: 8px;
`;

export const RouteWaypointsList: React.FC<{
    routeWaypoints: IOptimumRouteData['optimumRoute'];
    stationsList: ISimulationStationToWaypoint[];
}> = ({ routeWaypoints, stationsList }) => {
    const { hasStationsFlow } = useSelectedOptiFlowType();

    return (
        <table style={{}}>
            <tbody>
                {routeWaypoints.map((waypoint, i) => {
                    const passCount =
                        stationsList.find((s) => s.stationId === waypoint.waypointId)?.wayPoints.length || 0;

                    return (
                        <WaypointRowItem key={waypoint.place_id}>
                            <Div1>
                                {waypoint.isTarget ? <FlagIcon fontSize="small" /> : null}
                                {(waypoint.firstName || waypoint.lastName) && !hasStationsFlow ? (
                                    <>
                                        {waypoint.firstName} {waypoint.lastName} -{' '}
                                        {stringifyWaypoint(waypoint)}
                                    </>
                                ) : (
                                    stringifyWaypoint(waypoint)
                                )}
                            </Div1>
                            <div style={{ display: 'flex', gap: '6px' }}>
                                {passCount && !waypoint.isTarget && hasStationsFlow ? (
                                    <>
                                        <PeopleAltOutlinedIcon fontSize="small" />
                                        <span>{passCount}</span>
                                    </>
                                ) : null}
                            </div>
                        </WaypointRowItem>
                    );
                })}
            </tbody>
        </table>
    );
};

const styles = {
    footerAndHeaderBaseStyles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        minHeight: '30px',
    },
} as const;

const StyledBoxWrapper = styled.div`
    width: 340px;
    /* min-height: 285px */
    display: flex;
    flex-direction: column;
    background: white;
    overflow: hidden;
    height: 100%;
    border: 1px solid rgba(156, 156, 156, 0.277);
`;

export const EllipsisText = styled.p<{}>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const PdfWaypointsNotInRoutes = ({
    wayPointNotInRoutes,
    stationsList,
}: {
    wayPointNotInRoutes: IOptimumWaypoint[];
    stationsList: ISimulationStationToWaypoint[];
}) => {
    const dispatch = useAppDispatch();

    const data = {
        id: uuid(),
        routeName: t.waypointsNotInRoutes,
        routeWaypoints: wayPointNotInRoutes,
        distanceInKm: null,
        durationInMinutes: null,
        carName: '',
        color: 'orange',
        price: null,
        numberOfPassengersInRoute: wayPointNotInRoutes.length,
    };

    const numberOfPassengersInRoute = React.useMemo(() => {
        let count = 0;
        stationsList.forEach((s) => {
            count = count + s.wayPoints.length;
        });
        return count;
    }, [stationsList]);

    return (
        <StyledBoxWrapper>
            <div
                style={{
                    ...styles.footerAndHeaderBaseStyles,
                    borderBottom: '1px solid rgba(180, 190, 201, 0.303017)',
                }}
            >
                <div style={{ gap: '8px', ...flexCenter }}>
                    <LocationOnIcon sx={{ color: data.color }} fontSize="small" />
                    <p style={{ fontSize: '1rem', fontWeight: 'bold', padding: '6px' }}>{data.routeName}</p>
                </div>
                <div style={{ gap: '8px', paddingLeft: '8px', ...flexCenter }}>
                    <PeopleAltOutlinedIcon fontSize="small" />
                    <p>{numberOfPassengersInRoute}</p>
                </div>
            </div>
            <RouteWaypointsList routeWaypoints={data.routeWaypoints} stationsList={stationsList} />
        </StyledBoxWrapper>
    );
};

export default PdfWaypointsNotInRoutes;

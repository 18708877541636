import { useSetOptimizationSimulation } from 'src/hooks/useSetOptimizationSimulation';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    optiSliceActions,
    optimizationsRootSelector,
    optiUiSelector,
} from 'src/store/slices/optimizationsSlice';
import { setIsQuickDataLoading } from 'src/store/slices/summaryStageSlice';
import { CarType } from './types';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { t } from 'src/utils/lang';
import { SetOptimizationSimulationConfig } from 'src/api/optimizationApi';
import { useWaypointsForRunningOpti } from 'src/hooks/useWaypointsForRunningOpti';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';

export const useOptimizationRunner = () => {
    const dispatch = useAppDispatch();
    const setOptimizationSimulationHook = useSetOptimizationSimulation();
    const { getWaypointsJsons } = useWaypointsForRunningOpti();
    const { hasStationsFlow } = useSelectedOptiFlowType();

    const waypointsForOptiObject = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.waypointsForOptiObject
    );

    const selectAllRoutesChecked = useAppSelector(
        (state) => optiUiSelector(state).summaryStageExtra.selectAllRoutesChecked
    );

    const selectAllStationsChecked = useAppSelector(
        (state) => optiUiSelector(state).stationsConfiStage.selectAllStationsChecked
    );

    const dispatchAlert = useDispatchAlert();

    const targetWaypoint = useAppSelector((state) => optimizationsRootSelector(state).ui.targetWaypoint);
    const stationToWayPoints = useAppSelector(
        (state) => optimizationsRootSelector(state).data.getStationsSimulationResult.data?.stationToWayPoints
    );

    const carsForPricingOnSlice = useAppSelector((state) => state.summaryStage.carsForPricing);

    const runOpt = async ({
        carsForPricing,
        payloadOverride,
        isQuickRefetch = true,
        isStationsOptimization = false,
    }: {
        carsForPricing?: CarType[];
        payloadOverride?: Partial<SetOptimizationSimulationConfig>;
        isQuickRefetch?: boolean;
        isStationsOptimization?: boolean;
    }) => {
        try {
            dispatch(optiSliceActions.unselectAllRoutes({}));

            if (waypointsForOptiObject && waypointsForOptiObject.validWaypoints && targetWaypoint) {
                if (isQuickRefetch && !isStationsOptimization) {
                    dispatch(setIsQuickDataLoading(true));
                }

                const { defaultWaypointsArr = [], waypointsJsonForRoutesByStationsOpti = [] } =
                    getWaypointsJsons();

                if (isStationsOptimization) {
                    await setOptimizationSimulationHook.setOptimizationSimulation({
                        waypointsJson: [],
                        wayPointsJsonForStations: defaultWaypointsArr,
                        isQuickRefetch: isQuickRefetch,
                        carsForPricing: carsForPricing || carsForPricingOnSlice,
                        payloadOverride,
                        isStationsOptimization,
                        stationToWayPoints,
                        successHandlerOptions: {
                            reselectAllStations: selectAllStationsChecked === true,
                        },
                    });
                } else {
                    await setOptimizationSimulationHook.setOptimizationSimulation({
                        waypointsJson: hasStationsFlow
                            ? waypointsJsonForRoutesByStationsOpti
                            : defaultWaypointsArr,
                        wayPointsJsonForStations: defaultWaypointsArr,
                        isQuickRefetch: isQuickRefetch,
                        carsForPricing: carsForPricing || carsForPricingOnSlice,
                        payloadOverride,
                        isStationsOptimization,
                        stationToWayPoints,
                        successHandlerOptions: {
                            reselectAllRoutes: selectAllRoutesChecked === true,
                        },
                    });
                }
            } else {
                console.log('no waypoints OR no targetWaypoint');
                dispatchAlert('error', 'שגיאה', true);
                dispatch(setIsQuickDataLoading(false));
                if (isStationsOptimization) {
                    dispatch(optiSliceActions.scSetIsQuickDataLoading(false));
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message);
            }
            dispatchAlert('error', t.oopsSomethingWentWrongTryAgain, true);
        }
    };

    return { runOpt };
};

export default useOptimizationRunner;

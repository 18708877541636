import React from 'react';
import Header from 'src/components/Header';
import { useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';
import styled from 'styled-components';
import InfoFooter from 'src/components/InfoFooter';
import BaseTextField from 'src/components/TextInput';
import { Button } from '@mui/material';
import LoginBox from './components/LoginBox';

const StagesStepperContainer = styled.div`
    height: 110px;
    border: 1px solid #3853b6;
`;

const Login: React.FC = (props: {}) => {
    return (
        <ScreenContainer className="LoginContainer">
            <Header />
            <LoginBox />
            <InfoFooter />
        </ScreenContainer>
    );
};

const ScreenContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export default Login;

import { FC, useCallback } from 'react';
import { StationAccordion } from './StationAccordion';
import React from 'react';
import { ISimulationStationToWaypoint } from 'src/types/optimization/types';
import List from 'rc-virtual-list';
import { useAppSelector } from 'src/store/hooks';

interface Props {
    selectedStationsIds: string[];
    stationsWithWaypoints: ISimulationStationToWaypoint[];
}

const StationsList: FC<Props> = ({ selectedStationsIds, stationsWithWaypoints }) => {
    const filteredStations = useAppSelector(
        (state) => state.optimization.ui.stationsConfiStage.filteredStations
    );

    const [expanded, setExpanded] = React.useState<string>('');

    const toggleExpand = useCallback((stationId: string, expand: boolean) => {
        setExpanded(expand ? stationId : '');
    }, []);

    const listContRef = React.useRef<HTMLDivElement | null>(null);

    const [listHeight, setListHeight] = React.useState(430);

    React.useEffect(() => {
        if (!listContRef.current) return;
        setListHeight(listContRef.current.clientHeight);
    }, []);

    return (
        <div ref={listContRef} style={{ display: 'flex', flexDirection: 'column', height: '88%' }}>
            <List
                data={filteredStations}
                itemKey={'stationId'}
                itemHeight={66} // 50 + padding
                height={listHeight}
                style={{}}
            >
                {(station, i) => (
                    <div style={{ paddingBottom: '16px' }}>
                        <StationAccordion
                            isSelected={selectedStationsIds.includes(station.stationId)}
                            key={station.stationId}
                            stationData={station}
                            expanded={expanded === station.stationId}
                            toggleExpand={toggleExpand}
                            color={station.color}
                            index={i}
                        />
                    </div>
                )}
            </List>
        </div>
    );
};

export default StationsList;

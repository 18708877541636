import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { authRootSelector, restartAuthSlice } from 'src/store/slices/authSlice';
import { useDispatchAlert } from './useAlert';
import { restartOptimizationSliceAction } from 'src/store/slices/optimizationsSlice';
import { restartSummarySlice } from 'src/store/slices/summaryStageSlice';
import { commonSliceActions } from 'src/store/slices/commonSlice';
import { carsStageActions } from 'src/store/slices/carsStageSlice';
import { Stages } from 'src/constants/common';

export const useLogout = () => {
    const dispatch = useAppDispatch();
    const dispatchAlert = useDispatchAlert();

    const handleForceUserLogout = () => {
        dispatchAlert('error', 'אנא בצע התחברות מחדש', true);

        // resetting all slices
        dispatch(restartAuthSlice());

        dispatch(restartOptimizationSliceAction());

        dispatch(restartSummarySlice());

        dispatch(commonSliceActions.setStage({ stage: Stages.Start }));

        dispatch(carsStageActions.resetCarsStageSlice());
    };

    return {
        handleForceUserLogout,
    };
};

export const useClientTokenExpirationAuth = () => {
    const { handleForceUserLogout } = useLogout();

    const clientTokenExpirationDate = useAppSelector(
        (state) => authRootSelector(state).clientTokenExpirationDate
    );

    useEffect(() => {
        if (!clientTokenExpirationDate) return;

        const interval = setInterval(() => {
            const now = new Date();
            if (now > new Date(clientTokenExpirationDate)) {
                handleForceUserLogout();
            }
        }, 1000 * 60); // 1 minute

        return () => clearInterval(interval);
    }, [clientTokenExpirationDate]);
};

import { OverlayView } from '@react-google-maps/api';
import React from 'react';
import { uuid } from 'src/store/helpers';
import { IOptimumWaypoint, ISimulationStationToWaypoint } from 'src/types/optimization/types';
import styled from 'styled-components';

export const BaseOverlayCont = styled.div`
    background: #fff;
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #d6d6d6;
    position: relative;
`;

export const PassengerOverlay: React.FC<{ passenger: IOptimumWaypoint }> = ({ passenger }) => {
    return (
        <OverlayView
            position={{ lat: passenger.lat, lng: passenger.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            key={passenger.waypointId}
            zIndex={999}
        >
            <BaseOverlayCont style={{ left: '-120px' }}>{passenger.stationName}</BaseOverlayCont>
        </OverlayView>
    );
};

export const StationHoverOverlay: React.FC<{ station: ISimulationStationToWaypoint }> = ({ station }) => {
    return (
        <OverlayView
            position={{ lat: station.station.lat, lng: station.station.lng }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            key={station.stationId}
        >
            <BaseOverlayCont>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <span style={{ fontSize: '16px', fontWeight: 750 }}> תחנה:</span>
                    <div>{station.station.stationName}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <span style={{ fontSize: '16px', fontWeight: 750 }}> נוסעים:</span>
                    <div>{station.wayPoints.length}</div>
                </div>
            </BaseOverlayCont>
        </OverlayView>
    );
};

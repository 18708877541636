import Papa from 'papaparse';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { IBaseAddress, IOptimizationWaypoint } from 'src/types/optimization/types';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setWaypointsForOptiObject, setIsCsvLoadingAction } from 'src/store/slices/optimizationsSlice';
import { buildWaypointName, uuid } from 'src/store/helpers';
import { ENDPOINTS, getListOfWaypointsWithCoords } from 'src/api/optimizationApi';
import { setStageAction } from 'src/store/slices/commonSlice';
import { Stages } from 'src/constants/common';
import { getErrWithCode } from 'src/utils/lang';
import { logger } from 'src/api/loggerApi';

interface MyObject {
    [key: string]: any;
}

const parseHeaderItem = (header: string) => {
    // return toCamelCase(header).trim();
    return header.replace(/[^a-zA-Z]/g, '').toLowerCase();
};

const filterObjectArray = (arr: MyObject[]): MyObject[] => {
    const allowedKeys: string[] = ['city', 'housenum', 'street', 'firstname', 'lastname'];

    return arr.map((obj) => {
        const filteredObj: MyObject = {};
        Object.entries(obj).forEach(([key, value]) => {
            const parsedKey = parseHeaderItem(key);
            if (allowedKeys.includes(parsedKey)) {
                switch (parsedKey) {
                    case 'housenum':
                        filteredObj['houseNum'] = value;
                        break;

                    case 'firstname':
                        filteredObj['firstName'] = value;
                        break;

                    case 'lastname':
                        filteredObj['lastName'] = value;
                        break;

                    default:
                        filteredObj[parsedKey] = value;
                        break;
                }
            }
        });
        return filteredObj;
    });
};

const removeEmptyObjects = (arr: MyObject[]) => {
    return arr.filter((arr) => {
        return Object.values(arr).some((value) => value !== '');
    });
};

const buildAddressStringFromWaypoint = (waypoint: IOptimizationWaypoint) => {
    return `${waypoint.street ?? ''} ${waypoint.houseNum ?? ''} ${waypoint.city ?? ''}`;
};

export const useCsvReverseGeo = () => {
    const dispatch = useAppDispatch();
    const currentMonthCredit = useAppSelector((state) => state.auth.currentMonthCredit);
    const generalAdditionalCredit = useAppSelector((state) => state.auth.generalAdditionalCredit);
    const clientToken = useAppSelector((state) => state.auth.clientToken);

    const dispatchAlert = useDispatchAlert();

    const dataValidation = async (data: IBaseAddress[]) => {
        const validWaypoints: IOptimizationWaypoint[] = [];
        const invalidWaypoints: IOptimizationWaypoint[] = [];
        const unRecognizedWaypoints: IOptimizationWaypoint[] = [];

        console.log('dataaaa', data);

        if (clientToken) {
            try {
                const res = await getListOfWaypointsWithCoords({
                    clientToken,
                    baseAddresses: data,
                });

                if (res && res.data) {
                    res.data.forEach((responseResult) => {
                        // const passengerDetails = data.find()
                        // console.log('res.data', res.data);
                        if (responseResult.isSuccess) {
                            validWaypoints.push({
                                ...responseResult.waypoint,
                                waypointId: uuid(),
                                waypointName: buildWaypointName(responseResult.waypoint),
                            });
                        } else {
                            unRecognizedWaypoints.push({
                                ...responseResult.waypoint,
                                waypointId: uuid(),
                                waypointName: buildWaypointName(responseResult.waypoint),
                            });
                        }
                    });
                }
            } catch (err: any) {
                console.log('error', err);
                if (err.name === 'AxiosError') {
                    dispatchAlert(
                        'error',
                        getErrWithCode('תקלה בבדיקת הקובץ', 7454, { withSuffix: true }),
                        true
                    );
                    logger({
                        type: 'apiReqError',
                        feature: `Data loading screen, CSV reverse geo - Error with ${ENDPOINTS.MULTIPLE_REVERSE_GEO} request`,
                        details: err,
                    });
                } else {
                    dispatchAlert('error', getErrWithCode('תקלה בבדיקת הקובץ', 6341), true);
                }
            }
        } else {
            console.log('error no ClientToken');
        }

        return { validWaypoints, invalidWaypoints, unRecognizedWaypoints };

        // });
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement> | null, readyFile?: File) => {
        let file: null | File | undefined = null;

        if (readyFile) {
            if (readyFile.type === 'text/csv') {
                file = readyFile;
            } else {
                dispatch(setIsCsvLoadingAction(false));
                dispatchAlert('error', 'סוג הקובץ אינו תקין', true);

                return;
            }
        } else if (event) {
            file = event.target.files?.[0];
        }
        if (!file) {
            dispatch(setIsCsvLoadingAction(false));
            return;
        }

        Papa.parse(file, {
            complete: async (results) => {
                // Do something with the parsed data
                const header = results.meta.fields;
                const KEYS_AMOUNT = 5;

                // console.log('header', header);
                // console.log('data', results.data);

                if (header) {
                    const parsedHeaderItems = header.map((field) => parseHeaderItem(field));
                    // console.log('parsedHeaderItems', parsedHeaderItems);

                    if (
                        parsedHeaderItems.length < KEYS_AMOUNT ||
                        parsedHeaderItems[0] !== 'firstname' ||
                        parsedHeaderItems[1] !== 'lastname' ||
                        parsedHeaderItems[2] !== 'city' ||
                        parsedHeaderItems[3] !== 'street' ||
                        parsedHeaderItems[4] !== 'housenum'
                    ) {
                        dispatch(setIsCsvLoadingAction(false));
                        dispatchAlert('error', 'כותרת הקובץ אינה תקינה', true);

                        return;
                    }
                } else {
                    dispatch(setIsCsvLoadingAction(false));

                    dispatchAlert('error', 'כותרת הקובץ אינה תקינה', true);
                    return;
                }

                // Process the data

                const data = results.data as { [key in string]: string }[];

                if (data.length > 5000) {
                    dispatchAlert('error', 'ניתן לקלוט עד 5000 כתובות', true);
                    dispatch(setIsCsvLoadingAction(false));
                    return;
                }

                const dataWithoutUnesseseryKeys = filterObjectArray(data);
                const dataWithoutEmptyObjects = removeEmptyObjects(dataWithoutUnesseseryKeys);

                const waypointsObject = await dataValidation(
                    dataWithoutEmptyObjects as IOptimizationWaypoint[]
                );

                dispatchAlert('success', ' קובץ נקלט בהצלחה', true);
                dispatch(setIsCsvLoadingAction(false));

                dispatch(setWaypointsForOptiObject({ waypointsForOptiObject: waypointsObject }));

                if (
                    waypointsObject.unRecognizedWaypoints.length === 0 &&
                    waypointsObject.invalidWaypoints.length === 0
                ) {
                    dispatch(setStageAction({ stage: Stages.TargetSelection }));
                }
            },
            header: true, // Assumes the first row contains column headers
            encoding: 'windows-1255',
            transformHeader: (header: string) => header.trim().replace(/\s/g, '_'), // trim and replace spaces in headers
            skipEmptyLines: true,
        });
    };

    return {
        changeHandler,
    };
};

import React from 'react';
import { useIsFirstRender } from './useIsFirstRender';

export const useRunCbAfterTimeout = <T>({
    delay,
    cb,
    dep,
}: {
    delay: number;
    cb: (arg1?: any, arg2?: any) => T;
    dep: unknown;
}) => {
    const isFirstRender = useIsFirstRender();

    React.useEffect(() => {
        if (isFirstRender) return;

        const timeout = setTimeout(() => {
            cb();
        }, delay);

        return () => clearTimeout(timeout);
    }, [dep]);
};

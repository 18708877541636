import styled, { css } from 'styled-components';

const MapDiv = styled.div<{ isOpen: boolean }>`
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    width: 100%;
    z-index: 9;
    position: absolute;
    left: 0px;
    ${({ isOpen }) =>
        !isOpen &&
        css`
            min-width: 195px;
            width: 195px;
            min-height: 195px;
            height: 195px;
        `};
    transition: all 0.2s ease-in-out;
    border-radius: 1.5%;
    overflow: hidden;
`;

const Container = styled.div<{}>`
    width: 100%;
    position: relative;
    height: 100%;
    z-index: 9;
`;

const ExpandMapIcon = styled.button<{}>`
    background: none rgb(255, 255, 255);
    border: 0px;
    margin: 10px;
    padding: 2px 0px 0px 0px;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    overflow: hidden;
    top: 0px;
    left: 0px;
    :hover {
        background-color: #dddddd;
        transition: all 0.2s ease-in-out;
    }
`;

const imgUrl =
    'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%200v6h2V2h4V0H0zm16%200h-4v2h4v4h2V0h-2zm0%2016h-4v2h6v-6h-2v4zM2%2012H0v6h6v-2H2v-4z%22/%3E%3C/svg%3E';

const styles = {
    Container,
    MapDiv,
    ExpandMapIcon,
    imgUrl,
};

export default styles;

import { getExtractedWaypointsStations } from 'src/components/BtnsFooter/btnsFooterUtils';
import { getOptimizationWaypointsForSet } from 'src/store/helpers';
import { useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';
import { IOptimumWaypoint } from 'src/types/optimization/types';

export const useWaypointsForRunningOpti = () => {
    const waypointsForOptiObject = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.waypointsForOptiObject
    );
    const targetWaypoint = useAppSelector((state) => optimizationsRootSelector(state).ui.targetWaypoint);
    const stationToWayPoints = useAppSelector(
        (state) => optimizationsRootSelector(state).data.getStationsSimulationResult.data?.stationToWayPoints
    );

    const getWaypointsJsons = () => {
        if (waypointsForOptiObject && targetWaypoint) {
            const defaultWaypointsArr: IOptimumWaypoint[] = getOptimizationWaypointsForSet([
                ...waypointsForOptiObject.validWaypoints,
                targetWaypoint,
            ]);

            let waypointsJsonForRoutesByStationsOpti: IOptimumWaypoint[] | undefined = undefined;

            if (stationToWayPoints) {
                const waypointsArrByStationsToWaypoints = getExtractedWaypointsStations(
                    stationToWayPoints || []
                );
                const targetParsed = getOptimizationWaypointsForSet([targetWaypoint]);

                waypointsJsonForRoutesByStationsOpti = [
                    ...waypointsArrByStationsToWaypoints,
                    ...targetParsed,
                ];
            }

            return {
                defaultWaypointsArr,
                waypointsJsonForRoutesByStationsOpti,
            };
        }

        return {};
    };

    return {
        getWaypointsJsons,
    };
};

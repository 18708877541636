import React from 'react';
import styled from 'styled-components';
import { summaryStageSliceSelector } from 'src/store/slices/summaryStageSlice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { t } from 'src/utils/lang';
import { colors } from 'src/styles/defaultTheme';
import { toFixed } from 'src/utils/utilis';
import styles from './styles';
import { ChartBox } from './ChartBox';
import { addCommasToNumber, uuid } from 'src/store/helpers';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';
import BaseTooltip from 'src/components/BaseTooltip';

const StyledMapContainer = styled.div`
    width: 30%;
    display: flex;
    padding: 20px 0;
    justify-content: center;
    border: 1px solid #4dff00;
`;

const DataBox = ({
    mainText,
    subText,
    styleOverRide = {},
}: {
    mainText: string | undefined;
    subText: string;
    styleOverRide?: React.CSSProperties;
}) => {
    return (
        <div
            style={{
                ...styles.box,
                height: '80px',
                width: '25%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                // text overflow should go to next line
                wordBreak: 'break-word',
                ...styleOverRide,
            }}
        >
            <p style={{ fontWeight: 700, fontSize: '1.5rem' }}>{mainText}</p>
            <p style={{ fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>{subText}</p>
        </div>
    );
};

const TotalPriceBox = ({
    text,
    isStationFlow,
    isPDFOrigin,
}: {
    text: string;
    isStationFlow: boolean;
    isPDFOrigin: boolean;
}) => {
    const getBody = () => {
        return (
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <p style={{ fontWeight: 600, fontSize: '1rem', color: '#494949', textAlign: 'center' }}>
                    {t.totalOpPrice}
                </p>
                <p style={{ fontWeight: 700, fontSize: '1.8rem', margin: '12px 0 0 0' }}>{text}</p>
            </div>
        );
    };

    return (
        <div
            style={{
                ...styles.box,
                width: `${isStationFlow ? 'calc(50% + 32px)' : '100%'}`,
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
            }}
        >
            {isStationFlow ? <BaseTooltip title={t.linePriceDisclaimer}>{getBody()}</BaseTooltip> : getBody()}
            {isPDFOrigin && isStationFlow && (
                <p
                    style={{
                        position: 'absolute',
                        bottom: 3,
                        width: '100%',
                        fontWeight: 600,
                        fontSize: '0.8rem',
                        color: '#494949',
                        textAlign: 'center',
                    }}
                >
                    * {t.linePriceDisclaimer}
                </p>
            )}
        </div>
    );
};

const DataDisplay: React.FC<{
    isPDFOrigin?: boolean;
}> = ({ isPDFOrigin = false }) => {
    const dispatch = useAppDispatch();

    const routesStatistics = useAppSelector((state) => summaryStageSliceSelector(state).routesStatistics);
    const { hasStationsFlow } = useSelectedOptiFlowType();

    // console.log(routesStatistics);

    const statsToShow = [
        {
            subText: hasStationsFlow ? t.avgLinePrice : t.avgRoutePrice,
            mainText: `₪${addCommasToNumber(toFixed(routesStatistics?.avgRoutePrice, 0))}`,
        },
        {
            subText: t.pricePerPassenger,
            mainText: `₪${addCommasToNumber(toFixed(routesStatistics?.pricePerPassenger, 1))}`,
        },
        {
            subText: hasStationsFlow ? t.avgPassCountPerStation : t.avgRoutePassengersCount,
            mainText: `${toFixed(
                hasStationsFlow
                    ? routesStatistics?.avgPassCountPerStation
                    : routesStatistics?.avgRoutePassengersCount,
                1
            )}`,
        },
        {
            subText: t.totalPassengersCount,
            mainText: toFixed(routesStatistics?.totalPassengersCount, 0),
        },
    ];

    return (
        <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
            <div style={{ display: 'flex', width: '100%', gap: '16px' }}>
                <TotalPriceBox
                    isStationFlow={hasStationsFlow}
                    isPDFOrigin={isPDFOrigin}
                    text={`₪${
                        !routesStatistics ? 0 : addCommasToNumber(routesStatistics?.totalPrice.toFixed(0))
                    }`}
                />
                {hasStationsFlow ? (
                    <>
                        <DataBox
                            key={uuid()}
                            mainText={toFixed(routesStatistics?.avgStationsAmountInRoute, 1)}
                            subText={t.avgStationAmountInRoute}
                        />
                        <DataBox
                            key={uuid()}
                            mainText={toFixed(routesStatistics?.stationsAmount, 0)}
                            subText={t.stationAmount}
                        />
                    </>
                ) : null}
            </div>
            <div className="boxes" style={{ display: 'flex', gap: '16px' }}>
                {statsToShow.map((stat, i) => (
                    <DataBox key={uuid()} mainText={stat.mainText} subText={stat.subText} />
                ))}
            </div>
            {hasStationsFlow ? null : <ChartBox isPDFOrigin={isPDFOrigin} />}
        </div>
    );
};

export default DataDisplay;

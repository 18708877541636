import React, { useEffect } from 'react';
import NumberSlider from '../../ParametersStage/NumberSlider';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
    optimizationsRootSelector,
    setMaxTravelTimeAction,
    setVehicleCapacityAction,
} from 'src/store/slices/optimizationsSlice';
import CarOnRoadIcon from '../../Icons/CarOnRoadIcon';
import Loader from '../../Loader/Loader';
import { toFixed } from 'src/utils/utilis';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { isNumber, max } from 'lodash';
import { isStringNumber } from 'src/store/helpers';
import { StationsOptiParams } from '../../StationsOptiParams';
import { OptiFlowTypes } from 'src/constants/common';
import { PassCountSlider } from './PassCountSlider';
import { StationsCountSlider } from './StationsCountSlider';
import { useSelectedOptiFlowType } from 'src/hooks/useOptiFlowType';
import styled from 'styled-components';

export const MAX_TRAVEL_TIME_VALUE = 240;
export const MIN_TRAVEL_TIME_VALUE = 10;

export const MIN_VEHICLE_CAPACITY = 1;

export const MAXIMUM_MAX_WALK_DISTANCE_IN_METERS = 500;
export const MIN_MAX_WALK_DISTANCE_IN_METERS = 25;

export const MAX_STATIONS_COUNT = 100;
export const MIN_STATIONS_COUNT = 1;

const OptimizationParameters: React.FC = () => {
    const dispatch = useAppDispatch();
    const dispatchAlert = useDispatchAlert();
    const { hasStationsFlow } = useSelectedOptiFlowType();

    const { maxTravelTime, vehicleCapacity, maxTierTwoWalkDistance, maxWalkDistance } = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm
    );

    const displaySimulationCalcLoader = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.displaySimulationCalcLoader
    );

    const calculationPercentage = useAppSelector(
        (state) => optimizationsRootSelector(state).data.calculationPercentage
    );

    const maxVehicleCapacity = useAppSelector(
        (state) => optimizationsRootSelector(state).ui.parametersForm.maxVehicleCapacity
    );

    const isStationFlow = maxWalkDistance > 0 || maxTierTwoWalkDistance > 0;

    const handleMaxTravelTimeSliderChange = (event: Event, ltrVal: number | number[]) => {
        if (typeof ltrVal === 'number')
            dispatch(
                setMaxTravelTimeAction({
                    maxTravelTime: ltrVal,
                    maxValue: MAX_TRAVEL_TIME_VALUE,
                    minValue: MIN_TRAVEL_TIME_VALUE,
                })
            );
    };

    const handleMaxTravelTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isStringNumber(event.target.value)) return;

        dispatch(setMaxTravelTimeAction({ maxTravelTime: Number(event.target.value) }));
    };

    useEffect(() => {
        // input validation debounce
        const validateMaxTravelTime = () => {
            if (maxTravelTime < MIN_TRAVEL_TIME_VALUE) {
                dispatch(setMaxTravelTimeAction({ maxTravelTime: MIN_TRAVEL_TIME_VALUE }));
                dispatchAlert(
                    'error',
                    'זמן הנסיעה המינימלי הינו' + ' ' + MIN_TRAVEL_TIME_VALUE + ' ' + 'דקות',
                    true
                );
            }

            if (maxTravelTime > MAX_TRAVEL_TIME_VALUE) {
                dispatch(setMaxTravelTimeAction({ maxTravelTime: MAX_TRAVEL_TIME_VALUE }));
                dispatchAlert(
                    'error',
                    'זמן הנסיעה המקסימלי הינו' + ' ' + MAX_TRAVEL_TIME_VALUE + ' ' + 'דקות',
                    true
                );
            }
        };

        const timeout = setTimeout(validateMaxTravelTime, 500);
        return () => clearTimeout(timeout);
    }, [maxTravelTime]);

    useEffect(() => {
        const validateVehicleCapacity = () => {
            if (vehicleCapacity < MIN_VEHICLE_CAPACITY) {
                dispatch(setVehicleCapacityAction({ vehicleCapacity: MIN_VEHICLE_CAPACITY }));
                dispatchAlert('error', 'כמות הנוסעים המינימלית הינה' + ' ' + MIN_VEHICLE_CAPACITY, true);
            }

            if (vehicleCapacity > maxVehicleCapacity) {
                dispatch(setVehicleCapacityAction({ vehicleCapacity: maxVehicleCapacity }));
                dispatchAlert('error', 'כמות הנוסעים המקסימלית הינה ' + ' ' + maxVehicleCapacity, true);
            }
        };

        const timeout = setTimeout(validateVehicleCapacity, 500);
        return () => clearTimeout(timeout);
    }, [vehicleCapacity]);

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
            }}
        >
            {displaySimulationCalcLoader ? (
                <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    <Loader
                        percentage={
                            isStationFlow
                                ? +toFixed(calculationPercentage, 0)
                                : +toFixed(calculationPercentage, 0)
                        }
                    />
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                        width: '550px',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            fontSize: '36px',
                            fontWeight: 700,
                            color: '#494949',
                            marginTop: '40px',
                            marginBottom: '16px',
                        }}
                    >
                        הגדרת חוקי אופטימיזציה
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '20px',
                            width: '100%',
                            marginBottom: '3.5rem',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            <div>
                                <SliderLabelStyled>
                                    בחר את זמן הנסיעה המקסימלי שאתה מוכן לאפשר בנסיעה (בדקות)
                                </SliderLabelStyled>
                                <NumberSlider
                                    handleInputChange={handleMaxTravelTimeInputChange}
                                    handleSliderChange={handleMaxTravelTimeSliderChange}
                                    value={maxTravelTime}
                                    max={MAX_TRAVEL_TIME_VALUE}
                                    min={MIN_TRAVEL_TIME_VALUE}
                                    step={100}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                            {hasStationsFlow ? <StationsCountSlider /> : <PassCountSlider />}
                        </div>
                        {hasStationsFlow ? <StationsOptiParams /> : null}
                    </div>
                    <CarOnRoadIcon />
                </div>
            )}
        </div>
    );
};
export default OptimizationParameters;

export const SliderLabelStyled = styled.div<{}>`
    font-size: 14px;
    font-weight: 400;
    color: #242424;
    margin-bottom: 10px;
`;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '..';
import { CarType } from 'src/components/stages/Summary/types';
import { uuid } from '../helpers';
import { getCarsForPricingFromLocalStorage } from 'src/utils/utilis';

export type CarInputItem = {
    inputId: string;
} & CarType;

export interface CarsStageSliceState {
    carsForPricingInputsItems: CarInputItem[];
}
const INITIAL_CAR_INPUT_ITEM: CarInputItem = {
    carId: '',
    capacity: 0,
    pricePerKm: 0,
    pricePerHour: 0,
    carName: '',
    inputId: '',
};

export const createInitialCarInputItem = (): CarInputItem => ({ ...INITIAL_CAR_INPUT_ITEM, inputId: uuid() });

const carsForPricingFromLocalStorage = getCarsForPricingFromLocalStorage();

const initialState: CarsStageSliceState = {
    carsForPricingInputsItems: carsForPricingFromLocalStorage || [{ ...createInitialCarInputItem() }],
};

export const carsStageSlice = createSlice({
    name: 'carsStage',
    initialState,
    reducers: {
        resetCarsStageSlice: (state) => initialState,
        setCarInputsValues: (state, action: PayloadAction<CarInputItem[]>) => {
            state.carsForPricingInputsItems = action.payload;
        },
        removeCarInputItemByInputId: (state, action: PayloadAction<string>) => {
            state.carsForPricingInputsItems = state.carsForPricingInputsItems.filter(
                (item) => item.inputId !== action.payload
            );
        },
    },
});

export const { setCarInputsValues, removeCarInputItemByInputId } = carsStageSlice.actions;
export const carsStageActions = carsStageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const carsStageSliceSelector = (state: RootState) => state.carsStage;

export default carsStageSlice.reducer;

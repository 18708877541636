import { OptiFlowTypes } from 'src/constants/common';
import { useAppSelector } from 'src/store/hooks';
import { optimizationsRootSelector } from 'src/store/slices/optimizationsSlice';

export const useSelectedOptiFlowType = () => {
    const optiFlowType = useAppSelector((state) => optimizationsRootSelector(state).ui.selectedOptiFlowType);

    return {
        optiFlowType,
        hasStationsFlow: optiFlowType === OptiFlowTypes.WithStations,
        isRoutesOnlyFlow: optiFlowType === OptiFlowTypes.RoutesOnlyCreation,
    };
};
